/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */

import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector, connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setIsLoading } from "../../../slices/mapSlice"
import { setAcledCountryArr, setAcledLegend, setInfoEventTypeNum } from "../../../slices/toc/acledSlice"
import { acledSelectedFun } from './SelectedFun'
import conversionFun from '../../../utils/conversionFun'
import coverSubEventType from '../../../utils/SubEventType'
import useCountry from '../../../hooks/useCountry'
import useFly from '../../../hooks/useFly'
import useMap from '../../../hooks/useMap'
import TocUtils from '../../../utils/toc'

const { AcledUtil } = TocUtils;

const AcledMap = (props) => {
  const {
    uri
  } = props
  const acledClass = useSelector(state => state.acled.acledClass)
  const chooseId = useSelector(state => state.acled.chooseId)
  const acledSwitch = useSelector(state => state.acled.acledSwitch)
  const acledEvent = useSelector(state => state.acled.acledEvent)
  const acledStartYear = useSelector(state => state.acled.acledStartYear)
  const acledEndYear = useSelector(state => state.acled.acledEndYear)
  const acledSelectedCountries = useSelector(state => state.acled.acledCountryArr)
  const acled_selected = useSelector(state => state.toc.tocLayer) === 'ACLED'
  const acledEventType = useSelector(state => state.acled.acledEventType)
  const acledCheckedAll = useSelector(state => state.acled.acledCheckedAll)
  const [acledYMAllData, setAcledYMAllData] = useState({})
  const [acledCountry, setAcledCountry] = useState({})
  const [acledData, setAcledData] = useState({})
  const [blendFilter, setBlendFilter] = useState('')
  const node = useRef(null)
  const dispatch = useDispatch()
  const { paintLayers } = useCountry(true)
  // const { flyToCountry } = useFly()
  const [ map ] = useMap({ 
    node, 
    uri, 
    onSelectCountry:(country => { 
      if(!acledSwitch) { 
        dispatch(setAcledCountryArr(country)) 
      } 
    }),
    enableHover: !acledSwitch,
    enableClickCountry: !acledSwitch
  })
  const API_BASE = process.env.REACT_APP_API_URL

  //Load acled country layer data
  useEffect(() => {
    fetch(`${API_BASE}/arcgis/queryACLEDCountryLayer`)
      .then(response => response.json())
      .then(res => {
        setAcledCountry(res)
      })
  }, [])

  //Fly to country
  //disabled now, since it's not very user friendly
  // useEffect(() => {
  //   if (map && selectedCountry) {
  //     !acledSwitch && flyToCountry(map, selectedCountry)
  //   }
  // }, [map, selectedCountry, acledSwitch])

  useEffect(() => {
    if (acledCheckedAll) {
      let whereStr = ''
      let outFields = ''
      let newAcledEventType = ''
      let newAcledSubEventType = ''
      if (acledEventType !== 'Violence') {
        newAcledEventType = `E${chooseId}_${acledEventType.replace(/\s*/g, "")}`
      } else {
        newAcledEventType = `E${chooseId}_${acledEventType}againstcivilians`
      }
      if (acledClass.types) {
        acledClass.types.forEach(item => {
          newAcledSubEventType += `,sum(${coverSubEventType(item.id)}) as ${coverSubEventType(item.id)}`
        })
        const { startYear, endYear, startMonth, endMonth } = conversionFun(acledStartYear, acledEndYear)
        if (startYear !== endYear) {
          whereStr = `((YEAR=${startYear} and MONTHNR>=${startMonth}) or (YEAR>${startYear} and YEAR<${endYear}) or (YEAR=${endYear} and MONTHNR<=${endMonth}))`
        } else {
          whereStr = `((YEAR=${startYear} and MONTHNR>=${startMonth}) and (YEAR=${endYear} and MONTHNR<=${endMonth}))`
        }
        outFields = `SOVEREIGNT,sum(${newAcledEventType}) as event_type_total ${newAcledSubEventType}`
        fetch(`${API_BASE}/arcgis/queryACLEDCountryAPI/${whereStr}/${outFields}/sum(${newAcledEventType})`)
          .then(response => response.json())
          .then(res => {
            const { newArr, newBlendArr } = AcledUtil.filterColor(res)
            dispatch(setAcledLegend(newArr))
            setBlendFilter(newBlendArr)
            // dispatch(setAcledMax(res.features[res.features.length-1].properties.event_type_total))
            setAcledYMAllData(res)
            dispatch(setInfoEventTypeNum(res))
          })
      }
    }else if(acledYMAllData && acledYMAllData.features){
      const newFeatures = acledYMAllData.features.map(item=>{
        let num = 0 
        acledClass.types.forEach(v=>{
          num += v.checked ? item.properties[coverSubEventType(v.id)]:0
        })
        const newProperties = {...item.properties,event_type_total:num}
        return {
          ...item,
          properties:newProperties
        }
      })
      const res = {...acledYMAllData,features:newFeatures}
      setAcledYMAllData(res)
      const { newArr, newBlendArr } = AcledUtil.filterColor(res)
      setBlendFilter(newBlendArr)
      dispatch(setAcledLegend(newArr))
      dispatch(setInfoEventTypeNum(res))
    }
  }, [acledStartYear, acledEndYear, acledEventType, acledCheckedAll, acledClass])

  useEffect(() => {
    if (acledYMAllData.features && acledCountry.features) {
      const newTotal = acledYMAllData.features.map(item => {
        let newArr = {}
        acledCountry.features.forEach(v => {
          if (v.properties.SOVEREIGNT == item.properties.SOVEREIGNT) {
            newArr = v.geometry
          }
        })
        return {
          ...item, geometry: newArr
        }
      })
      setAcledData({ ...acledYMAllData, features: newTotal })
    }
  }, [acledYMAllData, acledCountry])

  //Paint border for selected countries
  useEffect(()=>{
    if(!map){
      return
    }
    paintLayers(map, acledSelectedCountries)
  }, [map, acledSelectedCountries])

  const addAcledCountryLayer = (upAcledData, acledSwitch) => {
    if(!upAcledData || Object.keys(upAcledData).length === 0){
      return
    }
    // //Add
    if(!map.getSource('acled_source')){
      map.addSource('acled_source', {
        'type': 'geojson',
        'data': upAcledData
      })
    }else{
      map.getSource('acled_source').setData(upAcledData)
    }
    map.addLayer({
      'id': 'acled_layer',
      'type': 'fill',
      'source': 'acled_source',
      'paint': {
        "fill-color": blendFilter || 'rgb(110,110,110)',
        // "fill-color": [
        //   'step',
        //   ['get', 'event_type_total'],
        //   "#F4F535", 154,
        //   "#ECD37E", 688,
        //   "#F59126", 1967,
        //   "#F46420", 4377,
        //   "#F2331C", 14612,
        //   "#EF0C1A"
        // ],
        "fill-outline-color": "rgb(110,110,110)"
      },
      'filter': ['==', '$type', 'Polygon']
    }, 'country-label')
    
  }
 
  const clearTocLayer = () => {
    const mapLayers = map.getStyle().layers
    if (mapLayers.some(value => value.id == 'acled_layer')) {
      map.removeLayer('acled_layer')
    }
  }

  useEffect(() => {
    if (map && acled_selected && !acledSwitch) {
      clearTocLayer()
      addAcledCountryLayer(acledData, acledSwitch)
    }
  }, [map, acled_selected, acledSwitch, acledData])

  useEffect(() => {
    if (map && acledSwitch) {
      acled_selected && acledSelectedFun(map, acledEvent, acledSwitch, acledClass, chooseId, acledStartYear, acledEndYear, clearTocLayer)
    }
  }, [map, acled_selected, acledSwitch, acledClass, acledStartYear, acledEndYear])

  return (
    <div ref={node} className='mapContainer' />
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setIsLoading,
  }, dispatch)
}

function mapStateToProps(state) {
  return {
    selectedCountry: state.filters.countryFilter,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AcledMap)
