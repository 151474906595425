/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { createSlice } from '@reduxjs/toolkit'
const tocSlice = createSlice({
  name: 'toc',
  initialState: {
    tocLayer: 'ACLED' //ACLED, SIPRI, GTD
  },
  reducers: {
    setTocLayer(state, action) {
      state.tocLayer = action.payload
    }
  }
})

export const {
  setTocLayer
} = tocSlice.actions;

export default tocSlice.reducer;