import { useState, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import { useDispatch } from 'react-redux'
import { countryCentroids } from '../utils/CountryLookups'
import { setIsLoading } from "../slices/mapSlice"
import { setUpdateInfograph } from "../slices/infographSlice"
import { setCountryList, setCountryFilter } from "../slices/filtersSlice"
import { setOpenLeftPanel,setOpenRightPanel } from '../slices/infographSlice'

const API_BASE = process.env.REACT_APP_API_URL
const Mapbox_Access_Token = process.env.REACT_APP_MAPBOX_ACCESS_KEY
const Country_URL = `${API_BASE}/arcgis/queryArcGISAPI`
const DefaultSourceId = 'ago_country'

const useMap = (options) => {
  const { node, uri, onSelectCountry, enableHover, enableClickCountry } = options 
  const [ map, setMap ] = useState()
  const [ sourceData, setSourceData ] = useState()
  const dispatch = useDispatch()


  useEffect(() => {
    fetch(Country_URL)
      .then(res => res.json())
      .then(res => {
        setSourceData(res)
      })
  }, [])

  useEffect(() => {
    if(!node || !sourceData || Object.keys(sourceData).length === 0){
      return
    }
    mapboxgl.accessToken = Mapbox_Access_Token
    const mapIns = new mapboxgl.Map({
      container: node.current,
      style: 'mapbox://styles/fiu-srh/' + uri, // style to init the map with
      center: [-79.019, 35.759],
      zoom: 1
    })

    mapIns.on("load", () => {
      setMap(mapIns)

      !mapIns.getSource(DefaultSourceId) && mapIns.addSource(DefaultSourceId, {
        'type': 'geojson',
        'data': sourceData
      })

      mapIns.on('sourcedata', (e) => { 
        if (e.isSourceLoaded) {
          const country_labels = Object.values(countryCentroids).map((el) => {
            return el.name
          })
          dispatch(setCountryList(country_labels))
          // dispatch(setIsLoading(false))
        }
      })

      mapIns.on('mousemove', 'country-helper-layer', (e) => {
        mapIns.getCanvas().style.cursor = 'pointer'
        if(enableHover){
          const feature = e.features[0]      
          mapIns.getLayer('country-hovered') && mapIns.setFilter('country-hovered', 
            ['in', 'COUNTRY_CODE', feature.properties.COUNTRY_CODE || feature.properties.SOVEREIGNT])
        }
      })
  
      mapIns.on('mouseleave', 'country-helper-layer', () => {
        mapIns.getLayer('country-hovered') && mapIns.setFilter('country-hovered', ['in', 'COUNTRY_CODE', ''])
      })

      if(enableClickCountry){
        mapIns.on('click', 'country-helper-layer', (e) => {
          const features = mapIns.queryRenderedFeatures([e.point.x, e.point.y], {
            layers: ['country-helper-layer']
          })
          if (features[0] !== undefined) {
            const feature = features[0]
            const countryNE = feature.properties.COUNTRY_NE
            dispatch(setCountryFilter(countryNE))
            dispatch(setUpdateInfograph(true))
            dispatch(setOpenLeftPanel(true))
            onSelectCountry && onSelectCountry(countryNE)
          }
        })
      }

      mapIns.on('idle', () => {
        dispatch(setIsLoading({ isLoading: false }))
      });
  
      //hover layer
      if(!mapIns.getLayer('country-hovered')){
        mapIns.addLayer({
          'id': 'country-hovered',
          'type': 'line',
          'source': 'composite',
          'source-layer': 'sevurity_iuuf_ss',
          'filter': ['in', 'COUNTRY_CODE', ''],
          'paint': {
            'line-color': 'yellow',
            'line-width': 2
          }
        })
      }
    })

  }, [node, uri, sourceData, enableHover, enableClickCountry])

  return [ 
    map
  ]
}

export default useMap