//ref: https://github.com/Stefie/geojson-world

export const Capitals = 
{
  "type": "FeatureCollection",
  "features": [{
      "properties": {
          "country": "Bangladesh",
          "city": "Dhaka",
          "tld": "bd",
          "iso3": "BGD",
          "iso2": "BD"
      },
      "geometry": {
          "coordinates": [90.24, 23.43],
          "type": "Point"
      },
      "id": "BD"
  }, {
      "properties": {
          "country": "Belgium",
          "city": "Brussels",
          "tld": "be",
          "iso3": "BEL",
          "iso2": "BE"
      },
      "geometry": {
          "coordinates": [4.2, 50.5],
          "type": "Point"
      },
      "id": "BE"
  }, {
      "properties": {
          "country": "Burkina Faso",
          "city": "Ouagadougou",
          "tld": "bf",
          "iso3": "BFA",
          "iso2": "BF"
      },
      "geometry": {
          "coordinates": [-1.31, 12.22],
          "type": "Point"
      },
      "id": "BF"
  }, {
      "properties": {
          "country": "Bulgaria",
          "city": "Sofia",
          "tld": "bg",
          "iso3": "BGR",
          "iso2": "BG"
      },
      "geometry": {
          "coordinates": [23.19, 42.41],
          "type": "Point"
      },
      "id": "BG"
  }, {
      "properties": {
          "country": "Bosnia and Herzegovina",
          "city": "Sarajevo",
          "tld": "ba",
          "iso3": "BIH",
          "iso2": "BA"
      },
      "geometry": {
          "coordinates": [18.25, 43.52],
          "type": "Point"
      },
      "id": "BA"
  }, {
      "properties": {
          "country": "Barbados",
          "city": "Bridgetown",
          "tld": "bb",
          "iso3": "BRB",
          "iso2": "BB"
      },
      "geometry": {
          "coordinates": [-59.37, 13.06],
          "type": "Point"
      },
      "id": "BB"
  }, {
      "properties": {
          "country": "Wallis and Futuna",
          "city": "Mata-Utu",
          "tld": "wf",
          "iso3": "WLF",
          "iso2": "WF"
      },
      "geometry": {
          "coordinates": [-171.56, -13.57],
          "type": "Point"
      },
      "id": "WF"
  }, {
      "properties": {
          "country": "Saint Barthelemy",
          "city": "Gustavia",
          "tld": "bl",
          "iso3": "BLM",
          "iso2": "BL"
      },
      "geometry": {
          "coordinates": [-62.51, 17.53],
          "type": "Point"
      },
      "id": "BL"
  }, {
      "properties": {
          "country": "Bermuda",
          "city": "Hamilton",
          "tld": "bm",
          "iso3": "BMU",
          "iso2": "BM"
      },
      "geometry": {
          "coordinates": [-64.47, 32.17],
          "type": "Point"
      },
      "id": "BM"
  }, {
      "properties": {
          "country": "Brunei",
          "city": "Bandar Seri Begawan",
          "tld": "bn",
          "iso3": "BRN",
          "iso2": "BN"
      },
      "geometry": {
          "coordinates": [114.56, 4.53],
          "type": "Point"
      },
      "id": "BN"
  }, {
      "properties": {
          "country": "Bolivia",
          "city": "La Paz",
          "tld": "bo",
          "iso3": "BOL",
          "iso2": "BO"
      },
      "geometry": {
          "coordinates": [-68.09, -16.3],
          "type": "Point"
      },
      "id": "BO"
  }, {
      "properties": {
          "country": "Bahrain",
          "city": "Manama",
          "tld": "bh",
          "iso3": "BHR",
          "iso2": "BH"
      },
      "geometry": {
          "coordinates": [50.34, 26.14],
          "type": "Point"
      },
      "id": "BH"
  }, {
      "properties": {
          "country": "Burundi",
          "city": "Bujumbura",
          "tld": "bi",
          "iso3": "BDI",
          "iso2": "BI"
      },
      "geometry": {
          "coordinates": [29.21, -3.22],
          "type": "Point"
      },
      "id": "BI"
  }, {
      "properties": {
          "country": "Benin",
          "city": "Porto-Novo",
          "tld": "bj",
          "iso3": "BEN",
          "iso2": "BJ"
      },
      "geometry": {
          "coordinates": [2.37, 6.29],
          "type": "Point"
      },
      "id": "BJ"
  }, {
      "properties": {
          "country": "Bhutan",
          "city": "Thimphu",
          "tld": "bt",
          "iso3": "BTN",
          "iso2": "BT"
      },
      "geometry": {
          "coordinates": [89.36, 27.29],
          "type": "Point"
      },
      "id": "BT"
  }, {
      "properties": {
          "country": "Jamaica",
          "city": "Kingston",
          "tld": "jm",
          "iso3": "JAM",
          "iso2": "JM"
      },
      "geometry": {
          "coordinates": [-76.48, 18],
          "type": "Point"
      },
      "id": "JM"
  }, {
      "properties": {
          "country": "Bouvet Island",
          "tld": "bv",
          "iso3": "BVT",
          "iso2": "BV"
      },
      "geometry": {
          "coordinates": [3.24, -54.26],
          "type": "Point"
      },
      "id": "BV"
  }, {
      "properties": {
          "country": "Botswana",
          "city": "Gaborone",
          "tld": "bw",
          "iso3": "BWA",
          "iso2": "BW"
      },
      "geometry": {
          "coordinates": [25.55, -24.45],
          "type": "Point"
      },
      "id": "BW"
  }, {
      "properties": {
          "country": "Samoa",
          "city": "Apia",
          "tld": "ws",
          "iso3": "WSM",
          "iso2": "WS"
      },
      "geometry": {
          "coordinates": [-171.44, -13.5],
          "type": "Point"
      },
      "id": "WS"
  }, {
      "properties": {
          "country": "Brazil",
          "city": "Brasilia",
          "tld": "br",
          "iso3": "BRA",
          "iso2": "BR"
      },
      "geometry": {
          "coordinates": [-47.55, -15.47],
          "type": "Point"
      },
      "id": "BR"
  }, {
      "properties": {
          "country": "Bahamas",
          "city": "Nassau",
          "tld": "bs",
          "iso3": "BHS",
          "iso2": "BS"
      },
      "geometry": {
          "coordinates": [-77.21, 25.05],
          "type": "Point"
      },
      "id": "BS"
  }, {
      "properties": {
          "country": "Jersey",
          "city": "Saint Helier",
          "tld": "je",
          "iso3": "JEY",
          "iso2": "JE"
      },
      "geometry": {
          "coordinates": [-2.06, 49.11],
          "type": "Point"
      },
      "id": "JE"
  }, {
      "properties": {
          "country": "Belarus",
          "city": "Minsk",
          "tld": "by",
          "iso3": "BLR",
          "iso2": "BY"
      },
      "geometry": {
          "coordinates": [27.34, 53.54],
          "type": "Point"
      },
      "id": "BY"
  }, {
      "properties": {
          "country": "Belize",
          "city": "Belmopan",
          "tld": "bz",
          "iso3": "BLZ",
          "iso2": "BZ"
      },
      "geometry": {
          "coordinates": [-88.46, 17.15],
          "type": "Point"
      },
      "id": "BZ"
  }, {
      "properties": {
          "country": "Russia",
          "city": "Moscow",
          "tld": "ru",
          "iso3": "RUS",
          "iso2": "RU"
      },
      "geometry": {
          "coordinates": [37.35, 55.45],
          "type": "Point"
      },
      "id": "RU"
  }, {
      "properties": {
          "country": "Rwanda",
          "city": "Kigali",
          "tld": "rw",
          "iso3": "RWA",
          "iso2": "RW"
      },
      "geometry": {
          "coordinates": [30.04, -1.57],
          "type": "Point"
      },
      "id": "RW"
  }, {
      "properties": {
          "country": "Serbia",
          "city": "Belgrade",
          "tld": "rs",
          "iso3": "SRB",
          "iso2": "RS"
      },
      "geometry": {
          "coordinates": [20.3, 44.5],
          "type": "Point"
      },
      "id": "RS"
  }, {
      "properties": {
          "country": "Timor-Leste",
          "city": "Dili",
          "tld": "tl",
          "iso3": "TLS",
          "iso2": "TL"
      },
      "geometry": {
          "coordinates": [125.36, -8.35],
          "type": "Point"
      },
      "id": "TL"
  }, {
      "properties": {
          "country": "Turkmenistan",
          "city": "Ashgabat",
          "tld": "tm",
          "iso3": "TKM",
          "iso2": "TM"
      },
      "geometry": {
          "coordinates": [58.23, 37.57],
          "type": "Point"
      },
      "id": "TM"
  }, {
      "properties": {
          "country": "Tajikistan",
          "city": "Dushanbe",
          "tld": "tj",
          "iso3": "TJK",
          "iso2": "TJ"
      },
      "geometry": {
          "coordinates": [68.48, 38.35],
          "type": "Point"
      },
      "id": "TJ"
  }, {
      "properties": {
          "country": "Romania",
          "city": "Bucharest",
          "tld": "ro",
          "iso3": "ROU",
          "iso2": "RO"
      },
      "geometry": {
          "coordinates": [26.06, 44.26],
          "type": "Point"
      },
      "id": "RO"
  }, {
      "properties": {
          "country": "Tokelau",
          "tld": "tk",
          "iso3": "TKL",
          "iso2": "TK"
      },
      "geometry": {
          "coordinates": [-172, -9],
          "type": "Point"
      },
      "id": "TK"
  }, {
      "properties": {
          "country": "Guinea-Bissau",
          "city": "Bissau",
          "tld": "gw",
          "iso3": "GNB",
          "iso2": "GW"
      },
      "geometry": {
          "coordinates": [-15.35, 11.51],
          "type": "Point"
      },
      "id": "GW"
  }, {
      "properties": {
          "country": "Guam",
          "city": "Hagatna",
          "tld": "gu",
          "iso3": "GUM",
          "iso2": "GU"
      },
      "geometry": {
          "coordinates": [144.44, 13.28],
          "type": "Point"
      },
      "id": "GU"
  }, {
      "properties": {
          "country": "Guatemala",
          "city": "Guatemala City",
          "tld": "gt",
          "iso3": "GTM",
          "iso2": "GT"
      },
      "geometry": {
          "coordinates": [-90.31, 14.37],
          "type": "Point"
      },
      "id": "GT"
  }, {
      "properties": {
          "country": "Greece",
          "city": "Athens",
          "tld": "gr",
          "iso3": "GRC",
          "iso2": "GR"
      },
      "geometry": {
          "coordinates": [23.44, 37.59],
          "type": "Point"
      },
      "id": "GR"
  }, {
      "properties": {
          "country": "Equatorial Guinea",
          "city": "Malabo",
          "tld": "gq",
          "iso3": "GNQ",
          "iso2": "GQ"
      },
      "geometry": {
          "coordinates": [8.47, 3.45],
          "type": "Point"
      },
      "id": "GQ"
  }, {
      "properties": {
          "country": "Japan",
          "city": "Tokyo",
          "tld": "jp",
          "iso3": "JPN",
          "iso2": "JP"
      },
      "geometry": {
          "coordinates": [139.45, 35.41],
          "type": "Point"
      },
      "id": "JP"
  }, {
      "properties": {
          "country": "Guyana",
          "city": "Georgetown",
          "tld": "gy",
          "iso3": "GUY",
          "iso2": "GY"
      },
      "geometry": {
          "coordinates": [-58.1, 6.48],
          "type": "Point"
      },
      "id": "GY"
  }, {
      "properties": {
          "country": "Guernsey",
          "city": "Saint Peter Port",
          "tld": "gg",
          "iso3": "GGY",
          "iso2": "GG"
      },
      "geometry": {
          "coordinates": [-2.32, 49.27],
          "type": "Point"
      },
      "id": "GG"
  }, {
      "properties": {
          "country": "Georgia",
          "city": "T'bilisi",
          "tld": "ge",
          "iso3": "GEO",
          "iso2": "GE"
      },
      "geometry": {
          "coordinates": [44.47, 41.43],
          "type": "Point"
      },
      "id": "GE"
  }, {
      "properties": {
          "country": "Grenada",
          "city": "Saint George's",
          "tld": "gd",
          "iso3": "GRD",
          "iso2": "GD"
      },
      "geometry": {
          "coordinates": [-61.45, 12.03],
          "type": "Point"
      },
      "id": "GD"
  }, {
      "properties": {
          "country": "United Kingdom",
          "city": "London",
          "tld": "uk",
          "iso3": "GBR",
          "iso2": "GB"
      },
      "geometry": {
          "coordinates": [-0.1, 51.3],
          "type": "Point"
      },
      "id": "GB"
  }, {
      "properties": {
          "country": "Gabon",
          "city": "Libreville",
          "tld": "ga",
          "iso3": "GAB",
          "iso2": "GA"
      },
      "geometry": {
          "coordinates": [9.27, 0.23],
          "type": "Point"
      },
      "id": "GA"
  }, {
      "properties": {
          "country": "El Salvador",
          "city": "San Salvador",
          "tld": "sv",
          "iso3": "SLV",
          "iso2": "SV"
      },
      "geometry": {
          "coordinates": [-89.12, 13.42],
          "type": "Point"
      },
      "id": "SV"
  }, {
      "properties": {
          "country": "Guinea",
          "city": "Conakry",
          "tld": "gn",
          "iso3": "GIN",
          "iso2": "GN"
      },
      "geometry": {
          "coordinates": [-13.42, 9.33],
          "type": "Point"
      },
      "id": "GN"
  }, {
      "properties": {
          "country": "Gambia",
          "city": "Banjul",
          "tld": "gm",
          "iso3": "GMB",
          "iso2": "GM"
      },
      "geometry": {
          "coordinates": [-16.34, 13.27],
          "type": "Point"
      },
      "id": "GM"
  }, {
      "properties": {
          "country": "Greenland",
          "city": "Nuuk",
          "tld": "gl",
          "iso3": "GRL",
          "iso2": "GL"
      },
      "geometry": {
          "coordinates": [-51.45, 64.11],
          "type": "Point"
      },
      "id": "GL"
  }, {
      "properties": {
          "country": "Gibraltar",
          "city": "Gibraltar",
          "tld": "gi",
          "iso3": "GIB",
          "iso2": "GI"
      },
      "geometry": {
          "coordinates": [-5.21, 36.08],
          "type": "Point"
      },
      "id": "GI"
  }, {
      "properties": {
          "country": "Ghana",
          "city": "Accra",
          "tld": "gh",
          "iso3": "GHA",
          "iso2": "GH"
      },
      "geometry": {
          "coordinates": [-0.13, 5.33],
          "type": "Point"
      },
      "id": "GH"
  }, {
      "properties": {
          "country": "Oman",
          "city": "Muscat",
          "tld": "om",
          "iso3": "OMN",
          "iso2": "OM"
      },
      "geometry": {
          "coordinates": [58.35, 23.37],
          "type": "Point"
      },
      "id": "OM"
  }, {
      "properties": {
          "country": "Tunisia",
          "city": "Tunis",
          "tld": "tn",
          "iso3": "TUN",
          "iso2": "TN"
      },
      "geometry": {
          "coordinates": [10.11, 36.48],
          "type": "Point"
      },
      "id": "TN"
  }, {
      "properties": {
          "country": "Jordan",
          "city": "Amman",
          "tld": "jo",
          "iso3": "JOR",
          "iso2": "JO"
      },
      "geometry": {
          "coordinates": [35.56, 31.57],
          "type": "Point"
      },
      "id": "JO"
  }, {
      "properties": {
          "country": "Croatia",
          "city": "Zagreb",
          "tld": "hr",
          "iso3": "HRV",
          "iso2": "HR"
      },
      "geometry": {
          "coordinates": [16, 45.48],
          "type": "Point"
      },
      "id": "HR"
  }, {
      "properties": {
          "country": "Haiti",
          "city": "Port-au-Prince",
          "tld": "ht",
          "iso3": "HTI",
          "iso2": "HT"
      },
      "geometry": {
          "coordinates": [-72.2, 18.32],
          "type": "Point"
      },
      "id": "HT"
  }, {
      "properties": {
          "country": "Hungary",
          "city": "Budapest",
          "tld": "hu",
          "iso3": "HUN",
          "iso2": "HU"
      },
      "geometry": {
          "coordinates": [19.05, 47.3],
          "type": "Point"
      },
      "id": "HU"
  }, {
      "properties": {
          "country": "Hong Kong",
          "city": "Hong Kong",
          "tld": "hk",
          "iso3": "HKG",
          "iso2": "HK"
      },
      "geometry": {
          "coordinates": [114.1, 22.15],
          "type": "Point"
      },
      "id": "HK"
  }, {
      "properties": {
          "country": "Honduras",
          "city": "Tegucigalpa",
          "tld": "hn",
          "iso3": "HND",
          "iso2": "HN"
      },
      "geometry": {
          "coordinates": [-87.13, 14.06],
          "type": "Point"
      },
      "id": "HN"
  }, {
      "properties": {
          "country": "Heard Island and McDonald Islands",
          "tld": "hm",
          "iso3": "HMD",
          "iso2": "HM"
      },
      "geometry": {
          "coordinates": [72.31, -53.06],
          "type": "Point"
      },
      "id": "HM"
  }, {
      "properties": {
          "country": "Venezuela",
          "city": "Caracas",
          "tld": "ve",
          "iso3": "VEN",
          "iso2": "VE"
      },
      "geometry": {
          "coordinates": [-66.56, 10.3],
          "type": "Point"
      },
      "id": "VE"
  }, {
      "properties": {
          "country": "Puerto Rico",
          "city": "San Juan",
          "tld": "pr",
          "iso3": "PRI",
          "iso2": "PR"
      },
      "geometry": {
          "coordinates": [-66.07, 18.28],
          "type": "Point"
      },
      "id": "PR"
  }, {
      "properties": {
          "country": "Palestinian Territory",
          "tld": "ps",
          "iso3": "PSE",
          "iso2": "PS"
      },
      "geometry": {
          "coordinates": [34.2, 31.25],
          "type": "Point"
      },
      "id": "PS"
  }, {
      "properties": {
          "country": "Palau",
          "city": "Melekeok",
          "tld": "pw",
          "iso3": "PLW",
          "iso2": "PW"
      },
      "geometry": {
          "coordinates": [134.38, 7.29],
          "type": "Point"
      },
      "id": "PW"
  }, {
      "properties": {
          "country": "Portugal",
          "city": "Lisbon",
          "tld": "pt",
          "iso3": "PRT",
          "iso2": "PT"
      },
      "geometry": {
          "coordinates": [-9.08, 38.43],
          "type": "Point"
      },
      "id": "PT"
  }, {
      "properties": {
          "country": "Svalbard",
          "city": "Longyearbyen",
          "tld": "sj",
          "iso3": "SJM",
          "iso2": "SJ"
      },
      "geometry": {
          "coordinates": [15.33, 78.13],
          "type": "Point"
      },
      "id": "SJ"
  }, {
      "properties": {
          "country": "Paraguay",
          "city": "Asuncion",
          "tld": "py",
          "iso3": "PRY",
          "iso2": "PY"
      },
      "geometry": {
          "coordinates": [-57.4, -25.16],
          "type": "Point"
      },
      "id": "PY"
  }, {
      "properties": {
          "country": "Iraq",
          "city": "Baghdad",
          "tld": "iq",
          "iso3": "IRQ",
          "iso2": "IQ"
      },
      "geometry": {
          "coordinates": [44.23, 33.2],
          "type": "Point"
      },
      "id": "IQ"
  }, {
      "properties": {
          "country": "Panama",
          "city": "Panama City",
          "tld": "pa",
          "iso3": "PAN",
          "iso2": "PA"
      },
      "geometry": {
          "coordinates": [-79.32, 8.58],
          "type": "Point"
      },
      "id": "PA"
  }, {
      "properties": {
          "country": "French Polynesia",
          "city": "Papeete",
          "tld": "pf",
          "iso3": "PYF",
          "iso2": "PF"
      },
      "geometry": {
          "coordinates": [-149.34, -17.32],
          "type": "Point"
      },
      "id": "PF"
  }, {
      "properties": {
          "country": "Papua New Guinea",
          "city": "Port Moresby",
          "tld": "pg",
          "iso3": "PNG",
          "iso2": "PG"
      },
      "geometry": {
          "coordinates": [147.1, -9.3],
          "type": "Point"
      },
      "id": "PG"
  }, {
      "properties": {
          "country": "Peru",
          "city": "Lima",
          "tld": "pe",
          "iso3": "PER",
          "iso2": "PE"
      },
      "geometry": {
          "coordinates": [-77.03, -12.03],
          "type": "Point"
      },
      "id": "PE"
  }, {
      "properties": {
          "country": "Pakistan",
          "city": "Islamabad",
          "tld": "pk",
          "iso3": "PAK",
          "iso2": "PK"
      },
      "geometry": {
          "coordinates": [73.1, 33.42],
          "type": "Point"
      },
      "id": "PK"
  }, {
      "properties": {
          "country": "Philippines",
          "city": "Manila",
          "tld": "ph",
          "iso3": "PHL",
          "iso2": "PH"
      },
      "geometry": {
          "coordinates": [121, 14.35],
          "type": "Point"
      },
      "id": "PH"
  }, {
      "properties": {
          "country": "Pitcairn",
          "city": "Adamstown",
          "tld": "pn",
          "iso3": "PCN",
          "iso2": "PN"
      },
      "geometry": {
          "coordinates": [-130.05, -25.04],
          "type": "Point"
      },
      "id": "PN"
  }, {
      "properties": {
          "country": "Poland",
          "city": "Warsaw",
          "tld": "pl",
          "iso3": "POL",
          "iso2": "PL"
      },
      "geometry": {
          "coordinates": [21, 52.15],
          "type": "Point"
      },
      "id": "PL"
  }, {
      "properties": {
          "country": "Saint Pierre and Miquelon",
          "city": "Saint-Pierre",
          "tld": "pm",
          "iso3": "SPM",
          "iso2": "PM"
      },
      "geometry": {
          "coordinates": [-56.11, 46.46],
          "type": "Point"
      },
      "id": "PM"
  }, {
      "properties": {
          "country": "Zambia",
          "city": "Lusaka",
          "tld": "zm",
          "iso3": "ZMB",
          "iso2": "ZM"
      },
      "geometry": {
          "coordinates": [28.17, -15.25],
          "type": "Point"
      },
      "id": "ZM"
  }, {
      "properties": {
          "country": "Western Sahara",
          "tld": "eh",
          "iso3": "ESH",
          "iso2": "EH"
      },
      "geometry": {
          "coordinates": [-13, 24.3],
          "type": "Point"
      },
      "id": "EH"
  }, {
      "properties": {
          "country": "Estonia",
          "city": "Tallinn",
          "tld": "ee",
          "iso3": "EST",
          "iso2": "EE"
      },
      "geometry": {
          "coordinates": [24.43, 59.26],
          "type": "Point"
      },
      "id": "EE"
  }, {
      "properties": {
          "country": "Egypt",
          "city": "Cairo",
          "tld": "eg",
          "iso3": "EGY",
          "iso2": "EG"
      },
      "geometry": {
          "coordinates": [31.15, 30.03],
          "type": "Point"
      },
      "id": "EG"
  }, {
      "properties": {
          "country": "South Africa",
          "city": "Pretoria",
          "tld": "za",
          "iso3": "ZAF",
          "iso2": "ZA"
      },
      "geometry": {
          "coordinates": [28.13, -25.42],
          "type": "Point"
      },
      "id": "ZA"
  }, {
      "properties": {
          "country": "Ecuador",
          "city": "Quito",
          "tld": "ec",
          "iso3": "ECU",
          "iso2": "EC"
      },
      "geometry": {
          "coordinates": [-78.3, -0.13],
          "type": "Point"
      },
      "id": "EC"
  }, {
      "properties": {
          "country": "Italy",
          "city": "Rome",
          "tld": "it",
          "iso3": "ITA",
          "iso2": "IT"
      },
      "geometry": {
          "coordinates": [12.29, 41.54],
          "type": "Point"
      },
      "id": "IT"
  }, {
      "properties": {
          "country": "Vietnam",
          "city": "Hanoi",
          "tld": "vn",
          "iso3": "VNM",
          "iso2": "VN"
      },
      "geometry": {
          "coordinates": [105.51, 21.02],
          "type": "Point"
      },
      "id": "VN"
  }, {
      "properties": {
          "country": "Solomon Islands",
          "city": "Honiara",
          "tld": "sb",
          "iso3": "SLB",
          "iso2": "SB"
      },
      "geometry": {
          "coordinates": [159.57, -9.26],
          "type": "Point"
      },
      "id": "SB"
  }, {
      "properties": {
          "country": "European Union",
          "tld": "eu",
          "iso3": "N/A",
          "iso2": "EU"
      },
      "geometry": {
          "coordinates": [9.9, 50.1021],
          "type": "Point"
      },
      "id": "EU"
  }, {
      "properties": {
          "country": "Ethiopia",
          "city": "Addis Ababa",
          "tld": "et",
          "iso3": "ETH",
          "iso2": "ET"
      },
      "geometry": {
          "coordinates": [38.42, 9.02],
          "type": "Point"
      },
      "id": "ET"
  }, {
      "properties": {
          "country": "Somalia",
          "city": "Mogadishu",
          "tld": "so",
          "iso3": "SOM",
          "iso2": "SO"
      },
      "geometry": {
          "coordinates": [45.22, 2.04],
          "type": "Point"
      },
      "id": "SO"
  }, {
      "properties": {
          "country": "Zimbabwe",
          "city": "Harare",
          "tld": "zw",
          "iso3": "ZWE",
          "iso2": "ZW"
      },
      "geometry": {
          "coordinates": [31.03, -17.5],
          "type": "Point"
      },
      "id": "ZW"
  }, {
      "properties": {
          "country": "Saudi Arabia",
          "city": "Riyadh",
          "tld": "sa",
          "iso3": "SAU",
          "iso2": "SA"
      },
      "geometry": {
          "coordinates": [46.43, 24.38],
          "type": "Point"
      },
      "id": "SA"
  }, {
      "properties": {
          "country": "Spain",
          "city": "Madrid",
          "tld": "es",
          "iso3": "ESP",
          "iso2": "ES"
      },
      "geometry": {
          "coordinates": [-3.41, 40.24],
          "type": "Point"
      },
      "id": "ES"
  }, {
      "properties": {
          "country": "Eritrea",
          "city": "Asmara",
          "tld": "er",
          "iso3": "ERI",
          "iso2": "ER"
      },
      "geometry": {
          "coordinates": [38.56, 15.2],
          "type": "Point"
      },
      "id": "ER"
  }, {
      "properties": {
          "country": "Montenegro",
          "city": "Podgorica",
          "tld": "me",
          "iso3": "MNE",
          "iso2": "ME"
      },
      "geometry": {
          "coordinates": [19.16, 42.26],
          "type": "Point"
      },
      "id": "ME"
  }, {
      "properties": {
          "country": "Moldova",
          "city": "Chisinau",
          "tld": "md",
          "iso3": "MDA",
          "iso2": "MD"
      },
      "geometry": {
          "coordinates": [28.51, 47],
          "type": "Point"
      },
      "id": "MD"
  }, {
      "properties": {
          "country": "Madagascar",
          "city": "Antananarivo",
          "tld": "mg",
          "iso3": "MDG",
          "iso2": "MG"
      },
      "geometry": {
          "coordinates": [47.31, -18.55],
          "type": "Point"
      },
      "id": "MG"
  }, {
      "properties": {
          "country": "Saint Martin",
          "city": "Marigot",
          "tld": "mf",
          "iso3": "MAF",
          "iso2": "MF"
      },
      "geometry": {
          "coordinates": [-63.05, 18.04],
          "type": "Point"
      },
      "id": "MF"
  }, {
      "properties": {
          "country": "Morocco",
          "city": "Rabat",
          "tld": "ma",
          "iso3": "MAR",
          "iso2": "MA"
      },
      "geometry": {
          "coordinates": [-6.49, 34.01],
          "type": "Point"
      },
      "id": "MA"
  }, {
      "properties": {
          "country": "Monaco",
          "city": "Monaco",
          "tld": "mc",
          "iso3": "MCO",
          "iso2": "MC"
      },
      "geometry": {
          "coordinates": [7.25, 43.44],
          "type": "Point"
      },
      "id": "MC"
  }, {
      "properties": {
          "country": "Uzbekistan",
          "city": "Tashkent",
          "tld": "uz",
          "iso3": "UZB",
          "iso2": "UZ"
      },
      "geometry": {
          "coordinates": [69.18, 41.2],
          "type": "Point"
      },
      "id": "UZ"
  }, {
      "properties": {
          "country": "Myanmar",
          "city": "Rangoon",
          "tld": "mm",
          "iso3": "MMR",
          "iso2": "MM"
      },
      "geometry": {
          "coordinates": [96.09, 16.48],
          "type": "Point"
      },
      "id": "MM"
  }, {
      "properties": {
          "country": "Mali",
          "city": "Bamako",
          "tld": "ml",
          "iso3": "MLI",
          "iso2": "ML"
      },
      "geometry": {
          "coordinates": [-8, 12.39],
          "type": "Point"
      },
      "id": "ML"
  }, {
      "properties": {
          "country": "Macao",
          "tld": "mo",
          "iso3": "MAC",
          "iso2": "MO"
      },
      "geometry": {
          "coordinates": [113.33, 22.1],
          "type": "Point"
      },
      "id": "MO"
  }, {
      "properties": {
          "country": "Mongolia",
          "city": "Ulaanbaatar",
          "tld": "mn",
          "iso3": "MNG",
          "iso2": "MN"
      },
      "geometry": {
          "coordinates": [106.55, 47.55],
          "type": "Point"
      },
      "id": "MN"
  }, {
      "properties": {
          "country": "Marshall Islands",
          "city": "Majuro",
          "tld": "mh",
          "iso3": "MHL",
          "iso2": "MH"
      },
      "geometry": {
          "coordinates": [171.23, 7.06],
          "type": "Point"
      },
      "id": "MH"
  }, {
      "properties": {
          "country": "Macedonia",
          "city": "Skopje",
          "tld": "mk",
          "iso3": "MKD",
          "iso2": "MK"
      },
      "geometry": {
          "coordinates": [21.26, 42],
          "type": "Point"
      },
      "id": "MK"
  }, {
      "properties": {
          "country": "Mauritius",
          "city": "Port Louis",
          "tld": "mu",
          "iso3": "MUS",
          "iso2": "MU"
      },
      "geometry": {
          "coordinates": [57.29, -20.09],
          "type": "Point"
      },
      "id": "MU"
  }, {
      "properties": {
          "country": "Malta",
          "city": "Valletta",
          "tld": "mt",
          "iso3": "MLT",
          "iso2": "MT"
      },
      "geometry": {
          "coordinates": [14.3, 35.53],
          "type": "Point"
      },
      "id": "MT"
  }, {
      "properties": {
          "country": "Malawi",
          "city": "Lilongwe",
          "tld": "mw",
          "iso3": "MWI",
          "iso2": "MW"
      },
      "geometry": {
          "coordinates": [33.47, -13.59],
          "type": "Point"
      },
      "id": "MW"
  }, {
      "properties": {
          "country": "Maldives",
          "city": "Male",
          "tld": "mv",
          "iso3": "MDV",
          "iso2": "MV"
      },
      "geometry": {
          "coordinates": [73.3, 4.1],
          "type": "Point"
      },
      "id": "MV"
  }, {
      "properties": {
          "country": "Northern Mariana Islands",
          "city": "Saipan",
          "tld": "mp",
          "iso3": "MNP",
          "iso2": "MP"
      },
      "geometry": {
          "coordinates": [145.45, 15.12],
          "type": "Point"
      },
      "id": "MP"
  }, {
      "properties": {
          "country": "Montserrat",
          "city": "Plymouth",
          "tld": "ms",
          "iso3": "MSR",
          "iso2": "MS"
      },
      "geometry": {
          "coordinates": [-62.13, 16.42],
          "type": "Point"
      },
      "id": "MS"
  }, {
      "properties": {
          "country": "Mauritania",
          "city": "Nouakchott",
          "tld": "mr",
          "iso3": "MRT",
          "iso2": "MR"
      },
      "geometry": {
          "coordinates": [-16.02, 18.07],
          "type": "Point"
      },
      "id": "MR"
  }, {
      "properties": {
          "country": "Isle of Man",
          "city": "Douglas",
          "tld": "im",
          "iso3": "IMN",
          "iso2": "IM"
      },
      "geometry": {
          "coordinates": [-4.29, 54.09],
          "type": "Point"
      },
      "id": "IM"
  }, {
      "properties": {
          "country": "Uganda",
          "city": "Kampala",
          "tld": "ug",
          "iso3": "UGA",
          "iso2": "UG"
      },
      "geometry": {
          "coordinates": [32.25, 0.19],
          "type": "Point"
      },
      "id": "UG"
  }, {
      "properties": {
          "country": "Malaysia",
          "city": "Kuala Lumpur",
          "tld": "my",
          "iso3": "MYS",
          "iso2": "MY"
      },
      "geometry": {
          "coordinates": [101.42, 3.1],
          "type": "Point"
      },
      "id": "MY"
  }, {
      "properties": {
          "country": "Mexico",
          "city": "Mexico City",
          "tld": "mx",
          "iso3": "MEX",
          "iso2": "MX"
      },
      "geometry": {
          "coordinates": [-99.08, 19.26],
          "type": "Point"
      },
      "id": "MX"
  }, {
      "properties": {
          "country": "Israel",
          "city": "Jerusalem",
          "tld": "il",
          "iso3": "ISR",
          "iso2": "IL"
      },
      "geometry": {
          "coordinates": [35.14, 31.46],
          "type": "Point"
      },
      "id": "IL"
  }, {
      "properties": {
          "country": "France",
          "city": "Paris",
          "tld": "fr",
          "iso3": "FRA",
          "iso2": "FR"
      },
      "geometry": {
          "coordinates": [2.2, 48.52],
          "type": "Point"
      },
      "id": "FR"
  }, {
      "properties": {
          "country": "British Indian Ocean Territory",
          "tld": "io",
          "iso3": "IOT",
          "iso2": "IO"
      },
      "geometry": {
          "coordinates": [71.3, -6],
          "type": "Point"
      },
      "id": "IO"
  }, {
      "properties": {
          "country": "Saint Helena Ascension and Tristan da Cunha",
          "city": "Jamestown",
          "tld": "sh",
          "iso3": "SHN",
          "iso2": "SH"
      },
      "geometry": {
          "coordinates": [-5.44, -15.56],
          "type": "Point"
      },
      "id": "SH"
  }, {
      "properties": {
          "country": "Finland",
          "city": "Helsinki",
          "tld": "fi",
          "iso3": "FIN",
          "iso2": "FI"
      },
      "geometry": {
          "coordinates": [24.56, 60.1],
          "type": "Point"
      },
      "id": "FI"
  }, {
      "properties": {
          "country": "Fiji",
          "city": "Suva",
          "tld": "fj",
          "iso3": "FJI",
          "iso2": "FJ"
      },
      "geometry": {
          "coordinates": [178.25, -18.08],
          "type": "Point"
      },
      "id": "FJ"
  }, {
      "properties": {
          "country": "Falkland Islands",
          "city": "Stanley",
          "tld": "fk",
          "iso3": "FLK",
          "iso2": "FK"
      },
      "geometry": {
          "coordinates": [-57.51, -51.42],
          "type": "Point"
      },
      "id": "FK"
  }, {
      "properties": {
          "country": "Micronesia",
          "city": "Palikir",
          "tld": "fm",
          "iso3": "FSM",
          "iso2": "FM"
      },
      "geometry": {
          "coordinates": [158.09, 6.55],
          "type": "Point"
      },
      "id": "FM"
  }, {
      "properties": {
          "country": "Faroe Islands",
          "city": "Torshavn",
          "tld": "fo",
          "iso3": "FRO",
          "iso2": "FO"
      },
      "geometry": {
          "coordinates": [-6.46, 62.01],
          "type": "Point"
      },
      "id": "FO"
  }, {
      "properties": {
          "country": "Nicaragua",
          "city": "Managua",
          "tld": "ni",
          "iso3": "NIC",
          "iso2": "NI"
      },
      "geometry": {
          "coordinates": [-86.17, 12.09],
          "type": "Point"
      },
      "id": "NI"
  }, {
      "properties": {
          "country": "Netherlands",
          "city": "Amsterdam",
          "tld": "nl",
          "iso3": "NLD",
          "iso2": "NL"
      },
      "geometry": {
          "coordinates": [4.54, 52.23],
          "type": "Point"
      },
      "id": "NL"
  }, {
      "properties": {
          "country": "Norway",
          "city": "Oslo",
          "tld": "no",
          "iso3": "NOR",
          "iso2": "NO"
      },
      "geometry": {
          "coordinates": [10.45, 59.55],
          "type": "Point"
      },
      "id": "NO"
  }, {
      "properties": {
          "country": "Namibia",
          "city": "Windhoek",
          "tld": "na",
          "iso3": "NAM",
          "iso2": "NA"
      },
      "geometry": {
          "coordinates": [17.05, -22.34],
          "type": "Point"
      },
      "id": "NA"
  }, {
      "properties": {
          "country": "Vanuatu",
          "city": "Port-Vila",
          "tld": "vu",
          "iso3": "VUT",
          "iso2": "VU"
      },
      "geometry": {
          "coordinates": [168.19, -17.44],
          "type": "Point"
      },
      "id": "VU"
  }, {
      "properties": {
          "country": "New Caledonia",
          "city": "Noumea",
          "tld": "nc",
          "iso3": "NCL",
          "iso2": "NC"
      },
      "geometry": {
          "coordinates": [166.27, -22.16],
          "type": "Point"
      },
      "id": "NC"
  }, {
      "properties": {
          "country": "Niger",
          "city": "Niamey",
          "tld": "ne",
          "iso3": "NER",
          "iso2": "NE"
      },
      "geometry": {
          "coordinates": [2.07, 13.31],
          "type": "Point"
      },
      "id": "NE"
  }, {
      "properties": {
          "country": "Norfolk Island",
          "city": "Kingston",
          "tld": "nf",
          "iso3": "NFK",
          "iso2": "NF"
      },
      "geometry": {
          "coordinates": [167.58, -29.03],
          "type": "Point"
      },
      "id": "NF"
  }, {
      "properties": {
          "country": "Nigeria",
          "city": "Abuja",
          "tld": "ng",
          "iso3": "NGA",
          "iso2": "NG"
      },
      "geometry": {
          "coordinates": [7.32, 9.05],
          "type": "Point"
      },
      "id": "NG"
  }, {
      "properties": {
          "country": "New Zealand",
          "city": "Wellington",
          "tld": "nz",
          "iso3": "NZL",
          "iso2": "NZ"
      },
      "geometry": {
          "coordinates": [174.51, -41.28],
          "type": "Point"
      },
      "id": "NZ"
  }, {
      "properties": {
          "country": "Nepal",
          "city": "Kathmandu",
          "tld": "np",
          "iso3": "NPL",
          "iso2": "NP"
      },
      "geometry": {
          "coordinates": [85.19, 27.43],
          "type": "Point"
      },
      "id": "NP"
  }, {
      "properties": {
          "country": "Nauru",
          "tld": "nr",
          "iso3": "NRU",
          "iso2": "NR"
      },
      "geometry": {
          "coordinates": [166.55, -0.32],
          "type": "Point"
      },
      "id": "NR"
  }, {
      "properties": {
          "country": "Niue",
          "city": "Alofi",
          "tld": "nu",
          "iso3": "NIU",
          "iso2": "NU"
      },
      "geometry": {
          "coordinates": [-169.55, -19.01],
          "type": "Point"
      },
      "id": "NU"
  }, {
      "properties": {
          "country": "Cook Islands",
          "city": "Avarua",
          "tld": "ck",
          "iso3": "COK",
          "iso2": "CK"
      },
      "geometry": {
          "coordinates": [-159.46, -21.12],
          "type": "Point"
      },
      "id": "CK"
  }, {
      "properties": {
          "country": "Ivory Coast",
          "city": "Yamoussoukro",
          "tld": "ci",
          "iso3": "CIV",
          "iso2": "CI"
      },
      "geometry": {
          "coordinates": [-5.17, 6.49],
          "type": "Point"
      },
      "id": "CI"
  }, {
      "properties": {
          "country": "Switzerland",
          "city": "Bern",
          "tld": "ch",
          "iso3": "CHE",
          "iso2": "CH"
      },
      "geometry": {
          "coordinates": [7.26, 46.57],
          "type": "Point"
      },
      "id": "CH"
  }, {
      "properties": {
          "country": "Colombia",
          "city": "Bogota",
          "tld": "co",
          "iso3": "COL",
          "iso2": "CO"
      },
      "geometry": {
          "coordinates": [-74.05, 4.36],
          "type": "Point"
      },
      "id": "CO"
  }, {
      "properties": {
          "country": "China",
          "city": "Beijing",
          "tld": "cn",
          "iso3": "CHN",
          "iso2": "CN"
      },
      "geometry": {
          "coordinates": [116.23, 39.55],
          "type": "Point"
      },
      "id": "CN"
  }, {
      "properties": {
          "country": "Cameroon",
          "city": "Yaounde",
          "tld": "cm",
          "iso3": "CMR",
          "iso2": "CM"
      },
      "geometry": {
          "coordinates": [11.31, 3.52],
          "type": "Point"
      },
      "id": "CM"
  }, {
      "properties": {
          "country": "Chile",
          "city": "Santiago",
          "tld": "cl",
          "iso3": "CHL",
          "iso2": "CL"
      },
      "geometry": {
          "coordinates": [-70.4, -33.27],
          "type": "Point"
      },
      "id": "CL"
  }, {
      "properties": {
          "country": "Cocos Islands",
          "city": "West Island",
          "tld": "cc",
          "iso3": "CCK",
          "iso2": "CC"
      },
      "geometry": {
          "coordinates": [96.5, -12.1],
          "type": "Point"
      },
      "id": "CC"
  }, {
      "properties": {
          "country": "Canada",
          "city": "Ottawa",
          "tld": "ca",
          "iso3": "CAN",
          "iso2": "CA"
      },
      "geometry": {
          "coordinates": [-75.42, 45.25],
          "type": "Point"
      },
      "id": "CA"
  }, {
      "properties": {
          "country": "Congo Republic",
          "city": "Brazzaville",
          "tld": "cg",
          "iso3": "COG",
          "iso2": "CG"
      },
      "geometry": {
          "coordinates": [15.17, -4.15],
          "type": "Point"
      },
      "id": "CG"
  }, {
      "properties": {
          "country": "Central African Republic",
          "city": "Bangui",
          "tld": "cf",
          "iso3": "CAF",
          "iso2": "CF"
      },
      "geometry": {
          "coordinates": [18.35, 4.22],
          "type": "Point"
      },
      "id": "CF"
  }, {
      "properties": {
          "country": "Congo Democratic Republic",
          "city": "Kinshasa",
          "tld": "cd",
          "iso3": "COD",
          "iso2": "CD"
      },
      "geometry": {
          "coordinates": [15.18, -4.19],
          "type": "Point"
      },
      "id": "CD"
  }, {
      "properties": {
          "country": "Czech Republic",
          "city": "Prague",
          "tld": "cz",
          "iso3": "CZE",
          "iso2": "CZ"
      },
      "geometry": {
          "coordinates": [14.28, 50.05],
          "type": "Point"
      },
      "id": "CZ"
  }, {
      "properties": {
          "country": "Cyprus",
          "city": "Nicosia",
          "tld": "cy",
          "iso3": "CYP",
          "iso2": "CY"
      },
      "geometry": {
          "coordinates": [33.22, 35.1],
          "type": "Point"
      },
      "id": "CY"
  }, {
      "properties": {
          "country": "Christmas Island",
          "city": "The Settlement",
          "tld": "cx",
          "iso3": "CXR",
          "iso2": "CX"
      },
      "geometry": {
          "coordinates": [105.43, -10.25],
          "type": "Point"
      },
      "id": "CX"
  }, {
      "properties": {
          "country": "Costa Rica",
          "city": "San Jose",
          "tld": "cr",
          "iso3": "CRI",
          "iso2": "CR"
      },
      "geometry": {
          "coordinates": [-84.05, 9.56],
          "type": "Point"
      },
      "id": "CR"
  }, {
      "properties": {
          "country": "Cape Verde",
          "city": "Praia",
          "tld": "cv",
          "iso3": "CPV",
          "iso2": "CV"
      },
      "geometry": {
          "coordinates": [-23.31, 14.55],
          "type": "Point"
      },
      "id": "CV"
  }, {
      "properties": {
          "country": "Cuba",
          "city": "Havana",
          "tld": "cu",
          "iso3": "CUB",
          "iso2": "CU"
      },
      "geometry": {
          "coordinates": [-82.21, 23.07],
          "type": "Point"
      },
      "id": "CU"
  }, {
      "properties": {
          "country": "Swaziland",
          "city": "Mbabane",
          "tld": "sz",
          "iso3": "SWZ",
          "iso2": "SZ"
      },
      "geometry": {
          "coordinates": [31.06, -26.18],
          "type": "Point"
      },
      "id": "SZ"
  }, {
      "properties": {
          "country": "Syria",
          "city": "Damascus",
          "tld": "sy",
          "iso3": "SYR",
          "iso2": "SY"
      },
      "geometry": {
          "coordinates": [36.18, 33.3],
          "type": "Point"
      },
      "id": "SY"
  }, {
      "properties": {
          "country": "Kyrgyzstan",
          "city": "Bishkek",
          "tld": "kg",
          "iso3": "KGZ",
          "iso2": "KG"
      },
      "geometry": {
          "coordinates": [74.36, 42.52],
          "type": "Point"
      },
      "id": "KG"
  }, {
      "properties": {
          "country": "Kenya",
          "city": "Nairobi",
          "tld": "ke",
          "iso3": "KEN",
          "iso2": "KE"
      },
      "geometry": {
          "coordinates": [36.49, -1.17],
          "type": "Point"
      },
      "id": "KE"
  }, {
      "properties": {
          "country": "Suriname",
          "city": "Paramaribo",
          "tld": "sr",
          "iso3": "SUR",
          "iso2": "SR"
      },
      "geometry": {
          "coordinates": [-55.1, 5.5],
          "type": "Point"
      },
      "id": "SR"
  }, {
      "properties": {
          "country": "Kiribati",
          "city": "Tarawa",
          "tld": "ki",
          "iso3": "KIR",
          "iso2": "KI"
      },
      "geometry": {
          "coordinates": [172.58, 1.19],
          "type": "Point"
      },
      "id": "KI"
  }, {
      "properties": {
          "country": "Cambodia",
          "city": "Phnom Penh",
          "tld": "kh",
          "iso3": "KHM",
          "iso2": "KH"
      },
      "geometry": {
          "coordinates": [104.55, 11.33],
          "type": "Point"
      },
      "id": "KH"
  }, {
      "properties": {
          "country": "Saint Kitts and Nevis",
          "city": "Basseterre",
          "tld": "kn",
          "iso3": "KNA",
          "iso2": "KN"
      },
      "geometry": {
          "coordinates": [-62.43, 17.18],
          "type": "Point"
      },
      "id": "KN"
  }, {
      "properties": {
          "country": "Comoros",
          "city": "Moroni",
          "tld": "km",
          "iso3": "COM",
          "iso2": "KM"
      },
      "geometry": {
          "coordinates": [43.14, -11.42],
          "type": "Point"
      },
      "id": "KM"
  }, {
      "properties": {
          "country": "Sao Tome and Principe",
          "city": "Sao Tome",
          "tld": "st",
          "iso3": "STP",
          "iso2": "ST"
      },
      "geometry": {
          "coordinates": [6.39, 0.12],
          "type": "Point"
      },
      "id": "ST"
  }, {
      "properties": {
          "country": "Slovakia",
          "city": "Bratislava",
          "tld": "sk",
          "iso3": "SVK",
          "iso2": "SK"
      },
      "geometry": {
          "coordinates": [17.07, 48.09],
          "type": "Point"
      },
      "id": "SK"
  }, {
      "properties": {
          "country": "Korea South",
          "city": "Seoul",
          "tld": "kr",
          "iso3": "KOR",
          "iso2": "KR"
      },
      "geometry": {
          "coordinates": [126.59, 37.33],
          "type": "Point"
      },
      "id": "KR"
  }, {
      "properties": {
          "country": "Slovenia",
          "city": "Ljubljana",
          "tld": "si",
          "iso3": "SVN",
          "iso2": "SI"
      },
      "geometry": {
          "coordinates": [14.31, 46.03],
          "type": "Point"
      },
      "id": "SI"
  }, {
      "properties": {
          "country": "Korea North",
          "city": "Pyongyang",
          "tld": "kp",
          "iso3": "PRK",
          "iso2": "KP"
      },
      "geometry": {
          "coordinates": [125.45, 39.01],
          "type": "Point"
      },
      "id": "KP"
  }, {
      "properties": {
          "country": "Kuwait",
          "city": "Kuwait City",
          "tld": "kw",
          "iso3": "KWT",
          "iso2": "KW"
      },
      "geometry": {
          "coordinates": [47.58, 29.22],
          "type": "Point"
      },
      "id": "KW"
  }, {
      "properties": {
          "country": "Senegal",
          "city": "Dakar",
          "tld": "sn",
          "iso3": "SEN",
          "iso2": "SN"
      },
      "geometry": {
          "coordinates": [-17.26, 14.4],
          "type": "Point"
      },
      "id": "SN"
  }, {
      "properties": {
          "country": "San Marino",
          "city": "San Marino",
          "tld": "sm",
          "iso3": "SMR",
          "iso2": "SM"
      },
      "geometry": {
          "coordinates": [12.25, 43.56],
          "type": "Point"
      },
      "id": "SM"
  }, {
      "properties": {
          "country": "Sierra Leone",
          "city": "Freetown",
          "tld": "sl",
          "iso3": "SLE",
          "iso2": "SL"
      },
      "geometry": {
          "coordinates": [-13.15, 8.3],
          "type": "Point"
      },
      "id": "SL"
  }, {
      "properties": {
          "country": "Seychelles",
          "city": "Victoria",
          "tld": "sc",
          "iso3": "SYC",
          "iso2": "SC"
      },
      "geometry": {
          "coordinates": [55.27, -4.38],
          "type": "Point"
      },
      "id": "SC"
  }, {
      "properties": {
          "country": "Kazakhstan",
          "city": "Astana",
          "tld": "kz",
          "iso3": "KAZ",
          "iso2": "KZ"
      },
      "geometry": {
          "coordinates": [71.25, 51.1],
          "type": "Point"
      },
      "id": "KZ"
  }, {
      "properties": {
          "country": "Cayman Islands",
          "city": "George Town",
          "tld": "ky",
          "iso3": "CYM",
          "iso2": "KY"
      },
      "geometry": {
          "coordinates": [-81.23, 19.18],
          "type": "Point"
      },
      "id": "KY"
  }, {
      "properties": {
          "country": "Singapore",
          "city": "Singapore",
          "tld": "sg",
          "iso3": "SGP",
          "iso2": "SG"
      },
      "geometry": {
          "coordinates": [103.51, 1.17],
          "type": "Point"
      },
      "id": "SG"
  }, {
      "properties": {
          "country": "Sweden",
          "city": "Stockholm",
          "tld": "se",
          "iso3": "SWE",
          "iso2": "SE"
      },
      "geometry": {
          "coordinates": [18.03, 59.2],
          "type": "Point"
      },
      "id": "SE"
  }, {
      "properties": {
          "country": "Sudan",
          "city": "Khartoum",
          "tld": "sd",
          "iso3": "SDN",
          "iso2": "SD"
      },
      "geometry": {
          "coordinates": [32.32, 15.36],
          "type": "Point"
      },
      "id": "SD"
  }, {
      "properties": {
          "country": "Dominican Republic",
          "city": "Santo Domingo",
          "tld": "do",
          "iso3": "DOM",
          "iso2": "DO"
      },
      "geometry": {
          "coordinates": [-69.54, 18.28],
          "type": "Point"
      },
      "id": "DO"
  }, {
      "properties": {
          "country": "Dominica",
          "city": "Roseau",
          "tld": "dm",
          "iso3": "DMA",
          "iso2": "DM"
      },
      "geometry": {
          "coordinates": [-61.24, 15.18],
          "type": "Point"
      },
      "id": "DM"
  }, {
      "properties": {
          "country": "Djibouti",
          "city": "Djibouti",
          "tld": "dj",
          "iso3": "DJI",
          "iso2": "DJ"
      },
      "geometry": {
          "coordinates": [43.09, 11.35],
          "type": "Point"
      },
      "id": "DJ"
  }, {
      "properties": {
          "country": "Denmark",
          "city": "Copenhagen",
          "tld": "dk",
          "iso3": "DNK",
          "iso2": "DK"
      },
      "geometry": {
          "coordinates": [12.35, 55.4],
          "type": "Point"
      },
      "id": "DK"
  }, {
      "properties": {
          "country": "British Virgin Islands",
          "city": "Road Town",
          "tld": "vg",
          "iso3": "VGB",
          "iso2": "VG"
      },
      "geometry": {
          "coordinates": [-64.37, 18.27],
          "type": "Point"
      },
      "id": "VG"
  }, {
      "properties": {
          "country": "Germany",
          "city": "Berlin",
          "tld": "de",
          "iso3": "DEU",
          "iso2": "DE"
      },
      "geometry": {
          "coordinates": [13.24, 52.31],
          "type": "Point"
      },
      "id": "DE"
  }, {
      "properties": {
          "country": "Yemen",
          "city": "Sanaa",
          "tld": "ye",
          "iso3": "YEM",
          "iso2": "YE"
      },
      "geometry": {
          "coordinates": [44.12, 15.21],
          "type": "Point"
      },
      "id": "YE"
  }, {
      "properties": {
          "country": "Algeria",
          "city": "Algiers",
          "tld": "dz",
          "iso3": "DZA",
          "iso2": "DZ"
      },
      "geometry": {
          "coordinates": [3.03, 36.45],
          "type": "Point"
      },
      "id": "DZ"
  }, {
      "properties": {
          "country": "United States",
          "city": "Washington, DC",
          "tld": "us",
          "iso3": "USA",
          "iso2": "US"
      },
      "geometry": {
          "coordinates": [-77.02, 38.53],
          "type": "Point"
      },
      "id": "US"
  }, {
      "properties": {
          "country": "Uruguay",
          "city": "Montevideo",
          "tld": "uy",
          "iso3": "URY",
          "iso2": "UY"
      },
      "geometry": {
          "coordinates": [-56.11, -34.53],
          "type": "Point"
      },
      "id": "UY"
  }, {
      "properties": {
          "country": "Mayotte",
          "city": "Mamoudzou",
          "tld": "yt",
          "iso3": "MYT",
          "iso2": "YT"
      },
      "geometry": {
          "coordinates": [45.13, -12.46],
          "type": "Point"
      },
      "id": "YT"
  }, {
      "properties": {
          "country": "Lebanon",
          "city": "Beirut",
          "tld": "lb",
          "iso3": "LBN",
          "iso2": "LB"
      },
      "geometry": {
          "coordinates": [35.3, 33.52],
          "type": "Point"
      },
      "id": "LB"
  }, {
      "properties": {
          "country": "Saint Lucia",
          "city": "Castries",
          "tld": "lc",
          "iso3": "LCA",
          "iso2": "LC"
      },
      "geometry": {
          "coordinates": [-61, 14.01],
          "type": "Point"
      },
      "id": "LC"
  }, {
      "properties": {
          "country": "Laos",
          "city": "Vientiane",
          "tld": "la",
          "iso3": "LAO",
          "iso2": "LA"
      },
      "geometry": {
          "coordinates": [102.36, 17.58],
          "type": "Point"
      },
      "id": "LA"
  }, {
      "properties": {
          "country": "Tuvalu",
          "city": "Funafuti",
          "tld": "tv",
          "iso3": "TUV",
          "iso2": "TV"
      },
      "geometry": {
          "coordinates": [179.12, -8.3],
          "type": "Point"
      },
      "id": "TV"
  }, {
      "properties": {
          "country": "Taiwan",
          "city": "Taipei",
          "tld": "tw",
          "iso3": "TWN",
          "iso2": "TW"
      },
      "geometry": {
          "coordinates": [121.3, 25.03],
          "type": "Point"
      },
      "id": "TW"
  }, {
      "properties": {
          "country": "Trinidad and Tobago",
          "city": "Port-of-Spain",
          "tld": "tt",
          "iso3": "TTO",
          "iso2": "TT"
      },
      "geometry": {
          "coordinates": [-61.31, 10.39],
          "type": "Point"
      },
      "id": "TT"
  }, {
      "properties": {
          "country": "Turkey",
          "city": "Ankara",
          "tld": "tr",
          "iso3": "TUR",
          "iso2": "TR"
      },
      "geometry": {
          "coordinates": [32.52, 39.56],
          "type": "Point"
      },
      "id": "TR"
  }, {
      "properties": {
          "country": "Sri Lanka",
          "city": "Colombo",
          "tld": "lk",
          "iso3": "LKA",
          "iso2": "LK"
      },
      "geometry": {
          "coordinates": [79.51, 6.56],
          "type": "Point"
      },
      "id": "LK"
  }, {
      "properties": {
          "country": "Liechtenstein",
          "city": "Vaduz",
          "tld": "li",
          "iso3": "LIE",
          "iso2": "LI"
      },
      "geometry": {
          "coordinates": [9.31, 47.08],
          "type": "Point"
      },
      "id": "LI"
  }, {
      "properties": {
          "country": "Latvia",
          "city": "Riga",
          "tld": "lv",
          "iso3": "LVA",
          "iso2": "LV"
      },
      "geometry": {
          "coordinates": [24.06, 56.57],
          "type": "Point"
      },
      "id": "LV"
  }, {
      "properties": {
          "country": "Tonga",
          "city": "Nuku'alofa",
          "tld": "to",
          "iso3": "TON",
          "iso2": "TO"
      },
      "geometry": {
          "coordinates": [-175.12, -21.08],
          "type": "Point"
      },
      "id": "TO"
  }, {
      "properties": {
          "country": "Lithuania",
          "city": "Vilnius",
          "tld": "lt",
          "iso3": "LTU",
          "iso2": "LT"
      },
      "geometry": {
          "coordinates": [25.19, 54.41],
          "type": "Point"
      },
      "id": "LT"
  }, {
      "properties": {
          "country": "Luxembourg",
          "city": "Luxembourg",
          "tld": "lu",
          "iso3": "LUX",
          "iso2": "LU"
      },
      "geometry": {
          "coordinates": [6.07, 49.36],
          "type": "Point"
      },
      "id": "LU"
  }, {
      "properties": {
          "country": "Liberia",
          "city": "Monrovia",
          "tld": "lr",
          "iso3": "LBR",
          "iso2": "LR"
      },
      "geometry": {
          "coordinates": [-10.48, 6.18],
          "type": "Point"
      },
      "id": "LR"
  }, {
      "properties": {
          "country": "Lesotho",
          "city": "Maseru",
          "tld": "ls",
          "iso3": "LSO",
          "iso2": "LS"
      },
      "geometry": {
          "coordinates": [27.29, -29.19],
          "type": "Point"
      },
      "id": "LS"
  }, {
      "properties": {
          "country": "Thailand",
          "city": "Bangkok",
          "tld": "th",
          "iso3": "THA",
          "iso2": "TH"
      },
      "geometry": {
          "coordinates": [100.31, 13.45],
          "type": "Point"
      },
      "id": "TH"
  }, {
      "properties": {
          "country": "French Southern Territories",
          "tld": "tf",
          "iso3": "ATF",
          "iso2": "TF"
      },
      "geometry": {
          "coordinates": [77.32, -37.5],
          "type": "Point"
      },
      "id": "TF"
  }, {
      "properties": {
          "country": "Togo",
          "city": "Lome",
          "tld": "tg",
          "iso3": "TGO",
          "iso2": "TG"
      },
      "geometry": {
          "coordinates": [1.13, 6.08],
          "type": "Point"
      },
      "id": "TG"
  }, {
      "properties": {
          "country": "Chad",
          "city": "N'Djamena",
          "tld": "td",
          "iso3": "TCD",
          "iso2": "TD"
      },
      "geometry": {
          "coordinates": [15.02, 12.06],
          "type": "Point"
      },
      "id": "TD"
  }, {
      "properties": {
          "country": "Turks and Caicos Islands",
          "city": "Grand Turk",
          "tld": "tc",
          "iso3": "TCA",
          "iso2": "TC"
      },
      "geometry": {
          "coordinates": [-71.08, 21.28],
          "type": "Point"
      },
      "id": "TC"
  }, {
      "properties": {
          "country": "Libya",
          "city": "Tripoli",
          "tld": "ly",
          "iso3": "LBY",
          "iso2": "LY"
      },
      "geometry": {
          "coordinates": [13.1, 32.53],
          "type": "Point"
      },
      "id": "LY"
  }, {
      "properties": {
          "country": "Holy See",
          "city": "Vatican City",
          "tld": "va",
          "iso3": "VAT",
          "iso2": "VA"
      },
      "geometry": {
          "coordinates": [12.27, 41.54],
          "type": "Point"
      },
      "id": "VA"
  }, {
      "properties": {
          "country": "Saint Vincent and the Grenadines",
          "city": "Kingstown",
          "tld": "vc",
          "iso3": "VCT",
          "iso2": "VC"
      },
      "geometry": {
          "coordinates": [-61.14, 13.09],
          "type": "Point"
      },
      "id": "VC"
  }, {
      "properties": {
          "country": "United Arab Emirates",
          "city": "Abu Dhabi",
          "tld": "ae",
          "iso3": "ARE",
          "iso2": "AE"
      },
      "geometry": {
          "coordinates": [54.22, 24.28],
          "type": "Point"
      },
      "id": "AE"
  }, {
      "properties": {
          "country": "Andorra",
          "city": "Andorra la Vella",
          "tld": "ad",
          "iso3": "AND",
          "iso2": "AD"
      },
      "geometry": {
          "coordinates": [1.31, 42.3],
          "type": "Point"
      },
      "id": "AD"
  }, {
      "properties": {
          "country": "Antigua and Barbuda",
          "city": "Saint John's",
          "tld": "ag",
          "iso3": "ATG",
          "iso2": "AG"
      },
      "geometry": {
          "coordinates": [-61.51, 17.07],
          "type": "Point"
      },
      "id": "AG"
  }, {
      "properties": {
          "country": "Afghanistan",
          "city": "Kabul",
          "tld": "af",
          "iso3": "AFG",
          "iso2": "AF"
      },
      "geometry": {
          "coordinates": [69.11, 34.31],
          "type": "Point"
      },
      "id": "AF"
  }, {
      "properties": {
          "country": "Anguilla",
          "city": "The Valley",
          "tld": "ai",
          "iso3": "AIA",
          "iso2": "AI"
      },
      "geometry": {
          "coordinates": [-63.03, 18.13],
          "type": "Point"
      },
      "id": "AI"
  }, {
      "properties": {
          "country": "Virgin Islands",
          "city": "Charlotte Amalie",
          "tld": "vi",
          "iso3": "VIR",
          "iso2": "VI"
      },
      "geometry": {
          "coordinates": [-64.56, 18.21],
          "type": "Point"
      },
      "id": "VI"
  }, {
      "properties": {
          "country": "Iceland",
          "city": "Reykjavik",
          "tld": "is",
          "iso3": "ISL",
          "iso2": "IS"
      },
      "geometry": {
          "coordinates": [-21.57, 64.09],
          "type": "Point"
      },
      "id": "IS"
  }, {
      "properties": {
          "country": "Iran",
          "city": "Tehran",
          "tld": "ir",
          "iso3": "IRN",
          "iso2": "IR"
      },
      "geometry": {
          "coordinates": [51.25, 35.4],
          "type": "Point"
      },
      "id": "IR"
  }, {
      "properties": {
          "country": "Armenia",
          "city": "Yerevan",
          "tld": "am",
          "iso3": "ARM",
          "iso2": "AM"
      },
      "geometry": {
          "coordinates": [44.3, 40.1],
          "type": "Point"
      },
      "id": "AM"
  }, {
      "properties": {
          "country": "Albania",
          "city": "Tirana",
          "tld": "al",
          "iso3": "ALB",
          "iso2": "AL"
      },
      "geometry": {
          "coordinates": [19.49, 41.19],
          "type": "Point"
      },
      "id": "AL"
  }, {
      "properties": {
          "country": "Angola",
          "city": "Luanda",
          "tld": "ao",
          "iso3": "AGO",
          "iso2": "AO"
      },
      "geometry": {
          "coordinates": [13.14, -8.5],
          "type": "Point"
      },
      "id": "AO"
  }, {
      "properties": {
          "country": "Netherlands Antilles",
          "city": "Willemstad",
          "tld": "an",
          "iso3": "ANT",
          "iso2": "AN"
      },
      "geometry": {
          "coordinates": [-68.56, 12.06],
          "type": "Point"
      },
      "id": "AN"
  }, {
      "properties": {
          "country": "Antarctica",
          "tld": "aq",
          "iso3": "ATA",
          "iso2": "AQ"
      },
      "geometry": {
          "coordinates": [0, -90],
          "type": "Point"
      },
      "id": "AQ"
  }, {
      "properties": {
          "country": "Asia &amp; Pacific",
          "tld": "asia",
          "iso3": "N/A",
          "iso2": "AP"
      },
      "geometry": {
          "coordinates": [0, 0],
          "type": "Point"
      },
      "id": "AP"
  }, {
      "properties": {
          "country": "American Samoa",
          "city": "Pago Pago",
          "tld": "as",
          "iso3": "ASM",
          "iso2": "AS"
      },
      "geometry": {
          "coordinates": [-170.42, -14.16],
          "type": "Point"
      },
      "id": "AS"
  }, {
      "properties": {
          "country": "Argentina",
          "city": "Buenos Aires",
          "tld": "ar",
          "iso3": "ARG",
          "iso2": "AR"
      },
      "geometry": {
          "coordinates": [-58.4, -34.36],
          "type": "Point"
      },
      "id": "AR"
  }, {
      "properties": {
          "country": "Australia",
          "city": "Canberra",
          "tld": "au",
          "iso3": "AUS",
          "iso2": "AU"
      },
      "geometry": {
          "coordinates": [149.13, -35.17],
          "type": "Point"
      },
      "id": "AU"
  }, {
      "properties": {
          "country": "Austria",
          "city": "Vienna",
          "tld": "at",
          "iso3": "AUT",
          "iso2": "AT"
      },
      "geometry": {
          "coordinates": [16.22, 48.12],
          "type": "Point"
      },
      "id": "AT"
  }, {
      "properties": {
          "country": "Aruba",
          "city": "Oranjestad",
          "tld": "aw",
          "iso3": "ABW",
          "iso2": "AW"
      },
      "geometry": {
          "coordinates": [-70.02, 12.31],
          "type": "Point"
      },
      "id": "AW"
  }, {
      "properties": {
          "country": "India",
          "city": "New Delhi",
          "tld": "in",
          "iso3": "IND",
          "iso2": "IN"
      },
      "geometry": {
          "coordinates": [77.12, 28.36],
          "type": "Point"
      },
      "id": "IN"
  }, {
      "properties": {
          "country": "Tanzania",
          "city": "Dar es Salaam",
          "tld": "tz",
          "iso3": "TZA",
          "iso2": "TZ"
      },
      "geometry": {
          "coordinates": [39.17, -6.48],
          "type": "Point"
      },
      "id": "TZ"
  }, {
      "properties": {
          "country": "Azerbaijan",
          "city": "Baku",
          "tld": "az",
          "iso3": "AZE",
          "iso2": "AZ"
      },
      "geometry": {
          "coordinates": [49.52, 40.23],
          "type": "Point"
      },
      "id": "AZ"
  }, {
      "properties": {
          "country": "Ireland",
          "city": "Dublin",
          "tld": "ie",
          "iso3": "IRL",
          "iso2": "IE"
      },
      "geometry": {
          "coordinates": [-6.14, 53.19],
          "type": "Point"
      },
      "id": "IE"
  }, {
      "properties": {
          "country": "Indonesia",
          "city": "Jakarta",
          "tld": "id",
          "iso3": "IDN",
          "iso2": "ID"
      },
      "geometry": {
          "coordinates": [106.49, -6.1],
          "type": "Point"
      },
      "id": "ID"
  }, {
      "properties": {
          "country": "Ukraine",
          "city": "Kiew",
          "tld": "ua",
          "iso3": "UKR",
          "iso2": "UA"
      },
      "geometry": {
          "coordinates": [30.554, 50.441],
          "type": "Point"
      },
      "id": "UA"
  }, {
      "properties": {
          "country": "Qatar",
          "city": "Doha",
          "tld": "qa",
          "iso3": "QAT",
          "iso2": "QA"
      },
      "geometry": {
          "coordinates": [51.32, 25.17],
          "type": "Point"
      },
      "id": "QA"
  }, {
      "properties": {
          "country": "Mozambique",
          "city": "Maputo",
          "tld": "mz",
          "iso3": "MOZ",
          "iso2": "MZ"
      },
      "geometry": {
          "coordinates": [32.35, -25.57],
          "type": "Point"
      },
      "id": "MZ"
  }]
}