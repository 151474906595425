import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import PointPopup from '../../../components/PointPopup/PointPopup'
import conversionFun from '../../../utils/conversionFun';
import filterDataFun from '../../../utils/filterDataFun';
const API_BASE = process.env.REACT_APP_API_URL
const layers = []
const createImage = async (map, chooseId, acledStartYear, acledEndYear, imgInfo, typeSource, imgId) => {
  // Add the image to the map style.
  let image = new Image(imgInfo.width, imgInfo.height);
  image.src = `data:image/png;base64,${imgInfo.imageData}`;
  const { startYear, endYear, monthStart, monthEnd } = conversionFun(acledStartYear, acledEndYear)
  const sourceData = await fetch(`${API_BASE}/arcgis/queryACLEDCHECKBOXAPI/${chooseId}/${startYear}/${endYear}/${typeSource.replace(/\//g, '%2F')}`).then(res => res.json())
  const newSourceData = filterDataFun(sourceData.features, startYear, endYear, monthStart, monthEnd)
  map.addImage(imgId, image);
  map.addSource(typeSource, {
    'type': 'geojson',
    'data': { ...sourceData, features: newSourceData }
  })

  // Add a layer to use the image to represent the data.
  map.addLayer({
    'id': `${typeSource}s`,
    'type': 'symbol',
    'source': typeSource, // reference the data source
    'layout': {
      'icon-image': imgId, // reference the image
      'icon-size': 1
    }
  });
  // console.log(map.getStyle().layers,'map.getStyle().layers');
  if (!layers.some(value => value === `${typeSource}s`)) {
    layers.push(typeSource)
  }
}

const deleteImage = (map, source, imgId) => {
  if (map.hasImage(imgId)) {
    map.removeImage(imgId);
  }
  if (map.getLayer(source + 's')) {
    map.removeLayer(source + 's');
    map.removeSource(source);
  }
}
let newChooseId;
const acledSelectedFun = (map, acledEvent, acledSwitch, acledClass, chooseId, acledStartYear, acledEndYear,clearTocLayers) => {
  if (chooseId !== newChooseId) {
    newChooseId = chooseId
    layers.forEach(layer => {
      deleteImage(map, layer, layer)
    })
    layers.length = 0
  }
  if (acledSwitch) {
    clearTocLayers()
    for (const uniqueValueInfo of acledClass.drawingInfo.renderer.uniqueValueInfos) {
      for (const types of acledClass.types) {
        if (types.id == uniqueValueInfo.value) {
          if (types.checked) {
            // console.log(map.getLayer(types.id + 's'),"map.getLayer(types.id + 's')");
            !map.getLayer(types.id + 's') && createImage(map, chooseId, acledStartYear, acledEndYear, uniqueValueInfo.symbol, types.id, types.id);
            const popup = new mapboxgl.Popup({
              closeButton: true,
              maxWidth: '328px',
            });
            map.on('click', types.id + 's', async (e) => {
              map.getCanvas().style.cursor = 'pointer';
              const feature = e.features[0];
              const result = await fetch(`${API_BASE}/arcgis/queryACLEDPOINTPOPUPSAPI/${chooseId}/${feature.properties.longitude}/${feature.properties.latitude}`).then(res => res.json())
              const PointPopups = document.createElement("div");
              ReactDOM.render(
                <PointPopup pointEvent={acledEvent} pointClass={acledClass} chooseId={chooseId} resultContent={result.features} />,
                PointPopups
              );
              popup
                .setLngLat(e.lngLat)
                .setDOMContent(PointPopups)
                .addTo(map);
            })
          } else {
            deleteImage(map, types.id, types.id)
          }
        }
      }
    }
  } else {
    layers.forEach(layer => {
      deleteImage(map, layer, layer)
    })
  }
}

export {
  acledSelectedFun
}

