/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


// Copied from MapControl.js
import React, { useState, useEffect } from 'react';
import { Row, Col, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import { setFsiSelected, setAgoSelected, removeMapLayer, setMapLayer,
   setMapMarker, setIUUAOI, setFirstVisit_dropdown, setAOI_dropdown, setScoreByCountry, setDateFirstVisit, setDateAreaInterest, setIsFirstVisitChecked} from '../../../../slices/mapSlice';
import LayerSeafood from './LayerSeafood';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from "react-redux-i18n";

import "./IUULayers.css";
import {RadioGroup, Radio} from 'react-radio-group';
//import LayerAOI from './Layers_AOI.js';
//import LayerAIS from './LayerAIS';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function IUULayers(props)
{
  const [showData, setShowData] = useState(true);
  const [eez_selected, setEez_selected] = useState(false);
  const [radioValue, setRadioValue] = useState("FSI");
  
  var width = "20rem";
  const dispatch = useDispatch();

  const selectedChallenge   = useSelector(state => state.filters.irChallengeType);
  const firstVisit_dropdown = useSelector(state => state.map.firstVisit_dropdown);
  const AOI_dropdown        = useSelector(state => state.map.AOI_dropdown);
  const selectedDateFV      = useSelector(state => state.map.dateSelectedFV);
  const selectedDateAOI     = useSelector(state => state.map.dateSelectedAOI);

  var dateFV = new Date(selectedDateFV);
  var dateAOI = new Date(selectedDateAOI);
  const [previousLayer, setPreviousLayer] = useState("seafood_no_selection");
  const [isScoreByCountChecked, setScoreCountry] = useState(false);
  const [isAOIChecked, setAreaOfInterest] = useState(true);
  const [isViewFishingChecked, setViewFishing] = useState(true);
  const [isFirstVisitChecked, set_FirstVisitChecked] = useState(false);
  const [isSeaFoodChecked, setSeaFood] = useState(false);

  const showHideData = () => {
    setShowData(!showData);
  }

  const renderCaret = () => {
    if (showData) {
      return (
        <FontAwesomeIcon className="caret" icon={faAngleDown} />
      );
    } else {
      return (
        <FontAwesomeIcon className="caret" icon={faAngleUp} />
      );
    }
  }

  const renderCollapseBar = () => {
    return (
      <Row>
        <Col xs={9}>
          <div className="dataBoxTitle dataLayerTitle">
            <Translate value="dataLayers" />
          </div>
        </Col>
        <Col xs={3}>
          <div className="dataBoxHide dataLayerTitle" onClick={showHideData}>
            {renderCaret()}
          </div>
        </Col>
      </Row>
    )
  }

  
function display_AOI(value)
{
  if(value)
  {
    setAreaOfInterest(true);
    setViewFishing(true);
    dispatch(setMapMarker(true));
    dispatch(setIUUAOI(true));
  }
  else
  {
    setAreaOfInterest(false);
    setViewFishing(false);
    dispatch(setMapMarker(false));
    dispatch(setIUUAOI(false));

    dispatch(removeMapLayer(previousLayer));
    dispatch(setMapLayer('seafood_no_selection'));
    setPreviousLayer('seafood_no_selection');
    setSeaFood(false);
  }
}


  useEffect(() => 
  {
    reset();
    switch (selectedChallenge)
    {
      case "IUU Fishing":
        display_AOI(true);
        break;
      case 'Security Environment':
        display_AOI(false);
        dispatch(setFsiSelected(true));
        setRadioValue('FSI');
        break;
      case 'Violence and Arms':
        break;
      default:
        break;
    }

  },[selectedChallenge])


  const renderData = () => 
  {
    var s = [];
    switch (selectedChallenge)
    {
      case "IUU Fishing":
        s = DLAYERS[0];
        break;
      case 'Security Environment':
        s = DLAYERS[1];
        break;
      case 'Violence and Arms':
        break;
      default:
        break;
    }

    return (
      <div className="mapLayerPanel IUULayerCheckboxPanel">
        <div>
          {s}
        </div>
      </div>
    )
  }

  function reset()
  {
     // const layer = "seafood_no_selection";
      setEez_selected(false);

      set_FirstVisitChecked(false);
      setSeaFood(false);
      /*
      dispatch(removeMapLayer(previousLayer));
      dispatch(setMapLayer(layer));
      setPreviousLayer(layer);*/


      dispatch(setAOI_dropdown('sc_aoi_0'));

      dispatch(setAgoSelected(false));
      dispatch(setFsiSelected(false));
      dispatch(setMapLayer("eez_not_selected"));
      dispatch(removeMapLayer("eez_selected"));
      
      dispatch(setFirstVisit_dropdown('0'));
      dispatch(setScoreByCountry(false));
      
      setScoreCountry(false);
      setFV_dropdown('-1');
      dispatch(setIsFirstVisitChecked(false));
  }

  const toggle_eez = () => 
  {
    setEez_selected(!eez_selected)

    if (!eez_selected)
    {
      dispatch(setMapLayer("eez_selected"))
      dispatch(removeMapLayer("eez_not_selected"))
    }
    else 
    {
      dispatch(setMapLayer("eez_not_selected"))
      dispatch(removeMapLayer("eez_selected"))
    }
  }

  const toggle_scoreByCountry = () => 
  {
    setScoreCountry(!isScoreByCountChecked);
    if(isScoreByCountChecked)
      dispatch(setScoreByCountry(false));
    else
    {
      dispatch(setScoreByCountry(true));
    }
  }

  function setAOIDropDown(value)
  {
    if(isAOIChecked)
    {
      dispatch(setAOI_dropdown(value));
    }
    else
      setAOI_dropdown(value);

    //renderAreaOfInterest();
  }

  const toggle_seafood = () => {

    setSeaFood(!isSeaFoodChecked);
  }

  const toggle_AOI = () => 
  {
    setAreaOfInterest(!isAOIChecked);
  
    if(isAOIChecked)
    {
      setViewFishing(false);
      //setFirstVisitChecked(false);
      dispatch(setIUUAOI(false));
      dispatch(setMapMarker(false));
    }
    else
    {
      dispatch(setIUUAOI(true));
    }
  }

  const toggle_VFA = () => 
  {
    setViewFishing(!isViewFishingChecked);

    if(isViewFishingChecked)
    {
      dispatch(setMapMarker(false));
    }
    else
    {
      dispatch(setMapMarker(true));
    }
  }

  const toggle_FV = () => 
  {
    set_FirstVisitChecked(!isFirstVisitChecked);
    if(isFirstVisitChecked)
    {
      dispatch(setIsFirstVisitChecked(false));
    }
    else
    {
      dispatch(setIsFirstVisitChecked(true));
    }
  }

  function setFV_dropdown(value)
  {
    if(isFirstVisitChecked)
    {
      dispatch(setFirstVisit_dropdown(value));
    }
    else
    {
      dispatch(setFirstVisit_dropdown(value < 0 ?'0':value));
    }
  }

  function setStartDate(date,i)
  {
    
    switch(i)
    {
      case 0:
        dateFV = date;
        dispatch(setDateFirstVisit(date.toString()));
        break;
      case 1:
        dateAOI = date;
        dispatch(setDateAreaInterest(date.toString()));
        break;
      default:
        break;
    }
  }

  const DLAYERS = 
  [
      <>
          <div className="mapLayerTitle">
              IUU Fishing
          </div>
          <div id="menu" >
          <ul>
              <li>
                  <input id="checkbox_iuuf" type="checkbox" name="checkbox_iuuf" value="iuuf" checked={isScoreByCountChecked} onChange={toggle_scoreByCountry} />
                  <label htmlFor="checkbox_iuuf">IUUF Score by Country</label>
              </li>
              <li>
                  <input id="eez" type="checkbox" name="eez" value="eez" checked={eez_selected} onChange={toggle_eez} />
                  <label htmlFor="eez">Exclusive Economic Zone (EEZ)</label>
              </li>
              <li>
                <input id="IAoI" type="checkbox" name="IAoI" value="IAoI" checked={isAOIChecked} onChange={toggle_AOI} />
                <label htmlFor="IAoI" >IUU Areas of Interest </label>
                <select disabled={!isAOIChecked} value={AOI_dropdown} onChange={e => setAOIDropDown(e.target.value)}>
                  {/*<option value="sc_aoi_0">No Selection</option>
                  <option value="sc_aoi_0">All Areas</option>*/}
                  <option value="sc_aoi_1">Argentina AOI</option>
                  <option value="sc_aoi_2">Galápagos AOI</option>
                  <option value="sc_aoi_3">Perú AOI</option>
                  <option value="sc_aoi_0">All Areas</option>
                </select>

                <div disabled={!isAOIChecked} style={{"marginLeft": "1rem" }}>
                <input id="VFA" type="checkbox" name="VFA" value="VFA" checked={isViewFishingChecked} onChange={toggle_VFA} />
                  <label htmlFor="VFA" >View Activities </label>
                  <DatePicker selected={dateAOI} 
                    className="calendarVFA" 
                    dateFormat="MM dd yyyy" 
                    dropdownMode="scroll" 
                    fixedHeight={true} 
                    popperPlacement="top-start"
                    popperProps={{ strategy: "fixed" }}
                    onChange={(date) => setStartDate(date,1)} />
                  <FontAwesomeIcon id="calIcon1" icon={faCalendarAlt} />
                </div>

                <div>
                    <input id="FV" type="checkbox" name="FV" value="FV" checked={isFirstVisitChecked} onChange={toggle_FV} />
                    <label htmlFor="FV">First Visit </label>
                    <div className="inlineContainer">
                    <select disabled={!isFirstVisitChecked} value={firstVisit_dropdown} onChange={e => setFV_dropdown(e.target.value)}>
                      <option value="1">Central America</option>
                      <option value="2">South America Atlantic</option>
                      <option value="3">South America Pacific</option>
                      <option value='0'>All Areas</option>
                    </select>
                  
                  
                <div id="calenderDivS" disabled={!isFirstVisitChecked}>
                <DatePicker selected={dateFV} 
                  className="calendarVFA" 
                  calendarClassName="calendarFV" 
                  wrapperClassName="calWrap" 
                  dateFormat="MM dd yyyy" 
                  dropdownMode="scroll"
                  fixedHeight={true}
                  popperPlacement="top-start"
                  popperProps={{ strategy: "fixed" }}
                  onChange={(date) => setStartDate(date, 0)} />
                <FontAwesomeIcon id="calIcon2" icon={faCalendarAlt} />
                </div>
                </div>
                </div>
              </li>
              <li>
                <input id="seafood" type="checkbox" name="seafood" value="seafood" checked={isSeaFoodChecked} onChange={toggle_seafood} />
                  <LayerSeafood isChecked={isSeaFoodChecked}/>            
              </li>            
          </ul>
          </div>
      </>,//1
  
      <>
      <div className="mapLayerTitle" >
          Security Environment
      </div>
      <div id="menu" style={{padding: ".5rem 0 0 0"}}>
          <div>
              <RadioGroup name="Radio_SE" selectedValue={radioValue} onChange={toggleRadioButton}>
                  <div>
                      <Radio value="FSI" /> FSI Annual Score By Country
                  </div>
                  {/* <div>
                      <Radio value="Hyperion" /> Today’s Hyperion Pulse Score
                  </div> */}
              </RadioGroup>
          </div>
      </div>
      </>,//2
  ];

  function toggleRadioButton(value)
  {
    switch(value)
    {
      case 'FSI':
        setRadioValue('FSI');
        //dispatch(setAgoSelected(false));
        dispatch(setFsiSelected(true));
        break;
/*       case 'Hyperion':
        setRadioValue('Hyperion');   
        dispatch(setFsiSelected(false));
        //dispatch(setAgoSelected(true));
        break; */
      default:
        setRadioValue('FSI');
        //dispatch(setAgoSelected(false));
        dispatch(setFsiSelected(false));
        break;   
    }
  }


 
  width = (selectedChallenge === "IUU Fishing"?"20rem":"15rem");
  return (
    <>
      <div className="dataBoxContainer dataLayerContiner">
        <div className="dataBoxTitleContainer layerContainer" style={{width: width}}>
          {renderCollapseBar()}
          <Collapse in={showData}>
            {renderData()}
          </Collapse>
        </div>
      </div>

    </>
  );
}

