/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


// import IUUinstructions from ''
// import crimeInstructions from ''
// export const layerInstructions = {
//     ...IUUinstructions,
//     ...crimeInstructions
    
// }

// i.e.

// {layer_name: 'summer-tuna', layer_type (AIS | SEAFOOD |...|... ),  opacity_key: 'heatmap-opacity', opacity_value: 0},
const unloadAllSeafoodLayersArray = [        
    {layer_name: 'summer-tuna',     opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'summer-shark',    opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'summer-viirs',    opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'summer-billfish', opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'winter-tuna',     opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'winter-shark',    opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'winter-viirs',    opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'winter-billfish', opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'spring-tuna',     opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'spring-shark',    opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'spring-viirs',    opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'spring-billfish', opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'fall-tuna',       opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'fall-shark',      opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'fall-viirs',      opacity_key: 'heatmap-opacity', opacity_value: 0},
    {layer_name: 'fall-billfish',   opacity_key: 'heatmap-opacity', opacity_value: 0}
]
export const layerInstructions = {
    //fsi is handled by map directly

    //eez
    "eez_selected": [
        {layer_name: 'eez-v11',         opacity_key: 'line-opacity', opacity_value: 1},
        {layer_name: 'eez-v11-label',   opacity_key: 'text-opacity', opacity_value: 1}
    ],
    "eez_not_selected": [
        {layer_name: 'eez-v11',         opacity_key: 'line-opacity', opacity_value: 0},
        {layer_name: 'eez-v11-label',   opacity_key: 'text-opacity', opacity_value: 0}
    ],
    //----
    //AIS
    //----
    "AIS_no_selection": [
        // unload all AIS layers
        {layer_name: 'summer-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0}
    ],
    "summer-2018-engaged-fishing": [
        //unload conflicting layers
        {layer_name: 'summer-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        //load layer
        {layer_name: 'summer-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0.9},

    ],
    "summer-2019-engaged-fishing": [
        //unload conflicting layers
        {layer_name: 'summer-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        //load layer
        {layer_name: 'summer-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0.9},

    ],
    "summer-2018-non-fishing": [
        //unload conflicting layers
        {layer_name: 'winter-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        // load layer
        {layer_name: 'summer-2018-non-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0.9}
    ],
    "summer-2019-non-fishing": [
        //unload conflicting layers
        {layer_name: 'winter-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        // load layer
        {layer_name: 'summer-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0.9}
    ],
    "winter-2019-engaged-fishing": [
        //unload conflicting layers
       {layer_name: 'summer-2018-non-fishing',      opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        // load layer
        {layer_name: 'winter-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0.9}
    ],
    "winter-2019-non-fishing": [
        //unload conflicting layers
       {layer_name: 'summer-2018-non-fishing',      opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        // load layer
        {layer_name: 'winter-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0.9}
    ],
    "winter-2018-non-fishing": [
        //unload conflicting layers
       {layer_name: 'summer-2018-non-fishing',      opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        // load layer
        {layer_name: 'winter-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0.9}
    ],
    "winter-2018-engaged-fishing": [
        //unload conflicting layers
       {layer_name: 'summer-2018-non-fishing',      opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-non-fishing',     opacity_key: 'heatmap-opacity', opacity_value: 0},
        // load layer
        {layer_name: 'winter-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0.9}
    ],
    //----
    //seafood
    //----
    "seafood_no_selection": [
        //unload all seafood layers
        ...unloadAllSeafoodLayersArray,
    ],
    // summer layers
    "summer-tuna": [
        // unload conflicting layers
        // {layer_name: 'summer-tuna', opacity_key: 'heatmap-opacity', opacity_value: 0},
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'summer-tuna', opacity_key: 'heatmap-opacity', opacity_value: 0.9}

    ],
    "summer-shark": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'summer-shark', opacity_key: 'heatmap-opacity', opacity_value: 0.9}

    ],
    "summer-viirs": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'summer-viirs', opacity_key: 'heatmap-opacity', opacity_value: 0.9}
    ],
    "summer-billfish": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'summer-billfish', opacity_key: 'heatmap-opacity', opacity_value: 0.9}
    ],
    //winter layers
    "winter-tuna": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'winter-tuna', opacity_key: 'heatmap-opacity', opacity_value: 0.9},
    ],
    "winter-shark": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'winter-shark', opacity_key: 'heatmap-opacity', opacity_value: 0.9},
    ],
    "winter-viirs": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'winter-viirs', opacity_key: 'heatmap-opacity', opacity_value: 0.9},
    ],
    "winter-billfish": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'winter-billfish', opacity_key: 'heatmap-opacity', opacity_value: 0.9},
    ],
    //spring layers
    "spring-tuna": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'winter-tuna', opacity_key: 'heatmap-opacity', opacity_value: 0.9},
    ],
    "spring-shark": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'winter-shark', opacity_key: 'heatmap-opacity', opacity_value: 0.9},
    ],
    "spring-viirs": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'spring-viirs', opacity_key: 'heatmap-opacity', opacity_value: 0.9},
    ],
    "spring-billfish": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'spring-billfish', opacity_key: 'heatmap-opacity', opacity_value: 0.9},
    ],

    //fall layers
    "fall-tuna": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'fall-tuna', opacity_key: 'heatmap-opacity', opacity_value: 0.9},
    ],
    "fall-shark": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'fall-shark', opacity_key: 'heatmap-opacity', opacity_value: 0.9},
    ],
    "fall-viirs": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'fall-viirs', opacity_key: 'heatmap-opacity', opacity_value: 0.9},
    ],
    "fall-billfish": [
        // unload conflicting layers
        ...unloadAllSeafoodLayersArray,
        // load layer
        {layer_name: 'fall-billfish', opacity_key: 'heatmap-opacity', opacity_value: 0.9},
    ],
    "IUUF-no-selected":[
        ...unloadAllSeafoodLayersArray,
        {layer_name: 'summer-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2018-non-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2019-non-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'summer-2019-non-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-engaged-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'winter-2018-non-fishing', opacity_key: 'heatmap-opacity', opacity_value: 0},
        {layer_name: 'eez-v11', opacity_key: 'line-opacity', opacity_value: 0},
        {layer_name: 'eez-v11-label', opacity_key: 'text-opacity', opacity_value: 0}
    ]

}