/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */

import React from 'react';
import { useSelector } from "react-redux"

import IUU from "./IUU/IUU"
import TOC from './TOC/TOC'
import SecurityEnvironment from "./SecurityEnvironment";
import WindwardPieGraphs from './WindwardPieGraphs';
import "./Infographs.css";

export default function InfographContainer(props)
{
  //need selectedCountry and infographData from redux
  const data = useSelector(state=>state.infograph.data);
  const selectedChallenge = useSelector(state=>state.filters.irChallengeType);
  const selectedCountry = useSelector(state=>state.filters.selectedCountry);
  const irChallengeType = useSelector(state=>state.filters.irChallengeType);

  const strings =[<IUU countryData={data} />,//0
                  <SecurityEnvironment countryData={data}/>,//1
                  // <WindwardPieGraphs display={-1}/>//2
                  <TOC countryData={data} selectedCountry={selectedCountry} />
                   ];


  function getInfograph(select = "")
  { 
    switch(select)
    {
      case "IUU Fishing":
        return strings[0];
      case 'Security Environment':
        return strings[1];
      case 'Violence and Arms':
        return strings[2];
      default:
        return strings[3];
    }
  }
   
  return(
    <>
    <div className="analytics-container">
      <div>
          {getInfograph(selectedChallenge)}
      </div>
    </div>
    </>
  );
}