/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */



import './News.css';
import LinkWithConfirm from "../../components/LinkWithConfirm/LinkWithConfirm"
import React from "react"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment";
import Grid from '@material-ui/core/Grid'
import { Translate } from "react-redux-i18n"


export default function NewsItem(props){

  const { country, url, title, source, dateTime, language, topic, oddEven} = props
  const dt = moment(dateTime, "YYYYMMDDTHHmmssZ");

  return(
    <>
      <div className={"newsCard " + oddEven}>
        <div className={"newsCardTitle"}>{title}</div>
        <div className={"newsCardLanguage"}><Translate value={"newsCardLanguage"}/>: {language}</div>
        <Grid  className={"newsCardGrid"}>
          <Grid container item xs={12} spacing={3} direction={"row"}>
            <Grid item xs={3} direction={"column"}><Translate value={"newsCardTopic"}/>: {topic}</Grid>
            <Grid item xs={3} direction={"column"}><Translate value={"newsCardCountry"}/>: {country}</Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} direction={"row"}>
            <Grid item xs={3} direction={"column"} ><Translate value={"newsCardSource"}/>: {source}</Grid>
            <Grid item xs={3} direction={"column"} className={"newsCardDateTime"}><Translate value={"newsCardDateTime"}/>: {dt.format('MMMM DD, YYYY hh:mm a')}</Grid>
            <Grid item xs={1} direction={"column"}></Grid>
            <Grid item xs={3} direction={"column"}>
              <span className={"newsCardLink"}>
              <LinkWithConfirm
                href={url}
                target="_blank"
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} />
                &nbsp;<Translate value={"newsCardOpenArticle"}/>
              </LinkWithConfirm>
              </span>
            </Grid>
          </Grid>

        </Grid>
      </div>
    </>
  )
}