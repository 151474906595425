/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useState, useEffect} from 'react';
import { Row, Col, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import { faAngleDown, faAngleUp, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Translate } from'react-redux-i18n';
import { makeStyles } from '@material-ui/core/styles';
import { renderInfoIconWithTooltip } from '../../../utils/helperFunctions';
import Tooltip from '@material-ui/core/Tooltip';

//import Statistic from "../Statistic";
import MSIFisheries from "./MSIFisheries";
import MSIOverview from "./MSIOverview";
import TransshipmentModal from "./TransshipmentModal";
import FishingModal from "./FishingModal";
import TunaCatchAnimateModal from "./TunaCatchAnimateModal";
import WindwardPieGraphs from './../WindwardPieGraphs';
import FirstVisitUI from './../FirstVisitUI';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  iframe: {
    minHeight: '100%',
    maxHeight: '100%',
    width: '100%',
    resize: 'both',
    overflow: 'auto',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //   height: '80%',
    //   width: '80%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    //   boxShadow: theme.shadows[5],
    //   padding: theme.spacing(2, 4, 3),
    height: '90%',
    minWidth: '90%',
    maxWidth: '90%',
    overflowY: 'scroll',
  },
}));

export default function IUU(props){

  const classes = useStyles();

  const [showData, setShowData] = useState(true);

  const [iuuScore, setIUUScore] = useState("N/A");
  const [fisheryScore, setFisheryScore] = useState("N/A");
  const [overviewScore, setOverviewScore] = useState("N/A");
  const [showTransshipmentModal, setTransshipmentModal] = useState(false);
  const [showFishingModal, setFishingModal] = useState(false);
  const [showTunaCatchAnimateModal, setTunaCatchAnimateModal] = useState(false);

  const {countryData} = props;
  const AOI_dropdown = useSelector(state=>state.map.AOI_dropdown);

  const [windWardComp, setWindWard] = useState("");
  const [FV_UI, setFV_UI] = useState("");
  const dateSelectedAOI   = useSelector(state => state.map.dateSelectedAOI);
  const dateSelectedFV    = useSelector(state => state.map.dateSelectedFV);
  const languageFilter    = useSelector(state => state.filters.languageFilter);
  const selectedCountry    = useSelector(state => state.filters.countryFilter);

  //TODO: Add translations for these tooltips in spanish and portuguese and move variables to index.js
  const maritimeActivitiesTooltip = "Number of maritime activities in selected AOIs. Only activities associated with fishing, cargo (bulk carriers, reefers, etc. are included in this category) or tanker vessels are counted. \"Transshipment\" (or meetings) is defined as direct interaction between two vessels outside a port region. Transshipment and Fishing events can be displayed on the map using the 'View Activities' option in the Data Layers box. Clicking on a particular event on the map will display more information. Data is extracted using the Windward Analytics platform.";
  const IUUTooltip = "Country selection can be changed from the top dropdown, or by clicking another country on the map. The IUU Index has been developed by Poseidon Aquatic Resource Management Ltd. and the Global Initiative Against Transnational Organized Crime.";
  
  const showHideData = () => {
    setShowData(!showData);
  }

  const renderCaret = () => 
  {
    if(showData){
      return(
        <FontAwesomeIcon className="caret" icon={faAngleDown}/>
      );
    }else{
      return(
        <FontAwesomeIcon className="caret" icon={faAngleUp}/>
      );
    }
  }

  const renderCollapseBar = () => 
  {
    return(
      <Row>
        <Col xs={9}>
          <div className="dataBoxTitle dataLayerTitle">
            IUU
          </div>
        </Col>
        {/*<Col xs={3}>
          <div className="dataBoxHide dataLayerTitle" onClick={showHideData}>
            {renderCaret()}
          </div>
        </Col>*/}
      </Row>
    )
  }

  useEffect( () => {
    if(countryData){
      ("iuuf_IUU_Score" in countryData) ? setIUUScore(countryData["iuuf_IUU_Score"]) : setIUUScore("N/A");
      ("ss_StableSeasScore" in countryData) ? setOverviewScore(countryData["ss_StableSeasScore"]) : setOverviewScore("N/A");
      ("ss_Fisheries" in countryData) ? setFisheryScore(countryData["ss_Fisheries"]) : setFisheryScore("N/A");
    }

  }, [countryData]);

  const handleTransshipmentOpen = () => {
    setTransshipmentModal(true);
  }
  const handleTransshipmentClose = () => {
    setTransshipmentModal(false);
  }

  const handleFishingOpen = () => {
    setFishingModal(true);
  }

  const handleFishingClose = () => {
    setFishingModal(false);
  }

  const handleTunaCatchOpen = () => {
    setTunaCatchAnimateModal(true);
  }

  const handleTunaCtchClose = () => {
    setTunaCatchAnimateModal(false);
  }

  useEffect( () => 
  {
    var str = "";
    var myDate = new Date(dateSelectedAOI);
    
   // console.log("s: ", <Translate value={"Maritimeactivitiesin"}/> );
    var s = "Maritime activities in";
    if(AOI_dropdown)
    {
      switch(AOI_dropdown)
      {
        case 'sc_aoi_3'://3
          str = <WindwardPieGraphs display={3} lang={languageFilter} title={s+" Perú AOI"} dateAOI={myDate}/>;
          break;
        case 'sc_aoi_1'://1
          str = <WindwardPieGraphs display={1} lang={languageFilter} title={s+" Argentina AOI"} dateAOI={myDate}/>;
          break;
        case 'sc_aoi_2'://2
          str = <WindwardPieGraphs display={2} lang={languageFilter} title={s+" Galápagos AOI"} dateAOI={myDate}/>;
          break;
        case 'sc_aoi_0':
        default: 
          str = <WindwardPieGraphs display={0} dateAOI={myDate}/>;
          break;
      }
    }

    str =  <>
      {str}
      <Row>
      <Col xs={5}>
        <a className="reference-url" href={"https://windward.ai/"} target="_blank" rel={"noreferrer"}>
          <Translate value="referenceUrl"/>
        </a>
        <FontAwesomeIcon icon={faExternalLinkAlt}/>
      </Col>
      <Col xs={7} className={"informationContainer"}>
        <Translate value={'TotalsVesselsincludeCargo'}/>
        <Tooltip title={maritimeActivitiesTooltip} placement="right">
          <span> [...] </span>
        </Tooltip>
      </Col>
      </Row>
      </>;

    setWindWard(str)

  }, [AOI_dropdown, dateSelectedAOI,languageFilter]);


  useEffect( () => 
  {
    var str = "";
    var myDate = new Date(dateSelectedFV);

    str = <FirstVisitUI dateFV={myDate} lang={languageFilter}/>;

    setFV_UI(str);

  }, [dateSelectedFV,languageFilter]);


  function iuuscore()
  {
      var s = <>
          <Row>
            <Col xs={4}>
            </Col>
            <Col xs={4}>
              <div className={"stat-data2"}>
                <div className="stat-data-container">
                  <div className={"stat-data-data2"}>{iuuScore}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
          <Col  className="urlContainer">
            <a className="reference-url" href={"https://www.iuufishingindex.net/about"} target="_blank" rel={"noreferrer"}><Translate value="referenceUrl"/></a>
            <FontAwesomeIcon icon={faExternalLinkAlt}/>
          </Col>
        </Row>
        </>;
        return s;
  }


  return(
    <div className="dataBoxContainer">
      <div className="dataBoxTitleContainer">
        {renderCollapseBar()}
      </div>
      <Collapse in={showData}>
        <div className="dataBoxData">
          <span className={"iuuModalIcon"}>
            {windWardComp}
          </span>
          <div className="iuu-sep"></div>
          {FV_UI}
          <div className="iuu-sep"></div>
          <span className={"iuuModalIcon"}>
            <div className="IUU_score">
              {selectedCountry + ' '}
              <Translate value={'IUUscore'}/>
              {renderInfoIconWithTooltip(IUUTooltip)}
            </div>
            {iuuscore()}
          </span>
          
          <div className="iuu-sep"></div>
          <span className={"iuuModalIcon"}>
            <div className="newsInfographCountryLabel" onClick={handleTransshipmentOpen}><Translate value={'tsModalLabel'}/></div>
            <img className={"transshipment-image"} src={window.location.origin + "/images/transshipmentChina.gif"} alt={"Trans shipment data"} onClick={handleTransshipmentOpen}/>
            <Modal
              className={classes.modal}
              open={showTransshipmentModal}
              onClose={handleTransshipmentClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout:500,
              }}
            >
              <Fade in={showTransshipmentModal}>
              <div className={classes.paper}>
                <TransshipmentModal/>
              </div>
              </Fade>
            </Modal>
          </span>

          <div className="iuu-sep"></div>
          <span className={"iuuModalIcon"}>
            <div className={"newsInfographCountryLabel"} onClick={handleFishingOpen}><Translate value={'fishModalLabel'}/></div>
            <img className={"fish-by-eez-image"} src={window.location.origin + "/images/crusteansAnimation.gif"} alt={"Fishing data"} onClick={handleFishingOpen}/>
            <Modal
              className={classes.modal}
              open={showFishingModal}
              onClose={handleFishingClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout:500,
              }}>
              <Fade in={showFishingModal}>
              <div className={classes.paper}>
                <FishingModal/>
              </div>
              </Fade>
            </Modal>
          </span>

          <div className="iuu-sep"></div>
          <span className={"iuuModalIcon"}>
            {/*}<img src={window.location.origin + "/images/icon_fishing.svg"} alt={"Fishing data"} onClick={handleFishingOpen}/>{*/}
            <div className={"newsInfographCountryLabel"} onClick={handleTunaCatchOpen}><Translate value={'tunaModalLabel'}/></div>
            <img className={"fish-by-eez-image"} src={window.location.origin + "/images/tuna_animation_JS.gif"} alt={"Tuna and Billfish History"} onClick={handleTunaCatchOpen}/>
            <Modal
              className={classes.modal}
              open={showTunaCatchAnimateModal}
              onClose={handleTunaCtchClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout:500,
              }}
            >
              <Fade in={showTunaCatchAnimateModal}>
              <div className={classes.paper}>
                <TunaCatchAnimateModal/>
              </div>
              </Fade>
            </Modal>
          </span>

          <div className="iuu-sep"></div>

          <MSIFisheries data={fisheryScore} title={"Marine Security Index/Fisheries"} referenceURLLink="https://www.stableseas.org"/>
          <div className="iuu-sep"></div>
          <MSIOverview data={overviewScore} title={"Maritime Security Index/Overview"} />
          <div className="iuu-sep"></div>


        </div>
      </Collapse>
    </div>
  );
}