/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */

import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSipriLegend, setSipriCountry } from "../../../slices/toc/sipriSlice"
import TocUtils from '../../../utils/toc'
import useCountry from '../../../hooks/useCountry'
import useMap from '../../../hooks/useMap'
import useArrow from '../../../hooks/useArrow'

const { SipriUtil } = TocUtils

const SipriMap = (props) => {
  const {
    uri,
    countryPoly
  } = props
  const sipriImportMapEvent = useSelector(state => state.sipri.sipriImportMapEvent)
  const sipriExportMapEvent = useSelector(state => state.sipri.sipriExportMapEvent)
  const sipriCountry = useSelector(state => state.sipri.sipriCountry)
  const selectedCountry = useSelector(state => state.filters.countryFilter)
  const sipriInd = useSelector(state => state.sipri.sipriInd)
  const [ sipriData, setSipriData ] = useState()
  const node = useRef(null)
  const dispatch = useDispatch()
  const { paintLayers } = useCountry()
  const { drawArrows } = useArrow()
  const [ map ] = useMap({ 
    node, 
    uri, 
    onSelectCountry:(country => dispatch(setSipriCountry(country))),
    enableHover: true,
    enableClickCountry: true
  })

  useEffect(() => {
    if (map && selectedCountry) {
      paintLayers(map, [selectedCountry])
      // dispatch(setSipriCountry(selectedCountry)) //todo, check the country by the top selector
    }
  }, [map, selectedCountry])

  useEffect(() => {
    let mapEvent = sipriImportMapEvent
    if(sipriInd === 'export'){
      mapEvent = sipriExportMapEvent
    }
    if (mapEvent && mapEvent.length > 0 && countryPoly && countryPoly.features) {
      const newFeatures = mapEvent.map(item => {
        let countryGeo = countryPoly.features.find(ct => ct.properties.SOVEREIGNT === item.SOVEREIGNT)
        if(!countryGeo){
          console.log(`Event country cant be found in country polygon: ${item.SOVEREIGNT}`)
          return
        }
        return Object.assign({}, item, { geometry: countryGeo.geometry })
      })
      setSipriData({ type: "FeatureCollection", features: newFeatures.filter(it=>!!it) })
    }
  }, [sipriInd, sipriImportMapEvent, sipriExportMapEvent, countryPoly])

  useEffect(() => {
    if(!map || !sipriData || !sipriData.features || sipriData.features.length === 0){
      return
    }
    if(!map.getSource('sipri_source')){
      map.addSource('sipri_source', {
        'type': 'geojson',
        'data': sipriData
      })
    }else{
      map.getSource('sipri_source').setData(sipriData)
    }
    const legend = SipriUtil.getCountryLegends(sipriData.features)
    dispatch(setSipriLegend(legend))

    if(map.getLayer('sipri_layer')){
      map.removeLayer('sipri_layer')
    }

    map.addLayer({ //todo, change to drawing info
      id: 'sipri_layer',
      type: 'fill',
      source: 'sipri_source',
      paint: {
        "fill-color": [
          'step',
          ['get', 'sum'],
          legend[0]?.color, legend[0].max,
          legend[1]?.color, legend[1].max,
          legend[2]?.color, legend[2].max,
          legend[3]?.color, legend[3].max,
          legend[4]?.color, legend[4].max, 
          legend[5]?.color
        ],
        "fill-outline-color": "rgb(110,110,110)"
      },
    }, 'country-label')
  }, [map, sipriData])

  useEffect(() => { 
    if(!map || !sipriInd || !sipriData || !sipriCountry){
      return
    }
    if (sipriInd === 'import') {
      const importCountries = sipriData.features.find(it=>it.SOVEREIGNT === sipriCountry)
      drawArrows(map, importCountries && importCountries?.properties?.xports, [sipriCountry],  'import')
    }else{
      const exportCountries = sipriData.features.find(it=>it.SOVEREIGNT === sipriCountry)
      drawArrows(map, [sipriCountry], exportCountries && exportCountries?.properties?.xports, 'export')
    }
  }, [map, sipriInd, sipriCountry, sipriData])

  return (
    <>
      <div ref={node} className='mapContainer' />
    </>
  )
}

export default SipriMap
