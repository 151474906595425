/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useState, useEffect } from 'react';
import { Col, Collapse, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faExternalLinkAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Statistic from "./Statistic";
import LineGraph from "./LineGraph";
import * as d3 from "d3";
import { Translate } from "react-redux-i18n";
import { countryCentroids } from '../../utils/CountryLookups';
import GaugeChart from 'react-gauge-chart'; //https://www.npmjs.com/package/react-gauge-chart // requires > npm i react-gauge-chart
//import { setMyPulsehData} from '../../slices/infographSlice';
//import { useDispatch } from 'react-redux';

//import myJson from "./hyperion_pulse.json";// REPLACE WITH LINK IN FUTURE. WAS UNABLE TO PULL FROM URL IN CURRENT ITERATION: https://srh.fiu.edu/pulse/hyperion_pulse.json


export default function SecurityEnvironment(props)
{
  const [showData, setShowData]       = useState(true);
  //const [fsiScore, setFSIScore]       = useState("N/A");
  const [fsiData, setFSIData]         = useState(null);
  const [giniData, setGINIData]       = useState(null);
  const [giniScore, setGINIScore]     = useState("N/A");
  const [lastYear, setLastYear]       = useState(null);
  const [povScore190, setPovScore190] = useState("N/A");//% Population Living on $1.90 a day
  const [povScore550, setPovScore550] = useState("N/A");//% Population Living on $5.50 a day

  const [total_2020, setTotal_2020] = useState("N/A");

  const [lastFive, setLastFive] = useState("N/A");
  const [lastTen, setLastTen] = useState("N/A");

  const [rank_2020, setRank_2020] = useState(null);
  const [currentCountry , setCode] = useState("N/A");

  //const [pulseData, setPulseData]    = useState(null);
  
  const { countryData } = props;
  //const dispatch = useDispatch();
  //const myPulseData = useSelector(state => state.infograph.pulseData);

  const renderCaret = () => 
  {
    if (showData) 
    {
      return (<FontAwesomeIcon className="caret" icon={faAngleDown} />);
    } 
    else 
    {
      return (<FontAwesomeIcon className="caret" icon={faAngleUp} />);
    }
  }

  const showHideData = () => {
    setShowData(!showData);
  }

  const calcFSIData = () => 
  {
    let fPrepend = "Total_20";
    let gPrepend = "GINI_20";
    let fData = [];
    let gData = [];
    let fKey, gKey = "";
    let year = "";
   // let giniYear = "";
   // let lastFSIScore = "N/A";
    let lastGINIScore = "N/A";
    for (let x = 10; x <= 20; x++)
    {
      gKey = gPrepend + x;
      fKey = fPrepend + x;
      year = "20" + x;
      if (fKey in countryData)
      {
        fData.push({
          date: d3.timeParse("%Y")(year),
          value: countryData[fKey]
        })
        //lastFSIScore = countryData[fKey];
      }
      if (gKey in countryData)
      {
       // giniYear = year;
        gData.push({
          date: d3.timeParse("%Y")(year),
          value: countryData[gKey]
        })
        lastGINIScore = countryData[gKey];
      }
    }
    
    setTotal_2020(countryData['Total_2020']);
    setLastYear((countryData['Total_2020'] - countryData['Total_2019']).toFixed(2));
    setLastFive((countryData['Total_2020'] - countryData['Total_2016']).toFixed(2));
    setLastTen ((countryData['Total_2020'] - countryData['Total_2011']).toFixed(2));

    setRank_2020(countryData['Rank_2020']);
     
    //setFSIScore(lastFSIScore);
    setFSIData(fData);
    setGINIData(gData);
    setGINIScore(lastGINIScore);
    //setPulseDataArr(pData);
    //setLastYear(giniYear);
  }


  useEffect(() => 
  {
    if (countryData != null && countryData['COUNTRY_CODE'] != currentCountry) 
    {
      setCode(countryData['COUNTRY_CODE']);//avoids the function from being called twice
      /* if(pulseData == null)
      {
        const API_BASE = process.env.REACT_APP_API_URL

        fetch(`${API_BASE}/pulse/daily`)
          .then(response => response.json())
          .then((jsonData) => 
          {
            setPulseData(parseJson(jsonData));
          })
          .catch((error) => 
          {
            console.error(error);
          })
      } */

      setPovScore190(countryData["SIPOVDDAY_" + lastYear]);
      setPovScore550(countryData["SIPOVUMIC_" + lastYear]);
      calcFSIData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[countryData]);

  function parseJson(input)
  {
    var myArr = [];
    //Object.values(input).some(value => 
    //for (const [key, value] of Object.entries(input)) 
    input.forEach(value =>
    {
      myArr[value.country.toUpperCase()] = value;
    });
    return myArr;
  }

  /* function getDailyScore(c)
  {
    var high = 0;
    var low = 0;
    var avg = 0;

    if (pulseData[c]) 
    {
      low = high = pulseData[c].pulse_history[0];
 
      pulseData[c].pulse_history.forEach(element => 
        {
            if(element > high)
              high = element;
            if(element < low)
              low = element;

            avg += element;
        } 
      );
    
      avg = (avg / pulseData[c].pulse_history.length).toFixed(2);

    }
    return {high: high, low: low, avg: avg};
  } */

  // const renderPulseGraph = () => 
  // {
  //   if (pulseData != null && countryCentroids[currentCountry] != null) 
  //   {
  //     let c = countryCentroids[currentCountry].iso_a2;
  //     var bounds = getDailyScore(c);
  //     var pData = [];
  //     const chartStyle = {
  //     //styles
  //     }
  //     var temp = new Date(pulseData[c].date);
  //     var tdate = new Date(pulseData[c].date);
  //     const d = 11;

  //     temp.setDate(tdate.getDate());
  //     pData.push({
  //       date: temp,
  //       value: pulseData[c].pulse
  //     });

  //     for(let i = 0; i<d; i++)
  //     {
  //       temp = new Date(pulseData[c].date);
  //       temp.setDate(temp.getDate()-(i+1));
  //       pData.push({
  //         date: temp,
  //         value: pulseData[c].pulse_history[i]
  //       });
  //     }

  //     return (
  //       <>
  //         <GaugeChart id="gauge-chart5" colors={["#ff0000", "#00ff00"]} nrOfLevels={25} cornerRadius={0} arcPadding={0} //arcsLength={0}
  //         style={chartStyle} needleColor="#5e646b" textColor="#000000" formatTextValue={value => value} percent={pulseData[c].pulse/100} /*{bounds.avg/100}*/ /> 

  //         <Statistic data={pulseData[c].pulse} title={"Today"} />
  //         <Statistic data={bounds.low+" - "+bounds.high} title={"Range over 10 days"} />
          
  //         <LineGraph data={pData} id={"pulse-graph"} yMax={bounds.high+10} /*referenceURLLink="https://www.geospark.io/"*/ option={1}/>
  //         <Row>
  //               <Col xs={5}>
  //                 <a className="reference-url" href={"https://www.geospark.io/"} target="_blank" rel={"noreferrer"}>
  //                   <Translate value="referenceUrl"/>
  //                 </a>
  //                 <FontAwesomeIcon icon={faExternalLinkAlt}/>
  //               </Col>
  //               <Col xs={7} className={"informationContainer"}>
  //                 <FontAwesomeIcon icon={faInfoCircle}/>The range from 0-100 indicates stability, with 0 being the most unstable and 100 being the most stable, see also: <a href="https://www.geospark.io/platform.html" target="_blank" rel={"noreferrer"}>
  //                 Source</a> <FontAwesomeIcon icon={faExternalLinkAlt} />
  //               </Col>
  //         </Row>
  //         {/*pulseData[i].pulse_history.slice(0, 25)*/}
  //         <hr className="dashRHS-hr"></hr>
  //       </>
  //     );
  //   }
  // }

  const renderFSIGraph = () => {
    if (fsiData != null) {
      return (
        <>
          <LineGraph data={fsiData} id={"fsi2-graph"} yMax={120} />
          <Row>
                <Col xs={5}>
                  <a className="reference-url" href={"https://fragilestatesindex.org/country-data/"} target="_blank" rel={"noreferrer"}>
                    <Translate value="referenceUrl" />
                  </a>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </Col>
                <Col xs={7} className={"informationContainer"}>
                  <FontAwesomeIcon icon={faInfoCircle} />Values range from 0 to 120, with highest being most fragile, see also: <a href="https://issuu.com/fundforpeace/docs/fsi_methodology" target="_blank" rel={"noreferrer"}>Fragile States Index and CAST Framework Methodology by The Fund for Peace - issuu</a> <FontAwesomeIcon icon={faExternalLinkAlt} />
                </Col>
          </Row>
        </>
      );
    }
  }

  const renderGiniGraph = () => 
  {
    if (giniData && giniData.length > 1) 
    {
      return (
        <>
          <LineGraph data={giniData} id={"gini-graph"} yMax={100} />
          <Row>
            <Col xs={5}>
              <div>
                <a className="reference-url" href={"https://data.worldbank.org/indicator/SI.POV.GINI"} target="_blank" rel={"noreferrer"}>
                  <Translate value="referenceUrl" />
                </a>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </div>
            </Col>
            <Col xs={7} className={"informationContainer"}>
              <FontAwesomeIcon icon={faInfoCircle} />GINI coefficient” to include the text “A Gini index of 0 represents perfect equality, while an index of 100 implies perfect inequality, see also : <a href="https://www.indexmundi.com/facts/indicators/SI.POV.GINI" target="_blank" rel={"noreferrer"}>GINI index (World Bank estimate) by Country (indexmundi.com)</a> <FontAwesomeIcon icon={faExternalLinkAlt} />
            </Col>
          </Row>
        </>
      );
    }
  }

  function drawOutput()
  {
      return (
        <div className="dataBoxContainer">
          <div className="dataBoxTitleContainer">
            <Row>
              <Col xs={9}>
                <div className="dataBoxTitle dataLayerTitle">
                  Security Environment
                </div>
              </Col>
              {/*<Col xs={3}>
                <div className="dataBoxHide" onClick={showHideData}>
                  {renderCaret()}
                </div>
              </Col>*/}
            </Row>
          </div>
          <Collapse in={showData}>
            <div className="dataBoxData">
              
              {/* <div className="graph-title">Today’s Hyperion Pulse Score</div> */}
              {/* {renderPulseGraph()} */}
              <span className={"iuuModalIcon"}>
                <div className={"stat-label"}><Translate value={'FSI'}/></div>
                <Statistic data={total_2020} title={"FSI Country Rank"} />
                <Statistic data={rank_2020} title={"FSI Score in 2020 (178 Countries)"} />
                <Statistic data={(lastYear > 0?'+'+lastYear:lastYear)} title={"Points Change from Last Year"} />
                <hr className="dashRHS-hr2"></hr>
                <Statistic data={(lastFive > 0?'+'+lastFive:lastFive)} title={"Points Change Over Five Years"} />
                <Statistic data={(lastTen > 0?'+'+lastTen:lastTen)} title={"Points Change over Ten Years"} />
              </span>
              
              <div className="graph-title">FSI Score - Overall Trend</div>
              {renderFSIGraph()}
              <hr className="dashRHS-hr"></hr>
              <Statistic data={giniScore} title={"Latest GINI Coefficient Score"} />
              <div className="graph-title">
                GINI Score - Overall Trend
              </div>
              {renderGiniGraph()}

              <Statistic data={povScore190} title={"% Population Living on $1.90 a day (" + lastYear + ")"} />
              <Statistic data={povScore550} title={"% Population Living on $5.50 a day (" + lastYear + ")"} />
            </div>
          </Collapse>
        </div>
      );
  }

  return (
    <>
      {drawOutput()}
    </>
  );
}