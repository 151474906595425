/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */

import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading } from "../../../slices/mapSlice"
import { setGtdCountryArr  } from "../../../slices/toc/gtdSlice"
import useCountry from '../../../hooks/useCountry'
import useMap from '../../../hooks/useMap'
import useGtdPointLayer from '../../../hooks/useGtdPointLayer'
import TocUtils from '../../../utils/toc'

const { GtdUtil } = TocUtils

const GTDMap = (props) => {
  const {
    uri,
    countryPoly
  } = props
  const gtdLegend = useSelector(state => state.gtd.gtdLegend)
  const gtdMapEvents = useSelector(state => state.gtd.gtdMapEvents)
  const gtdEventSource = useSelector(state => state.gtd.gtdEventSource)
  const selectedCountries = useSelector(state => state.gtd.gtdCountryArr)
  const gtdSwitch = useSelector(state => state.gtd.gtdSwitch)
  const subGtdInd = useSelector(state => state.gtd.subGtdInd)
  const [gtdData, setGtdData] = useState({})
  const node = useRef(null)
  const dispatch = useDispatch()
  const { paintLayers } = useCountry(true)
  const [ map ] = useMap({ 
    node, 
    uri, 
    onSelectCountry:(country => !gtdSwitch && dispatch(setGtdCountryArr(country))),
    enableHover: !gtdSwitch,
    enableClickCountry: !gtdSwitch
  })
  const { clearPointLayers } = useGtdPointLayer({ map, country_layer_id: 'gtd_layer' })

  useEffect(() => {
    if(!gtdMapEvents || !gtdMapEvents.features || gtdMapEvents.features.length === 0 || gtdSwitch 
        || !subGtdInd || subGtdInd.length === 0){
      return
    }
    if(countryPoly && countryPoly.features){
      const newFeatures = gtdMapEvents.features.map(item => {
        let countryGeo = countryPoly.features.find(ct => ct.properties.SOVEREIGNT === item.properties.SOVEREIGNT)
        if(!countryGeo){
          console.log(`Event country cant be found in country polygon: ${item.properties.SOVEREIGNT}`)
          return
        }
        return Object.assign({}, item, { geometry: countryGeo.geometry })
      })
      setGtdData({ ...gtdMapEvents, features: newFeatures })
    }
  }, [gtdEventSource, gtdMapEvents, countryPoly, gtdSwitch, subGtdInd])

  //Paint border for selected countries
  useEffect(()=>{
    if(!map || gtdSwitch){
      return
    }
    paintLayers(map, selectedCountries)
  }, [map, selectedCountries, gtdSwitch])

  useEffect(() => {
    if (!map || !!gtdSwitch || !gtdLegend || gtdLegend.length === 0) {
      return
    }
    if(!map.getSource('gtd_source')){
      map.addSource('gtd_source', {
        'type': 'geojson',
        'data': gtdData
      })
    }else{
      map.getSource('gtd_source').setData(gtdData)
    }
    if(map.getLayer('gtd_layer')){
      map.removeLayer('gtd_layer')
    }
    clearPointLayers()
    
    map.addLayer({
      'id': 'gtd_layer',
      'type': 'fill',
      'source': 'gtd_source',
      'paint': {
        "fill-color": [
          'step',
          ['get', 'sum'],
          gtdLegend[0]?.color, gtdLegend[0].max,
          gtdLegend[1]?.color, gtdLegend[1].max,
          gtdLegend[2]?.color, gtdLegend[2].max,
          gtdLegend[3]?.color, gtdLegend[3].max,
          gtdLegend[4]?.color, gtdLegend[4].max, 
          gtdLegend[5]?.color
        ],
        // 'fill-opacity': 0.4
        "fill-outline-color": "rgb(110,110,110)"
      },
      'filter': ['==', '$type', 'Polygon']
    }, 'country-label')

    
    
  }, [map, gtdSwitch, gtdData, gtdLegend, clearPointLayers])


  return (
    <>
      <div ref={node} className='mapContainer' />
    </>
  )
}

export default GTDMap