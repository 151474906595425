import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { trackPromise } from 'react-promise-tracker';

import {
	setAcledCheckedAll, setAcledSwitch, setAcledEvent, setAcledStartYear,
	setAcledCountryArr,
	setAcledEndYear, setAcledClass, setChooseId,
	setAcledEventType
} from '../../../../slices/toc/acledSlice';
import Slider from '../../../../components/Slider/Slider'
import { setIsLoading } from "../../../../slices/mapSlice"
import { Checkbox, Radio } from 'antd';

import AntSwitch from '../../../../components/AntSwitch'
import { DateRangeForAcled } from '../../../../utils/DateRangeForSlider'

function Acled() {
	const dispatch = useDispatch()
	const [classData, setClassData] = useState([])
	const [acledInd, setAcledInd] = useState(0);
	const chooseId = useSelector(state => state.acled.chooseId);
	const acledSwitch = useSelector(state => state.acled.acledSwitch); //ACLED - Point - Country
	const acledEvent = useSelector(state => state.acled.acledEvent);
	const acledClass = useSelector(state => state.acled.acledClass); //Battles, Explosions
	const acledCountryArr = useSelector(state => state.acled.acledCountryArr)
	const acledEventType = useSelector(state => state.acled.acledEventType)
	const acledStartYear = useSelector(state => state.acled.acledStartYear)
	const acledEndYear = useSelector(state => state.acled.acledEndYear)

	const API_BASE = process.env.REACT_APP_API_URL
	const [dateRange, setDateRange] = useState([]);

	useEffect(() => {
		trackPromise(
			fetch(`${API_BASE}/arcgis/queryACLEDGISAPI`)
				.then(result => result.json())
				.then(data => {
					dispatch(setAcledEvent(data));
				}),
			'api'
		)
		handleClassChange(acledInd, chooseId, acledEventType)
	}, []);

	//todo: this approach take country selection as a global property, not specific to map's scope
	useEffect(() => {
		if (acledSwitch) {
			dispatch(setAcledCountryArr([]));
		}
	}, [acledSwitch]);

	useEffect(()=>{
		const fetch = async ()=>{
			let country=`SOVEREIGNT='Brazil'`;
			let d=[];
			let api=`${API_BASE}/arcgis/queryAcledCountryEventsStackedYM/${country}`
			d = await DateRangeForAcled(api);
			setDateRange(d);
		}
		fetch().catch(e=>{
			console.error(e)
		})
	},[]);

	// Change the state of switch     ACLED
	const handleChangeAcled = (event) => {
		dispatch(setAcledSwitch(!acledSwitch))
		dispatch(setIsLoading({ isLoading: true }))
	};

	const handleAcledRadio = e => {
		const idx = e.target.value;
		const { id, name: label } = acledEvent.layers[idx];
		handleClassChange(idx, id, label);
		dispatch(setIsLoading({ isLoading: true }))
	}

	const handleClassChange = (idx, id, label) => {
		setAcledInd(idx);
		dispatch(setChooseId(id));
		dispatch(setAcledEventType(label));
		fetch(`${API_BASE}/arcgis/queryACLEDGISAPI/${id}`)
			.then(result => result.json())
			.then(data => {
				const newTypes = data?.types?.map(item => {
					return { ...item, checked: true }
				})
				setClassData({ ...data, types: newTypes })
				dispatch(setAcledClass({ ...data, types: newTypes }))
			});
	};

	const handleCheckBox = (e, id) => {
		if (!acledClass.types || acledClass.types.length === 0) {
			return;
		}
		const curSelection = acledClass.types.filter(it => it.checked);
		if (curSelection.length === 1 && curSelection[0].id === id) {
			console.log('Cant unselect the only selection');
			return;
		}
		const newAcledClass = { ...acledClass };
		const newTypes = newAcledClass.types.map(v => {
			if (v.id == id) {
				return { ...v, checked: e.target.checked }
			} else {
				return { ...v }
			}
		})
		dispatch(setAcledCheckedAll(!newTypes.find(fd => !fd.checked)));
		dispatch(setAcledClass({ ...acledClass, types: newTypes }));
		dispatch(setIsLoading({ isLoading: true }))
	}

	const slider_result = (start, end) => {
		dispatch(setAcledStartYear(start))
		dispatch(setAcledEndYear(end))
	}

	return (
		<div className="mapLayerContent">
			<div className="sliderBox">
				{dateRange && dateRange.length > 0 &&
					<Slider
						data={dateRange}
						range={{ draggableTrack: true }}
						step={1}
						before={1}
						later={dateRange.length - 1}
						result={slider_result}
						defaultValue={[0, dateRange.length - 1]}
					/>
				}
			</div>
			<div className="chooseView">
				<Typography component="div">
					<Grid component="label" container alignItems="center" spacing={1}>
						<Grid item>Country View</Grid>
						<Grid item>
							<AntSwitch checked={acledSwitch} onChange={handleChangeAcled} name="checkedA" />
						</Grid>
						<Grid item>Point View</Grid>
					</Grid>
				</Typography>
			</div>
			<div className="mapLayerContentHr"></div>
			<Radio.Group onChange={handleAcledRadio} value={acledInd} >
				{
					acledEvent.layers && acledEvent.layers.map((item, index) => {
						return (
							<div className="mapLayerEventType" key={index}>
								<Radio value={index}>
									<span className="labelMark">{item.name}</span>
								</Radio>
								<div className="layerSubEventType">
									{
										acledClass.types && (
											acledClass.types.map((v, i) => {
												if (acledInd === index) {
													return (
														<div key={i}>
															<Checkbox checked={v.checked} onChange={(e) => handleCheckBox(e, v.id)} >
																<span className="labelMark">{v.name}</span>
															</Checkbox>
														</div>
													)
												} else {
													return null
												}
											})
										)
									}
								</div>
								<div className="mapLayerEventTypeHr"></div>
							</div>
						)
					})
				}
			</Radio.Group>
		</div>
	)
}



export default Acled