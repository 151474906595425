/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setMapLayer, removeMapLayer } from '../../../../slices/mapSlice';


export default function LayerSeafood (props) 
{
  const [season, setSeason] = useState("seafood_no_selection");
  const [species, setSpecies] = useState("seafood_no_selection");
  const [previousLayer, setPreviousLayer] = useState("seafood_no_selection");
  const {isChecked = false} = props;
  const dispatch = useDispatch();

  useEffect(() => 
  {
    if (isChecked && season !== "seafood_no_selection" && species !== "seafood_no_selection") {
      const layer = season + "-" + species
      if (layer !== previousLayer) {
        //remove map layer with previous ais
        // set previous ais
        dispatch(removeMapLayer(previousLayer))
        dispatch(setMapLayer(layer))
        setPreviousLayer(layer)
      }
    } else {
      const layer = "seafood_no_selection"
      dispatch(removeMapLayer(previousLayer))
      dispatch(setMapLayer(layer))
      setPreviousLayer(layer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [season, species, isChecked])

  return (
    <>
     <label>Seafood Hotspot: </label>
    <div>
     
      <select value={season} onChange={e => setSeason(e.target.value)}>
        <option value="seafood_no_selection">No Selection</option>
        <option value="summer">Summer</option>
        <option value="winter">Winter</option>
        <option value="fall">Fall</option>
        <option value="spring">Spring</option>
      </select>

      <select value={species} onChange={e => setSpecies(e.target.value)}>
        <option value="seafood_no_selection">No Selection</option>
        <option value="tuna">Tuna</option>
        <option value="shark">Shark</option>
        <option value="viirs">Squid</option>
        <option value="billfish">Billfish</option>
      </select>
      </div>
    </>
  )
}