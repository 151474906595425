/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Test proposed by Keven, need to check with the legal team
 * Copyright 2021 The Florida International University.
 */


import React from "react"

export default function TunaCatchAnimateModal(props) {

  return (
    <>
      <div className={'iuuModalContainer'}>
        <span className={'iuuModalImage'}>
          <img src={window.location.origin + "/images/tuna_animation_JS.gif"} alt={"Tuna and Billfishes Catch History"} />
        </span>
        <br />
        <div>
          <p>
            The plot shows the commercial fishing history for Tuna and Billfishes from year 2006 to year 2016 in country Peru, 
            Brazil, Costa Rica, Chile, Ecuador, and Colombia
          </p>
          <p>
            <b>See also:</b> 
          </p>
        </div>
      </div>
    </>
  )


}

