/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React from "react"

export default function TransshipmentModal(props) {

  return (
    <>
      <div className={'iuuModalContainer'}>
        <span className={'iuuModalImage'}>
          <img src={window.location.origin + "/images/transshipmentChina.gif"} alt={"Trans shipment data"} />
        </span>
        <br />
        <div>
          <p>
            This plot shows the amount of fishing effort that is ultimately transferred through transshipment in oceanic area around South America. As pointed out in the <i>Global Fishing Watch (GFW)</i> article <a className={"iuuModalLink"} target="_blank" rel="noopener noreferrer" href={"https://globalfishingwatch.org/transshipment-success/"}>here</a>, transshipment can be used to mask illegal fishing activity. Transshipment is also a tool used by distant water fishing fleets to escape port authority overview by simply never returning to port. Through 2012-2020 data provided by Global Fishing Watch, we can see the amount of fishing effort that is performed by a vessel prior to engaging in transshipment. This effort is tracked from the vessel itself to the carrier to the eventual port destination.
            To read more about GFW’s algorithms to estimate fishing effort see: <i>D.A. Kroodsma, J. Mayorga, T. Hochberg, N.A. Miller, K. Boerder, F. Ferretti, A. Wilson, B. Bergman, T.D. White, B.A. Block, P. Woods, B. Sullivan, C. Costello, and B. Worm. "Tracking the global footprint of fisheries." Science 361.6378 (2018).</i>
          </p>
          <p>
            <b>See also:</b> <a className={"iuuModalLink"} target="_blank" rel="noopener noreferrer" href={'https://fiugis.maps.arcgis.com/home/notebook/notebook.html?id=63802f11e9034652b7ca08d169d92472#'}>https://fiugis.maps.arcgis.com/home/notebook/notebook.html?id=63802f11e9034652b7ca08d169d92472#</a>
          </p>
        </div>
      </div>
    </>
  )

}

