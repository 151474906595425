import { useEffect, useState } from 'react'
import TocUtils from '../utils/toc'

const API_BASE = process.env.REACT_APP_API_URL
const { Common } = TocUtils

const useCountryCode = ( initialState ) => {
  const [ countryCode, setCountryCode ] = useState(initialState)

  useEffect(() => {
    fetch(`${API_BASE}/arcgis/queryCountryCode`)
				.then(result => result.json())
				.then(data => {
					setCountryCode(Common.getCountryCodeMap(data))
				})
  }, [])

  return [
    countryCode, setCountryCode
  ]
}

export default useCountryCode