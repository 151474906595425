import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import './PointPopup.css'
const moment = require('moment');
function PointPopup(props) {
  const { pointEvent, pointClass, chooseId, resultContent } = props;
  const [title, setTitle] = useState('');
  const [showData, setShowData] = useState(true)
  const [ind, setInd] = useState(0)
  const [notes, setNotes] = useState('')
  const [isShow, setIsShow] = useState(false)
  useEffect(() => {
    pointEvent.layers && pointEvent.layers.forEach(v => {
      if (v.id == chooseId) {
        setTitle(v.name)
      }
    })
  }, [pointEvent, pointClass, chooseId])

  const showHideData = () => {
    setShowData(!showData);
  }

  const renderCaret = () => {
    if (showData) {
      return (
        <FontAwesomeIcon className="caret" icon={faAngleUp} />
      );
    } else {
      return (
        <FontAwesomeIcon className="caret" icon={faAngleDown} />
      );
    }
  }
  const handleIcon = (index) => {
    setInd(index);
  }
  const handleMouseEnter = (v) => {
    setNotes(v.properties.notes)
    setIsShow(true)
  }
  const handleMouseLeave = () => {
    setIsShow(false)
  }

  const showContent = (val, title) => {
    return (
      <table className='popupTable' style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th className="numThBox"></th>
            <th className="dateThBox">Date:</th>
            <th className="locationThBox">Location:</th>
            <th className="notesThBox">Notes:</th>
          </tr>
        </thead>
        <tbody>
          {
            resultContent.map((v, i) => {
              if (v.properties.sub_event_type === title) {
                return (
                  <tr key={i}>
                    <td style={{ marginLeft: '5px' }}>{v.properties.Join_Count}</td>
                    <td>{moment(v.properties.event_date).format('MM/DD/YYYY')}</td>
                    <td>{v.properties.location}</td>
                    <td className="notesBox" onMouseEnter={() => handleMouseEnter(v)} onMouseLeave={handleMouseLeave}>
                      {v.properties.notes}
                    </td>
                  </tr>
                )
              }
            })
          }
        </tbody>

      </table>
    )
  }

  return (
    <>
      <div className="pointPopupBox">
        <div className="pointPopupHead">
          <div className="pointPopupNum">
            {resultContent.length}
          </div>
          <div className="pointPopupTitle">
            {title}
          </div>
        </div>
        <div className="pointPopupContent">
          {
            pointClass.types && pointClass.types.map((v, i) => {
              return (
                <div className="pointPopupConIcon" key={i}>
                  <div className={ind == i ? 'contentIconTitle contentIconTitleActive' : 'contentIconTitle'}>
                    <div className="contentIconTitleLeft">
                      <div className="contentIconTitleLeftNum">
                        {resultContent.filter(item => item.properties.sub_event_type === v.name).length}
                      </div>
                      <span className="contentIconTitleContent">{v.name}</span>
                    </div>
                    <div className="contentIconTitleRight" onClick={() => { handleIcon(i) }}>
                      {
                        ind == i ? <FontAwesomeIcon className="caret" icon={faAngleUp} /> :
                          <FontAwesomeIcon className="caret" icon={faAngleDown} />
                      }
                    </div>
                  </div>
                  <div className="contentIconContent">
                    {/* {isShow && <PopupTooltip notes={notes} />} */}
                    {
                      ind == i ? showContent(ind, v.name) : null
                    }
                  </div>
                </div>
              )

            })
          }
        </div>
      </div>
    </>
  )
}

export default PointPopup;