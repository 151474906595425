/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setSelectedArticleCountry } from '../../slices/mapSlice';
import LinkWithConfirm from '../../components/LinkWithConfirm/LinkWithConfirm';

let reverseLookup = {
  'United States': 'United States of America',
}

export default function IRItem(props) {
  const { country, url, title } = props
  const [imageIcon, setImageIcon] = useState('/images/Icon_News.png')
  const dispatch = useDispatch()


  const handleMouseOver = () => {
    setImageIcon('/images/Icon_News_On.png')
    const currCountry = reverseLookup[country] ? reverseLookup[country] : country;
    dispatch(setSelectedArticleCountry(currCountry));
  }

  const handleMouseOut = () => {
    setImageIcon('/images/Icon_News.png')
    dispatch(setSelectedArticleCountry(''));
  }

  return (
    <LinkWithConfirm
      href={url}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      target="_blank"
    >
      <div className='dash-item-div'>
        <div className='dash-item-icon'>
          <span><img src={window.location.origin + imageIcon} alt={"News Item"}/></span>
        </div>
        <div className='dash-item-text'>
          <span>{title}</span>
        </div>
      </div>
    </LinkWithConfirm>
  );
}
