/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { Component } from 'react';
import Select from 'react-select';
import { setChallengeFilter } from '../../slices/filtersSlice';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class ChallengeFilter extends Component
{
  constructor(props)
  {
    super(props);
    this.props.setChallengeFilter("IUU Fishing"); //TODO test TOC
    //this.props.setChallengeFilter("Violence and Arms");
    this.state = {  
      selectedOption: {value: 'IUU Fishing', label: 'IUU Fishing'}, //TODO test toc
      // selectedOption: {value: 'Violence and Arms', label: 'Violence and Arms'},
      options : [
        {value: 'IUU Fishing', label: 'IUU Fishing'},
        {value: 'Security Environment', label: 'Security Environment'},
        {value: 'Violence and Arms', label: 'Violence and Arms'}
       // {value: 'Violence and Arms', label: 'Violence and Arms'}
      ],
    };
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.props.setChallengeFilter(selectedOption.value);
  }

  render()
  {
   // const { selectedOption } = this.state;
    return (
      <Select 
		    className='heading3 ddl-Security'
        value={this.state.selectedOption}
        onChange={this.handleChange}
        options={this.state.options}
      />
      
    );
  }
}

/*ChallengeFilter.propTypes = {
  setChallengeFilter: PropTypes.func.isRequired,
  challenge: PropTypes.string,
  challengeList: PropTypes.array
}*/

function infographDispatchToProps(dispatch) 
{
  return bindActionCreators({
    setChallengeFilter
  }, dispatch);
}

function infographToProps(state) 
{
  return {
    challenge: state.filters.irChallengeType
    //challengeList: state.filters.challengeList,
  };
}

export default connect(infographToProps, infographDispatchToProps)(ChallengeFilter);

