/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { Component } from 'react';
import Select from 'react-select';
import { setCountryFilter } from '../../slices/filtersSlice';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class CountryFilter extends Component {
  constructor(props) {
    super(props);
    this.props.setCountryFilter("United States of America");
    this.state = {
      selectedOption: { value: "United States of America", label: "United States of America" },
      /*options : [
        {value: 'Venezuela', label: 'Venezuela'},
        {value: 'Brazil', label: 'Brazil', selected: true},
        {value: 'Chile', label: 'Chile'},
        {value: 'Argentina', label: 'Argentina'},
      ],*/
    };
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.props.setCountryFilter(selectedOption.value);
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { selectedOption } = this.state;
    let options = [];
    if (this.props.countryList && this.props.countryList.length > 0) {
      this.props.countryList.forEach(country =>
        options.push({ value: country, label: country })
      );
    }
    return (
      <Select
        //value={this.state.options.filter(option => option.label === 'Brazil')}
        //value={this.state.options.filter(option => option.label === selectedOption.label)}
        value={options.filter(option => option.label === this.props.country)}
        //value={selectedOption}
        className='heading3 ddl-Security'
        onChange={this.handleChange}
        //options={this.state.options}
        options={options}
      />

    );
  }
}

CountryFilter.propTypes = {
  setCountryFilter: PropTypes.func.isRequired,
  country: PropTypes.string,
  countryList: PropTypes.array
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setCountryFilter
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    country: state.filters.countryFilter,
    countryList: state.filters.countryList,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryFilter);
