const conversionFun = (start, end) => {
    const conversionObj = { 1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December' }
    const startYear = start.split('/')[1]
    const startMonth = start.split('/')[0]
    const endYear = end.split('/')[1]
    const endMonth = end.split('/')[0]
    const monthStart = Object.keys(conversionObj).filter(item => Number(item) >= Number(startMonth)).map(item => conversionObj[item])
    const monthEnd = Object.keys(conversionObj).filter(item => Number(item) <= Number(endMonth)).map(item => conversionObj[item])
    return { startYear, endYear, monthStart, monthEnd, startMonth, endMonth}
}

export default conversionFun