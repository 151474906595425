
import React, { useState, useEffect } from 'react';
import Statistic from "./Statistic";
import { Translate } from'react-redux-i18n';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

//was called TransnationalCrime
export default function FirstVisitUI(props)
{
  const [layers, setLayer] = useState(null);
  const {dateFV, lang} = props;
  const [myArray, ] = useState(new Array(3));

  //TODO: Add translations for these tooltips in spanish and portuguese and move variables to index.js
  const firstVisitsTooltip = "Number of fishing, cargo (bulk carriers, reefers, etc. are included in this category) or tanker vessels first entering Maritime Regions around Central and South America. First visits indicate a deviation from their usual pattern of activity. This could include, for example, entering South American waters for the first time after operating exclusively in Asia for years. Maritime regions and associated first visits for a particular day can be displayed from the Data Layers box. Note that maritime regions follow closely, but are not identical to EEZs. More information about the first visiting vessels can be seen by clicking on a red dot representing a first visiting vessel. Data is extracted using the Windward Analytics platform.";

  useEffect(() => 
  {
    if(layers == null)
      getJson();
  });


 // var totalObjects = {Transshipments: 0, Fishing_Activities: 0, entries: 0, processed: 0, total_entries:0, countryList:[], vesselsList: []};
  var parsedObjects = [];

  const getJson = () => 
  {
      const SAP = 'https://services2.arcgis.com/FtJyXM7nBotS8g5t/ArcGIS/rest/services/windward_first_visit_Feb2022/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson&token=';  
      const SAA = 'https://services2.arcgis.com/FtJyXM7nBotS8g5t/ArcGIS/rest/services/windward_first_visit_Feb2022/FeatureServer/1/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson&token=';
      const CA =  'https://services2.arcgis.com/FtJyXM7nBotS8g5t/ArcGIS/rest/services/windward_first_visit_Feb2022/FeatureServer/2/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnHiddenFields=false&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson&token=';

      setupXMLRequest(SAP,  callback4);
      setupXMLRequest(SAA, callback5);
      setupXMLRequest(CA, callback6);
  }
  
  function setupXMLRequest(url, callback)
  {
    var xmlhttp1 = new XMLHttpRequest();
    xmlhttp1.onreadystatechange = callback;
    xmlhttp1.open("GET", url, true);
    xmlhttp1.send();
  }

  function callback4()
  {
    if (this.readyState === 4 && this.status === 200)
    {
      readJson_FirstVisit(0, JSON.parse(this.responseText).features);
    }
  }
  function callback5()
  {
    if (this.readyState === 4 && this.status === 200)
    {
      readJson_FirstVisit(1, JSON.parse(this.responseText).features);
    }
  }
  function callback6()
  {
    if (this.readyState === 4 && this.status === 200)
    {
      readJson_FirstVisit(2, JSON.parse(this.responseText).features);
    }
  }

  function readJson_FirstVisit(i=0, myArr)
  {
      myArray[i] = myArr;
      parsedObjects[i] = countVessels2(myArr);
      displayTotal2();
  }

  useEffect(() => 
  {
    if(myArray == null || !Array.isArray(myArray[0]))
      return;

    for(var i = 0; i<3; i++)
    {
      readJson_FirstVisit(i, myArray[i]);
    }
  },[dateFV]);

  function countVessels2(myArr2)
  {
     var tempDate;
    if(dateFV != null)
    {
      tempDate = dateFV;
    }
    else
      tempDate = new Date('2022 02 19');

    var flags = {Fishing_Activities:0, Transshipments:0, total_entries: 0, processed:0, countries: [], unqiueVessels:[], countryList:[]};
    var start_date = null;
    var end_date   = null;

    myArr2.forEach(element =>
    {
        start_date = new Date(element.attributes.Start_Date);
        end_date   = new Date(element.attributes.End_Date);

        if(start_date && end_date)
        {
          if(start_date.getMonth() <= tempDate.getMonth() && end_date.getMonth() >= tempDate.getMonth() )
          {
            if(!(flags['unqiueVessels'].includes(element.attributes.vesselId)))//not found
              flags['unqiueVessels'].push(element.attributes.vesselId);

              flags['total_entries']++;
          }
          flags['processed']++;
        }
    });

    return flags;
  }

  var d = 0
  function displayTotal2()
  { 
    ++d;
    if(d === 3)
    {
      setLayer(parsedObjects);
    }
  }

  function getLang()
  { 
    var s = '';
    switch(lang)
    {
      case 'en':
        s = 'en-US';
        break;
      case 'esp':
        s = 'es-US';
        break;
      case 'por':
        s = 'por-US';
        break;
      default:
        s = 'en-US';
        break;
    }
    return s;
  }


  function firstVisitStats() 
  {
    if (layers != null && layers[0]) 
    {
      return(
        <span className={"iuuModalIcon"}>
          <div className="newsInfographCountryLabel">
            <Translate value={'firstVisit'}/> {dateFV.toLocaleDateString(getLang(), {month: 'long'})}
            <Tooltip title={firstVisitsTooltip} className='inTitleTooltipIcon' placement="right">
              <span>
                <FontAwesomeIcon icon={faInfoCircle}/>
              </span>
            </Tooltip>
          </div>
          <Statistic data={(layers[2]['unqiueVessels'].length)?layers[2]['unqiueVessels'].length:'0'} title="Central America"        style={[2]}/>
          <Statistic data={(layers[1]['unqiueVessels'].length)?layers[1]['unqiueVessels'].length:'0'} title="South America Atlantic" style={[2]}/>
          <Statistic data={(layers[0]['unqiueVessels'].length)?layers[0]['unqiueVessels'].length:'0'} title="South America Pacific"  style={[2]}/>
        </span>
      );
    }

    return <></>;
  }

 
  return (
    <>
      {firstVisitStats()}
    </>
  );
}