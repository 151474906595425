/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { createSlice } from '@reduxjs/toolkit'
const sipriSlice = createSlice({
  name: 'sipri',
  initialState: {
    sipriCountry: '',
    sipriInd: 'import', //import or export
    sipriStartYear: '2010',
    sipriEndYear: '2020',
    sipriXportEventSource: {},
    sipriImportMapEvent: {},
    sipriExportMapEvent: {}, //will use when redefine export to be used
    sipriLegend: []
  },
  reducers: {
    setSipriCountry(state, action) {
      state.sipriCountry = action.payload
    },
    setSipriInd(state, action) {
      state.sipriInd = action.payload
    },
    setSipriStartYear(state, action) {
      state.sipriStartYear = action.payload
    },
    setSipriEndYear(state, action) {
      state.sipriEndYear = action.payload
    },
    setSipriXportEventSource(state, action) {
      state.sipriXportEventSource = action.payload
    },
    setSipriImportMapEvent(state, action) {
      state.sipriImportMapEvent = action.payload
    },
    setSipriExportMapEvent(state, action) {
      state.sipriExportMapEvent = action.payload
    },
    setSipriLegend(state, action) {
      state.sipriLegend = action.payload
    }
  }
})

export const { 
  setSipriCountry, 
  setSipriStartYear, 
  setSipriEndYear, 
  setSipriInd,
  setSipriXportEventSource,
  setSipriImportMapEvent,
  setSipriExportMapEvent,
  setSipriLegend
} = sipriSlice.actions;


export default sipriSlice.reducer;