/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { useEffect, useState } from "react"
import * as d3 from 'd3';
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-redux-i18n";

export default function LineGraph(props)
{
  const { data, id, yMax, referenceURLLink, option=null } = props;
  const [globalSvg, setGlobalSvg] = useState(null);

  const renderReferenceLink = () => {
    if (referenceURLLink)
    {
      return(
        <>
        <Row>
          <Col xs={12} className="urlContainer">
            <a className="reference-url" href={referenceURLLink} target="_blank" rel={"noreferrer"}>
              <Translate value="referenceUrl"/></a>
            <FontAwesomeIcon icon={faExternalLinkAlt}/>
          </Col>
        </Row>
        </>
      );
    }
  }

  useEffect(() => 
  {
    let parent = d3.select("#" + id);
    if (globalSvg) {
      parent.selectAll("svg").remove();
    }

    let margin = { top: 10, bottom: 10, left: 10, right: 10 };
    let width = 450 - margin.left - margin.right;
    let height = 250 - margin.top - margin.bottom;
    let svg = parent.append("svg")
      //.attr("width", width+margin.left+margin.right)
      //.attr("height", height+margin.top+margin.bottom)
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "-30 -20 500 275")


      //x-axis
      let x = d3.scaleTime()
        .domain(d3.extent(data, function (d) { return d.date }))
        .range([0, width])

    if(option == null)
    {
      svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).ticks(d3.timeYear.every(1)))
      .select("path")
      .attr("stroke", "#081e3f")
      .attr("stroke-width", 2.5);
    }
    else
    {
      svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).ticks(d3.timeDay.every(1)))
        .select("path")
        .attr("stroke", "#081e3f")
        .attr("stroke-width", 2.5);
    }

    //y-axis
    let y = d3.scaleLinear()
      //.domain([0,d3.max(data,function(d){return d.value;})])
      .domain([0, yMax])
      .range([height, 0]);
    svg.append("g")
      .call(d3.axisLeft(y).ticks())
      .select("path")
      .attr("stroke", "#081e3f")
      .attr("stroke-width", 2.5);

    //add the trend line
    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#fc0")//"#081e3f","#fc0"
      .attr("stroke-width", 2.5)
      .attr("d", d3.line()
        .x(function (d) { return x(d.date) })
        .y(function (d) { return y(d.value) }));

    //add the individual points
    svg.selectAll("myCircles")
      .data(data)
      .enter()
      .append("circle")
      .attr("fill", "#081e3f")
      .attr("stroke", "none")
      .attr("cx", function (d) { return x(d.date) })
      .attr("cy", function (d) { return y(d.value) })
      .attr("r", 3);

    setGlobalSvg(svg);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div id={id}>
      </div>
      {renderReferenceLink()}
    </>
  );
}