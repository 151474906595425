/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import IRItem from './IRItem';
import ResourceItem from './ResourceItem';
import LinkWithConfirm from '../../components/LinkWithConfirm/LinkWithConfirm';
import { Translate } from "react-redux-i18n"
import { useSelector } from 'react-redux';
import CircleSpinner from '../../components/CircleSpinner/CircleSpinner';
import moment from "moment";

// global constants
// TODO: move all global variables to .env file
const articleLimit = 5
const allReportsUrl = 'https://digitalcommons.fiu.edu/srhreports/'
const allIUUFReportsUrl = 'https://digitalcommons.fiu.edu/srhreports/iuufishing/iuufishing/'
const allResourcesUrl = 'https://digitalcommons.fiu.edu/srhreports/resources/'
const allIUUFResourcesUrl = 'https://digitalcommons.fiu.edu/srhreports/iuufishing/iuufishing-resources/'
const allTOCResourcesUrl = 'https://digitalcommons.fiu.edu/srhreports/toc/toc-resources/'
const queryTargets = ['abstract', 'title']  // the keys to query against in article objects

const reverse_topic_map = {
  'Illegal Logging': "illegal_logging",
  'Illegal Mining': "illegal_mining",
  'IUU Fishing': "IUUF",
  'Human Rights': "human_rights",
  'Violent Extremism': "violent_extremism",
  'Critical Infrastructure': "critical_infrastructure",
  'Cybersecurity': "cybersecurity",
  'Migration': "migration",
  'Economic Stability': "economic_stability",
  'Extreme Events': "extreme_events",
  'Health/Pandemics': "health_pandemic",
  'Transnational Organized Crime': "toc",
  'Political Stability': "political_stability"
}

const query_in_targets = (query, queryTargets) => {
  return (article) => {
    for (let queryTarget of queryTargets) {
      let words = String(article[queryTarget]);
      if (words.toLowerCase().indexOf(query) !== -1) {
        return true;
      }
    }
    return false;
  }
}

const security_challenge = (challenge_type) => {
  return (article) => {
    const security_challenges = article.configured_field_t_security_challenge;
    return security_challenges && security_challenges.includes(challenge_type);
  }
}

export default function DashboardIR() {
  const challenge_type = useSelector(state => state.filters.irChallengeType)
  const query = useSelector(state => state.filters.searchText)
  const countryFilter = useSelector(state => state.filters.countryFilter)
  const [resultFromIR, setResultFromIR] = useState([])
  const [resultFromIRv1, setResultFromIRv1] = useState([])
  const [articles, setArticles] = useState([])
  const [resources, setResources] = useState([])
  const { promiseInProgress } = usePromiseTracker({ area: 'api' })
  const { promiseInProgress: promiseInProgressV1 } = usePromiseTracker({ area: 'apiV1' })

  function seeAllResourcesLinkByChallengeType(){
    switch(challenge_type) {
      case 'IUU Fishing':
        return <><LinkWithConfirm href={allIUUFResourcesUrl}><Translate value="seeAllResources" /></LinkWithConfirm></>
      case 'Transnational Organized Crime':
        return <><LinkWithConfirm href={allTOCResourcesUrl}><Translate value="seeAllResources" /></LinkWithConfirm></>
      default:
        return <><LinkWithConfirm href={allResourcesUrl}><Translate value="seeAllResources" /></LinkWithConfirm></>
    }
  }

  function seeAllReportsLinkByChallengeType(){
    switch(challenge_type) {
      case 'IUU Fishing':
        return <><LinkWithConfirm href={allIUUFReportsUrl}><Translate value="seeAllResources" /></LinkWithConfirm></>
      default:
        return <><LinkWithConfirm href={allReportsUrl}><Translate value="seeAllResources" /></LinkWithConfirm></>
    }
  }

  function noIRByChallengeType(){
    switch(challenge_type) {
      case 'IUU Fishing':
        return <span><Translate value={ 'noIrIUU' } country={countryFilter} /></span>
      case 'Violence and Arms':
        return <span><Translate value={ 'noIrTOC' } country={countryFilter} /></span>
      case 'Security Environment':
        return <span><Translate value={ 'noIrSecEnv' } country={countryFilter} /></span>
      default:
        return <span><Translate value={ 'noIr' } country={countryFilter} /></span>
    }
  }

  useEffect(() => {
    const API_BASE = process.env.REACT_APP_API_URL
    //console.log('challenge_type = ' + challenge_type);
    trackPromise(
      fetch(`${API_BASE}/ir/resources/${challenge_type}`)
        .then(result => result.json())
        .then(setResultFromIRv1),
      'apiV1'
    )
  }, [challenge_type])

  useEffect(() => {
    const API_BASE = process.env.REACT_APP_API_URL
    trackPromise(
      fetch(`${API_BASE}/ir/content/${challenge_type}/${countryFilter}`)
        .then(result => result.json())
        .then(setResultFromIR),
      'api'
      // fetch(`https://dashboard.srh.fiu.edu/api/ir/content/${challenge_type}/${countryFilter}`)
      //   .then(result => result.json())
      //   .then(setResultFromIR),
      // 'api'
    )

  }, [countryFilter, challenge_type])

  useEffect(() => {
    // console.log(resultFromIR,'resultFromIR')
    setArticles(resultFromIR.filter(query_in_targets(query, queryTargets)).filter(security_challenge(challenge_type)).filter(query_in_targets).sort(sortArticleByPubDate).slice(0, articleLimit))
  }, [query, resultFromIR, challenge_type])

  useEffect(() => {
    // setResources(resultFromIRv1.filter(is_resource).filter(query_in_targets(query, queryTargets)).slice(0, resourceLimit))
    setResources(resultFromIRv1.filter(query_in_targets(query, queryTargets)).filter(security_challenge(challenge_type)).filter(query_in_targets).sort(sortArticleByPubDate).slice(0, articleLimit))
    
  }, [query, resultFromIRv1, challenge_type])

  // consider deletion?
  // eslint-disable-next-line no-unused-vars
  const sortArticleByPubDate = (articleA, articleB) => {
    let dateA = moment(articleA.publication_date, "YYYY-MM-DDTHH:mm:ssZ");
    let dateB = moment(articleB.publication_date, "YYYY-MM-DDTHH:mm:ssZ");
    return dateB - dateA;
  }

  const ArticlesEle = () => articles.length > 0 ? articles.map((article, idx) =>
    <IRItem
      key={idx}
      title={article.title}
      inst_title={article.institution_title}
      url={article.url}
    />)
    :
    <div className='dash-item-div'>
      <div className='dash-item-icon'>
      </div>
      <div className='dash-item-text'>
        {noIRByChallengeType()}
      </div>
    </div>


  return (
    <>
      <div>
        <div className='dash-article-sub-title'><Translate value="reportsPublicationsTitle" /></div>
        {promiseInProgress ?
          <div className='dash-item-div2 spinner'>
            <CircleSpinner />
          </div> :
          <ArticlesEle />
        }
        {seeAllReportsLinkByChallengeType()}
        <hr className='dash-article-hr' />
        <div className="dash-article-sub-title"><Translate value="resourcesTitle" /></div>
        {promiseInProgressV1 ?
          <div className='dash-item-div2 spinner'>
            <CircleSpinner />
          </div> :
          resources.map((resource) => {
            return <ResourceItem
              title={resource.title}
              inst_title={resource.institution_title}
              url={resource.url}
            />
          })
        }
        {seeAllResourcesLinkByChallengeType()}
        {/* <LinkWithConfirm href={allResourcesUrl}><Translate value="seeAllResources" /></LinkWithConfirm> */}
      </div>
    </>
  )
}
