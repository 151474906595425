/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { Col, Row } from "react-bootstrap"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { Translate } from "react-redux-i18n"

export default function Statistic(props)
{
  const {data, title, referenceURLLink, style = ""} = props;

  const renderReferenceLink = () => {
    if (referenceURLLink){
      return(
        <>
        <Row>
          <Col xs={12} className="urlContainer">
            <a className="reference-url" href={referenceURLLink} target="_blank" rel={"noreferrer"}><Translate value="referenceUrl"/></a>
            <FontAwesomeIcon icon={faExternalLinkAlt}/>
          </Col>
        </Row>
        </>
      );
    }
  }

  const renderScore = () => 
  {
    if(data){
      return data;
    }else{
      return "N/A";
    }
  }

  
  const classNames =(name)=> 
  {
      return name+style;
  }


  return(
        <>
          <Row>
            <Col xs={5}>
              <div className={classNames("stat-data")}>
                <div className="stat-data-container">
                  <div className={classNames("stat-data-data")}>{renderScore()}</div>
                </div>
              </div>
            </Col>
            <Col  xs={7}>
              <div className="stat-label">{title}</div>
            </Col>
          </Row>
          {renderReferenceLink()}
        </>
  )
}