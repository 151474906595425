/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useEffect, useState } from "react"
import * as d3 from 'd3';
import { renderInfoIconWithTooltip } from '../../../utils/helperFunctions';

export default function PieGraph_IUU(props) {

  const { data, id, countryName,/* total */} = props;
  const [globalSvg, setGlobalSvg] = useState(null);

  const colorMap = {
    'Illegal Logging': "#f0f8ff",
    'Critical Infrastructure': "#364A86",
    'Cybersecurity': "#F15A24",
    'Economic Stability': "#56A336",
    'Health/Pandemics': "#4985E0",
    'Human Rights': "#AA9A68",
    'IUU Fishing': "#FFCC2F",
    'Migration': "#57A599",
    'Extreme Events': "#C1272D",
    'Political Stability': "#BF34BA",
    'Violence and Arms': "#8C4900",
    'Illegal Mining': "#CFDBDF",
    'Violent Extremism': "#000000",
	  'Fishing Activities': "#BF34BA",
    'Unqiue Vessels': "#8C4900",
    'Unqiue Countries': "#CFDBDF",

    'Spain': "#56A336",
    'Japan': "#4985E0",
    'Brazil': "#AA9A68",
    'Colombia': "#FFCC2F",
    'Ecuador': "#D0f8ff",
    'UnitedKingdom': "#364A86",
    'Panama': "#F15A24",
    'Nicaragua': "#57A599",
    'Argentina': "#C1272D",
    'SouthKorea': "#BF34BA",
    'Poland': "#8C4900",
    'Germany': "#CFDBDF",
    'Lithuania': "#6A8CF9",
    'China': "#0A6CF9",
    'Peru': "#3B3CF9",
    'Venezuela': "#0B4DF9",
    'UnitedStates': "#e7931b",
    'Taiwan':  "#3A4B86",
    'Belize':  "#4B8D73",
    //'Unknown': "#e7931b"
  };

  const windwarGraphTooltip = "Distribution of fishing, cargo (bulk carriers, reefers, etc. are included in this category) or tanker vessels in selected areas of interests (highlighted in yellow in the map) for a given day by Flag of Convenience. By default, all AOIs are selected. Selection can be made to a single AOI using the IUU Areas of Interest dropdown in the Data Layers box. Data is extracted using the Windward Analytics platform.";

  useEffect(() => 
  {
    let idNospace = id.replace(/ /g, "_");
    if(data == null || Object.entries(data) < 1)
    {
      d3.select("#" + idNospace).html("NO DATA")
        .style("-webkit-transform", "translate(0%, 30%)")
        .style("margin-left", "0em");
      return;
    }
    else
    {
      d3.select("#" + idNospace).html("")
      .style("margin-left", "-4em")
      .style("-webkit-transform", "translate(23.5%, 0%)");
    }

    if (globalSvg) {
      d3.select("#" + idNospace).select("svg").remove();
    }
    let selectedColors = [];
    let selectedTopics = [];
    for (const d in data) {
      selectedTopics.push({
        label: d,
        color: colorMap[d]
      })
      selectedColors.push(colorMap[d]);
    }
    let svg = d3.select("#" + idNospace).append("svg");
    svg.attr("viewBox", "0 0 400 180");
    let width = 140;
    let height = 140;
    let radius = Math.min(width, height) / 2;
    let g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
    //let color = d3.scaleOrdinal(["#081e3f", "#fc0"]);
    let color = d3.scaleOrdinal(selectedColors);

    let pie = d3.pie();

    let arc = d3.arc()
      .innerRadius(0)
      .outerRadius(radius)
    //let tData = data ? data : 0;
    let arcs = g.selectAll("arc")
      .data(pie(Object.values(data)))
      .enter()
      .append("g")
      .attr("class", "arc")
      .each(function (d, i) {
        d3.select(this)
          .attr("topic", Object.keys(data)[i]);
      })
    //add tooltips
    let tooltip = d3.select("#" + idNospace)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px");
    //tooltip from:
    //https://www.d3-graph-gallery.com/graph/interactivity_tooltip.html
    // Three function that change the tooltip when user hover / move / leave a cell
    let mOver = function (d, i)
    {
      tooltip
        .style("opacity", 1);
      d3.select(this)
        .style("stroke", "black")
        .style("opacity", 1);
    }
    let mMove = function (d, i) 
    {
      let topic = d3.select(this.parentNode).attr("topic");
      tooltip
        .html(topic + ": " + i.data)
        .style("left", d.layerX + "px")
        .style("top", (d.layerY)  + "px");
    }
 /* var mousemove = function(d) {
    Tooltip
      .html("The exact value of<br>this cell is: " + d.value)
      .style("left", (d3.mouse(this)[0]+45) + "px")
      .style("top", (d3.mouse(this)[1]) + "px")
  }*/
    let mLeave = function (d) {
      tooltip
        .style("opacity", 0);
      d3.select(this)
        .style("stroke", "none")
        .style("opacity", 0.8);
    }
    arcs.append("path")
      .attr("fill", function (d, i) {
        return color(i);
      })
      .attr("d", arc)
      .on("mouseover", mOver)
      .on("mousemove", mMove)
      .on("mouseleave", mLeave);

    //legend
    let size = 15;
    svg.selectAll("mydots")
      .data(selectedTopics)
      .enter()
      .append("rect")
      .attr("x", 175)
      .attr("y", function (d, i) { return 10 + i * (size + 5) }) // 100 is where the first dot appears. 25 is the distance between dots
      .attr("width", size)
      .attr("height", size)
      .style("fill", function (d) { return d.color })
    //labels for leged
    svg.selectAll("mylabels")
      .data(selectedTopics)
      .enter()
      .append("text")
      .attr("x", 175 + size * 1.2)
      .attr("y", function (d, i) { return 15 + i * (size + 5) + (size / 2) }) // 100 is where the first dot appears. 25 is the distance between dots
      .style("fill", function (d) { return "#000000" })
      .text(function (d) { return d.label })
      .attr("text-anchor", "left")
      .style("alignment-baseline", "middle")

    setGlobalSvg(svg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  function display()
  {
    var s =  <>
              <div className={"newsInfographCountryLabel"}>
                {countryName}
                {renderInfoIconWithTooltip(windwarGraphTooltip)}
              </div>
              <div id={id.replace(/ /g, "_")}>
              </div></>;

    return s;
  }



  return (
    <>
      {display()}
    </>
  );
}