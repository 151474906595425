/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import SearchBar from './SearchBar'
import DashboardNews from './DashboardNews'
import DashboardIR from './DashboardIR'
import './DashboardArticles.css'

export default function DashboardArticles() {
  return (
    <>
      <div>
        <SearchBar />
      </div>
      <div className='overflow-auto' >
        <hr className='dash-article-hr' />
        <DashboardNews />
        <hr className='dash-article-hr' />
        <DashboardIR />
      </div>
    </>
  )
}