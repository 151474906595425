/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { Col, Row } from "react-bootstrap"
import { Translate } from "react-redux-i18n"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import DonutGraph from "../DonutGraph"


export default function MSIFisheries(props){

  const {data, title, referenceURLLink } = props;

  const renderReferenceLink = () => {
    if (referenceURLLink){
      return(
        <>
          <Row>
            <Col xs={12} className="urlContainer">
              <a className="reference-url" href={referenceURLLink} target="_blank" rel={"noreferrer"}>
                <Translate value="referenceUrl"/>
              </a>
              <FontAwesomeIcon icon={faExternalLinkAlt}/>
            </Col>
          </Row>
        </>
      );
    }
  }

  return(
    <>
      <Row>
        <Col xs={6}>
          <div className="stat-data">
            <div className="stat-data-container">
              {/*TODO: add donut graph with data*/}
              <DonutGraph data={data} id={"fishery-graph"}/>
            </div>
          </div>
        </Col>
        <Col xs={6}>
          <div className="stat-label iuuLabel">{title}</div>
        </Col>
      </Row>
      {renderReferenceLink()}
    </>
  )
}