import { useEffect, useState, useCallback } from 'react'
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux'
import mapboxgl from 'mapbox-gl';
import PointPopup from '../components/GtdPointPopup/GtdPointPopup'
import TocUtils from '../utils/toc'

const { GtdUtil } = TocUtils

const defaultPoint = {
  "icon": "iVBORw0KGgoAAAANSUhEUgAAAAgAAAATCAYAAACtHkzTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAArElEQVQokd2Q3Q2CQBCEv7ucRUgXdySKCS3YxlEGWIa0gSWQCA8cVoFdcD7wE1SC787b7Mzuzq7iB9SSVAYLNJGjeTPcDWfpSfGEALWm6QWXk6NQANKTwiCOCKXnChSqMtip8wP7ymCV7Am9WA8oIFCHB0mtsSv60zNm8IJEDDtnEUEeORoFEDnyUnPbjSYvyaZT5z/ELV1thuLXH7bwh4ajIys1weaEuKVb8hcINjXYtozOpAAAAABJRU5ErkJggg==",
  "width": 6,
  "height": 14
}

const API_BASE = process.env.REACT_APP_API_URL

const useGtdPointLayer = (options) => {
  const { map, country_layer_id } = options
  const gtdEventSource = useSelector(state => state.gtd.gtdEventSource)
  const gtdInd = useSelector(state => state.gtd.gtdInd)
  const subGtdInd = useSelector(state => state.gtd.subGtdInd)
  const gtdSwitch = useSelector(state => state.gtd.gtdSwitch)
  const [layers, setLayers] = useState([])
  const [gtdPointData, setGtdPointData] = useState()

  const clearPointLayers = useCallback(() => {
    if (!map || !layers || layers.length === 0) {
      return
    }
    try {
      layers.forEach(layer => {
        if (map.getLayer(layer)) {
          map.removeLayer(layer)
        }
      })
    } catch (error) {
      console.error(error)
    }
    return []
  }, [map, layers])

  useEffect(() => {
    if (!gtdSwitch || !gtdEventSource || !gtdEventSource.features || gtdEventSource.features.length === 0 || !subGtdInd || subGtdInd.length === 0) {
      setGtdPointData(null)
      return
    }
    const typearr = subGtdInd.map(ind => {
      let title = GtdUtil.getEventTypeTitle(ind, 2)
      if (!title) {
        return
      }
      return {
        type: 'FeatureCollection',
        key: ind,
        features: gtdEventSource.features.filter(it => it.properties.attacktype1_txt === title)
      }
    })
    setGtdPointData(typearr)
  }, [gtdEventSource, subGtdInd, setGtdPointData, gtdSwitch])

  useEffect(() => {
    if (!map || !gtdPointData) {
      return
    }

    if (country_layer_id && map.getLayer(country_layer_id)) {
      map.removeLayer(country_layer_id)
    }

    const _layers = []

    gtdPointData.forEach((pointdata, key) => {
      const sourceId = `gtd_point_${pointdata.key}`
      const layerId = `gtd_layer_${pointdata.key}`
      const iconInfo = GtdUtil.getSubEventTypeObject(pointdata.key, 'name') || defaultPoint
      
      if (!map.hasImage(pointdata.key)) {
        let image = new Image(iconInfo.width, iconInfo.height);
        image.src = `data:image/png;base64,${iconInfo.icon}`;
        map.addImage(pointdata.key, image);
      }

      if (!map.getSource(sourceId)) {
        map.addSource(sourceId, {
          'type': 'geojson',
          'data': pointdata
        })
      } else {
        map.getSource(sourceId).setData(pointdata)
      }

      if (map.getLayer(layerId)) {
        map.removeLayer(layerId)
      }
      
      map.addLayer({
        'id': layerId,
        'type': 'symbol',
        'source': sourceId,
        'layout': {
          'icon-image': pointdata.key,
          'icon-size': 1
        }
      })

      map.on('click', layerId, async (e) => {
        const feature = e.features[0];
        
        const popup = new mapboxgl.Popup({
          closeButton: true,
          maxWidth: 'none'
        })
        const whereStr = ` latitude=${feature?.properties?.latitude} and longitude=${feature?.properties?.longitude} `
        const result = await fetch(`${API_BASE}/arcgis/queryGtdPointEventLayer/${whereStr}/*`).then(res => res.json())
        
        const PointPopups = document.createElement("div");
        ReactDOM.render(
          <PointPopup
            pointEvents={result}
            category={GtdUtil.getEventTypeTitle(gtdInd, 1)}
          />,
          PointPopups
        )
        popup
          .setLngLat(e.lngLat)
          .setDOMContent(PointPopups)
          .setMaxWidth('400')
          .addTo(map);
      })

      _layers.push(layerId)
    })

    setLayers(_layers)

    return () => {
      _layers.forEach(layer => {
        if(map.getLayer(layer)){
          map.removeLayer(layer)
        }
      })
    }
  }, [map, gtdPointData, country_layer_id])

  

  return {
    clearPointLayers
  }
}

export default useGtdPointLayer