/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useEffect, useState } from "react"
import PieGraph from "./PieGraph"
import { useSelector } from "react-redux"

export default function Infographs(props) {
  const selectedCountries = useSelector(state => state.infograph.newsSelectedCountries)
  const data = useSelector(state => state.infograph.newsData);
  // isLoading seems to be unused
  // eslint-disable-next-line no-unused-vars
  const [toRender, setToRender] = useState([]);
  useEffect(() => {
    if (data) {
      setToRender(data);
    }
  }, [data])

  useEffect(() => {
    if (data && selectedCountries) {
      setToRender(
        data.filter(data => selectedCountries.includes('All Countries') || selectedCountries.includes(data.countryName))
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountries])

  return (
    <>
      <div className={'newsInfographContainer'}>
        {toRender && toRender.map((country, count) => {
          return (
            <>
              <PieGraph data={country.data} id={'graph-' + country.countryName.replace(" ", "_")} countryName={country.countryName} total={country.total} />
              <div className={'newsInfographSep'} />
            </>
          )
        })}

      </div>
    </>
  );

}