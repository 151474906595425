import React from 'react';

import './Radio.css'

function Radio(props) {
    const { name, checked, onChange, scale } = props
    return (
        <div className="Radio_Box" style={{transform:[`scale(${scale})`]}}>
            <input className="radio" type="radio" name={name} checked={checked} onChange={onChange} />
            <label htmlFor="radio"className="label" onClick={onChange}>
            </label>
        </div>
    );
}

export default Radio;