/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


// Copied from Dashboard holder, and will be rendered inside of dashboard holder
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import components
import IUULegends from "./IUU/Legends/IUULegends";
import TOCLegends from './TOC/Legends/TOCLegends';
import IUULayers from "./IUU/Layers/IUULayers";
import TOCLayers from './TOC/Layers/TOCLayers'
import ReactMapboxComponent from "./ReactMapboxComponent";
import TocMapboxContainer from './TOC/TocMapboxContainer';

import "./Map.css";

class MapContainers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_bar_text: '',
      language: "",
      challenge_type: 'IUU Fishing' // TODO Test 'IUU Fishing',
    };
  }

  choose = (type) => {
    switch (type) {
      case 'IUU Fishing':
        return (
          <>
            <IUULegends
              fsi_selected = {this.props.fsi_selected}
              mapMarker    = {this.props.mapMarker}
              IUUAOI       = {this.props.IUUAOI}
              AOI_dropdown = {this.props.AOI_dropdown}
              isScoreByCountryChecked  = {this.props.isScoreByCountryChecked}
              ago_selected    = {this.props.ago_selected}
              isFirstVisitChecked = {this.props.isFirstVisitChecked}
              year         = {this.props.year}
              activity     = {this.props.activity}
              season       = {this.props.season}
              species      = {this.props.species}
            />
            <IUULayers
              uri={this.props.uri}
              fsi_selected={this.props.fsi_selected}
              ago_selected={this.props.ago_selected}
              eez_selected={this.props.eez_selected}
              year={this.props.year}
              activity={this.props.activity}
              season={this.props.season}
              species={this.props.species}
            />
          </>
        )
      case 'Violence and Arms':
        return (
          <>
            <TOCLegends
              fsi_selected={this.props.fsi_selected}
              year={this.props.year}
              activity={this.props.activity}
              season={this.props.season}
              species={this.props.species} />
            <TOCLayers
              uri={this.props.uri}
              fsi_selected={this.props.fsi_selected}
              ago_selected={this.props.ago_selected}
              eez_selected={this.props.eez_selected}
              year={this.props.year}
              activity={this.props.activity}
              season={this.props.season}
              species={this.props.species}
            />
          </>
        )

      default:
        return (
          <>
            <IUULegends
              fsi_selected = {this.props.fsi_selected}
              mapMarker    = {this.props.mapMarker}
              IUUAOI       = {this.props.IUUAOI}
              AOI_dropdown = {this.props.AOI_dropdown}
              isScoreByCountryChecked  = {this.props.isScoreByCountryChecked}
              ago_selected    = {this.props.ago_selected}
              isFirstVisitChecked = {this.props.isFirstVisitChecked}
              year         = {this.props.year}
              activity     = {this.props.activity}
              season       = {this.props.season}
              species      = {this.props.species}
            />
            <IUULayers
              uri={this.props.uri}
              fsi_selected={this.props.fsi_selected}
              ago_selected={this.props.ago_selected}
              eez_selected={this.props.eez_selected}
              year={this.props.year}
              activity={this.props.activity}
              season={this.props.season}
              species={this.props.species}
            />
          </>
        )
    }
  }

  mapboxCon = (type) => {
    switch(type){
      case 'Violence and Arms':
        return (
          <TocMapboxContainer
            uri={this.props.uri}
            acledSwitch={this.props.acledSwitch}
            acledEvent={this.props.acledEvent}
            acledClass={this.props.acledClass}
            chooseId={this.props.chooseId}
            acledStartYear={this.props.acledStartYear}
            acledEndYear={this.props.acledEndYear}
            acledCountryArr={this.props.acledCountryArr}
            sipriCountry={this.props.sipriCountry}
            gtdSwitch={this.props.gtdSwitch}
            year={this.props.year}
            selectedCountry={this.props.selectedCountry}
          />
        );
      case 'IUU Fishing':
      default:
        return (
          <ReactMapboxComponent
            uri             = {this.props.uri}
            fsi_selected    = {this.props.fsi_selected}
            ago_selected    = {this.props.ago_selected}
            eez_selected    = {this.props.eez_selected}
            mapMarker       = {this.props.mapMarker}
            IUUAOI          = {this.props.IUUAOI}
            firstVisit_dropdown = {this.props.firstVisit_dropdown}
            AOI_dropdown    = {this.props.AOI_dropdown}
            isScoreByCountryChecked = {this.props.isScoreByCountryChecked}
            year            = {this.props.year}
            activity        = {this.props.activity}
            season          = {this.props.season}
            species         = {this.props.species}
            selectedCountry = {this.props.selectedCountry}
            countryCounts   = {this.props.countryCounts}
            isFirstVisitChecked = {this.props.isFirstVisitChecked}
            layers={this.props.layers}
          />)
    }
  };

  // renders
  render() {
    return (
      <>
        {
          this.mapboxCon(this.props.irChallengeType)
        }
        {
          this.choose(this.props.irChallengeType)
        }
        {/* switch case based on security topic (fetched from redux) */}
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    layers: state.map.layers,
    fsi_selected: state.map.fsi_selected,
    ago_selected: state.map.ago_selected,
    mapMarker: state.map.mapMarker,
    IUUAOI: state.map.IUUAOI,
    firstVisit_dropdown: state.map.firstVisit_dropdown,
    AOI_dropdown: state.map.AOI_dropdown,
    isScoreByCountryChecked: state.map.isScoreByCountryChecked,
    year: state.map.year,
    season: state.map.season,
    species: state.map.species,
    activity: state.map.activity,
    selectedCountry: state.filters.countryFilter,
    irChallengeType: state.filters.irChallengeType,
    isFirstVisitChecked: state.map.isFirstVisitChecked
  };
}
export default connect(mapStateToProps)(MapContainers);
