import { useState, useCallback, useMemo } from 'react';
import { rhumbDestination, point } from '@turf/turf'
import { Capitals } from '../utils/capitals';
import { countryCentroids } from '../utils/CountryLookups';

const ArrowUnit = 'miles'
const ArrowColor = '#2B72AC'
const ArrowLineWidth = 2
const ArrowAngleOffset = 20
const ArrowDistince = 80
const SourceField = 'officalNam'

/**
 * Use it to draw arrow between two country
 */
const useArrow = () => {
  const capitalMap = useMemo(() => {
    if (!countryCentroids || Object.keys(countryCentroids).length === 0) {
      return
    }
    if (!Capitals || Object.keys(Capitals).length === 0 || Capitals.features.length === 0) {
      return
    }
    let map = new Map()
    Array.from(Object.values(countryCentroids)).map(ctr => {
      let capti = Capitals.features.find(cap => cap.properties.iso2 === ctr.iso_a2)
      if (!map.get(ctr.name) && !!capti) {
        map.set(ctr.name, capti.geometry.coordinates)
      }
    })
    return map
  }, [countryCentroids, Capitals])

  const _getAngleCord = (from, to, Isfield) => {
    var point2 = point(to);
    var angle = Math.atan2((from[0] - to[0]), (from[1] - to[1]))
    var theta = Isfield === 'one' ? angle * (180 / Math.PI) + (40 - ArrowAngleOffset) : angle * (180 / Math.PI) + (318 + ArrowAngleOffset);
    var distance = ArrowDistince;
    var options = { units: ArrowUnit };
    return rhumbDestination(point2, distance, theta, options).geometry.coordinates;
  }

  const arrowOut = useCallback((srcCountry, tgtCountries) => {
    let sourceCord = capitalMap.get(srcCountry)
    if(!sourceCord){
      console.log(`Fail to get country"s capital in ArrowOut: ${srcCountry}`)
      return
    }
    const features = tgtCountries.map(item => {
      let tgtCord = capitalMap.get(item.country)
      if(!tgtCord){
        console.log(`Fail to get country"s capital: ${item.country}`)
        return
      }
      let ang1 = _getAngleCord(sourceCord, tgtCord, 'one')
      let ang2 = _getAngleCord(sourceCord, tgtCord, 'two')
      return {
        'geometry': {
          'type': 'LineString',
          'coordinates': [
            sourceCord,
            tgtCord,
            ang1,
            tgtCord,
            ang2
          ]
        }
      }
    }).filter(it=>!!it)
    
    if (!features || features.length === 0) {
      console.log(`Fail to get cap arrow related coordinates: ${srcCountry}`)
      return
    }
    return features
  }, [])

  const arrowIn = useCallback((srcCountries, tgtCountry) => {
    let tgtCord = capitalMap.get(tgtCountry)
    if(!tgtCord){
      console.log(`Fail to get country"s capital in ArrowIn: ${tgtCountry}`)
      return
    }
    const features = srcCountries.map(item => {
      let sourceCord = capitalMap.get(item.country)
      if(!sourceCord){
        console.log(`Fail to get country"s capital: ${item.country}`)
        return
      }
      let ang1 = _getAngleCord(sourceCord, tgtCord, 'one')
      let ang2 = _getAngleCord(sourceCord, tgtCord, 'two')
      return {
        'geometry': {
          'type': 'LineString',
          'coordinates': [
            sourceCord,
            tgtCord,
            ang1,
            tgtCord,
            ang2
          ]
        }
      }
    }).filter(it=>!!it)

    if (!features || features.length === 0) {
      console.log(`Fail to get cap arrow related coordinates: ${tgtCountry}`)
      return
    }
    return features
  }, [])

  const drawArrows = useCallback((map, srcCountries, tgtCountries, xport = 'import') => {
    if (!map || !srcCountries || srcCountries.length === 0 || !tgtCountries || tgtCountries.length === 0) {
      return
    }
    const features = xport === 'import' ? arrowIn(srcCountries, tgtCountries[0]) : arrowOut(srcCountries[0], tgtCountries)
    if (map.getLayer('arrows')) {
      map.removeLayer("arrows")
      map.removeSource('arrows_source')
    }
    map.addSource('arrows_source', {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': features
      }
    })
    map.addLayer({
      'id': 'arrows',
      'type': 'line',
      'source': 'arrows_source',
      'paint': {
        'line-width': ArrowLineWidth,
        'line-color': ArrowColor
      }
    });
  }, [])

  return { drawArrows }
}

export default useArrow