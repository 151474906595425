/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { Row, Col } from 'react-bootstrap';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { Fragment } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Switch, Route, Link, BrowserRouter, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import { setRoute } from "../slices/uiSlice";

const useStyles = makeStyles(theme => ({
    indicator: {
        backgroundColor: "#ffcc00",
        background: "blue",
        height: "10px",
        //top: "45px"
    },
    tabsWrapper: {
        //height: "30px",
        background: "white",
    }
}));

function UserManagementBar (){
    const classes = useStyles();
    const navLinks = ["/","/news", "/collaboration"];

    //const [value, setValue] = React.useState("/");
    const value = useSelector(state=>state.ui.route);
    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        //setValue(newValue);
        dispatch(setRoute(newValue));
    };

    return (
    
    <Row>
        <Col xs={6} style={{paddingLeft:"0px"}} >
            <div id={"dashboard-title"}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label={"Navigation Tab Menu"}
              className={classes.tabsWrapper}
              TabIndicatorProps={{ className: classes.indicator }}
            >
                <Tab label="Dashboards" value="/" component={Link} to={navLinks[0]} aria-label={"Dashboards"}/>
                <Tab label="News" value="/news" component={Link} to={navLinks[1]} aria-label={"News"}/>
                {/* <Tab label="Collaboration" value="/collaboration" component={Link} to={navLinks[2]} aria-label={"Collaboration"} /> */}
            </Tabs>
            </div>
        </Col>
		{/* By Keven, do not need columns if not to responsive to screen
        <Col xs={8}>
        <div>
            
        </div>
        </Col>
		*/}

        <Col xs={6}>
        <div className="user-management-button">
           User Management
        </div>
        </Col>

    </Row>
    
    );
}

export default UserManagementBar;