/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './DashboardHolder.css'
// importing actions
import { setMapLayer } from '../slices/mapSlice';
import { setInfographData } from "../slices/infographSlice";
import { setOpenLeftPanel,setOpenRightPanel } from '../slices/infographSlice';

//import components
import MapContainer from "../Features/Map/MapContainer";
import DashboardArticles from '../Features/DashboardArticles/DashboardArticles'
import InfographContainer from "../Features/Infographs/InfographContainer"

// import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons'
import { faCaretSquareRight } from '@fortawesome/free-solid-svg-icons'

class DashboardHolder extends Component {

   handleLeft=()=>{
     this.props.setOpenLeftPanel(!this.props.openLeftPanel);
  }

  handleRight=()=>{
    this.props.setOpenRightPanel(!this.props.openRightPanel);
  }

  render() {
    return (
      <div className="mainDashboard">
        <LoadingOverlay
          active={this.props.isLoading}
          spinner
          text='Dashboard is loading...'
        >
          <Row className="mainDashboard-row">
            <Col sm={this.props.openLeftPanel ? 3 : 0} className="dashboardLHS-container">
              <div className="dashboardLHS">
                {/*}<DashboardAnalytics selectedCountry={this.props.selectedCountry}></DashboardAnalytics>{*/}
                {this.props.openLeftPanel ?<InfographContainer/> : null }
              </div>
            </Col>
            <Col sm={12 - (this.props.openLeftPanel ? 3 : 0) - (this.props.openRightPanel ? 3 : 0)} className="dashboardMap-container">
            {this.props.openLeftPanel ? <FontAwesomeIcon icon={faCaretSquareLeft} onClick={this.handleLeft} className="left-bar"/> : <FontAwesomeIcon icon={faCaretSquareRight} onClick={this.handleLeft} className="left-bar"/>}
              <MapContainer
                // legend props
                fsi_selected={this.props.fsi_selected}
                year={this.props.year}
                activity={this.props.activity}
                season={this.props.season}
                species={this.props.species}
                // iuu layers prop
                setMapLayer={this.props.setMapLayer}
                // map props
                uri={this.props.uri}
                eez_selected={this.props.eez_selected}
                selectedCountry={this.props.selectedCountry}
                countryCounts={this.props.countryCounts}
              ></MapContainer>
              {this.props.openRightPanel ? <FontAwesomeIcon icon={faCaretSquareRight} onClick={this.handleRight} className="right-bar"/> : <FontAwesomeIcon icon={faCaretSquareLeft} onClick={this.handleRight} className="right-bar"/>}
            </Col>
            <Col sm={this.props.openRightPanel ? 3 : 0} className="dashboardRHS-container">
              <DashboardArticles />
            </Col>
          </Row>
        </LoadingOverlay>
      </div>
    );
  }
}

DashboardHolder.propTypes = {
  setMapLayer: PropTypes.func.isRequired,
  uri: PropTypes.string,
  fsi_selected: PropTypes.bool,
  ago_selected: PropTypes.bool,
  eez_selected: PropTypes.bool,
  layerAIS: PropTypes.string,
  layerSeafoodHotspot: PropTypes.string,
  selectedCountry: PropTypes.string,
  countryCounts: PropTypes.object
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setMapLayer,
    setInfographData,
    setOpenLeftPanel,
    setOpenRightPanel,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    uri: state.map.uri,
    fsi_selected: state.map.fsi_selected,
    ago_selected: state.map.ago_selected,
    eez_selected: state.map.eez_selected,
    layerAIS: state.map.layerAIS,
    layerSeafoodHotspot: state.map.layerSeafoodHotspot,
    selectedCountry: state.filters.countryFilter,
    countryCounts: state.map.countryCounts,
    isLoading: state.map.isLoading,
    openLeftPanel:state.infograph.openLeftPanel,
    openRightPanel:state.infograph.openRightPanel,

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHolder);