/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useState, useEffect } from "react"
// import { useSelector } from "react-redux"

import "./News.css";
import NewsFilters from "./NewsFilters"
import News from "./News"
import LoadingOverlay from 'react-loading-overlay';

export default function NewsContainer(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([])
  const [selectedTopics, setSelectedTopics] = useState([])
  const [selectedNewsSource, setSelectedNewsSource] = useState('GDelt');

  useEffect(() => { // add 'All Countries' option
    setSelectedCountries(['All Countries'])
    //console.log('selectedTopics in newscontainer = ' + JSON.stringify(selectedTopics, null, 4));
    if(!selectedTopics || selectedTopics.length == 0){
      //console.log('selectedTopics in newscontainer = ' + JSON.stringify(selectedTopics, null, 4));
      setSelectedTopics(['All Security Topics'])
    }
  }, [countryList])

  return (
    <>
      <div className={"news-container"}>
        <LoadingOverlay
          active={isLoading}
          spinner
          text='News articles are loading...'
        >
          <NewsFilters
            countryList={countryList.sort()}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
            selectedNewsSource={selectedNewsSource}
            setSelectedNewsSource={setSelectedNewsSource}
          />
          <News setIsLoading={setIsLoading} setCountryList={setCountryList} selectedCountries={selectedCountries} selectedTopics={selectedTopics} selectedNewsSource={selectedNewsSource} />
        </LoadingOverlay>
      </div>
    </>
  );
}