/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import ChallengeFilter from './filters/ChallenegeFilter';
import CountryFilter from './filters/CountryFilter';
import LanguageFilter from './filters/LanguageFilter';

function FiltersBar() {
	function handleResetClick(e) {
		window.location.reload();
		//e.preventDefault();
	}
	return (
		<div className="filtersBar">
			<Row >
				<Col sm={6}>
					<div className="gray-box filterItem">
						<ChallengeFilter></ChallengeFilter>
					</div>


					<div className="gray-box filterItem">
						<LanguageFilter></LanguageFilter>
					</div>


					<div className="gray-box filterItem">
						<CountryFilter></CountryFilter>
					</div>
				</Col>


				<Col sm={6} className='btnSet'>
					<div className="gray-box">

					</div>
					<div>
						<Button className="heading3 filter-reset-button" onClick={handleResetClick}>Reset</Button>
					</div>
					<div className="iconContainer">
						<FontAwesomeIcon icon={faShareAlt} />
					</div>
					<div className="iconContainer">
						<FontAwesomeIcon icon={faDownload} />
					</div>
				</Col>
			</Row>
		</div>
	);
}

export default FiltersBar;