import { useEffect } from "react"
import * as echarts from 'echarts';
import  Highcharts  from 'highcharts';
import './LineChart.css';

export default function LineChart(props) {
  const { chartData, chartDate, width, height } = props;

  useEffect(()=>{
    if(chartData && chartData.length!==0){
      lineCharts();
    }
  },[chartData,chartDate, lineCharts])



  const lineCharts = () => {
    var chartDom = document.getElementById('line');
  Highcharts.chart(chartDom,{
  
      tooltip:{
    borderWidth: 0,
    shared:true,
    useHTML: true,
      },
      plotOptions: {
        series: {
          stickyTracking: false,
        }
      },
      legend:{
        enabled:true,
        align:'left',
      },
      credits:{
        enabled:false
      },
      title:{
text:""
      },
        xAxis: {
          categories: chartDate,
          data: chartDate,
        },
        yAxis:[{
          title : {
            text:null,
          },
        },
      ],
        scrollbar: {
          enabled: true
      },
        series: chartData,
      });

    }


  


  return (
    <>
      <div id='line' style={{ width: width, height: height}}>
      </div>
    </>
  );
}
