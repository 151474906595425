/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


// copied from MapLegends
import React, { useCallback } from 'react';
import { useSelector } from "react-redux"

export default function SipriLegend() {
	const sipriLegend = useSelector(state => state.sipri.sipriLegend);

	const SipriCountryLegend = useCallback(() => {
		if(!sipriLegend || sipriLegend.length === 0){
			return null;
		}
		return sipriLegend.length ? sipriLegend.map((item, ind) => {
			return (
				<div className="TOCLegendIcon" key={ind}>
					<div className="acledLegend" style={{ backgroundColor: item.color, borderColor: '#000' }}></div>
					<span>{item.min} - {item.max}</span>
				</div>
			)
		}) : null;
	}, [sipriLegend])

	
  return (
    <>
      <div className="TOCLegend">
        <div className="legendTitle">
          Country View
        </div>
        <div className="legendContent">
          <SipriCountryLegend />
          <div className="TOCLegendIcon">(Millions US Dollars)</div>
        </div>
        <div className="TOCLegendDescribe">
          <div className="TOCLegendDescribeHR"></div>
          <div className="TOCLegendDescribeContent">Label "Imports" or "Exports" depending on radio button selection</div>
        </div>
      </div>
    </>
  )
}