
let d = [];
const DateRangeForAcled = async (api) => {
	await fetch(api)
		.then(response => response.json())
		.then(res => {
			d = RangeOfData(res);
		})

	return d;
}

const RangeOfData = (obj) => {
	let narr = [];
	obj.features.map(currObj => {

		let str = `${currObj.attributes.MONTHNR}/${currObj.attributes.YEAR}`;
		if (narr.indexOf(str) == -1) narr.push(str);

	});

	narr.sort((a, b) => {
		let str1 = a.split('/');
		let str2 = b.split('/');
		let ma = Number(str1[0]);
		let mb = Number(str2[0]);
		let ya = Number(str1[1]);
		let yb = Number(str2[1]);

		if (ya != yb) return ya - yb;
		else return ma - mb;
	});

	return narr;

}


const dateRangeForSipri = (obj) => {
	let narr = [];
	obj.features.map(currobj => {
		let str = `${currobj.properties.YEAR}`;
		if (narr.indexOf(str) == -1) narr.push(str);
	});

	narr.sort((a, b) => {
		let year1 = Number(a);
		let year2 = Number(b);
		return year1 - year2;
	});

	return narr;

}


export { DateRangeForAcled, RangeOfData, dateRangeForSipri };