/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useEffect, useState } from "react"
import * as d3 from 'd3';

export default function DonutGraph(props) {

  const { data, id } = props;
  const [globalSvg, setGlobalSvg] = useState(null);

  useEffect(() => {
    if (globalSvg) {
      d3.select("#" + id).select("svg").remove();
    }

    let svg = d3.select("#" + id).append("svg");
    svg.attr("viewBox", "0 0 140 140");
    let width = 140;
    let height = 140;
    let radius = Math.min(width, height) / 2;
    let g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
    let color = d3.scaleOrdinal(["#081e3f", "#fc0"]);

    let pie = d3.pie();

    let arc = d3.arc()
      .innerRadius(60)
      .outerRadius(radius)
    let tData = data ? data : 0;
    let arcs = g.selectAll("arc")
      .data(pie([tData, 100 - tData]))
      .enter()
      .append("g")
      .attr("class", "arc");

    arcs.append("path")
      .attr("fill", function (d, i) {
        return color(i);
      })
      .attr("d", arc)

    let title = data ? data : "N/A";
    arcs.append("text")
      .attr("text-anchor", "middle")
      .attr("class", "text-label")
      .text(title);


    setGlobalSvg(svg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div id={id}>
      </div>
    </>
  );
}