/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React from "react"


export default function FishingModal(props) {

  return(
    <>
      <div className={'iuuModalContainer'}>
        <span className={'iuuModalImage'}>
          <img src={window.location.origin + "/images/crusteansAnimation.gif"} alt={"Trans shipment data"}/>
        </span>
        <br/>
        <div>
          <p>
            This diagram shows the amount of seafood fished in a country's Exclusive Economic Zone (EEZ) in South America for a given year and seafood type. This data ranging from 2006 to 2016 originally comes from the FishStatJ database provided by the United Nations Food and Agriculture Organization and has been added to by Sea Around Us. Sea Around Us uses surveys and estimation techniques to derive the true amount of seafood fished in each country's EEZ which includes reported and unreported catch. To read more about their methods visit their website: <a className={"iuuModalLink"} target="_blank" rel="noopener noreferrer" href={"http://www.seaaroundus.org"}>http://www.seaaroundus.org</a>. Their data was downloaded from an R package.
          </p>
          <p>
            <b>See also:</b> <a className={"iuuModalLink"} target="_blank" rel="noopener noreferrer" href={"https://fiugis.maps.arcgis.com/home/item.html?id=b336a62e0bda427bb84d394bc6cf9be4"}>https://fiugis.maps.arcgis.com/home/item.html?id=b336a62e0bda427bb84d394bc6cf9be4</a>
          </p>

        </div>
      </div>
    </>
  )

}

