/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


export const countryCentroids = 
{
   // "ZZZ": {"iso_a2": "ZZ", "name": "Latin America", "bbox": [[0, 0], [-110, -45]]},
"IDN": {"iso_a2": "ID", "name": "Indonesia", "bbox": [[95.01270592500003, -10.922621351999908], [140.97762699400005, 5.910101630000042]]},
 "MYS": {"iso_a2": "MY", "name": "Malaysia", "bbox" : [[99.64522806000008, 0.8513703410001057], [119.27808678500003, 7.35578034100007]]},
 "CHL": {"iso_a2": "CL", "name": "Chile", "bbox"    : [[-109.45372473899994, -55.9185042229999], [-66.42080644399994, -17.506588197999946]]},
 "BOL": {"iso_a2": "BO", "name": "Bolivia", "bbox"  : [[-69.66649226999988, -22.89725758799996], [-57.465660766999946, -9.679821471999986]]},
 "PER": {"iso_a2": "PE", "name": "Peru", "bbox"     : [[-81.33755752899992, -18.337746206368088], [-68.68425248299991, -0.02909271199992247]]},
 "ARG": {"iso_a2": "AR", "name": "Argentina", "bbox": [[-73.58803584899991, -55.05201588299991], [-53.661551879999934, -21.786937763999973]]},
 "-99": {"iso_a2": "-99", "name": "Scarborough Reef", "bbox": [[117.75185638100004, 15.150082025000074], [117.75569233100009, 15.154369263000092]]},
 "CYP": {"iso_a2": "CY", "name": "Cyprus", "bbox"   : [[32.27173912900008, 34.62501943000002], [34.09913170700008, 35.18708213000012]]},
 "IND": {"iso_a2": "IN", "name": "India", "bbox"    : [[68.14340254000007, 6.74555084800005], [97.36225305200003, 35.49540557900012]]},
 "CHN": {"iso_a2": "CN", "name": "China", "bbox"    : [[73.60225630700006, 15.77537669500009], [134.77257938700006, 53.56944447900007]]},
 "ISR": {"iso_a2": "IL", "name": "Israel", "bbox"   : [[34.24835085700005, 29.48969147300005], [35.88807255000012, 33.406721700000006]]},
 "PSE": {"iso_a2": "PS", "name": "Palestine", "bbox": [[34.200269441306034, 31.211448958000076], [35.572536255000045, 32.54264007600004]]},
 "LBN": {"iso_a2": "LB", "name": "Lebanon", "bbox"  : [[35.099619988000086, 33.055580342], [36.604101196000045, 34.68754791300002]]},
 "ETH": {"iso_a2": "ET", "name": "Ethiopia", "bbox" : [[32.98979984500011, 3.4033334350000928], [47.97916914900014, 14.879532166000047]]},
 "SSD": {"iso_a2": "SS", "name": "South Sudan", "bbox": [[24.121555623000063, 3.4902015180000916], [35.92083540900012, 12.216154684000088]]},
 "SOM": {"iso_a2": "SO", "name": "Somaliland"/*"Somalia"*/, "bbox"  : [[40.96538537600014, -1.6963029929999607], [51.41703781100006, 11.989118646000065]]},
 "KEN": {"iso_a2": "KE", "name": "Kenya", "bbox"    : [[33.890468384000144, -4.677504164999959], [41.885019165000074, 5.030375822607695]]},
 "PAK": {"iso_a2": "PK", "name": "Pakistan", "bbox" : [[60.844378703000075, 23.694525458000044], [77.04897098800006, 37.05448354100004]]},
 "MWI": {"iso_a2": "MW", "name": "Malawi", "bbox"   : [[32.66330814700012, -17.135335387999902], [35.90429895000011, -9.381235046999876]]},
 "TZA": {"iso_a2": "TZ", "name": "United Republic of Tanzania", "bbox": [[29.32103153500009, -11.73127248199998], [40.44939212300005, -0.9858301799998799]]},
 "SYR": {"iso_a2": "SY", "name": "Syria", "bbox"    : [[35.723399285000085, 32.31304168700005], [42.377185506000046, 37.324906311000106]]},
 "SUR": {"iso_a2": "SR", "name": "Suriname", "bbox" : [[-58.067691202999924, 1.8335067750000604], [-53.986357453999915, 6.011573766000083]]},
 "GUY": {"iso_a2": "GY", "name": "Guyana", "bbox"   : [[-61.39671280899992, 1.1858202110000775], [-56.481819010999914, 8.558010158000059]]},
 "KOR": {"iso_a2": "KR", "name": "South Korea", "bbox": [[124.61361738400001, 33.19757721600007], [131.86252188600008, 38.624335028000075]]},
 "PRK": {"iso_a2": "KP", "name": "North Korea", "bbox": [[124.21131598900001, 37.67560455900008], [130.69996178500003, 43.010269878000074]]},
 "MAR": {"iso_a2": "MA", "name": "Morocco", "bbox"  : [[-17.013743325393165, 21.419971097583428], [-1.0319994709999492, 35.92651914900006]]},
 "ESH": {"iso_a2": "EH", "name": "Western Sahara", "bbox": [[-17.104644334999932, 20.766913153000075], [-8.680809081999882, 27.661465148999994]]},
 "CRI": {"iso_a2": "CR", "name": "Costa Rica", "bbox": [[-87.11766516799992, 5.51508209800005], [-82.56283687399991, 11.209937032000099]]},
 "NIC": {"iso_a2": "NI", "name": "Nicaragua", "bbox": [[-87.6858210929999, 10.7134815470001], [-82.72569739499994, 15.030969950000099]]},
 "COG": {"iso_a2": "CG", "name": "Republic of the Congo", "bbox": [[11.114016304109821, -5.019630835999919], [18.642406860000023, 3.7082760620000528]]},
 "COD": {"iso_a2": "CD", "name": "Democratic Republic of the Congo", "bbox": [[12.210541212000066, -13.45835052399994], [31.280446818000087, 5.375280253000085]]},
 "BTN": {"iso_a2": "BT", "name": "Bhutan", "bbox"   : [[88.73006677200004, 26.696149394000102], [92.08877648900011, 28.358398930786237]]},
 "UKR": {"iso_a2": "UA", "name": "Ukraine", "bbox"  : [[22.13283980300011, 45.21356842700004], [40.1595430910001, 52.36894928000008]]},
 "BLR": {"iso_a2": "BY", "name": "Belarus", "bbox"  : [[23.165644979000035, 51.2351683560001], [32.71953210400005, 56.15680592900007]]},
 "NAM": {"iso_a2": "NA", "name": "Namibia", "bbox"  : [[11.717621290000068, -28.959368183999885], [25.259780721000112, -16.951057230999936]]},
 "ZAF": {"iso_a2": "ZA", "name": "South Africa", "bbox": [[16.469981316000087, -46.96575286299992], [37.97779381600009, -22.126451924999927]]},
 "MAF": {"iso_a2": "MF", "name": "Saint Martin", "bbox": [[-63.14683997299994, 18.033391472351283], [-63.01073157499991, 18.12213776200008]]},
 "SXM": {"iso_a2": "SX", "name": "Sint Maarten", "bbox": [[-63.11888587099992, 18.01911041900007], [-63.01756858528839, 18.062119859000063]]},
 "OMN": {"iso_a2": "OM", "name": "Oman", "bbox": [[51.97861495000012, 16.642401434000078], [59.84457441500007, 26.385972398000035]]},
 "UZB": {"iso_a2": "UZ", "name": "Uzbekistan", "bbox": [[55.97583866300005, 37.18514740000005], [73.14864058400013, 45.558718974]]},
 "KAZ": {"iso_a2": "KZ", "name": "Kazakhstan", "bbox": [[46.47827884900005, 40.58465566000011], [87.32379602100013, 55.43455027300003]]},
 "TJK": {"iso_a2": "TJ", "name": "Tajikistan", "bbox": [[67.34269006300013, 36.678640849000104], [75.16412479700006, 41.039976705000115]]},
 "LTU": {"iso_a2": "LT", "name": "Lithuania", "bbox": [[20.924568700365, 53.88684112599999], [26.80072025600009, 56.44260243700002]]},
 "BRA": {"iso_a2": "BR", "name": "Brazil", "bbox": [[-74.01847469099994, -33.74228037499992], [-28.877064581999946, 5.267224833000029]]},
 "URY": {"iso_a2": "UY", "name": "Uruguay", "bbox": [[-58.43936113199993, -34.97340260199991], [-53.1108361419999, -30.096869811999937]]},
 "MNG": {"iso_a2": "MN", "name": "Mongolia", "bbox": [[87.73570886300013, 41.586144918000016], [119.90702681500011, 52.12958404600003]]},
 "RUS": {"iso_a2": "RU", "name": "Russia", "bbox": [[-179.99999999999991, 41.19268056200009], [180.0, 81.85871002800008]]},
 "CZE": {"iso_a2": "CZ", "name": "Czechia", "bbox": [[12.076140991000045, 48.557915752], [18.837433716000106, 51.04001230900009]]},
 "DEU": {"iso_a2": "DE", "name": "Germany", "bbox": [[5.852489868000106, 47.27112091100007], [15.022059367000054, 55.065334377000056]]},
 "EST": {"iso_a2": "EE", "name": "Estonia", "bbox": [[21.832367384000065, 57.51581858300001], [28.186475464000125, 59.67088450700004]]},
 "LVA": {"iso_a2": "LV", "name": "Latvia", "bbox": [[20.968597852000073, 55.66699086600006], [28.217274617000072, 58.07513844900008]]},
 "SWE": {"iso_a2": "SE", "name": "Sweden", "bbox": [[11.108164910000085, 55.342678127000056], [24.163413534000114, 69.03635569300012]]},
 "FIN": {"iso_a2": "FI", "name": "Finland", "bbox": [[20.62316451000001, 59.81122467700004], [31.56952478000011, 70.07531036400012]]},
 "VNM": {"iso_a2": "VN", "name": "Vietnam", "bbox": [[102.11865523300008, 8.565578518000052], [109.47242272200003, 23.366275127000065]]},
 "KHM": {"iso_a2": "KH", "name": "Cambodia", "bbox": [[102.3134237060001, 10.415773620000024], [107.61051639900006, 14.704581605000016]]},
 "LUX": {"iso_a2": "LU", "name": "Luxembourg", "bbox": [[5.714927205000038, 49.44132436200003], [6.502579387000139, 50.17497467100007]]},
 "ARE": {"iso_a2": "AE", "name": "United Arab Emirates", "bbox": [[51.569346550000034, 22.620945943000052], [56.38363691500007, 26.074791972000085]]},
 "BEL": {"iso_a2": "BE", "name": "Belgium", "bbox": [[2.521799927545686, 49.49522288100006], [6.374525187000074, 51.49623769100005]]},
 "GEO": {"iso_a2": "GE", "name": "Georgia", "bbox": [[39.985976355436605, 41.04411082000006], [46.69480310100005, 43.57584259000002]]},
 "MKD": {"iso_a2": "MK", "name": "Macedonia", "bbox": [[20.44415734900008, 40.849394023000045], [23.00958215300011, 42.37033477900009]]},
 "ALB": {"iso_a2": "AL", "name": "Albania", "bbox": [[19.272032511000106, 39.637013245000034], [21.036679321000065, 42.654813538000084]]},
 "AZE": {"iso_a2": "AZ", "name": "Azerbaijan", "bbox": [[44.77455855300013, 38.392644755000035], [50.625743035000085, 41.890441590000094]]},
 "TUR": {"iso_a2": "TR", "name": "Turkey", "bbox": [[25.663259311000047, 35.81977854500006], [44.80699282900008, 42.09878164300005]]},
 "ESP": {"iso_a2": "ES", "name": "Spain", "bbox": [[-18.167225714999915, 27.642238674000055], [4.337087436000047, 43.79344310100004]]},
 "LAO": {"iso_a2": "LA", "name": "Laos", "bbox": [[100.0970732020001, 13.915456645000106], [107.66436324000011, 22.496044007000094]]},
 "KGZ": {"iso_a2": "KG", "name": "Kyrgyzstan", "bbox": [[69.22629602000012, 39.18923695900007], [80.2575606690001, 43.26170155800007]]},
 "ARM": {"iso_a2": "AM", "name": "Armenia", "bbox": [[43.43629398600007, 38.86370127400001], [46.602612346000114, 41.290452373000065]]},
 "DNK": {"iso_a2": "DK", "name": "Denmark", "bbox": [[8.094004754000082, 54.56858958500004], [15.151377800000091, 57.751166083000044]]},
 "LBY": {"iso_a2": "LY", "name": "Libya", "bbox": [[9.286543823000073, 19.496123759000014], [25.156260613000086, 33.18122531500005]]},
 "TUN": {"iso_a2": "TN", "name": "Tunisia", "bbox": [[7.47983239800007, 30.22890533500008], [11.564130900000123, 37.34520091400009]]},
 "ROU": {"iso_a2": "RO", "name": "Romania", "bbox": [[20.24282596900008, 43.6500499480001], [29.699554884000065, 48.27483225600007]]},
 "HUN": {"iso_a2": "HU", "name": "Hungary", "bbox": [[16.09403527800012, 45.74134348600002], [22.877600546000053, 48.569232890000066]]},
 "SVK": {"iso_a2": "SK", "name": "Slovakia", "bbox": [[16.84448042800011, 47.75000640900008], [22.539636678000136, 49.60177968400002]]},
 "POL": {"iso_a2": "PL", "name": "Poland", "bbox": [[14.12392297300002, 48.994013164000094], [24.143156372000107, 54.838324286000045]]},
 "IRL": {"iso_a2": "IE", "name": "Ireland", "bbox": [[-10.478179490999935, 51.44570547100005], [-5.993519660999937, 55.386379299000055]]},
 "GBR": {"iso_a2": "GB", "name": "United Kingdom", "bbox": [[-13.69131425699993, 49.90961334800005], [1.7711694670000497, 60.84788646000004]]},
 "GRC": {"iso_a2": "GR", "name": "Greece", "bbox": [[19.626475457000083, 34.81500885600008], [28.239756707000083, 41.750475973000064]]},
 "ZMB": {"iso_a2": "ZM", "name": "Zambia", "bbox": [[21.97987756300006, -18.069231871999946], [33.67420251500005, -8.194124042999917]]},
 "SLE": {"iso_a2": "SL", "name": "Sierra Leone", "bbox": [[-13.30109615799995, 6.919419664000088], [-10.282235879999888, 9.996005961000066]]},
 "GIN": {"iso_a2": "GN", "name": "Guinea", "bbox": [[-15.081125454999949, 7.190208232000103], [-7.662447469999961, 12.673387757000029]]},
 "LBR": {"iso_a2": "LR", "name": "Liberia", "bbox": [[-11.476185675999943, 4.3472354190000715], [-7.384118204999936, 8.565395609000063]]},
 "CAF": {"iso_a2": "CF", "name": "Central African Republic", "bbox": [[14.387266072000074, 2.2364537560000457], [27.441301310000142, 11.000828349000074]]},
 "SDN": {"iso_a2": "SD", "name": "Sudan", "bbox": [[21.809448690000124, 8.681641744000046], [38.603851759000065, 22.226964823000102]]},
 "DJI": {"iso_a2": "DJ", "name": "Djibouti", "bbox": [[41.74911014800006, 10.929824931000084], [43.418711785000085, 12.707912502000056]]},
 "ERI": {"iso_a2": "ER", "name": "Eritrea", "bbox": [[36.423647095000035, 12.360021871000086], [43.12387129000007, 18.004828192000048]]},
 "AUT": {"iso_a2": "AT", "name": "Austria", "bbox": [[9.52115482500011, 46.37864308700007], [17.148337850000075, 49.00977447500007]]},
 "IRQ": {"iso_a2": "IQ", "name": "Iraq", "bbox": [[38.77451135200005, 29.063136699000026], [48.559255405000044, 37.37549753800006]]},
 "ITA": {"iso_a2": "IT", "name": "Italy", "bbox": [[6.6027283120000675, 35.48924388200004], [18.517425977000073, 47.08521494500006]]},
 "CHE": {"iso_a2": "CH", "name": "Switzerland", "bbox": [[5.954809204000128, 45.82071848599999], [10.466626831000013, 47.801166077000076]]},
 "IRN": {"iso_a2": "IR", "name": "Iran", "bbox": [[44.01486332200011, 25.059408165000036], [63.31962813300004, 39.771526998000084]]},
 "NLD": {"iso_a2": "NL", "name": "Netherlands", "bbox": [[-68.41738847599993, 12.022040106000077], [7.198505900000043, 53.55809153900003]]},
 "LIE": {"iso_a2": "LI", "name": "Liechtenstein", "bbox": [[9.475886271000121, 47.05240041200004], [9.615722697000109, 47.2628010050001]]},
 "CIV": {"iso_a2": "CI", "name": "Ivory Coast", "bbox": [[-8.618719848999916, 4.34406159100007], [-2.5063280839999322, 10.726478170000036]]},
 "SRB": {"iso_a2": "RS", "name": "Republic of Serbia", "bbox": [[18.84497847500006, 42.23494482000011], [22.98457076000011, 46.17387522400013]]},
 "MLI": {"iso_a2": "ML", "name": "Mali", "bbox": [[-12.2641304119999, 10.140054017000097], [4.235637655000119, 24.99506459600009]]},
 "SEN": {"iso_a2": "SN", "name": "Senegal", "bbox": [[-17.536040818999936, 12.305606588000089], [-11.37777624499995, 16.691385397000047]]},
 "NGA": {"iso_a2": "NG", "name": "Nigeria", "bbox": [[2.6710819900001184, 4.272162177000041], [14.66993615700008, 13.880290832000057]]},
 "BEN": {"iso_a2": "BJ", "name": "Benin", "bbox": [[0.7598808180001129, 6.213893947000088], [3.8374190670000132, 12.39924428300013]]},
 "AGO": {"iso_a2": "AO", "name": "Angola", "bbox": [[11.66939414300009, -18.031404723999884], [24.061714315000103, -4.391203714999932]]},
 "HRV": {"iso_a2": "HR", "name": "Croatia", "bbox": [[13.501475457000083, 42.41632721600007], [19.40783817500011, 46.546979065000116]]},
 "SVN": {"iso_a2": "SI", "name": "Slovenia", "bbox": [[13.365261271000094, 45.423636779999995], [16.515301554000075, 46.86396230100003]]},
 "QAT": {"iso_a2": "QA", "name": "Qatar", "bbox": [[50.750987175000034, 24.559871521000062], [51.61654707100007, 26.160101630000042]]},
 "SAU": {"iso_a2": "SA", "name": "Saudi Arabia", "bbox": [[34.57276451900009, 16.37095774900007], [55.63756473800004, 32.12134796200006]]},
 "BWA": {"iso_a2": "BW", "name": "Botswana", "bbox": [[19.978345988000115, -26.891794127999987], [29.350073689000055, -17.781807555999947]]},
 "ZWE": {"iso_a2": "ZW", "name": "Zimbabwe", "bbox": [[25.219369751000045, -22.39733978299992], [33.04276818900013, -15.614808044999876]]},
 "BGR": {"iso_a2": "BG", "name": "Bulgaria", "bbox": [[22.345023234000053, 41.238104147000044], [28.603526238000086, 44.22843453899999]]},
 "THA": {"iso_a2": "TH", "name": "Thailand", "bbox": [[97.35140100100011, 5.629890035000059], [105.65099776200003, 20.44500640900013]]},
 "SMR": {"iso_a2": "SM", "name": "San Marino", "bbox": [[12.385628745000105, 43.892055514999996], [12.492392254000094, 43.98256678600008]]},
 "HTI": {"iso_a2": "HT", "name": "Haiti", "bbox": [[-74.48916581899994, 18.025946356000077], [-71.63911088099988, 20.089789130000042]]},
 "DOM": {"iso_a2": "DO", "name": "Dominican Republic", "bbox": [[-72.0098376059999, 17.54555898600006], [-68.32860266799992, 19.93768952000005]]},
 "TCD": {"iso_a2": "TD", "name": "Chad", "bbox": [[13.449183797000103, 7.4555667110001025], [23.98440637200008, 23.444719951000067]]},
 "KWT": {"iso_a2": "KW", "name": "Kuwait", "bbox": [[46.53243575000005, 28.53350494400003], [48.432781227000135, 30.09821563700008]]},
 "SLV": {"iso_a2": "SV", "name": "El Salvador", "bbox": [[-90.11477901199993, 13.158636786000045], [-87.69319351199994, 14.445372620000086]]},
 "GTM": {"iso_a2": "GT", "name": "Guatemala", "bbox": [[-92.24625650399989, 13.731404008980572], [-88.22093665299991, 17.81601959300012]]},
 "TLS": {"iso_a2": "TL", "name": "East Timor", "bbox": [[124.03003991000003, -9.501227721999967], [127.31324303500003, -8.135023695999905]]},
 "BRN": {"iso_a2": "BN", "name": "Brunei", "bbox": [[113.99878991000003, 4.016681010000042], [115.36074100800016, 5.057196356000077]]},
 "MCO": {"iso_a2": "MC", "name": "Monaco", "bbox": [[7.365750207000076, 43.71796907700008], [7.43745403212602, 43.76350555400002]]},
 "DZA": {"iso_a2": "DZ", "name": "Algeria", "bbox": [[-8.68238521299989, 18.975561218000067], [11.96886071700007, 37.09393952000005]]},
 "MOZ": {"iso_a2": "MZ", "name": "Mozambique", "bbox": [[30.21384525500008, -26.86027150399994], [40.847992384000065, -10.469008070999905]]},
 "SWZ": {"iso_a2": "SZ", "name": "eSwatini", "bbox": [[30.782906128000093, -27.31626434299993], [32.11739831600005, -25.735999043999954]]},
 "BDI": {"iso_a2": "BI", "name": "Burundi", "bbox": [[28.9868917230001, -4.463344014999933], [30.833962443000075, -2.3030624389998877]]},
 "RWA": {"iso_a2": "RW", "name": "Rwanda", "bbox": [[28.857235555000074, -2.826854756999893], [30.88780928500006, -1.0586939489998741]]},
 "MMR": {"iso_a2": "MM", "name": "Myanmar", "bbox": [[92.17497277900003, 9.790716864000046], [101.17385502100007, 28.53846588100008]]},
 "BGD": {"iso_a2": "BD", "name": "Bangladesh", "bbox": [[88.02178959200006, 20.738714911000045], [92.64285119700003, 26.623544007000064]]},
 "AND": {"iso_a2": "AD", "name": "Andorra", "bbox": [[1.4064563390001013, 42.42867747000004], [1.7650907800001505, 42.64936167400002]]},
 "AFG": {"iso_a2": "AF", "name": "Afghanistan", "bbox": [[60.48677779100012, 29.386605326000037], [74.89230676300008, 38.47367340200013]]},
 "MNE": {"iso_a2": "ME", "name": "Montenegro", "bbox": [[18.433530721000068, 41.85236237200007], [20.355170532000102, 43.547885641000036]]},
 "BIH": {"iso_a2": "BA", "name": "Bosnia and Herzegovina", "bbox": [[15.716073852000108, 42.55921213800009], [19.618884725000044, 45.28452382500008]]},
 "UGA": {"iso_a2": "UG", "name": "Uganda", "bbox": [[29.54845951300007, -1.4752059939998503], [35.006472615000064, 4.219691875000095]]},
 "CUB": {"iso_a2": "CU", "name": "Cuba", "bbox": [[-84.94961503799993, 19.827826239000046], [-74.1328832669999, 23.265570380000042]]},
 "HND": {"iso_a2": "HN", "name": "Honduras", "bbox": [[-89.36379125999989, 12.979777324000068], [-83.13044447244795, 17.41864655200004]]},
 "ECU": {"iso_a2": "EC", "name": "Ecuador", "bbox": [[-92.01158606699994, -5.011372578999939], [-75.2272639579999, 1.6643740910000702]]},
 "COL": {"iso_a2": "CO", "name": "Colombia", "bbox": [[-81.72370357999995, -4.236484476999905], [-66.87506058799994, 13.57835521000004]]},
 "PRY": {"iso_a2": "PY", "name": "Paraguay", "bbox": [[-62.65035721899994, -27.58684214299994], [-54.24528885899994, -19.28672861699995]]},
 "PRT": {"iso_a2": "PT", "name": "Portugal", "bbox": [[-31.284901495999918, 30.029242255000042], [-6.205947224999932, 42.15362966000002]]},
 "MDA": {"iso_a2": "MD", "name": "Moldova", "bbox": [[26.617889038000015, 45.46177398700006], [30.131576375000122, 48.486033834000054]]},
 "TKM": {"iso_a2": "TM", "name": "Turkmenistan", "bbox": [[52.43767061735559, 35.140646871000044], [66.64578169800006, 42.79118764300004]]},
 "JOR": {"iso_a2": "JO", "name": "Jordan", "bbox": [[34.9493851167955, 29.189950663999994], [39.29199914500009, 33.3716850790001]]},
 "NPL": {"iso_a2": "NP", "name": "Nepal", "bbox": [[80.03028772000005, 26.343767802000073], [88.1690674240001, 30.416904195000072]]},
 "LSO": {"iso_a2": "LS", "name": "Lesotho", "bbox": [[27.002154989000132, -30.658799335999916], [29.435908244000075, -28.570761413999975]]},
 "CMR": {"iso_a2": "CM", "name": "Cameroon", "bbox": [[8.505056186000047, 1.6545512900001285], [16.20772342900011, 13.081140646000023]]},
 "GAB": {"iso_a2": "GA", "name": "Gabon", "bbox": [[8.695567254000082, -3.936856189796041], [14.498990519000131, 2.3224950160001185]]},
 "NER": {"iso_a2": "NE", "name": "Niger", "bbox": [[0.15294112100011148, 11.69577301000001], [15.970321899000112, 23.517351176000105]]},
 "BFA": {"iso_a2": "BF", "name": "Burkina Faso", "bbox": [[-5.5225780849998785, 9.391882629000108], [2.3901689040000917, 15.079907532000092]]},
 "TGO": {"iso_a2": "TG", "name": "Togo", "bbox": [[-0.1661091709999596, 6.100490627000056], [1.7823507080001377, 11.134980367000125]]},
 "GHA": {"iso_a2": "GH", "name": "Ghana", "bbox": [[-3.262509317999985, 4.737127997000073], [1.187968384000044, 11.16293731700003]]},
 "GNB": {"iso_a2": "GW", "name": "Guinea-Bissau", "bbox": [[-16.728436776914492, 10.92763906500005], [-13.660711832999937, 12.679433900000063]]},
 "GIB": {"iso_a2": "GI", "name": "Gibraltar", "bbox": [[-5.358386758461411, 36.11050039300005], [-5.338773483242616, 36.14111967199992]]},
 "USA": {"iso_a2": "US", "name": "United States of America", "bbox": [[-179.1435033839999, 18.906117143000074], [179.78093509200005, 71.41250234600005]]},
 "CAN": {"iso_a2": "CA", "name": "Canada", "bbox": [[-141.00556393099993, 41.66908559200006], [-52.61660722599993, 83.11652252800008]]},
 "MEX": {"iso_a2": "MX", "name": "Mexico", "bbox": [[-118.36880422899992, 14.546279476000095], [-86.70059160099993, 32.71283640500009]]},
 "BLZ": {"iso_a2": "BZ", "name": "Belize", "bbox": [[-89.23651220799991, 15.879651999000075], [-87.78307044199994, 18.49075876900004]]},
 "PAN": {"iso_a2": "PA", "name": "Panama", "bbox": [[-83.05324621699995, 7.20571523600006], [-77.16326981599991, 9.62929248000006]]},
 "VEN": {"iso_a2": "VE", "name": "Venezuela", "bbox": [[-73.39114864099992, 0.6493154910000101], [-59.81559484899992, 15.702948309000078]]},
 "PNG": {"iso_a2": "PG", "name": "Papua New Guinea", "bbox": [[140.84921106000002, -11.636325778999947], [155.96753991000003, -1.3463680969999245]]},
 "EGY": {"iso_a2": "EG", "name": "Egypt", "bbox": [[24.688342732000137, 21.9943692020001], [36.899180535000085, 31.656480210000026]]},
 "YEM": {"iso_a2": "YE", "name": "Yemen", "bbox": [[42.54574629000007, 12.111443672000064], [54.54029381600009, 18.99563751300012]]},
 "MRT": {"iso_a2": "MR", "name": "Mauritania", "bbox": [[-17.0811748859999, 14.73439890600011], [-4.821613117999902, 27.285415751000116]]},
 "GNQ": {"iso_a2": "GQ", "name": "Equatorial Guinea", "bbox": [[5.6119897800000444, -1.475681247999944], [11.33634118700013, 3.7724063170000477]]},
 "GMB": {"iso_a2": "GM", "name": "Gambia", "bbox": [[-16.829701300999943, 13.065008856000077], [-13.818712524999881, 13.81998443600007]]},
 "HKG": {"iso_a2": "HK", "name": "Hong Kong", "bbox": [[113.837331576, 22.177069403000075], [114.40129642000011, 22.56394603200009]]},
 "VAT": {"iso_a2": "VA", "name": "Vatican", "bbox": [[12.452714082000085, 41.90275194100009], [12.454035442000077, 41.903914738000125]]},
 "ATA": {"iso_a2": "AQ", "name": "Antarctica", "bbox": [[-179.99999999999991, -89.99999999999994], [180.0, -60.51620859199994]]},
 "AUS": {"iso_a2": "AU", "name": "Australia", "bbox": [[112.91944420700008, -54.75042083099993], [159.1064559250001, -9.240166924999926]]},
 "GRL": {"iso_a2": "GL", "name": "Greenland", "bbox": [[-73.05724036399994, 59.792629299000055], [-11.37682044199994, 83.63410065300008]]},
 "FJI": {"iso_a2": "FJ", "name": "Fiji", "bbox": [[-179.99999999999991, -21.711114190999922], [180.0, -12.475274346999925]]},
 "NZL": {"iso_a2": "NZ", "name": "New Zealand", "bbox": [[-177.9579971999999, -52.60031327099995], [178.84392337300005, -8.543226820999905]]},
 "NCL": {"iso_a2": "NC", "name": "New Caledonia", "bbox": [[163.6157332690001, -22.670668226999908], [171.343765287, -19.623711846999925]]},
 "MDG": {"iso_a2": "MG", "name": "Madagascar", "bbox": [[43.22291100400008, -25.59856536299992], [50.503916863000086, -11.943617445999905]]},
 "PHL": {"iso_a2": "PH", "name": "Philippines", "bbox": [[116.95492597700002, 4.65570709800005], [126.61768639400009, 21.122381903000075]]},
 "LKA": {"iso_a2": "LK", "name": "Sri Lanka", "bbox": [[79.65577233200008, 5.923732815000051], [81.89031009200005, 9.829575914000088]]},
 "CUW": {"iso_a2": "CW", "name": "Cura\u00e7ao", "bbox": [[-69.17174231699994, 12.04132721600007], [-68.73973548099991, 12.391506252000056]]},
 "ABW": {"iso_a2": "AW", "name": "Aruba", "bbox": [[-70.06240800699993, 12.417669989000046], [-69.87682044199994, 12.632147528000075]]},
 "BHS": {"iso_a2": "BS", "name": "The Bahamas", "bbox": [[-79.59434973899994, 20.912398909000046], [-72.7461645169999, 26.92841217700004]]},
 "TCA": {"iso_a2": "TC", "name": "Turks and Caicos Islands", "bbox": [[-72.48131262899994, 21.29010651200008], [-71.12889563699991, 21.95921458500004]]},
 "TWN": {"iso_a2": "TW", "name": "Taiwan", "bbox": [[118.27955162900003, 21.90460846600007], [122.00538170700008, 25.28742096600007]]},
 "JPN": {"iso_a2": "JP", "name": "Japan", "bbox": [[122.93816165500004, 24.212103583000044], [153.98560631600003, 45.520412502000056]]},
 "SPM": {"iso_a2": "PM", "name": "Saint Pierre and Miquelon", "bbox": [[-56.396595831999946, 46.75275299700007], [-56.14476477799991, 47.14126211100006]]},
 "ISL": {"iso_a2": "IS", "name": "Iceland", "bbox": [[-24.539906378999945, 63.39671458500004], [-13.502919074999909, 66.56415436400005]]},
 "PCN": {"iso_a2": "PN", "name": "Pitcairn Islands", "bbox": [[-130.75308183499993, -25.07708098799992], [-124.77806555899991, -23.92441171699994]]},
 "PYF": {"iso_a2": "PF", "name": "French Polynesia", "bbox": [[-154.5369766919999, -27.64120859199994], [-134.94298255099994, -7.950127862999921]]},
 "ATF": {"iso_a2": "TF", "name": "French Southern and Antarctic Lands", "bbox": [[39.72828209700009, -49.72161223799992], [77.58521569100003, -11.550632419999886]]},
 "SYC": {"iso_a2": "SC", "name": "Seychelles", "bbox": [[46.207367384000065, -9.75554778399993], [56.287445509000065, -3.7911109349999492]]},
 "KIR": {"iso_a2": "KI", "name": "Kiribati", "bbox": [[-174.54340572799993, -11.461114190999922], [176.850922071, 4.723089911000045]]},
 "MHL": {"iso_a2": "MH", "name": "Marshall Islands", "bbox": [[165.2822371750001, 4.5737979190000715], [172.0297957690001, 14.610500393000052]]},
 "TTO": {"iso_a2": "TT", "name": "Trinidad and Tobago", "bbox": [[-61.92870032499991, 10.04205963700008], [-60.52208411399994, 11.351060289000088]]},
 "GRD": {"iso_a2": "GD", "name": "Grenada", "bbox": [[-61.79051673099991, 12.002834377000056], [-61.42162024599992, 12.529730536000045]]},
 "VCT": {"iso_a2": "VC", "name": "Saint Vincent and the Grenadines", "bbox": [[-61.459828253999945, 12.585150458000044], [-61.123931443999936, 13.38076406500005]]},
 "BRB": {"iso_a2": "BB", "name": "Barbados", "bbox": [[-59.65420488199993, 13.051174221000053], [-59.42690995999993, 13.344549872000073]]},
 "LCA": {"iso_a2": "LC", "name": "Saint Lucia", "bbox": [[-61.07852128799993, 13.714667059000078], [-60.88296464799993, 14.111883856000077]]},
 "DMA": {"iso_a2": "DM", "name": "Dominica", "bbox": [[-61.48892167899993, 15.20180898600006], [-61.249256964999915, 15.63385651200008]]},
 "UMI": {"iso_a2": "UM", "name": "United States Minor Outlying Islands", "bbox": [[-177.3895971339999, -0.3887671849999492], [166.6523543630001, 28.215318101000037]]},
 "MSR": {"iso_a2": "MS", "name": "Montserrat", "bbox": [[-62.230132615999935, 16.67536041900007], [-62.14053300699993, 16.81932200700004]]},
 "ATG": {"iso_a2": "AG", "name": "Antigua and Barbuda", "bbox": [[-61.89415442599994, 16.98924388200004], [-61.66759192599994, 17.727687893000052]]},
 "KNA": {"iso_a2": "KN", "name": "Saint Kitts and Nevis", "bbox": [[-62.86107337099992, 17.100531317000048], [-62.536773240999935, 17.415838934000078]]},
 "VIR": {"iso_a2": "VI", "name": "United States Virgin Islands", "bbox": [[-65.04149344199993, 17.682766018000052], [-64.55939693899995, 18.386598614000036]]},
 "BLM": {"iso_a2": "BL", "name": "Saint Barthelemy", "bbox": [[-62.86733964799993, 17.881984768000052], [-62.79165605399993, 17.92914459800005]]},
 "PRI": {"iso_a2": "PR", "name": "Puerto Rico", "bbox": [[-67.93781490799995, 17.92291901200008], [-65.24461829299992, 18.522772528000075]]},
 "AIA": {"iso_a2": "AI", "name": "Anguilla", "bbox": [[-63.42882239499994, 18.169094143000052], [-62.972645636999914, 18.601263739000046]]},
 "VGB": {"iso_a2": "VG", "name": "British Virgin Islands", "bbox": [[-64.77399654899995, 18.33466217700004], [-64.27074133999992, 18.746242580000057]]},
 "JAM": {"iso_a2": "JM", "name": "Jamaica", "bbox": [[-78.37466386599993, 17.703192450000074], [-76.18797766799992, 18.525091864000046]]},
 "CYM": {"iso_a2": "KY", "name": "Cayman Islands", "bbox": [[-81.41654202099994, 19.263863615000048], [-79.72664269199993, 19.75763083700008]]},
 "BMU": {"iso_a2": "BM", "name": "Bermuda", "bbox": [[-64.88599398897043, 32.248077381595785], [-64.64763070564054, 32.388657945000034]]},
 "HMD": {"iso_a2": "HM", "name": "Heard Island and McDonald Islands", "bbox": [[73.23601321700005, -53.19255950299993], [73.81218509200005, -52.961602471999925]]},
 "SHN": {"iso_a2": "SH", "name": "Saint Helena", "bbox": [[-14.417713995999918, -40.39788176899992], [-5.650380011999914, -7.877862237999921]]},
 "MUS": {"iso_a2": "MU", "name": "Mauritius", "bbox": [[56.524180535000085, -20.51734791499996], [63.49390709700003, -10.32390715899993]]},
 "COM": {"iso_a2": "KM", "name": "Comoros", "bbox": [[43.213226759000065, -12.380303643999923], [44.52906334700003, -11.361260674999926]]},
 "STP": {"iso_a2": "ST", "name": "S\u00e3o Tom\u00e9 and Principe", "bbox": [[6.4616805350000845, 0.024115302000041083], [7.462738477000073, 1.6997744810000768]]},
 "CPV": {"iso_a2": "CV", "name": "Cabo Verde", "bbox": [[-25.36042232999995, 14.803941148000092], [-22.666574673999946, 17.196600653000075]]},
 "MLT": {"iso_a2": "MT", "name": "Malta", "bbox": [[14.183604363000086, 35.801214911000045], [14.567149285000085, 36.07558828300006]]},
 "JEY": {"iso_a2": "JE", "name": "Jersey", "bbox": [[-2.2420141269999476, 49.17133209800005], [-2.0082901679999168, 49.26703522300005]]},
 "GGY": {"iso_a2": "GG", "name": "Guernsey", "bbox": [[-2.673451300999943, 49.41156647300005], [-2.1703181629999335, 49.73139069200005]]},
 "IMN": {"iso_a2": "IM", "name": "Isle of Man", "bbox": [[-4.790150519999941, 54.05695221600007], [-4.311919725999928, 54.41901276200008]]},
 "ALA": {"iso_a2": "AX", "name": "Aland", "bbox": [[19.513194207000083, 59.90448639500005], [21.09669030000009, 60.48078034100007]]},
 "FRO": {"iso_a2": "FO", "name": "Faroe Islands", "bbox": [[-7.644154425999943, 61.39411041900007], [-6.2757869129999335, 62.39891185100004]]},
 "IOT": {"iso_a2": "IO", "name": "British Indian Ocean Territory", "bbox": [[71.26099694100003, -7.432224216999941], [72.49463951900003, -5.2269833309999285]]},
 "SGP": {"iso_a2": "SG", "name": "Singapore", "bbox": [[103.64039147200003, 1.26430898600006], [104.00342858200003, 1.4486351580000587]]},
 "NFK": {"iso_a2": "NF", "name": "Norfolk Island", "bbox": [[167.9121199880001, -29.080010674999926], [167.99634850400003, -28.997491143999923]]},
 "COK": {"iso_a2": "CK", "name": "Cook Islands", "bbox": [[-165.82453365799992, -21.938897393999923], [-157.31281490799992, -8.946709893999923]]},
 "TON": {"iso_a2": "TO", "name": "Tonga", "bbox": [[-176.21930904899992, -22.33879973799992], [-173.91425533799992, -15.55950286299992]]},
 "WLF": {"iso_a2": "WF", "name": "Wallis and Futuna", "bbox": [[-178.18573971299992, -14.319431247999944], [-176.1255997389999, -13.208916924999926]]},
 "WSM": {"iso_a2": "WS", "name": "Samoa", "bbox": [[-172.7825821609999, -14.05282968499995], [-171.43769283799992, -13.462823174999926]]},
 "SLB": {"iso_a2": "SB", "name": "Solomon Islands", "bbox": [[155.50798587300005, -12.290622653999947], [168.82585696700005, -6.599867445999905]]},
 "TUV": {"iso_a2": "TV", "name": "Tuvalu", "bbox": [[176.12525475400003, -9.420668226999908], [179.9067488940001, -5.677504164999959]]},
 "MDV": {"iso_a2": "MV", "name": "Maldives", "bbox": [[72.68482506600003, -0.6885718729999439], [73.75318444100003, 7.107245184000078]]},
 "NRU": {"iso_a2": "NR", "name": "Nauru", "bbox": [[166.90699303500003, -0.5518531229999439], [166.95826256600003, -0.4904110659999219]]},
 "FSM": {"iso_a2": "FM", "name": "Federated States of Micronesia", "bbox": [[138.063812696, 0.918158270000049], [163.04656009200005, 9.775580145000049]]},
 "SGS": {"iso_a2": "GS", "name": "South Georgia and the Islands", "bbox": [[-38.08702551999994, -59.47275155999995], [-26.23932857999995, -53.9724260399999]]},
 "FLK": {"iso_a2": "FK", "name": "Falkland Islands", "bbox": [[-61.31818600199995, -52.406522725999935], [-57.73428300699993, -51.02776458099993]]},
 "VUT": {"iso_a2": "VU", "name": "Vanuatu", "bbox": [[166.5205184250001, -20.253106377999927], [169.8989363940001, -13.064873955999929]]},
 "NIU": {"iso_a2": "NU", "name": "Niue", "bbox": [[-169.95042883999992, -19.142754815999922], [-169.78290768099993, -18.964043877999927]]},
 "ASM": {"iso_a2": "AS", "name": "American Samoa", "bbox": [[-171.0865372389999, -14.53289153399993], [-168.16047115799992, -11.051364841999941]]},
 "PLW": {"iso_a2": "PW", "name": "Palau", "bbox": [[131.13111412900003, 2.949042059000078], [134.7273429907992, 8.09661782863137]]},
 "GUM": {"iso_a2": "GU", "name": "Guam", "bbox": [[144.62419681100005, 13.241034247000073], [144.9521590500001, 13.654120184000078]]},
 "MNP": {"iso_a2": "MP", "name": "Northern Mariana Islands", "bbox": [[144.90211022200003, 14.110663153000075], [145.86890709700003, 20.55540599200009]]},
 "BHR": {"iso_a2": "BH", "name": "Bahrain", "bbox": [[50.44890384200005, 25.789536851000037], [50.64565981318161, 26.242580471000053]]},
 "MAC": {"iso_a2": "MO", "name": "Macao S.A.R", "bbox": [[113.51986738400001, 22.10537344000005], [113.58749433700007, 22.220770575000074]]},
 "FRA": {"iso_a2": "FR", "name": "France", "bbox": [[-5.668945,41.972830],[8.657227,51.181383]]},
 "NOR": {"iso_a2": "NO", "name": "Norway", "bbox": [[2.963181,56.602646],[29.857712,71.379060]]}
}


export function getNameFromCode(countryCode)
{
    let tempNameReturn = '';
    if(countryCode) 
    {
        countryCode = countryCode.toUpperCase();
        for (const [key, value] of Object.entries(countryCentroids)) 
        {
            if (value['iso_a2'] === countryCode) 
            {
                tempNameReturn = key;
                return tempNameReturn;
            }
        }
    }
    return tempNameReturn;
}

export function getCountryNameFromCode(countryCode)
{
    let tempNameReturn = '';
    if(countryCode) 
    {
        countryCode = countryCode.toUpperCase();
        Object.values(countryCentroids).some(value => 
        {
            if (value['iso_a2'] === countryCode) {
                tempNameReturn = value['name'];
                return true;
            }
        });
    }
    return tempNameReturn;
}