/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import promiseMiddleware from 'redux-promise-middleware';
import { createLogger } from 'redux-logger';
//import logger from 'redux-logger';
import rootReducer from '../reducers';
//import templateMerger from '../middleware/template_merger';
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import translationsObject from '../locale/index'

export default function customConfigureStore(initialState) {
  const ourMiddleware = [promiseMiddleware, ...getDefaultMiddleware({
    immutableCheck: { ignoredPaths: ['acled', 'gtd'] },
    serializableCheck: { ignoredPaths: ['acled', 'gtd'] }
  })];
  // disable the redux-logger in a production environment
  if (process.env.NODE_ENV === 'development1') {
    ourMiddleware.push(createLogger());
  }

  const store = configureStore({
    reducer: rootReducer,
    middleware: ourMiddleware,
    initialState,
  })
  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translationsObject));
  store.dispatch(setLocale('en'));

  return store;
}
