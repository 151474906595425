/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */

import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from "react-redux"
import SipriInfo from './SipriInfo';
import InfoInner from './InfoInner';
import GtdInfo from './GtdInfo';
import conversionFun from '../../../utils/conversionFun';
import coverSubEventType from '../../../utils/SubEventType'
import subEventTypeFun from '../../../utils/subEventTypeFun'
import TocUtils from '../../../utils/toc'
import './TOC.css'

const { AcledUtil } = TocUtils

const { lineChartDataFun, getYearFun } = AcledUtil

export default function TOC(props) {
  const [showData, setShowData] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [lineYear, setLineYear] = useState([])
  const [InfoData, setInfoData] = useState([])
  const tocLayer = useSelector(state => state.toc.tocLayer)
  const acled_selected = useSelector(state => state.toc.tocLayer) === 'ACLED'
  const sipri_selected = useSelector(state => state.toc.tocLayer) === 'SIPRI'
  const gtd_selected = useSelector(state => state.toc.tocLayer) === 'GTD'
  const country = useSelector(state => state.map.country)
  const acledSwitch = useSelector(state => state.acled.acledSwitch)
  const acledClass = useSelector(state => state.acled.acledClass)
  const acledStartYear = useSelector(state => state.acled.acledStartYear)
  const acledEndYear = useSelector(state => state.acled.acledEndYear)
  const acledCountryEvents = useSelector(state => state.acled.acledCountryEvents)
  const acledCountryArr = useSelector(state => state.acled.acledCountryArr)
  const acledEventType = useSelector(state => state.acled.acledEventType)
  const infoEventTypeNum = useSelector(state => state.acled.infoEventTypeNum)
  const chooseId = useSelector(state => state.acled.chooseId)

  const gtdStartYear = useSelector(state => state.gtd.gtdStartYear)
  const gtdEndYear = useSelector(state => state.gtd.gtdEndYear)
  const gtdCountryEvents = useSelector(state => state.gtd.gtdCountryEvents)
  const gtdEventType = useSelector(state => state.gtd.gtdEventType)
  const API_BASE = process.env.REACT_APP_API_URL
  
  useEffect(() => {
    if(tocLayer !== 'ACLED'){
      return
    }
    let str = ''
    let whereStr = ''
    let newAcledEventType = ''
    let newAcledSubEventType = ''
    if (acledEventType !== 'Violence') {
      newAcledEventType = `E${chooseId}_${acledEventType.replace(/\s*/g, "")}`
    } else {
      newAcledEventType = `E${chooseId}_${acledEventType}againstcivilians`
    }

    if (acledClass.types && acledClass.types.length > 0) {
      acledClass.types.filter(it=>it.checked).forEach(item => {
        newAcledSubEventType += `,sum(${coverSubEventType(item.id)}) as ${coverSubEventType(item.id)}`
      })
    }
    const { startYear, endYear, startMonth, endMonth } = conversionFun(acledStartYear, acledEndYear)
    if (acledCountryArr.length > 0) {
      const newInfoEventTypeNum = acledCountryArr.map((item, ind) => {
        if (ind == 0) {
          str = str + `SOVEREIGNT='${item}'`
        } else {
          str = str + ` or SOVEREIGNT='${item}'`
        }
        return infoEventTypeNum.features?.find(v => {
          if (v && v.properties && v.properties.SOVEREIGNT == item) {
            return v
          }
          return null;
        })
      })
      setInfoData(newInfoEventTypeNum)

      if (startYear !== endYear) {
        whereStr = `((YEAR=${startYear} and MONTHNR>=${startMonth}) or (YEAR>${2018} and YEAR<${endYear}) or (YEAR=${endYear} and MONTHNR<=${endMonth}))`
      } else {
        whereStr = `((YEAR=${startYear} and MONTHNR>=${startMonth}) and (YEAR=${endYear} and MONTHNR<=${endMonth}))`
      }
      str = `(${str}) and ${whereStr}`
      const outFields = `SOVEREIGNT,YEAR,sum(${newAcledEventType}) as event_type_total ${newAcledSubEventType}`
      const groupBy = 'SOVEREIGNT,YEAR'
      fetch(`${API_BASE}/arcgis/queryACLEDInfoAPI/${str}/${outFields}/${groupBy}`)
        .then(result => result.json())
        .then(data => {
          setChartData(lineChartDataFun(data, acledCountryArr, acledClass.types))
          setLineYear(getYearFun(startYear, endYear))
        });
    }else{
      setInfoData([])
      setChartData([])
      setLineYear([])
    }
  }, [acledCountryArr, tocLayer, chooseId, acledStartYear, acledEndYear, infoEventTypeNum, acledClass])

  useEffect(() => {
    if (gtd_selected) {
      if (gtdCountryEvents && gtdCountryEvents.features) {
        // const { startYear, endYear, startMonth, endMonth } = conversionFun(gtdStartYear, gtdEndYear)
      }
    }
  }, [gtdCountryEvents, gtdEventType, gtdStartYear, gtdEndYear])

  const showHideData = () => {
    setShowData(!showData);
  }
  const renderCaret = () => {
    if (showData) {
      return (
        <FontAwesomeIcon className="caret" icon={faAngleDown} />
      );
    } else {
      return (
        <FontAwesomeIcon className="caret" icon={faAngleUp} />
      );
    }
  }
  const renderCollapseBar = () => {
    return (
      <Row>
        <Col xs={9}>
          <div className="dataBoxTitle dataLayerTitle">
            Violence and Arms
          </div>
        </Col>
        <Col xs={3}>
          <div className="dataBoxHide dataLayerTitle" onClick={showHideData}>
            {renderCaret()}
          </div>
        </Col>
      </Row>
    )
  }
  const showContent = () => {
    switch (true) {
      case acled_selected:
        return <InfoInner
          country={country}
          StartYear={acledStartYear}
          EndYear={acledEndYear}
          Class={acledClass}
          Switch={acledSwitch}
          chartData={chartData}
          lineYear={lineYear}
          InfoData={InfoData}
          subEventTypeFun={subEventTypeFun}
          CountryEvents={acledCountryEvents}
        />
      case sipri_selected:
        return <SipriInfo />
      case gtd_selected:
        return <GtdInfo />
      default:
        break;
    }
  }

  return (
    <div className="dataBoxContainer">
      <div className="dataBoxTitleContainer">
        {renderCollapseBar()}
      </div>
      <Collapse in={showData}>
        <div className="dataBoxData">
          {showContent()}
        </div>
      </Collapse>
    </div>
  );
}