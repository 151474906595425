/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Collapse } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { setTocLayer } from '../../../../slices/toc/tocSlice'
import useCountryCode from '../../../../hooks/useCountryCode'
import { setIsLoading } from "../../../../slices/mapSlice"
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from "react-redux-i18n"

import TocUtils from '../../../../utils/toc'
import Radio from '../../../../components/Radio/Radio'
import Acled from './Acled'
import Gtd from './Gtd'
import Sipri from './Sipri'

import "./TOCLayers.css"

const { SipriUtil, Common } = TocUtils

export default function TOCLayers(props) {
	const [showData, setShowData] = useState(true)
	const dispatch = useDispatch()
	const tocLayer = useSelector(state => state.toc.tocLayer)
	const [countryCode, setCountryCode] = useCountryCode()
	const layterType = Common.layerTypes
	
	const showHideData = () => {
		setShowData(!showData)
	}

	const renderCaret = () => {
		if (showData) {
			return (
				<FontAwesomeIcon className="caret" icon={faAngleDown} />
			)
		} else {
			return (
				<FontAwesomeIcon className="caret" icon={faAngleUp} />
			)
		}
	}
	const renderCollapseBar = () => {
		return (
			<Row>
				<Col xs={9}>
					<div className="dataBoxTitle dataLayerTitle">
						<Translate value="dataLayers" />
					</div>
				</Col>
				<Col xs={3}>
					<div className="dataBoxHide dataLayerTitle" onClick={showHideData}>
						{renderCaret()}
					</div>
				</Col>
			</Row>
		)
	}

	const showContent = useCallback((val) => {
		switch (val) {
			case 'ACLED':
				return <Acled />
			case 'SIPRI':
				return <Sipri countryCode={countryCode} />
			case 'GTD':
				return <Gtd />
			default:
				return null
		}
	}, [countryCode])

	const onChangeLayer = useCallback((name) => {
		dispatch(setTocLayer(name))
		dispatch(setIsLoading({isLoading :true}))
	}, [dispatch, setTocLayer, setIsLoading])

	const renderData = () => {
		return (
			<div className="mapLayerPanel TOCLayerCheckboxPanel">
				<div>
					{
						layterType.map((item, index) => {
							return (
								<React.Fragment key={index}>
									<div className="mapLayerTitle">
										<div className="mapLayerTitleLeft">
											<div className="mapLayerTitleLeftRadio">
												<Radio name='maplayerTitle' checked={item.name == tocLayer} onChange={()=>onChangeLayer(item.name)} scale="1" />
											</div>
											<span className="mapLayerTitleLeftContent">{item.title}</span>
										</div>
										<div className="mapLayerTitleRight" onClick={() =>onChangeLayer(item.name)}>
											{
												item.name == tocLayer ? <FontAwesomeIcon className="caret" icon={faAngleUp} /> :
													<FontAwesomeIcon className="caret" icon={faAngleDown} />
											}
										</div>
									</div>
									{
										item.name == tocLayer ? showContent(tocLayer) : null
									}
								</React.Fragment>
							)
						})
					}
				</div>
			</div>
		)
	}

	return (
		<>
			<div className="dataBoxContainer dataLayerContiner">
				<div className="dataBoxTitleContainer layerContainer">
					{renderCollapseBar()}
					<Collapse in={showData}>
						{renderData()}
					</Collapse>
				</div>
			</div>
		</>
	)
}

