/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import * as types from './types';

// ------------------------- ERROR MESSAGES -------------------------------- //

// sets the currently visible error message
export default function setErrorMessage(errorMessage) {
  return {
    type: types.SET_ERROR_MESSAGE,
    errorMessage
  };
}
