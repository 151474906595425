import { countryCentroids } from './CountryLookups'
import coverSubEventType from './SubEventType'

const TocUtils = {
  AcledUtil: {
    filterColor: res => {
      return TocUtils.Common.filterColor(res, 'event_type_total')
    },
    lineChartDataFun: (data, countryArr, classtypes) => {
      if (!data || !data.features || data.features.length === 0 || !countryArr || countryArr.length === 0
          || !classtypes || classtypes.length === 0) {
        return;
      }
      
      return countryArr.map(item => {
        let totalNum = []
        data.features.forEach(v => {
          if (v.attributes.SOVEREIGNT == item) {
            let sum = 0
            classtypes.filter(it=>it.checked).forEach(it=>{
              sum += v.attributes[`${coverSubEventType(it.id)}`]
            })

            totalNum.push(sum)
          }
        })
        return {
          name: item,
          type: 'line',
          data: totalNum
        }
      })
    },
    getYearFun: (start, end) => {
      const arr = [];
      for (let i = start * 1; i <= end * 1;) {
        arr.push(parseInt(i))
        i++
      }
      return arr
    }
  },
  SipriUtil: {
    colorPalette: ['#E3DAF4', '#BBADD7', '#9D89C4', '#755EA2', '#493179', '#2C1952'],
    filterColor: res => {
      return TocUtils.Common.filterColor(res, 'AllExp_Total')
    },
    processFeatures: (ds, countryCodeMap, field='TotalImport') => {
      if(!ds || Object.keys(ds).length === 0 || !ds.features || !countryCodeMap || countryCodeMap.size === 0){
        console.error('Cant process the import/export events, parameter error')
        return []
      }
      return ds.features.map(fea => {
        let xfrom = Object.entries(fea.properties)
                    .filter(it=>it[1] > 0 && !!countryCodeMap.get(it[0]))
                    .reduce((pre, cur) => [...pre, { country: countryCodeMap.get(cur[0]), val: cur[1] }], [])
        if(xfrom.reduce((pre, cur)=>pre + parseInt(cur.val) || 0, 0) !== fea.properties[field] ){
          console.error(`Mismatch found in import events, source country is ${ fea.properties.SOVEREIGNT } `)
        }
        return {
          type: "Feature",
          SOVEREIGNT: fea.properties.SOVEREIGNT,
          YEAR: fea.properties.YEAR,
          total: fea.properties.TotalImport,
          xfrom
        }
      })
    },
    getCountryMap: features => {
      if(!features || features.length === 0 ){
        return
      }
      const map = new Map()
      features.forEach(fea => {
        if(!map.get(fea.SOVEREIGNT)){
          map.set(fea.SOVEREIGNT, {
            byYear: [],
            properties: {
              SOVEREIGNT: fea.SOVEREIGNT,
              xports: []
            }
          })
        }
        //add yearly data
        let yearly = map.get(fea.SOVEREIGNT).byYear
        yearly.push(fea)
        
        //add property data
        let source = map.get(fea.SOVEREIGNT).properties.xports
        fea.xfrom && fea.xfrom.length > 0 && fea.xfrom.forEach(cur => {
          let findit = source.find(it=>it.country === cur.country)
          if(!findit){
            source.push(cur)
          }else{
            findit.val += cur.val
          }
        })
      })
      return map
    },
    getCountryFeatures: map =>{
      if(!map || map.size === 0){
        return []
      }
      return Array.from(map.entries()).map(item=>{
        return {
          type: 'Feature',
          SOVEREIGNT: item[0],
          properties:{
            ...item[1].properties,
            sum: item[1].properties.xports.reduce((pre, cur)=>pre + cur.val, 0) 
          }
        }
      })
    },
    getCountryByYear: (country, features) =>{
      if(!country || !features || features.length === 0){
        return
      }
      
    },
    getCountryLegends: countryFeatures => {
      if (!countryFeatures || countryFeatures.length === 0) {
        return []
      }
      let max = 0
      countryFeatures.forEach(item => {
        if (item.properties.sum > max) {
          max = item.properties.sum
        }
      })
      return TocUtils.Common.getLegends(TocUtils.SipriUtil.colorPalette, max)
    },
    handleSipri: (newRes, startYear, endYear) => { //highly depends on res structure
      const couCentroids = TocUtils.Common.countryMap()
      const resArr = Object.entries(newRes)
      const newResult = resArr.filter(item => couCentroids[item[0].split('_')[0]]).map(item => {
        return {
          country: couCentroids[item[0].split('_')[0]],
          year: item[0].split('_')[1],
          numb: item[1]
        }
      })
      const newArr2 = newResult.filter(item => {
        if (Number(item.year) >= startYear && Number(item.year) <= endYear) {
          return item
        }
      })
      const countryArr = Object.values(couCentroids)
      const newCountryArr = countryArr.map(item => {
        let total = 0
        newArr2.forEach(v => {
          if (v.country == item) {
            total += v.numb * 1;
          }
        })
        return {
          country: item,
          total: total
        }
      })

      const showCountry = newCountryArr.filter(item => {
        return item.total != 0
      })
      const sipriLineData = showCountry.map(item => {
        let data = []
        newArr2.forEach(v => {
          if (v.country == item.country) {
            data.push(v.numb)
          }
        })
        return {
          name: item.country,
          type: 'line',
          data: data
        }
      })
      return {
        showCountry,
        sipriLineData
      }
    }
  },
  GtdUtil: {
    colorPalette: ['#FFFFF9', '#85CC6F', '#6AB155', '#4F963C', '#337B24', '#136207'],
    getEvents: () => {
      return [
        {
          name: 'attacks',
          title: 'Attacks',
          children: [
            { name: 'A1_ArmedAssault', type: 'ArmedAssault', title: 'Armed Assault', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACMAAABICAYAAAB86kzAAAAACXBIWXMAAC4jAAAuIwF4pT92AAABtUlEQVRoge2Z0XHCMAyGf7MAjJBuwAgZgRHoBGWEjsAIYQNGSDfICGxQb6CewOFMGoEdh9gP+u/0llhfJJ0tOYaIECNjzAZADWDrbOO9bgF0zloislGLM0yIOYAzvxJh/Hwd7OPlA7evbyMhhtaGQL0C+U6EGNoxGsbVQTMzSG8cpU0MTPcmkN66MaAxkHdFZGjNUxgAuwUgfDuMwrg6sQvDWD9dK2/LOQBYR21S6WJ/x4dNL1NU/kWnj8wuQ1R6rZ3/B5icuvl3acqVonuqmMO4evnNHBnWx8odhCWoWhUCcpXCSGKYSyEs9toDG2Nsxk3vKiIyfZranCAAfuDVzDkvi/NfwEFJDwelm29yRed0n68KaK4qqe08LAwz3nZmaMjPIdMBp6uMUcUDSh1po0BCxtvjzCDx4+0AqJ5hwrwkD/4DqP2EKxFO9T7Ux9TLIm6gKwBfI5dFJ++yKK4jCKUWojUs8jZlPe30JCmMJIWRpDCSFEaSwkhSGEkKI0lhJCmMJIWRpDCSFEaSwkhSGEkKIykVpikGhogY5rMIGMwMNEvNzAUUfdv5dDFj+A/JtH+eAP4Aa+x+hnBm0sYAAAAASUVORK5CYII=' },
            { name: 'A2_Assassination', type: 'Assassination', title: 'Assassination', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACMAAABICAYAAAB86kzAAAAACXBIWXMAAC4jAAAuIwF4pT92AAACM0lEQVRoge2awW3bMBSGf2uBuLwT9YXneoO6E8TdwNnA3SAjpBPUmaDOBFU3SM68xOCdsCdg8dRHQ3H1EkuhJaHgB/BgWKI/PT5Sj4QnIQR0wWuzADDjFtkDeKSmnN237baVjNdmBWAJ4PqMy58AbABslbPPyWS8NmsA1D6e02kD93T/W9F6VcZrM+en+9RRos4BwEo5u5UuKF4RoSEpE4kQVwB+em020gWNkWGRH4kkmrhXzq7elOFZ8uuCIpHvytm1KOO1mQKgzL/qQYb4Ws+h05zZoD+R6vc4AC9leHjOWT9SQg9+G/s7DpPXhmbO555lIh9oDSpYZDagCFHNrDhMywFFMDaZamGtcsZrs+95FjXxJUZmaBFiWnDyjoF5cVIcDUqhnC1HIzMCh0gZZXbDu2AfZYYeqoNy9nEsMlUZUdQ+HEYhw1W7WChfmF0ssOqzaT1QdI71zFGGo3Mr3nIZfitnj7uFF+uMcvaOLuhJ5BBLh0YZhsqJpx5klqfb3n9keLgWuJwQReSm6TUkbm+5at8mLkdJZEELXNOX4ruJIqScpQh9SzTLaPM/k0TQ4hRiylN/3aEQewBwd0510PqwyGuz5CSfC4cCOz4wKtuczVSQTNfG61KotfI9/Y2pnskyIllGIstIZBmJLCORZSSyjESWkcgyEllGIstIZBmJLCORZSSyjMR/JSOez3XhXTIhBDoNvRmFDP4KbVIJJcmZVEKd/xrX2NlkMg8hdMsjAH8Avikfje+sxD4AAAAASUVORK5CYII=' },
            { name: 'A3_BombingExplosion', type: 'BombingExplosion', title: 'Bombing Explosion', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACMAAABICAYAAAB86kzAAAAACXBIWXMAAC4jAAAuIwF4pT92AAACLklEQVRoge2aTU7CQBSAH7owqQvwBHTlUvEEwg24geUGbF00IXbh1iNwBDyB9QRSl24cbkAXNHFhagbekGnpa2E6ZYiZL5kNKZ0vb/7eG2ilaQqqJIHbAYCe9PW547Ol6vsOkkkC1wWAIbb7kkffAWDGm+MzplUmCdw+AEwqBMrEJo7PwloyOAwvAPCgIJHnFQC8smEkZZLA7WGouxpEBDEA9B2fzfeWQREe1rZGkUqhsyOLAL43xH4yZCKDc4Qb6xwaCh4hV55D+chMNc+RMtrY35ZtZHD5vh1JRGYglr0cmYkBkUy/68jgzvptSIZzx1eXiMzYoAjHA2mY+mZdNv0LmVuzLpv+W6unLt98PgzLcAY8Mp0TEFmzcxyYxMpQiE1PPRHWx5WITGRYZMFPbyFTmZ82TOagnBmWWfcvpxDsiLmMDB8iflCfYAohSAI3VKyNVIkcn21z4fw+42FuegxikToUymApmnmgQcb5cmVnB3Z8xmf2qGGRkeOzaf7DwuMAHxw1MGQxJQJ71No9LCd0JF8R1tqFpW2ljCTl4RJU2YcWeAtRGI2DZSSpoXQ/U1b+xtL9zN67u/LNFS9vHr9uZquf3+0QXl6cR8/Xn8NDLogycBnVhgdcKrWwzvtspkdhZSisDIWVobAyFFaGwspQWBkKK0NhZSisDIWVobAyFFaGwspQ/CsZ5b80FVFXxtP581AtmTRNl/ibtBah2nNGp5CWCSwJkXe8lQDAH09gJuA0RmIvAAAAAElFTkSuQmCC'},
            { name: 'A4_FacilityInfrastructureAttack', type: 'FacilityInfrastructureAttack', title: 'Facility Infrastructure Attack', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACMAAABICAYAAAB86kzAAAAACXBIWXMAAC4jAAAuIwF4pT92AAACKklEQVRoge2Zz22DMBSHf6lU5Zh2gnSDRl0gyQRFKlckInUBNugEpSOgcuWQDUomaLJBswE5Rj1QGT0QAR4JxkBU+ZO4RMH+8POfZ3sUxzFkcU1/BuAu93rkBNZWtrxGMlS5DWAB4LHmrzsAIQCvidxFMq7pi8rfAMwvLTjHRrzrBFbYSsY1fRECD8CzhESVlO0E1k9jGQqJ+JqJApGUAwCDa6UbRsTuQARU3heVX6LUMh21SBXLYgudyFAfETHtWgQUslm+DxXD5PXQIikTqi8jk6Hhq2LUNGHumr5RkqF5ZAg+TmSo08pMaCqYUv1Zy1QOtR6x8zKLgWWSfpPK1C16fTAVdYzeXz5FvL4HlhEsbwr5yKBUrk1DcXUy0RV4JCQLpWv68omwOu7TMO0GFtk7gRWlMmfz045J6k9lvKEs8vVnyZVr+tuBZmIRogdcSQqR1VtMO8OeU4mNE1jZIl2c9GzKTfvgUExdTmQoOc7SwI4pbehKywFtH1Ydi6ycwFoXf6zbUYpYrjvYUdpVIqhbKKmFZrRHVsGG9kmVImhwCmHQEJSZh/Z0CnF2YpU9nzHSVLFGID2fuXipaXVyZZmv2+PvMWut8e1494T5Qix6UgUKGdmHvj7OPWGb8nTayaFlOLQMh5bh0DIcWoZDy3BoGQ4tw6FlOLQMh5bh0DIcWoZDy3D8Kxmld1VtZWyV10OtZOI4juhOXIlQ6z6jUkhJB84JyV+iAfgDBn4botKYIYcAAAAASUVORK5CYII=' },
            { name: 'A5_Hijacking', type: 'Hijacking', title: 'Hijacking', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACMAAABHCAYAAACNvP4VAAAACXBIWXMAAC4jAAAuIwF4pT92AAACGklEQVRoge2aO1LDMBCG/zBDTSiogQrshtwAcgJyA0JHyQzuCb0L6CjhBjmCOQGhsYeKuKaBmsLMwhrkx4ISG4tivxk1yUj6ZvVYSUkvyzLYEnh+H8AIwAGAAYC9mqopgBmAKZUwiV9s27eSCTx/C8AEwJG1+Te3VDdM4nljmcDzSeJ8CYkyVywlRkqU4WhMhaFYlgcA4zCJZ9YygefTfIgArLUokvNKc65OaKVjEXC7EfdToBAZXi0k0ubQSNCqG5hzqByZSctz5Cc2AVya339FhsN235GIyTBMYhqNQmQmDkQK/X5EhpfxkyMZYps2xTwyI4cixCmMYXItQ7nuS2bfrcvnCu6d7XquVlGZIUWm/w9EPqikA5eojES+6dmfPf+O9TwyqWORlLJ3LhM5likkyqlbl8/+zSPEnM8YXUNDRIn6Hx4hcgLPn3V40oMZFdTsM+MORVA+LRRk+Ppw3JHIcfm6UtmBwyS+6UDolvspUJsO/liIIlI7HcTcxELDFnfnlG8ClYj8KsNCET99XPC1dBleuf4gv5JIWL/PGG8zVA4tqtwBuFnkjWahx6KS3EG60b9e7WEn/+wtw+Pm88vJbxEQIZllCye4zChRk/b0pCehMhIqI6EyEiojoTISKiOhMhIqI6EyEiojoTISKiOhMhIqI6EyEk1lThu89VVoJJNl2Yx/k25FqPEwtSnUypwxhKz/eVYBwDsDjxvaS4aSVwAAAABJRU5ErkJggg==' },
            { name: 'A6_HostageTakingBarricadeIncide', type: 'HostageTakingBarricadeIncide', title: 'Hostage Taking Barricade Incide', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACMAAABICAYAAAB86kzAAAAACXBIWXMAAC4jAAAuIwF4pT92AAACKklEQVRoge2ZP1LCQBSHf0ELO7GwNXamA0+gN4AxBxBPYG4gR4Ab4AEyAycw3kC6lG5tIXQW6jovs8kEyYuJ5A/F+2YyDJDZ/Xj7svt2sbTWKIPtOl0A1wD65vU3AYBXAHPlh6sybReWsV2HOvYADEq0/wxgpvxwVomMkRgDuCoh8RsFYKT8MMi7qfOHCEk87SgSNUXt2K4zybspMzImL2Ylh6QoS8q1rHziIhPUJEL0qH3zg/NlbNehiPRqEkkLbSX1hoztOkMAtzWLxAxs1/HSHyQ5Y8JG88NxQzLEmuYr5YfU70ZkvIZFYPobx2+iyLQUlTQn9HTFkRm2KEKMkBqmYYsiSf+xTNaC1yTRDG+d3VxQvry3LENcdkwpsA90cxfKphEZDpJ52ROXVTwDr1qe9KD80IqHKbccbACqlZOcmbcsE/Wfllm3KDNLZEw9mlss18hjXA+nH+1JC9FZp+uZRMbYjRqWGcdVHrK2KqYgb6IOXig/3ChdtmZg5YcUnWXNIsusUeCWA6pvFjWKZG7icvfaZnv7UKHIVPmhx31ZZOPfN09a7Rv/uo9EFuacppojkQyp5LDo9Pvo/lAfJHvmT+tr9db5mJpKICh7WASS+e9lFliduoJd2pNKj0NkOESGQ2Q4RIZDZDhEhkNkOESGQ2Q4RIZDZDhEhkNkOESGQ2Q4dpUpdDzWiIzWmmTu9kIGFQtVkjNVCZU+7cxtzLLOtdbJHxOlAPADDb0WCL4VtjAAAAAASUVORK5CYII=' },
            { name: 'A7_HostageTakingKidnapping', type: 'HostageTakingKidnapping', title: 'Hostage Taking Kidnapping', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACMAAABICAYAAAB86kzAAAAACXBIWXMAAC4jAAAuIwF4pT92AAACLUlEQVRoge2ZPU7DQBBGv00BdCD5AqGhhRvgko7cADgBkXyBHABL4QbhBtDRYU4AtFTQY2E6oGDRRGPLBI9jxz+bYp7kIo69+zy7Wc9sjLUWdYhNuAPAB3DAx07u9gTAIx+RZ4OkTtuVZWITksAYwHGN9m8ATD0bRK3IxCakp58COKwhscg9gMkyqcESkQmAh4Yi4PvvYhNOyy4qjAzPC7rxpKFEERSlUdF8kiITdSQCjlLED1wuE5twBmC/I5GUfY68LBObcNRhRBY5iU04zp/L5gyH7QXAdk8yxAeAYTp/8pEZ9ywC7i8brnlkHEUlJYtOGpmRIxFwv9Q/8jIu+SPjO5aZ92/ecEHz5d2xDLE74DRgHRiWvij7RmUkBrzYrQNJugInDhe9OZ4NTDpMlXLUDqGEK5sz145l5v3nZT4cyswyGc4nXEXnSspn+o4O9TdJP2QybDcRb+sGqqWypeVfqcIJeR958I1ngz+pS9EKTMP11LEItX+6ePKfDA+Xn/72OxLxi4q40lqby9HzFkUuPRuMpS+rFP4+Z/BNCrtXGpZlhX+dLZFTzlXrbInQUM88G8yqXLzqZhFJDZ+Pvs8/N3+ymnnra5Ds3W5c5TaL6mUEJLPqwS9YmzuiJu1ppiehMhIqI6EyEiojoTISKiOhMhIqI6EyEiojoTISKiOhMhIqI6EyEk1lKm2P9SJjrSWZs7WQQctCrcyZtoRq73aWNmbM0Fq72n+eAH4B3xkaiYIlxqUAAAAASUVORK5CYII=' },
            { name: 'A8_UnarmedAssault', type: 'UnarmedAssault', title: 'Unarmed Assault', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACMAAABICAYAAAB86kzAAAAACXBIWXMAAC4jAAAuIwF4pT92AAACNklEQVRoge2ZT07CQBSHf4q4FU8AN7CJhriDI+AN6s4l6yZtSJt07RG4gbh0BewUNIEbyA0kcceiZppXUkof0GFoGzNfQkKhzHy8+fdmehYEAWRwPb8GwEj56cyxrR+ZMjPJuJ7fBtChV33HrQsAIwB9x7ZGSmVczxeVP+8R2CXWc2yrf5SM6/kN8e8AtCQkkowBmI5tfWeWoSYZALhSIBKxJKFB2pfnjIgJYKhYBFTeC5W/xVZkKCJDxRJpPCQjtCFDfWR2goikIZrMiPehZDP1cxIB1bMxwtYy1DwqRk0WWvH+E49ML2eRrXpDGdfzjQKiElGnVllHJnWo5YgZl2kXLBPWHw5t1/Pllm61XFfOK5eivzyVQOZNNFOtBCIhqWtTUZRORipFPAWlGk1RM80LFlmIJD6SOThpPhFhXhPJ7E2W85BZJ1eu548KWixFE4mkrhQpRDd6s5ahzdZrziLjeB6cnPRMyk3zYJlMXTZkaI8slvM8hDrJDd3WcuDYltgddE8coce0PfiuHaVB84/qHWWHOwxgF0qKUENhpx7TPomdYA89hWjT0JeZh8Z0CrF3ls96PmPQCBByNztuncfOZ2aHli99ciVoNu8/q9XqbXS9Wq2+JpP3O9nyLqRNAEynH7+Jj5LXmdBpJ4eW4dAyHFqGQ8twaBkOLcOhZTi0DIeW4dAyHFqGQ8twaBkOLcPxr2SUPqs6VsZU+XjoKJkgCKJDbCVCR/cZlUJKOnBMSP4hGoA/27HEP0eLc8cAAAAASUVORK5CYII=' },
            { name: 'A9_Unknown', type: 'Unknown', title: 'Unknown', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACMAAABICAYAAAB86kzAAAAACXBIWXMAAC4jAAAuIwF4pT92AAACKElEQVRoge2awU3DMBSG/yDutEfEoWxQowzgHBBXugEdgRE6QpkA2KC9Ig71ABFhg/aAOEInMHqpU5yU16Zu0kTIn5SqB9v5ZD8n71kJtNZwQahYAOgAiKzuM/pJZDhzGXMvGaHiIYABgNsSzacAJnQlMvyuTEao+B7ACMBZmUELLAGMExmODpIxS/EEoO8gUWRBs5rIMOEanGwRoSV5q0iE6NF4ZtzyMqbDY0USRR45oQ2ZmkUy/hTKxYyJEdqWLoHqwpUdQ8WZGTvuGFee7H5rGTNt8ogiRN9eLntmdj4HamJ931RGqDgyW68JekLFA3tmBg2JZORkop3N6yW9f7q1hYrdXt3V0j0x8dIGBPtuagIvw0EypbKwY9Ca3ZTIMMiWSTXs8g4rZibNuqyqirbIpKlEKpPIcN7gUqkswWpfCoHfKvDhyCJTu/osPvRGWWQfASruckl5TsaUodRgWbMLjR8Vy96N14EJpgj1CWUiG5Xln+8mS6jqJVtwIqxMQaiqoKZxxLZau+wpxKUJ7jsHCToaGW2T2EvGkuqY5JkuwVQUS/N4n5mzmXnZ8Z1PrtLOQTCT1zeye36Br88PqNcXpbV2TmNPnU0M6vXl0CHW+LSTw8tweBkOL8PhZTi8DIeX4fAyHF6Gw8tweBkOL8PhZTi8DIeX4fhXMjuPxvbhIBmtNX1x9NwKGayEhlUJHXyMRpBQEAT0l05FnaksgM0M5T5B2QsAP+zIusCmwAoMAAAAAElFTkSuQmCC' },
          ]
        },
        {
          name: 'targets',
          title: 'Targets/Victims',
          children: [
            { name: 'T1_AbortionRelated', type: 'AbortionRelated', title: 'AbortionRelated', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACtElEQVRoge3aQW4TMRQG4H9S9u1uNowYcQHoOgvCCQgL9uEGPUJ6g5wAyA3CDWYzO6S2F0BFWWXXrpAQkpEjG4xnxn52/MwC/9KoSmtbnya2x/a0EkLATF83LwHsACznh/0t/kFchtkItgPwTP5Un7PGMtz0dbMaBRsFz9WvznOjRwwyH/u62egPxy7R180FgHuroM4jgAV395jAmnk/P+w/abDEvHC0x4omYHXezvq6aQG0noJs3SMAeyfLzeaHvewKC3UXXUmODsTKb/jhOOjUV50VHYOFOUsY6DtudCwWepawGrtQjbkGIcyBqOos1ViQGD3ryKubH/ZdCuwoOAIty70hlNuosrtY7CQ4EJ06k1jYj2YzqgKlT6eME+sEIz/ai4UPjHxoEhYUMP6g10low5CxoIJVrpIR/86GigUVrNYbr9L4BlmGFKbe4aBGA+Obw6PArIv4vm4W1LJUsG/5mS0hg66AY0IFc2/3005rzODHkL0iFbyL96RtO+TRvI0muZMerMJ1l9dq7U3K5ALeTMC2JjbpVmsZsFC7mo5yp53gTFgdEnoSzIT1nXt40aPgwK34NYBvnnKfAbxWaxLfzsWJnjrQDj43kPU+XD6/eXr28/jH7zjDD1Tbd1++XpmDKWA37j+XOPmQo6rsKedaCDHYWp2Cdh1oB2FDErCxHXSPWW6sTixanw9nxZ6C1ufDnadCcqxOILrVfXjlqMCG1SGi5TuOW32g/aB2xvbEzo4loo8vZGAdaN9bFbJhHWh5Ay81FvaTzjiF3+bGjqCloR3sRuSDI9Ulm7Oudcr25VW2+dwpYO4UMHcKmDsFzJ0C5k4Bc6eAuVPA3Clg7hQwdwqYOwXMnf8ezPUC/XeSgoUQK2508i7BjX7C0ahEV1XF0TTt3w+iG6+qVgghT/bTBMAvF+EGezm1aiQAAAAASUVORK5CYII=' },
            { name: 'T2_AirportsAircraft', type: 'AirportsAircraft', title: 'Airports Aircraft', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACyElEQVRoge3ZzW3bMBgG4Fc+9tA4BXrppe4CVqwJvEGcCRIPIMDZwJmgjTVA3A08QrKAIE7QegMHPbQ3FTRIR5b181HixzQFX0CGf2j6ASFS5ifkeX5yjCerm6r3XR3jyWo4nqwWVb8XyIdiwihZA7gG8F2k8Q0cJ4ySIYBH+RTAE4CZSOOdVhzApYY6TtE1hq1CZ/LFoPDBt1JDmWs14q+Flfks3w+j5AJ6hMMoWQD42tAf60g3YIt5BjAKxpPVCMAPQr8saCJW52og0vgngDmhsfXTwxA7F2m82Z/DIo3XrtEdsPvfPUw6l+iuWBSXtUJn8jx9IHR0ck6HUSLnw0i/Fmn8aBNbCTZFA5AdyvYzAGcVbQSAjWq364OtBRuiTbJV62onbCOYEd2WWixKV7qTGExEJ1i0geEW3YoFBYwX9K0VVnVIWFDBKkOrxJfcUrGggtXauehvq8yFSWPqCE9r1lgbmXGAjUbBMGf6v65N8JQRDJP5YTLp/on8t+AdoY2TUMEZM4bcPxW86W5pjSjWHdpCvTRn6q8hR4x2LyaTbskEtn+lY740G+0RqSO8Jm5ruoaMbgWrji4ZsTokdCO4UMl0lVZ0LZiIfVYHJbJ0ekdo14iuBBtgpyKN5YS8AnD/6cMvfHz/e/+hfH7+7s9W7VS+iDSWbZd9izVNBW0K9ugKFQRBeQt+l+f5yXLYp1hzNMJ9sCbpUxY7gF1hdbqiB6+B1emC1gXtrGXPRsJSz+FyDM7puS5oN22BrI9sOcSRlhNwrQvaWc0X2LE6LejDatFU0HaGbTCgvLQNar7gHFthgKoKHd8Iqrl1O+xym1Z2VzqWXW/3ysWg8jds3j+2Ca47fF2COx7MHQ/mjgdzx4O548Hc8WDueDB3PJg7HswdD+aOB3PHg7ljGyzeGnjKjbYKzvN8x422fg5zo1kmXQFttyAO4C+oqcU7lPvBfwAAAABJRU5ErkJggg==' },
            { name: 'T3_Business', type: 'Business', title: 'Business', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABNCAYAAADdLIb+AAAACXBIWXMAAC4jAAAuIwF4pT92AAACtElEQVRoge2bQW7TQBSG/4nYlxtAT9AegEjNDbJjhdRssqY3KCegXeJN0h0b1KDucVAuACcgnIB0DchoqjfBnjj2e+N5ThHzSVbSZPzyZTzzPH5OURTFzvbu04unda8/hm0AjywfngP4QY8HwX52lg83WT4c+59fESbJGf05O4R0yeEIwG2WD6/K7xvbzdTQfpvbmhiT6Wg171nW52Y6Wp1vhbN8eApgSd+qDnXpBlnHg7SxE4xkT1piqkkzZB0XO5OugRkdiUPJfgUwH0xHqw2AM3qhjWVMaaHsmXV96GGB9FEsaYHsjZNFOUtQEO54vqcgX7RlXXZwVIRDpanHx3SUyqwp1sL1UBdZ1AkHSFuRZxwBAPaIvA2V3SsslI7NXln4p+YywuwRi0bZRmH0L90qizZh9CfNkgVHGH+lrxhNQ2DLsoWJ10rCS0ljljDlWa1sIeoIbg/7J4SYnFAK/WeELey1CVeY3QPaSCZdEg6BKxy0jBSw4bZ9DML3knU1V3hBS0kNFpKYklOzKLCA+MKE1rCYS64R9y7gywgua0JhXyO29nAPspBcjTcK9yTrYEnvFVaS/d7yfqt0rbCwyDEB8LFF0rY7pkVOp2JNXV0iuG7w8tW4EuzXz9/XH97fVda7XYs1fuWnW5HDGD/lvCmK4tJv10V6OyS6ykroUssb9C3rCJW2Be3nAL7FkOUOiTLS4WHrw2uaxZ1kQ5HWPVx92Mp87lvWwZDeTr5yHh7X7KAu62iQrmSKQcMOvck2ONjH09q05u1w0bdsjcM19ey60iDm/WMbztsuY9+fTpf52iRhbZKwNklYmySsTRLWJglrk4S1ScLaJGFtkrA2SVibJKzNfy88iRxvh6jCRVHMtaWjDwlt6ScaQa20McY+tTd8osL6+UEoxhj7P03s3/O0AuAPv8ZtdybOzFYAAAAASUVORK5CYII=' },
            { name: 'T4_EducationalInstitution', type: 'EducationalInstitution', title: 'Educational Institution', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACz0lEQVRoge2ZQW7UMBSG/4zYl+YClEURu2bJstygOQHTG5RlVrSbZssNmhFb1BSJA0xPQDkB5QJhegIjj+yRm8TJs+OXQcKfFE2n43n55unlJbYhhOgch9e3R33/n/M4vL592Xe+BVqkZb0E8Eu97gV17j9pWV+0z59Ia40aeGN8ft4UeTWndI/DCsBFU+Qb+WYxMFByM2emLQ4fAOySts1wWtYZgB8DsdgzbZE1WTVFvkxkcQN4BHAwEpNNmiC7c9Al8UgYzFIeDrIyw9VCFfMpgJ+ELwWVdpTdnneb4X1I+8jC7BJzSvvKot2HVTB5Ea4BnBACbi/EtKzP1I/N1HGgfvhGxbprivxhqmyvsIf0E6HDSO5VzE++slZhuEuHxCoLs4bbGDW9+ldkMZRhk7SsK3WL5GRUFkMZNlGBODNNkgVVWLGeYjQAWRaOwpchLQ0eXAaThNXT3KuJYjacbkDUDJ8F0+tyolooCapwxijsFJ8qTM4ANy4XXRT2gSrM1YM1G+pAqrBTr3TkST96BhNuivxOPUZy4DSxdanhz0zCTuXmIkyuM0cqdSclQX28pE5rfJHldkqp5dEMzyALNcVaUzI9KDyTrIYkbRVmkr0f+XxUulfYZSoO4L16tbU9Od3/COB1U+SUOeKgdN+6hPe6wfHxG/E2e7d7//3rlyshROfBnzhH7L0Qxxa0ybLbYEnSbjm9wlOkxxa0ybKuECe2nfJY7ENW4yMtF7SP5CZMCFmXkjBxKQ+5PiwXs6+myk7BZd1Drw/LLHzbh6xmRHp38Zl9eNmzNjyLrMYi/dvsFH0L2jrTs8papGUCs2e92LJ1u/TZppXhWsel73avzSHo/nFIYdsRp/ncRGFuojA3UZibKMxNFOYmCnMThbmJwtxEYW6iMDdRmJsozM1/L3weOF6HoMJCiIpbOnhJcEu/4AgqpZMkkX/KDZ9wAPgLX53EBBHV2CoAAAAASUVORK5CYII=' },
            { name: 'T5_FoodorWaterSupply', type: 'FoodorWaterSupply', title: 'Foodor Water Supply', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACyElEQVRoge3aP3LTQBQG8G81FMgNnmGYIQ34BtENMAV9uIFpqMMFmNDRJTcAd5S5Ab6B5I4ynAC7MZW8zJqV2Ojv29W+TcF+Mx4n9mr9syK9aJ8NKeW9W5mnWZmnd+q++VyoW5mnqzJPd2WeLpuvmcDIsZhdANgAeKnuj8UsQ+Aci9kKwBcATwB8PxazS1MglBp/By7VgAZvD2CZZIciBNvANrNOssOqBh+L2QJAod9VM0HQA9gqJ7Qo83SuD4PzgcGsaAK2ytuEMAh6z7Mc0xbYrTIkSXbYqb2nHxiKd7QlVv2Fd6c9/BBoF+zJWj0aEu2KhVnWjMkoJyHME1FXGVXD1ZtYGGM2uvpsqhedgu0EO6AV6BVh3K0ee+2K7QVbon2nFwvzGG49QT+mfWYQOwh+APQoFmNghEOTsKCA8Q995YXWDhkLKljnkjTKPjdULBms6+xY6XKN1Y6g7uELJqzKuS6hpFDB3CsP8vxU8IIwJkhsTroIdgkVzL0I9VvWmMF7m7UiFXzr7vE7t82/5rUzaTgbm8E2J53VxBa5sVlu9V7Am9ETbnoaLT5C7nuM7uEAWNgsbAfBgbBVSOheMBN2P/L8KLoTbIFVlePDyGpkr8e91tckk/oeXX0JMrZqgert5u+v3/w6e/YYz58K5D9+48VZuv74+dvK3Mil79ELdsXWkwnRLDmfpJStpdUUdH1ITMXaZEpbLAmNrV/YEZ3o9VpQbBUXtOoP3xEuQLxjq9j2Par+8GpgAzZsjRhH1yefWYe7NthyY6sMoO9ViqGG9lb/Hiwdhp+9Za2BXtu0j3ymYchaV3A+P45V0zVuV74/7o3LfO5EMHcimDsRzJ0I5k4EcyeCuRPB3Ilg7kQwdyKYOxHMnQjmzn8Pfud5vla8gqWUX7nR3g8JbvQjjkkVWgihfvT+bSzS1w+cJxdiLqX01xQH8AckyWb/Uo8HggAAAABJRU5ErkJggg==' },
            { name: 'T6_GovernmentDiplomatic', type: 'GovernmentDiplomatic', title: 'Government Diplomatic', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACvElEQVRoge2bvY7TQBDH/w4p6KBASFTkAfgIElDnDfCVdLnKkuXiHsF09BgLd3dPgNPRkRoEhDc4Okp4gj1ttAsbxx+z8c7mBPuTrEiX8dzPk7V3d3IXCSFgUpTVHEANIM7SZIMjoBxyeTQddoRV4BrALQC/ASx8S7c4LLM0qfX7k45AqNe1+vkxZLXD+6KsljpmW+GirG4DkJW835LHS6VbZJucZmlyroVlyV/05GOVJshqTm7cuXtvBuA1gJs9gfK9l58+f/nw/NnTn0eS/S5vwkmWJpeyeqqKfTgf05ay8hP+9ecpYXGyvLC5ulCvsjCfEmp8UitdqxvVqyyaz+ExyYqyWqgLNpGfwsa8WcfItgofkFTKxAOxP9TMtRkj2ylsKe2aTlmYY7iJxZh2Sa9srzD8Sw/KYkgY/qRJsqAI46/0mRO1fciyoAorYmeKu+RUWVCFi7KaDSyOxrC0OZdaYa7qwrYQVGHWRbyaJUlQhWd8unbY3HTXgn9WeNTa1yVU4TWnRJYm5PzXQXhlE0ydmi9tE1tw7lxYUZOi7MlttludC3gTD4t5d6s1TzuPx6qFMFjpXmHP2ySSdKfwkfZ0g9Ktwpa75gvCbkTGnQJ4QojtlXbal9h8+/rRDJhOp6sHDx+dmV0ip32J0cmiqPnIeSWEyJsnj/k9fQ1tK1kbLDa2e8Nj4ltWc6h09Obtu5lqIY2WpQ4JE9ti6f7wUGfdWWWbWFZ6ocdwrBp2XmU1RGn5HUe9FVYyccsJ7LJE6e0XMhhoaHuT7XGQrydaFs2Zzjhh5Vu2xUEXbHdZKycOV4dM1zhyl/nlEbb53ARhboIwN0GYmyDMTRDmJghzE4S5CcLcBGFugjA3QZibIMzNfy984TjfHk6FhRBLbmnnQ4JbesqRVEpHUcSRmvbnBwcnj6K5EMLdv1IAuAJdBQX9rP3ytQAAAABJRU5ErkJggg==' },
            { name: 'T7_GovernmentGeneral', type: 'GovernmentGeneral', title: 'Government General', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAAC1klEQVRoge3az2oTQRwH8O9GoYJg9aYH0Tdo9C5EPHmLT9DIPoD1uDnFy+ZofIBgvHmMDyA04N30BaTePSTgoSqy8iszcTvZP7/Znd+k6HxhKS2zkw+T+U+jLMuQz3Q8vAlgAuAoTtIVdhBlGNFjGjoF2GMAh/RT/e41OcMLAMvpeNgtBOcKHqg/HfhGFxjuKUN/C2wU1PGGLsDq7AOY6ZY+78PT8XCmukFZTgD0pPp0BTafNRmu3N37eR/A25o6bwN4+vnTx/cPHz052wGW8oNauhMn6SmA54y6nXcPC+xafcPL8z4cJ+nMN7oJFvlB5xPdFAs96IzKBow+DXMgTsdDGgv00GgmEH3IKk7SY1fYQnADNH0zgxrAB7V6LttgS8E59ETNg66yZtRXiq0EK3RXtYhLdFUqsTD3EmbUiz1V0c6xqAPDH5qFBQeMv+iBE9p22FhwwSp9Vin7HHGxbLCaO6s2R23Ss3mX28JSrWtdNxfcZZRpmn3zVHHZwVBLOSs2g+5S5J8Fnwo72OGC2fNkw7idhwHMBbELm8Mtd2mmLrFoxSrPzKawzaCbOAKasVrpKvfDOhbHmqZ5Fycpa3NV28IesJRDdZlTm0qwJ6wVuhTsGatTiy4EWx7FObMHlXsD4DHj5FKJLrvQtjqKqzuJ/pdv31/fun5tU+Ds1+/FnRt7kzhJN/O4xcG2cCBeALe+5Igic8p5lWXZyHy5DbrqQtsKaxOLg+1W9+j4xuo0RXd2gW2D5i7NzrE6luh+R+2UBhUviGF1mGgagHN9oV12USKO1alBb2aL/IX23LjQ9oatQb/MT20d4wXq2A8AfPWNzRk0mgzP4iS9uK2lhcPVQ9UZz8hl/fSEY750Alg6ASydAJZOAEsngKUTwNIJYOkEsHQCWDoBLJ0Alk4AS+e/B584rm8rrsE9abRTcJZlK2m08z4sjb4qUSmhoygitNt/HwPwB7cP9RF3aE0LAAAAAElFTkSuQmCC' },
            { name: 'T8_JournalistsMedia', type: 'JournalistsMedia', title: 'Journalists Media', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACxklEQVRoge3ZMW7bMBQG4F9GM8dDB2tp3RM0mzX6BnVOkPgGzgkknaDpCerewEdIN43yCVpPWhWgQEcVNEhHliXxUeJj2oI/oCC2aeoDQVLWE6qqujiS2eK+7X1XRzJbTJPZYtN2vkD8qScNoy2AOwDf4iK7h+OkYTQF8ATgI4DvAFZxkZVKcQI3Gqo4RXcYDhKdixeT2gePjYYid3LEXwsr8l68n4bRDdQIp2G0AfC5pz/Wke7B1vMMYB4ks8UcwA9CvyxoIlbldhIX2U8Aa0Jj69PDELuOi2x3nMNxkW1dowdgj+c9LTqX6KFY1Le1Wmdinn4ldHQxp9MwEuthrl7HRfZkE9sKNkUDEB2K9isA1y1t9gB2sl05BtsJNkSb5CD31UHYXjAjWpdOLBpXuosYLEQnWOjAcIvWYkEB4wX9YIXVHhIWVLDM1CrxJQ9ULKhguXduxttac2PSmDrCy4491kZWHGCjUTDMtfqtaxO8ZATDZH2YLLq/Iv8tuCS0cRIqOGfGkPungnfDLdrs63UHXaiX5lz+NOSI0d2LyaJLmMD2r3TMl2aje0TqCG+JtzVDQ0ZrwbKjT4xYFRK6F1yrZLqKFt0JJmKf5UGJKJ2mhHa96FawAXYZF5lYkLcAvpTvrvArvDp+KP7//fbNQd6pfIiLTLRNxhZr+graFOzZFSoIguYteFpV1cV2OKZYczbCY7AmGVMWO4FdYVWGoievgVUZglYF7Vxzz0bCUudwMwZzeq0K2n23QNZHthniSIsFuFUF7bzjC+xYFQ36tFv0FbSdYXsMaG5tk44vOMe2GCCrQucPgjoe3U6HPKYV3TWOZOjjXrEZtJ7D5vNjm+Cuw9cluOPB3PFg7ngwdzyYOx7MHQ/mjgdzx4O548Hc8WDueDB3PJg7tsH7fw285EZbBVdVVXKjrc9hbjTLoquh7RbEAfwB0fLFnyUWT68AAAAASUVORK5CYII=' },
            { name: 'T9_Maritime', type: 'Maritime', title: 'Maritime', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACu0lEQVRoge2asY7TMBjH/+ktbNzIdr2NjbwBfQAEvifooVZqx+MN7p4A2DJEgnuCBMTAWEYmysZ4JzEw3q0sQY4clKaO/TnO54Lkn2RVbWznp6+O688uqqraK4tVca77PGRZrIpUd78JOizX5XsA79TrQVD3/qZzSKR1g6owb12/zjNxHlJa4/AdwCzPxJ18M2lVfNOpKJmHjLRGVvIEwGa5Lo/RRHi5LgWAwtAXe6R7ZNvUkT76+SudAvgM4IGhcvrx04/T588elweSlTwC8HWSZ+IGAEWEZXgQZSUv80yU9RhWX/c1odFcjfVRcJStg2WbJawdDGXovXbmYYdIy3l68EPoE5idCA/pUE45AIQqTzvXb9X1TXNj329RK+zYMRUpvwXwYqisUZhJmoLx+dhbS7RxGNNBZGETRlhp0sxjFUYYafI0SRJW3PgYGXjlMqe7CHMtfo5dKpOE1WruZLCSGadAUCOcjizZ5mS5LqfUylThmbeWmdGF/xmiMDdU4Q2zB3mOpwpvh7tYuVVpGgnqT3OploccOGUuLmOYa3/izqWyizB5rnTktUu6Rf1p5l7Ik3NEq3DArIMkbRQ+QIpkle4VZkpCPxDqGaW1wo5p/qlchAP40r14lPy+V5nKWZ6JaZ4J4bvvsZc1e21yJEk3Bb+qquqy29DnHjsRDrVV5bPD1N7QDravBg/pWji0bMMQ6WSxKqZqcfPQV5Y6hrs4BOys2dC+8JX1gRhpeWSwaTa0pczbQ8g2WKT/niRNWg0uNA2CyLYcdNL6Yy9Ng6CyPQ7y9CptZGt6jm61x6a2IrvrlMuhx72LVSF0n496fjymcF+JaT43UZibKMxNFOYmCnMThbmJwtxEYW6iMDdRmJsozE0U5iYKczO28P3/Jjzjlh5VuKqqLbf06GOYW5rloWtJO/0XwgqAP8FcuWxz+K+wAAAAAElFTkSuQmCC' },
            { name: 'T10_Military', type: 'Military', title: 'Military', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACyklEQVRoge2ZwW3bMBSGfxm9p5f0GKUoirineAR3gyAeoM4G6QRNNsgGkQcQkG7goIf21vSmW6MJ6kzAggZlqBIpvUfxSYfyAwTHsfz0+eHxSSShlGodJ5dnp7b/j3mcXJ69tl1vhgbpar4G8Nu8ToK59p90Nb9uXj/R1hXmxPva51dlXmRjSlscNgCuy7zY6TezjhM192Nm2uHwCcAhafsMp6v5AsDPjljimXbI1tmUebFOdHEDeAZw1BNTTJoge3CoSuKZcLJIeTBkdYazmSnmJYBfhC8FlWbK7q+7z/AU0j6yqHeJMaV9ZdHswyaYHoRbAOeEgPuBmK7mF+bHLsxxZH74zsR6KPPiaaisVdhD+oXQYTSPJuYXX1mnMPjSIXHKdgrXxDNzt5lcFvVB58IE2EibUmRBEcY40iRZUIUN2yFGHZBlwRS+CWlZ44lzMknYPM2lA8VcsG5A1AxfBNNrc25aKAmq8EJQmBWfKkzOgDScQReFfaAKS/Xgih31RKowq1cyeakePYMJl3nxYB4jJWBNbDk1fCckzCo3jjC5zphk5k5Kovd5GLxpjS+63JaUWu7N8AiyMFOsLSXTncIjyVaQpJ3CQrKPPZ/3SluFOVNxAB/Nq6vt6en+ZwBvy7xYEmYundK2dQnvdYPj4zfq/Yd3h/ffv/24VUq1HvyJE1vrQOxb0CbL7oMlSbPlWIWHSPctaJNluRAntq3ymE0hW+EjrRe0T/UmTAhZTknU4ZSHXh/Wi9m3Q2WHwFn3qNaHdRa+TiFb0SN9GHz1Pry2rA2PIlvhkC7rncK2oF1lelRZh7RO4OKfXuzYul37bNPqcI3jxne71+UQdP84pLDriNN8aaKwNFFYmigsTRSWJgpLE4WlicLSRGFporA0UViaKCxNFJbmvxe+ChyvRVBhpVQmLR28JKSlX0kE1dJJkug/9YZPOAD8Bcy/wSpQKjWWAAAAAElFTkSuQmCC' },
            { name: 'T11_NGO', type: 'NGO', title: 'NGO', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACx0lEQVRoge3asW4TMRgH8L8jpJZKiIgXIG/QvgFhylreIAycGMuWsZ16G1mJkCBiYczITdwLIJU3AIkJIZE8gZEr32GcnO+zz5874L90atT6nF9cn+/8KUJKCTNVWZwB2AA4ny1WN7iDVGUxB7DUhtoUjCzsOQDV4LH6qfFJo7HvADwE8Lkqiwvz/dsRrspiqhpYuB2AaaqRNrB21rPFat6Cq7KYALjRn8pOErQD2+QWLT5dvxjraXDqaMyKJmCbPBsRGkGPPMuc9sB+VYbRbLHaqtHTv3AlOtoTq/7D29sRvgt0CBbmspYSHYqFuawZnVEuQpgXol5l1BquPsTEaFPr1adu3nQI9iA4AK1ATwjtNrrt61BsJ9gTHTudWNi3ZjMeczpmnFgnGOnRvVj0gZEOTcKCAsZf9GUU2n7IWFDBOhekVv5ZUrFksL5J9C1dofEaCOoIT5mwKqd6CSWFCubeeZD7p4InhDZJ4nPRZXBIqGDuTWjcZY0ZvPPZK1LBm3BP3L59bs3rYJI7tU9jn4uOa1osfbZbnQ/wZjqqQjFDrnv0jrBRHOQMeWPrBOsO6o4SVuyQ0J1gJuyu5++96INgD6xaOV717EZ2ut1T/UwyqO7RVdAmYZsSqD5vvP7y8/fJ0REePbiPH7+2ODk+fvP2w8eXVv/edY9OcCi27UwIe8m5klLuba2GoNspMRTrkyFlsVFqbJNQtCpou6rvXljqlDDjOz1Uffgb4cYQbWTt+NY9mvrw3HECG7YJAd1efOY6fOgEdmwTB/qflcJV0E6GdRi+dy5r1glXqbEHDOrueLb3BKduHLEO1Z11XMbsXx15m8+dDOZOBnMng7mTwdzJYO5kMHcymDsZzJ0M5k4GcyeDuZPB3Pnvwc8j97eXqGAp5XtudPQpwY2+x9GpQgsh1Mvo38Yiff0guHMhxlJK8vd5egPgD9n4+nnJlc69AAAAAElFTkSuQmCC' },
            { name: 'T12_Other', type: 'Other', title: 'Other', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACl0lEQVRoge3bz23bMBQG8E9G0Gu7QXzovU61gDeQsoEDLZARlD0iIJmg0gTxtQcBzq1Hd4N2AhY0yJaW9ec9i48OUn6A4CChnn9maImkk0QpBTdNla4A1ADyrGh3uECModRH13AENg23AD4C+A1gHRrdY9hkRVvbny8GGsI8bs33L4G1hm9NlW5sm0MPN1X6CYDuyeueOkF6ugfbzV1WtE8WrLs8G6kniiZgbW6T+vHr0vTuVGMRNAP7qp9/kRXtXn9hQGPxPqa52Kxof/29SjBO1i9sZV5oUCzcq4T5VVN7ujZv1KBYdK/Dc4o1Vbo2L9iN/i3s3HE/B9sLPqOoxuQTbX+aO9duDnYQzET7ziAW7hjuhjGmfWYUOwpGePQkFlNghEOTsKCA8Q9974V2GjIWVLBJ7o14nJKKBRXcVOlyYnI0JxvOudQelupdcDuCChadxJu7JClU8FKOywvnTfcm8m7Bs+a+PkMFbyURWdGS678FcMNpTL0177mFGXnyDjapSa34KTnLrcEJvJsAk3l/s7VAK48vZgthsqdHwYGXSST0IPhCa7pJdC+YuWp+JqxGdLs7ADeEtqNor/sS338sX9wGH67Q3Hze37u7RF73JWYXS5LuJedBKVV2T57zPGMb2iwsJ4yF7cnwWITG2pyLXpj1WlDsHLTdH57apPaOtWGi13YM52bDLijWhojWn3HUB7DB5D0niGOJ6MMHMpjY0A6GHTHox1uLRfdO55zQhMb2GGyHHU9r9Y3D16HLdY7SZ319xGW+dCJYOhEsnQiWTgRLJ4KlE8HSiWDpRLB0Ilg6ESydCJZOBEvnvwc/e653Eq9gpdRGGu19SEijrySKanSSJBKlaX9+cHbxJFkppfz9VwKAPxk+Bfwa4HZzAAAAAElFTkSuQmCC' },
            { name: 'T13_Police', type: 'Police', title: 'Police', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACtElEQVRoge3aTW7TQBgG4NcWSGxQ2LCGGzTcwGyzMidoOAHmBukJ8A0INwgbr5sN6/QGRVwgYQvSoKm+Mc747xt7vkkF80pW1Wo8eTSZfzVRSqGZZFW+AFACKFRVHHGBkGGjH9uQdmBvAVzrn/R70DQMHwAcklW57AQ3Cl7Rn65CozsMr8iQt8BWQZNg6A6syQLA1rT0Qx9OVuWWukFf7gBkUn16ANvMSRvSZFW+HsFCsqWZ2Dqpqop7AO8ZZb2jHbAn+oYPD31YVcU2NHoKFs1BFxI9FQsz6KzK1gA+Mz73bCDSWNCPHs0apD/kqKri1he2EzwBrb+Z9QjgK62ehznYXjChc8IsGHBuToz6erGDYEIvqUV8oocyiIW9l7BDL2ZU0cWxGAMjHJqFBQeMv+i1F1o7bCy4YErOKuWegotlg2nuHNtvTE3m8h63haVa17luLnjJKDM1C/tU8djBoKWcFZdB9yjyz4LvhR3scMHseXJi/M7DAHaC2L3L4Za7NOsusZ/F6s/WpbDLoCs9Ae04rXSD++G6kONRfEK+qKpgba5GWzgAVueaLnNGMwgOhHVC94IDY01G0Z1gx6M4Z/bQ5W4AvGWcXAbRfRfaTkdxupPIs6c/Pv1Uz+sCz/Br/+33y1JVRT2POxxsOwfiGXj2JUeS2FPOjVJq0/rQGeihC20nrEscDrat7pGGxppMRaeXwM5Bc5dm71gTR3Se0k5pPfCCGNaEidYDcGcutPsuSsSxJiPoerZoXmjvrAvtYNgR9Mfm1JZaL+iO/QbA99DYhsGgteGdqorzba1eOHw9ujrr2fisXz/xmC+dCJZOBEsngqUTwdKJYOlEsHQiWDoRLJ0Ilk4ESyeCpRPB0vnvwXee62vFNziTRnsFK6WO0mjvfVga/USiUo1OkkSj/f77GIA/My3yBI9ZZKMAAAAASUVORK5CYII=' },
            { name: 'T14_PrivateCitizensProperty', type: 'PrivateCitizensProperty', title: 'Private Citizens Property', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACu0lEQVRoge3ZTW7aQBgG4NdcINygVPLKm+YI7L0hJwi5AdyAnKDtCUpvwALv0xvAAqsSVluWkVqVSO3a1aAZYvw339jzTdpqXslRgGF4ZM2P/Rl5nleO/Xo3rXvf1bFf74b79W5W93uB+FNMlqRLALcAPoZxNIXjZEk6BPAA4A2ATwAmYRwdleIMLjVUcYpuMBwkeiNeDAofvCs1FLmVZ/ylsCKvxPtZkl5DneEsSWcA3rb0x3qmW7DFPAEYBfv1bgTgK6FfFjQRq3IzCOPoG4A7QmPrw8MQexfG0eo0hsM4WrpGd8Cefvc86Vyiu2JRXNYKnYlx+oHQUWVMZ0kq5sNIvQ7j6MEmthZsigYgOhTtJwCuatpsAaxku2MfbCPYEG2Sg1xXO2FbwYxoXRqxKO10lRhMRCdY6MBwi9ZiQQHjGT23wqoPCQsqWGZolficORULQ/CsB6ot1yaNSeAsSZvWWBuZWAebngXDXKlrXZvgMSMYJvPDZAz/FflvwUdCGyehgjfMGHL/VPCqu0WbbbHuoAt1a97IS0OOGN29mEy6BRPY/k4nw7UWG90jUrdmVW/jChmtBTvAqpDQrWCHWBUtuhFMxD7JgxJROr3vi64FG2DHYRyJC5cbAO8/P37Bj98/Tx+K/x9/fT/IO5XXYRyJtou+xZq2gjYFe7FDBUFQvgW/z/O8shz2KdZcnOE+WJP0KYudwa6wKl3Rg5fAqnRBD2QBT3dfZR2rYoieqoJ227bLhlUhosUEXKqC9qbhC+xYFQ36vFroCtozF1iN4WJpG7R8gVw+spmSYV55ENTw6HbU5TGt6K50LLo+7m0yWH1+bBPcdPi6BHc8mDsezB0P5o4Hc8eDuePB3PFg7ngwdzyYOx7MHQ/mjgdzxzZ4+6+Bx9xoq+A8z4/caOtjmBvNMukKaLvVewB/APAsv5nGXpH7AAAAAElFTkSuQmCC' },
            { name: 'T15_ReligiousFiguresInstitution', type: 'ReligiousFiguresInstitution', title: 'Religious Figures Institution', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACm0lEQVRoge2a323bMBCHf/ZLH5sN4k5Qwx6g3qDOBE5fLT+kG6QTtAWk5zYT1N3AHUBoukGyQTIBCwpUQcuUeEfq6BTgBxBBLP75cKFoHhkopU7KdlFcuz5PWbaLYu4ab4oOxXL3HcA38/MsmLF/uxwm2rrFVNhYz++qurxOKe1w+ANgVdXlk/5lalX80qmo2aSMtENW8xbAoVjuLtBGuFju1gB+DPQlHukeWZsm0pPtopgBuAfw2tOnmDRBtuVqWtXlA4A9obLI9GDIfqjqct/MYRO5O0KjjZnro8CUbYLlWyW8HYQSOtbROsyItF6ng+dzTGCOIhzSoV5yAKxNedd5/mhe6H07cOxf0SnM7JhKK/8+VHZQWEiawuD7cbKXsGHM6SSy8AkjrTRp5fEKI400eZkkCRseYowG+MhZ0znCUpufC05lkrDZzV0GKw3DCgQ1wvORJW0ui+VuRq1MFV5Faw0zuvCLIQtLQxU+CHuQ1/iXIPxo0jQS1K/mg9keSsDKXDhzWOp84olTmSNMXiuZfOakW9SvZumNPDlH9AonzDpI0oPCZ0iRvNK9wkJJ6E9CvUFppzAzzX+jN+EAfp08faWeTaZyVdXlrKrLdey5x0nWHHXIMZl0U/BPSqnbbsOYMY4inOqoKuaEyT7QTnauhgjpRji1bEuINOdA2ytLncNdQg60b2JlYyBGWl8ZHNoDbS3z9RyyLR7pfzdJU6vBjaNBElnLwSXtvvZyNEgq2+OgL4LmrWxDz9Wt89rUV3R3nXIbet27XRRr1+ej3h+PKdxXcpovTRaWJgtLk4WlycLSZGFpsrA0WViaLCxNFpYmC0uThaXJwtKMLfz8vwmvpKVHFVZK3UtLjz6HpaVFXjpLmvW/EF4A/AXrzLaQoSZ02QAAAABJRU5ErkJggg==' },
            { name: 'T16_Telecommunication', type: 'Telecommunication', title: 'Telecommunication', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACzElEQVRoge2ZMW7bMBSGfxnd01lL0iHdinjrWBfxKiA3qHOD9ARNbtADFIg8ZG4arxqcEzQ9QdMhXuucgAUN0lAkUXqP4pOG8gMEx7H89Pnh8UkkoZSqHad3T0dN/x/yOL17et10vQkqzFebBYDf5nUUzLX/zlebi+r1E21tMSdelz4/L7I0H1K6wWEJ4KLI0q1+M2k5UXM9ZKYdDp8A7JO2y/B8tZkC+NkSSzzTDtkyyyJLF4kubgCPAA46YopJE2T3DrYkHgkni5QHQ1ZnOJ+YYp4B+EX4UlBppuzuursMjyHtI4tylxhS2lcW1T5sgulBuAZwQgi4G4jz1ebM/NipOQ7MD9+aWLdFlj70lW0U9pB+JnQYzb2J+cVX1ikMvnRInLKtwiXx3NxtRpdFedC5MAGW0qYUWVCEMYw0SRZUYcO6j1ELZFkwhS9DWpZ44JxMEjZPc4c9xVywbkDUDJ8F06tzYlooCarwVFCYFZ8qTM6ANJxBF4V9oApL9WDLlnoiVZjVK5k820fPYMJFlt6ax0gJWBNbTg1/FRJmlRtHmFxnTHJzJyXR+TwM3rTGF11uM0otd2Z4AFmYKdaakulW4YFkLSRpp7CQ7H3H553SjcKcqTiAj+bV1fb0dP8zgDdFls4IM5dW6aZ1Ce91g+Pjt+rd+w/7999vvl0ppWoP/sSJbeNA7FrQJsvugiVJteU0CveR7lrQJstyIU5sa+UxGUPW4iOtF7SP9CZMCFlOSZThlIdeH9aL2Vd9ZfvAWfew68M6Cz/GkLV0SO8HX7kPLxrWhgeRtTik/5Q7RdOCts30oLIOaZ3A6Yte7Ni6Xfhs0+pwlePSd7vX5RB0/ziksOuI03xporA0UViaKCxNFJYmCksThaWJwtJEYWmisDRRWJooLE0Ulua/Fz4PHK9GUGGlVC4tHbwkpKVfSQTV0kmS6D/1hk84APwDbfLE84hFMqIAAAAASUVORK5CYII=' },
            { name: 'T17_TerroristsNonStateMilitia', type: 'TerroristsNonStateMilitia', title: 'Terrorists Non State Militia', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACVUlEQVRoge3a7W3DIBAG4CPqAB0hG9QjeISMkG6QETpCfvZfnQ06gkdwN8gIyQRXXYUVf2C4wxyOVF4JpWoxeYxsMNSAiKMCABUAXOlz+rdcBQCOAHADgHrmm2APtiLaz+xoi8VBOTnBdDaTitnRDmxfmhEYAPaDnt0E7cGO0JRXAOgClVXRDGxfDlywGlqA7f6s9qBN0FLs9KbLio7BomNYy4KOxVIx/Z3XxxhD6BYA3sCfux3YO2PM3t4QlR1x+rT2S1tEpJOk9gn7FWib8mPbv41+u9ADkp5umfUamgRie9Z5SUSiU5dFrBe8EdqLDYIzo4NYFjgTmoVlg/HxJLcplsqOMbz0OQnqSnKeDV2+MHu30rwcJJMOt4drpd6lvNnJihUuuNLzytrngveMOlkiuekKOCZccKfsYA9rzwC+0yMqtzIX/B3vSds2C2xnoks0yZ9WUlly02ldFmdjDH+cZ07Nrl2hlIW9RuQ+RyztCmVHPwuWjc6N5bTnRa/F9ith32qkXzHXKfY9VmEdy6hpnU9H+6vQSbCD46f1PhbqRaOTYSXgNehkWCk4Fg2B3Xc2NgYcg94h4pXxAHJBxKNkvuXGPqfUdvMvnMGZLp1lsGfX9DCzpx+XROAANnYt2GOY33QLB4iwKcAOw+wfnEsHiL8oFXhgaFxbWOLGcoB9pSzztVPA2ilg7RSwdgpYOwWsnQLWTgFrp4C1U8DaKWDtFLB2Clg7/x78nri9WZKCEbHRRie/JLTRLxqNEtoYQz8mfxtr9oZ20saNoQ1p/muKoQDAL1jFPLu8GndpAAAAAElFTkSuQmCC' },
            { name: 'T18_Tourists', type: 'Tourists', title: 'Tourists', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACnUlEQVRoge3bv3HbMBQG8I86p3Y2iDJBlDostEHoBUJ5AxccgKnDIcwJTE0QFRxArt04G8RlKuSgAxKI4p/3RDzIl+C748lng08/wRAJQHailIKbKk9XABoAWVG3e1wgxlDqo2s4ApuGOwDXAF4ArEOjewybom4b+/PFQEOYx535/iWw1vBQ5enGtjn0cJWnbwHonnzXUydIT/dgu7kt6vbegnWXfx6pJ4omYG1ukm9fPi1N7041FkEzsI/6+RdF3T7rLwxoLN7HNBdb1O3PP1cJxsn6ha3MCw2KhXuVML9qak835o0aFIvudXhOsSpP1+YFu9G/hb077udge8FnFNWYbKLtD3Pn2s/BDoKZaN8ZxMIdw90wxrTPjGJHwQiPnsRiCoxwaBIWFDD+ou+80E5DxoIKNsm8EY9TUrGggqs8XU5MjuZkwzmX2sNSvQtuR1DBopN4c5ckhQpeynF54bzpXkX+WfCsua/PUME7SURRt+T6rwG85TSm3pqfuYUZufcONmlIrfgpOcutwQm8mwCTeX+ztUArjw9mC2Gyp0fBgZdJJPQg+EJrukl0L5i5aq4JqxHd7hbAR0LbUbTXfYmnX9ff3QZXC2zfv3m5c3eJvO5LzC6WJN1LzlelVNk9ec7zjG1os7CcMBa2J8NjERprcy6asz88iaUOCTfn7g9PbVJ769lumD29tmM4Mxt2QbE2RLT+jKM5gA0m6zlBHEtEHz6QwcSGdjDsiEE/3lgsunc654RtaGyPwXbY8bRW3zh8Hbpc5yh91tdHXOZLJ4KlE8HSiWDpRLB0Ilg6ESydCJZOBEsngqUTwdKJYOlEsHT+e3Dtud5JvIKVUhtptPchIY2+kiiq0UmSSJSm/fnB2cWTZKWU8vdfCQB+A4KJDHdYXsnfAAAAAElFTkSuQmCC' },
            { name: 'T19_Transportation', type: 'Transportation', title: 'Transportation', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACuUlEQVRoge3aPW7bMBgG4FdCAQFd3KlrO3WNewMX6AHU1UusEzS9gXOC+gZyFnV1bhDfwLmBe4LaQwd3UcHgoyvT+vko8aODhi8gBAko+gHNfyQqyxLVHPLpGwALADdJVuxwgZBhrh7TENdgHwBcq5/0u9dUDF8BbA75dFwLrhS8oj9d+UbXGN6RIT0DGwV1vKFrsDojAEvd0k99+JBPl9QNmvIIYCLVp1uw1eyVIT7k0/cdWEi2NBN7TJxkxRZAxijrHG2B3dM3vHnqw0lWLH2j+2BRHXQ+0X2x0IPOqGwGIGd87slApLGgHjWaFUh9yC7JigdX2FpwD7T6ZmYdgHtaPTdDsI1gQqeEGTHg3OwZ9TViW8GEHlOLuES3pRULcy9hhl6cUEUXx6ILDH9oFhYcMP6hZ05o52FjwQVTUlYp+9xwsWwwzZ1d+42+mdi8x21hqda1rpsLHjPK9M3IPFU8dzBoKWfFZtA9i/y34K2wgx0umD1P9ozbeRjAShC7tjnccpdm1SXWg1jNWdoUthl0C0dAM1YrXet+WMf2KN4jd0lWsDZXnS3sAatyTZc5nWkFe8JaoRvBnrE6nehasOVRnDN7qHK3AD4xTi6t6KYLbaujON1JpD+2r79/qGxjfv2J1p/f/l4kWXGcxy0OtrUD8QQ8+JIjiswp57Ysy7n58hB024W2FdYmFgfbs+4R+8bq9EXHl8AOQXOXZudYHUt0GtNOadbyghhWh4lWA3ClL7SbLkrEsTod6ONsUb3QXhkX2t6wHehv1aktNl5QHfsjgJ++sRWDRivDlyQrTre1auFw9ajqjGfusn71hGO+dAJYOgEsnQCWTgBLJ4ClE8DSCWDpBLB0Alg6ASydAJZOAEvnxYMfHdd3FtfgiTTaKbgsy5002nkflka/kqhUoaMoUmi3/z4G4C8Rte/tajgnNgAAAABJRU5ErkJggg==' },
            { name: 'T20_Unknown', type: 'Unknown', title: 'Unknown', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACtUlEQVRoge3Z3W3bMBQF4CMvEG9QF3mvBGgAeQRngsQb2Bs4E7SdoO4GHkF6twB6gsRAXwsoXUAFU9GR9cdLiZdpCx5Age3Q9AeCpKQrlGXZOsL0+ND1uasjTI/zMD1uun4vkH/qibJ8D+AewHeRxA9wnCjL5wBSACGADMBKJHGhFBdwo6GKU3SP4VyhhXwzq/3jS6OhzH014u+FlfkgP4+yPIIa4SjLNwA+D/THOtID2HpeACyCMD0uADwR+mVBE7EqdzORxM8A1oTG1qeHIXYtkvjwOodFEu9do0dgX3/3suhcosdiUd/Wap3JefqN0FFrTkdZLtfDQr0XSZzaxHaCTdEAZIey/QrATUebE4BD1a6Ygu0FG6JNcq721VHYQTAjWpdeLBpnulYMFqITLHRguEVrsaCA8YbeWmF1h4QFFVxlbpX4li0VC0PwZgJqKJFJYxI4yvK+PdZGVtbBpqNgmBt1rWsTvGQEw2R9mMzhvyL/LbggtHESKlgwY8j9U8GH8RZtTvW6gy7UU7OoLg05YnT3YrLodkxg+2e6Klx7sdE9IvXUrOptXCGjtWAHWBUSehDsEKuiRfeCidiX6qBElk4fp6I7wQbYpUhieeFyB+Drpx/PWPz6s6XK17fFz3N1p/JRJLFsu5tarBkqaFOwV2eoIAiat+CPZVm2tsMpxZqrEZ6CNcmUstgF7AqrMhY9ew+syhi0KmgLzT0bCUudw80YzOm1KmgPnXatj2wzxJGWC3CvCtqi5wvsWBUN+rJb6AraGxdYjeFqa5sNfIFcPrKZhmHbehDU8+h2MeYxreyucezGPu7tM1h9fmwT3Hf4ugR3PJg7HswdD+aOB3PHg7njwdzxYO54MHc8mDsezB0P5o4Hc8c2+PSvgZfcaKvgsiwLbrT1OcyNZll0NbTd6j2A3wgaw928QqJAAAAAAElFTkSuQmCC' },
            { name: 'T21_Utilities', type: 'Utilities', title: 'Utilities', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACnUlEQVRoge2az23bMBSHf9alvSkbxIV9rzeoNyg1gdMN0g3SCdpO0GYCuRu4EzS965BsIKEDsKBAFrRMiY8SH5MC/AAiiMU/H14omo8MpJQXpRWbG9fnKUsrNjvXeAUGdNX2O4Bv+uezoMf+5XJYKWuDrnCwnt+XdXOTUtrh8BvAvqybVv1SWBW/DCoqDikj7ZBVvAVw6qrtFUyEu2orANQTfbFHekTWpo/0qhWbNYAHAKWnTzZpgqyhKsq6eQRwJFRmmR4Bsh/Kujn2c1hH7p7Q6KDnehQCZftg+VYJbwdzmTvW2TocEGm1Ts+ez0sCcxbhOR2qJQeA0OXd4PmTfqGPZuClf0WncGDHVIz8+7myk8JM0hQm34+LvYRNwJxOIgufMNJKk1YerzDSSJOXSZKw5nGJ0QQfQ9b0EGGuzc9VSGWSsN7NXc9WmiYoENQI7yJL2lx31XZNrUwV3i/Wmia68IshC3NDFT4xe5DX+Jcg/KTTNBLUr+aT3h5yEJS5hMxhrvOJNqRyiDB5rQzkc0i6Rf1q5t7Ik3NEr3DCrIMkPSn8DCmSV3pUmCkJ/UGoNyntFA5M89+oTTiAn8OHf16/6nSmUpV1sy7rRiw997jImhcdcqxWwxT8k5TybthwyRhnEU51VLXkhMk+0E52roYF0r1walnDHOlCpyeC0CiqrCFQWpgD7VtPZRZZA1FaXRmczIG2kvk6UpFV1uCR/neTVFgNbh0NkshaDi5p97WXo0FS2REHdRG0M7I9I1e3zmtTX1HdDcrd3OveVmyE6/Oo98cxhcdKTvO5ycLcZGFusjA3WZibLMxNFuYmC3OThbnJwtxkYW6yMDdZmJvYwt3/Jrznlo4qLKV84JaOPoe5pVleOks66H8hvAD4C9xKs5cITE/EAAAAAElFTkSuQmCC' },
            { name: 'T22_ViolentPoliticalParty', type: 'ViolentPoliticalParty', title: 'Violent Political Party', checked: true, width: 6, height: 14, icon: 'iVBORw0KGgoAAAANSUhEUgAAACwAAABMCAYAAAAWcFVbAAAACXBIWXMAAC4jAAAuIwF4pT92AAACyUlEQVRoge2ZQW7bMBBFv4zu030MNF1EKLqJ9924N8gBYtS5QXqCJjfoDSJDF0iAHsA5QdNlvGkKaF/nBCookK4qkdIMxZEW5QMEJzEzeh4MaXGIsixb19PxxYnt72NeT8cXr233m6HBbr5aA/ipXydB3/v3br66at4/UdYGPfC29v5lWuTZmNIWhw2Aq7TI9+qXWcdAxe2YmXY4fAJwSFqV4d18tQDwvSOWeKYdsnU2aZGvE1XcAJ4BHPXEFJMmyB4cTEk8EwaLlAdDVmU4m+liXgL4QfinoNJM2eq+VYankPaRRX2VGFPaVxbNdVgHU5NwC+CMELCaiLv56lx/2IW+jvQH3+tYd2mRPw6VtQp7SL8QVhjFg475xVfWKQy+dEicsp3CNfFMf9tMLov6pHOhA2ykTSmyoAhjHGmSLKjCmu0Qow7IsmAKX4e0rPHIGUwS1k9zbwaKuWB9AVEzfB5Mr82ZXkJJUIUXgsKs+FRhcgak4Uy6KOwDVVhqDTbsqQOpwqy1ksmLefQMJpwW+Z1+jJSAtbHl1PBXIWFWuXGEyXXGJNPfpCR6n4fB29b4osptSanl3gyPIAu9xdpSMt0pPJKsgSTtFBaSfeh5v1faKszZigP4qF9dy57a7n8G8DYt8iVh59IpbetLePcNTk/T8sO7938HfLu/Kcuy9eBP3NhaJ2JfQ5ssWwVLkuaSYxUeIt3X0CbLciFubFvlMZtC1uAjrRraJ+oQJoQspyTqcMpD9YdVM/tmqOwQOH0P0x9WWbifQtbQI32YfPV1eG3pDY8ia3BI/6qvFLaGtsn0qLIOaZXAxT9rsePodu1zTKvCNa5r3+Nel0PQ8+OQwq4rbvOlicLSRGFporA0UViaKCxNFJYmCksThaWJwtJEYWmisDRRWJr/XvgycLwWQYXLssykpYOXhLT0K4mgSjpJEvWjOvAJB4A/ddnDVSxj42AAAAAASUVORK5CYII=' }
          ]
        }
      ]
    },
    getCountryMap: (countryEvents, startYear, endYear, subGtdInd) => {
      if (!countryEvents || !countryEvents.features || countryEvents.features.length === 0 || !startYear || !endYear || !subGtdInd || subGtdInd.length === 0) {
        console.log('Parameter error, cant get the GTD Country Sum')
        return
      }
      let countryMap = new Map()
      countryEvents.features.filter(item => item.properties.YEAR >= startYear && item.properties.YEAR <= endYear).forEach(item => {
        if (!countryMap.get(item.properties.SOVEREIGNT)) {
          countryMap.set(item.properties.SOVEREIGNT, {
            byYear: [],
            properties: subGtdInd.reduce((pre, cur) => ({ ...pre, [cur]: 0 }), {})
          })
        }
        //add yearly data
        let yearly = countryMap.get(item.properties.SOVEREIGNT).byYear
        yearly.push(item.properties)
        //add property data
        let it = countryMap.get(item.properties.SOVEREIGNT).properties
        let sum = countryMap.get(item.properties.SOVEREIGNT).properties.sum || 0
        subGtdInd.forEach(ind => {
          if (it.hasOwnProperty(ind)) {
            it[ind] += item.properties[ind]
            sum += item.properties[ind]
          }
        })
        it['sum'] = sum
        it['SOVEREIGNT'] = item.properties.SOVEREIGNT
      })
      return countryMap
    },
    getCountryByYear: (selectedCountries, countryMap) => {
      if (!selectedCountries || selectedCountries.length === 0 || !countryMap || countryMap.keys().length === 0) {
        return
      }
      return selectedCountries.map(country => {
        let find = countryMap.get(country)
        return {
          SOVEREIGNT: country,
          properties: find && find.properties,
          yearly: find && find.byYear
        }
      })
    },
    getCountryFeature: (countryMap) => {
      let res = { type: 'FeatureCollection', features: {} }
      if (!countryMap || countryMap.keys().length === 0) {
        return
      }
      res.features = Array.from(countryMap.values()).map(item => {
        return {
          type: "Feature",
          geometry: null,
          properties: item.properties
        }
      })
      return res
    },
    getSumFromProperty: (properties, subGtdInd) => {
      if(Object.keys(properties).length === 0 || !subGtdInd || subGtdInd.length === 0){
        return 0
      }
      let sum = 0
      subGtdInd.forEach(ind => {
        if(properties.hasOwnProperty(ind)){
          sum += parseInt(properties[ind])
        }
      })
      return sum
    },
    //return [ { color: 'BDFFA4', min: 10, max: 100 }, { color: '', min: 101, max: 200 }]
    getCountryLegends: (countryMap) => {
      if (!countryMap || countryMap.keys().length === 0) {
        return
      }
      let max = 0
      Array.from(countryMap.values()).forEach(item => {
        if (item.properties.sum > max) {
          max = item.properties.sum
        }
      })
      return TocUtils.Common.getLegends(TocUtils.GtdUtil.colorPalette, max)
    },
    getEventTypeTitle: (eventName, level) => {
      if (!eventName || !level) {
        return ''
      }
      let source = TocUtils.GtdUtil.getEvents()
      if (level === 2) {
        source = source.reduce((pre, cur) => [...pre, ...cur.children], [])
      }
      let find = source.find(it => it.name === eventName)
      return find && find.title
    },
    getSubEventTypeObject: (eventName, field='name') => {
      if (!eventName ) {
        return ''
      }
      let source = TocUtils.GtdUtil.getEvents()
      source = source.reduce((pre, cur) => [...pre, ...cur.children], [])
      return source.find(it => it[field] === eventName)
    }
  },
  Common: {
    layerTypes: [
      {
        title: 'Armed Conflict Location & Event Data(ACLED)',
        name: 'ACLED',
        id: 1
      }, {
        title: 'Stockholm Int. Peace Research Institute (SIPRI)',
        name: 'SIPRI',
        id: 2
      }, {
        title: 'Global Terrorism Database (GTD)',
        name: 'GTD',
        id: 3
      }
    ],
    countryMap: () => {
      if (!countryCentroids || Object.keys(countryCentroids).length === 0) {
        return {}
      }
      return Array.from(Object.entries(countryCentroids)).reduce((pre, cur) => { return { ...pre, [cur[0]]: cur[1].name } }, {})
    },
    getLegends: (colorPalette, max) => {
      if (!max || !colorPalette || colorPalette.length < 6) {
        return
      }
      let res = []
      for (let i = parseInt(max / 32), j = 0; i < max;) {
        res.push({
          color: colorPalette[j],
          min: j === 0 ? 0 : res[j - 1].max + 1,
          max: j === 5 ? max : i
        })
        i = i * 2
        j++
      }
      return res
    },
    getCountryCodeMap: (ds) => {
      if(!ds || !ds.features){
        return
      }
      let map = new Map()
      ds.features.forEach(({attributes}) => {
        if(!attributes || !attributes.SIPRI_Country || !attributes.ISO){
          return
        }
        if(!map.get(attributes.ISO)){
          map.set(attributes.ISO, attributes.SIPRI_Country)
        }
      })
      return map
    },
    filterColor: (res, field) => { //depends on sort
      let newArr = [];
      let newBlendArr = [];
      if (res.features) {
        const acledMax = res.features[res.features.length - 1].properties.event_type_total
        const arr = [{ lable: `0-${parseInt(acledMax / 32)}` }]
        for (let i = acledMax / 32; i < acledMax;) {
          arr.push({ lable: parseInt(i) + '-' + parseInt(i * 2) })
          i = i * 2
        }
        newArr = arr.map((item, ind) => {
          if (ind == 0) {
            return { ...item, color: "#F4F535" }
          } else if (ind == 1) {
            return { ...item, color: "#ECD37E", minLable: item.lable.split('-')[0] }
          } else if (ind == 2) {
            return { ...item, color: "#F59126", minLable: item.lable.split('-')[0] }
          } else if (ind == 3) {
            return { ...item, color: "#F46420", minLable: item.lable.split('-')[0] }
          } else if (ind == 4) {
            return { ...item, color: "#F2331C", minLable: item.lable.split('-')[0] }
          } else if (ind == 5) {
            return { ...item, color: "#EF0C1A", minLable: item.lable.split('-')[0] }
          }
        })
        const blendArr = []
        newArr.forEach(item => {
          blendArr.push(Number(item.minLable), item.color)
        })
        newBlendArr = ['step', ['get', field]].concat(blendArr.slice(1))
      }
      return {
        newArr,
        newBlendArr
      }
    },
    debounce: (fn, timeout = 300) => {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          fn.apply(this, args)
        }, timeout)
      }
    }
  }
}


export default TocUtils;