import React from "react";
import LineChart from './LineChart';
import conversionFun from "../../../utils/conversionFun";
import coverSubEventType from "../../../utils/SubEventType";

const InfoInner = (props) => {
  const { StartYear, EndYear, Class, Switch, chartData, lineYear, InfoData } = props
  const { startYear, endYear, startMonth, endMonth } = conversionFun(StartYear, EndYear)
  
  return (
    <>
      {
        InfoData && InfoData.length > 0 && InfoData.map((item, ind) => {
          return (
            <span className={'tocModalIcon'} key={ind}>
              <div className="tocModalIconHeader">
                <div className="countryBox">
                  {item?.properties?.SOVEREIGNT}
                </div>
                {ind === 0 && <div className="dateBox">{StartYear} - {EndYear}</div>}
              </div>
              <div className="toc-sep"></div>
              <div className="event_type">
                <span className="event_type_left">{item?.properties?.event_type_total}</span>
                <span className="event_type_right">{Class.name}</span>
              </div>
              <div className="sub_event_type">
                {Class.types && Class.types.map((v, i) => {
                  return <div className="sub_event_typeInner" key={i}>
                    <div className="sub_event_typeLeft">
                      {
                        !v.checked ? 0 : item && item?.properties && (item?.properties[coverSubEventType(v.name)])
                      }
                    </div>
                    <div className="sub_event_typeRight">{v.name}</div>
                  </div>
                })
                }
              </div>
            </span>
          )
        })
      }
      {
        InfoData.length ? !Switch &&
          <div className='lineChart'>
            <p>{Class.name}</p>
            <LineChart
              width="98%"
              height='200px'
              chartData={chartData}
              chartDate={lineYear}
            />
          </div> : ''
      }

    </>
  )
}

export default InfoInner