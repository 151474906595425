import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { setGtdSwitch, setGtdStartYear, setGtdEndYear, setGtdInd, setSubGtdInd, setGtdEventSource,
    setGtdMapEvents, setGtdCountryArr, setGtdLegend } from '../../../../slices/toc/gtdSlice';
import { setOpenLeftPanel } from '../../../../slices/infographSlice';
import { setIsLoading } from "../../../../slices/mapSlice"
import Slider from '../../../../components/Slider/Slider'
import { Checkbox, Radio } from 'antd';
import AntSwitch from '../../../../components/AntSwitch'
import TocUtils from '../../../../utils/toc'
import { dateRangeForSipri } from '../../../../utils/DateRangeForSlider';

const { GtdUtil, Common } = TocUtils

function Gtd() {
  const dispatch = useDispatch();
  const gtdSwitch = useSelector(state => state.gtd.gtdSwitch)
  const gtdStartYear = useSelector(state => state.gtd.gtdStartYear)
  const gtdEndYear = useSelector(state => state.gtd.gtdEndYear)
  const gtdInd = useSelector(state => state.gtd.gtdInd)
  const subGtdInd = useSelector(state => state.gtd.subGtdInd)
  const gtdEventSource = useSelector(state => state.gtd.gtdEventSource)
  const [ gtdEvent, setGtdEvent ] = useState([])
 
  const API_BASE = process.env.REACT_APP_API_URL
  
  const [dateRange, setDateRange] = useState([]);

  // events type in level1
  useEffect(() => {
    let tocEvent = GtdUtil.getEvents()
    setGtdEvent(tocEvent)
    dispatch(setGtdInd('attacks'))
  }, [])

  useEffect(()=>{
    let country=`SOVEREIGNT='Brazil'`;
    let outfields=`YEAR`
		let api=`${API_BASE}/arcgis/queryGtdCountryEvent/${country}/${outfields}`
    fetch(api)
    .then(response=>response.json())
    .then(res=>{
      setDateRange(dateRangeForSipri(res))
    })
  },[])

  useEffect(() => {
    if(!gtdSwitch){
      let whereStr = '1=1'
      let outFields = '*'
      fetch(`${API_BASE}/arcgis/queryGtdCountryEvent/${whereStr}/${outFields}`)
        .then(result => result.json())
        .then(data => {
          dispatch(setGtdEventSource(data))
        });
    }else{
      let whereStr = `(iyear between ${gtdStartYear} and ${gtdEndYear}) `
      let outFields = 'country_txt,attacktype1_txt,latitude,longitude'
      let condtype = '1=0'
      subGtdInd.forEach(ind=>{
        let title = GtdUtil.getEventTypeTitle(ind, 2)
        if(!title){
          return
        }
        condtype += ` or attacktype1_txt='${title}' `
      })
      whereStr += ` and (${condtype})`
      fetch(`${API_BASE}/arcgis/queryGtdPointEventLayer/${whereStr}/${outFields}`)
        .then(result => result.json())
        .then(data => {
          dispatch(setGtdEventSource(data))
        });
    }
    
  }, [dispatch, setGtdEventSource, gtdSwitch, gtdStartYear, gtdEndYear, subGtdInd])

  useEffect(() => {
    let selectedEvent = gtdEvent.find(it => it.name === gtdInd)
    if(selectedEvent){
      dispatch(setSubGtdInd(selectedEvent.children.map(ch => ch.name)))
    } 
  }, [gtdEvent, gtdInd])

  useEffect(() => {
    if(!gtdEventSource || !subGtdInd || subGtdInd.length === 0){
      return
    }
    if(!gtdSwitch){
      let countryMap = GtdUtil.getCountryMap(gtdEventSource, gtdStartYear, gtdEndYear, subGtdInd)
      dispatch(setGtdMapEvents(GtdUtil.getCountryFeature(countryMap)))
      dispatch(setGtdLegend(GtdUtil.getCountryLegends(countryMap)))
    }
  }, [gtdEventSource, gtdSwitch, gtdStartYear, gtdEndYear, subGtdInd])

  const subChecked = useCallback((subitem) => subGtdInd && subGtdInd.length > 0 && subGtdInd.includes(subitem), [subGtdInd])

  const handleChangeGtd = () => {
    dispatch(setGtdSwitch(!gtdSwitch))
    dispatch(setIsLoading({isLoading :true}))
    dispatch(setGtdEventSource({}))
    !gtdSwitch && dispatch(setGtdCountryArr([]))
  };

  const handleGtdRadio = e => {
		const name = e.target.value;
		dispatch(setGtdInd(name))
    dispatch(setIsLoading({isLoading :true}))
	}

  const handleCheckBox = useCallback(subitem => {
    if(!subGtdInd || subGtdInd.length == 0){
      return 
    }
    dispatch(setIsLoading({isLoading :true}))
    let findIdx = subGtdInd.findIndex(sub => subitem.name === sub)
    if(findIdx >= 0 && subGtdInd.length > 1){
      let temp = [...subGtdInd]
      temp.splice(findIdx, 1)
      dispatch(setSubGtdInd([...temp]))
    }else{
      dispatch(setSubGtdInd([...subGtdInd, subitem.name ]))
    }
  }, [dispatch, subGtdInd, setSubGtdInd, setIsLoading])


  
  const slider_result = (start, end) => {
    dispatch(setGtdStartYear(start))
    dispatch(setGtdEndYear(end))
  }

  return (
    <div className="mapLayerContent">
      <div className="sliderBox">
        { dateRange && dateRange.length > 0 && 
          <Slider
            data={dateRange}
            range={{ draggableTrack: true }}
            step={1}
            result={slider_result}
            defaultValue={[0, dateRange.length - 1]}
          />
        }
      </div>
      <div className="chooseView">
        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Country View</Grid>
            <Grid item>
              <AntSwitch checked={gtdSwitch} onChange={handleChangeGtd} name="checkedC" />
            </Grid>
            <Grid item>Point View</Grid>
          </Grid>
        </Typography>
      </div>
      <div className="mapLayerContentHr"></div>
      <Radio.Group onChange={handleGtdRadio} value={gtdInd} >
      {
        gtdEvent && gtdEvent.map((item, index) => {
          return (
            <div className="mapLayerEventType" key={index}>
              <Radio value={item.name}>
                <span className="labelMark">{item.title}</span>
              </Radio>
              <div className="layerSubEventType">
                {
                  gtdInd === item.name && item.children && item.children.map((v, i) => {
                    return (
                      <div key={i}>
                        <Checkbox onChange={(e) => handleCheckBox(v)} checked={subChecked(v.name)} >
                          <span className="labelMark">{v.title}</span>
                        </Checkbox>
                      </div>
                    )
                  })
                }
              </div>
              <div className="mapLayerEventTypeHr"></div>
            </div>
          )
        })
      }
      </Radio.Group>
    </div>
  )
}


export default Gtd