import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import './GtdPointPopup.css'

function PointPopup(props) {
  const { pointEvents, category } = props;
  const [events, setEvents] = useState([])
  const [ind, setInd] = useState(0)

  useEffect(() => {
    if (!pointEvents) {
      return
    }
    const map = new Map()
    pointEvents.features.forEach(({ properties }) => {
      if (!map.get(properties.attacktype1_txt)) {
        map.set(properties.attacktype1_txt, [])
      }
      map.get(properties.attacktype1_txt).push(properties)
    })
    setEvents(Array.from(map))
  }, [pointEvents])

  const handleClick = (index) => {
    setInd(index);
  }
  
  return (
    <>
      <div className="pointPopupBox">
        <div className="pointPopupHead">
          <div className="pointPopupNum">
            {pointEvents?.features?.length}
          </div>
          <div className="pointPopupTitle">
            {category}
          </div>
        </div>
        <div className="pointPopupContent">
          {
            events && events.map((v, i) => {
              return (
                <div className="pointPopupConIcon" key={i}>
                  <div className={ind == i ? 'contentIconTitle contentIconTitleActive' : 'contentIconTitle'}>
                    <div className="contentIconTitleLeft">
                      <div className="contentIconTitleLeftNum">
                        {v[1].length}
                      </div>
                      <span className="contentIconTitleContent">{v[0]}</span>
                    </div>
                    <div className="contentIconTitleRight" onClick={() => { handleClick(i) }}>
                      {
                        ind == i ? <FontAwesomeIcon className="caret" icon={faAngleUp} /> :
                          <FontAwesomeIcon className="caret" icon={faAngleDown} />
                      }
                    </div>
                  </div>
                  <div className="contentCon">
                    {
                      ind === i && v[1] && v[1].map((subv, subi) => {
                        return (
                        <div key={subi} className="con">
                          <div className="contentIconContent">
                            <div>
                              <div className='title'>Date:</div>
                              <div>{subv?.summary?.split(':')[0]}</div>
                            </div>
                            <div>
                              <div className='title'>Target:</div>
                              <div>{subv?.targtype1_txt}</div>
                            </div>
                            <div>
                              <div className='title'>Weapon:</div>
                              <div>{subv?.weaptype1_txt}</div>
                            </div>
                          </div>
                          <div>
                            <div className='title'>Perpetrator:</div>
                            <div>TBD</div>
                          </div>
                          <div>
                            <div className='title'>Summary:</div>
                            <div>{subv?.summary}</div>
                          </div>
                        </div>
                      )})
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  )
}

export default PointPopup;