/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { createSlice } from '@reduxjs/toolkit'
const mapSlice = createSlice({
  name: 'map',
  initialState: {
    uri: 'cki6711763a8119qntg3w2dsy',
    countryCounts: {},
    selectedArticleCountry: '',
   // setCountryFilter: '',
    isLoading: true,
    fsi_selected: false,
    mapMarker: true,
    IUUAOI: true,
    isScoreByCountryChecked: false,
    isFirstVisitChecked: false,
    firstVisit_dropdown: '-1',//dropdown value
    AOI_dropdown: 'sc_aoi_0',//dropdown value
    dateSelectedFV:  '2022 02 19',
    dateSelectedAOI: '2022 02 19',
    layers: []
  },
  reducers: {
    setMapLayer(state, action) {
      state.layers = [...state.layers, action.payload]
    },
    removeMapLayer(state, action) {
      state.layers = state.layers.filter(layer => layer !== action.payload)
    },
    setFsiSelected(state, action) {
      state.fsi_selected = action.payload
    },
    setAgoSelected(state, action) {
      state.ago_selected = action.payload
    },
    setCountryCounts(state, action) {
      const { countryCounts } = action.payload;
      state.countryCounts = countryCounts;
    },
    setIsLoading(state, action) {
      //isLoading: action.isLoading
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    },
    setSelectedArticleCountry(state, action) 
    { 
      //const { selectedArticleCountry } = 10;

      state.selectedArticleCountry = action.payload;
    },
    setMapMarker(state, action) {
      state.mapMarker = action.payload;
    },
    setIUUAOI(state, action) {
      state.IUUAOI = action.payload;
    },
    setFirstVisit_dropdown(state, action) {
      state.firstVisit_dropdown = action.payload;
    },
    setAOI_dropdown(state, action) {
      state.AOI_dropdown = action.payload;
    },
    setScoreByCountry(state, action) {
      state.isScoreByCountryChecked = action.payload;
    },
    setDateFirstVisit(state, action) {
      state.dateSelectedFV = action.payload;
    },
    setDateAreaInterest(state, action) {
      state.dateSelectedAOI = action.payload;
    },
    setIsFirstVisitChecked(state, action) {
      state.isFirstVisitChecked = action.payload;
    },
    setSliderResult(state, action) {
      state.slider_result = action.payload
    },
  }
})

export const { 
  setSliderResult, 
  setMapLayer, 
  removeMapLayer, 
  setFsiSelected, 
  setAgoSelected, 
  setCountryCounts, 
  setIsLoading, 
  setSelectedArticleCountry,
  setMapMarker, 
  setIUUAOI, 
  setFirstVisit_dropdown, 
  setAOI_dropdown,
  setScoreByCountry,
  setDateFirstVisit,
  setDateAreaInterest, 
  setIsFirstVisitChecked
} = mapSlice.actions;


export default mapSlice.reducer;