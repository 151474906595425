import { MapMouseEvent } from 'mapbox-gl';
import { useState, useCallback, useEffect } from 'react';

const DefaultSourceId = 'ago_country'
const DefaultBorderColor = '#1eedc9'
const DefaultBorderWidth = 3
const SourceField = 'officalNam'
/**
 * Use it to manage the state of selected countries borders on the mapbox container
 */
const useCountry = ( multi = false ) => {
  const [ countrylayers, setCountryLayers ] = useState([])
  
  const _unpaint = useCallback((map) => {
    if(!countrylayers || countrylayers.length === 0){
      return []
    }
    try {
      countrylayers.forEach(layer => {
        if(map.getLayer(layer)){
          map.removeLayer(layer)
        }
      })
    } catch (error) {
      console.error(error)
    }
    return []
  }, [countrylayers])

  const _paint = (map, countries, sourceId=DefaultSourceId) => {
    return countries.map(country=>{
      const layerId = `country-border-${ multi ? country : 'singlecountry'}`
      if(!map.getLayer(layerId)){
        map.addLayer({
          'id': layerId,
          'type': 'line',
          'source': sourceId,
          'filter': ['in', SourceField, country],
          'paint': {
            'line-color': DefaultBorderColor,
            'line-width': DefaultBorderWidth
          }
        })
      }else{
        map.setFilter(layerId, ['in', SourceField, country]) 
      }
      return layerId
    })
  }

  const paintLayers = useCallback((map, countries) => {
    if(!map){
      return
    }
    if(countrylayers && countrylayers.length > 0){ 
      _unpaint(map)
    }
    setCountryLayers(_paint(map, countries))
  }, [countrylayers])
  
  return { paintLayers }
}

export default useCountry