/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Recaptcha from 'react-recaptcha';



// import setShow


export default class LoginModal extends Component{
  constructor(props){
    super(props);
    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();
    this.passwordTwoRef = React.createRef();
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.orgRef = React.createRef();
    this.state = {
      show: false, 
      registerStatus: this.props.registerStatus, 
      registerStatusText: this.props.registerStatusText,
      passwordsMatch: true,
      isVerified: false
    };
  }

  setShow = (newShow) => {this.setState({show: newShow})}
  handleClose = () => this.setShow(false);
  handleShow = () => this.setShow(true);

  renderPasswordError = () => {
    //const { registerStatus, registerStatusText} = this.props;

    if(!this.state.passwordsMatch) {
      return(
      <div className="errorMessage">
        <Alert variant="danger">Passwords do not match</Alert>
      </div>
      );
    }
  }

  renderRegisterButton = () => {
    // let password = this.passwordRef.current.value.trim();
    if (this.state.isVerified){  // renders only if reCAPTCHA passed
      if (this.state.passwordsMatch){
        return(
            <Button variant="primary" onClick={this.handleSubmit}>Register</Button>
        );
      }
      else {
        return (
            <Button variant="primary" disabled>Register</Button>
        );
      }
    }
  }
  handleChange = (e) => {
    e.preventDefault();
    let password = this.passwordRef.current.value.trim();
    let passwordTwo = this.passwordTwoRef.current.value.trim();
    this.setState({passwordsMatch: password===passwordTwo });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.isVerified) {
          // pull inputs from dom
    const firstname = this.firstNameRef.current.value.trim();
    const lastname = this.lastNameRef.current.value.trim();
    const org = this.orgRef.current.value.trim();
    const username = this.usernameRef.current.value.trim();
    const password = this.passwordRef.current.value.trim();
    //const passwordTwo = this.passwordTwoRef.current.value.trim();

    //console.log(username, password, org, firstname, lastname)
    // axios request to backend
    // console.log(`username is: ${username}`)
    // if(this.state.passwordsMatch){
        this.props.registerUser(username, password, org, firstname, lastname);
    // }
    // potentially put error handling here for wrong username/password

    // console.log(`username.value is: ${username.value}`)
    } else {
      alert('Please verify that you are human')
    }
  }
  onloadCallback = () => {
    //console.log('reCAPTCHA successfully loaded')
  }
  verifyCallback = (response) => {
    if (response) {
      this.setState({isVerified: true});
    }
  }

  render(){
    return(
    <>
      <Button variant="primary" onClick={this.handleShow} style={{height:"33px", display:"none"}}>
        Register
      </Button>

      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* form goes here */}
          <form >
            <label htmlFor="firstName">Enter your first name:&nbsp;</label>
            <input type="text" name="firstName" id="firstName" required ref={this.firstNameRef}/>

            <label htmlFor="lastName">Enter your last name:&nbsp;</label>
            <input type="text" name="lastName" id="lastName" required ref={this.lastNameRef}/>

            <label htmlFor="org">Enter your organization:&nbsp;</label>
            <input type="text" name="org" id="org" required ref={this.orgRef}/>

            <label htmlFor="email">Enter your email:&nbsp;</label>
            <input type="text" name="email" id="email" required ref={this.usernameRef}/>

            <label htmlFor="password" >Enter your password:&nbsp;</label>
            <input  type="password" name="password" id="password" required ref={this.passwordRef} onChange={this.handleChange} />

            <label htmlFor="passwordTwo" >Enter your password again:&nbsp;</label>
            <input  type="password" name="passwordTwo" id="passwordTwo" required ref={this.passwordTwoRef} onChange={this.handleChange} />

            <Recaptcha
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              render="explicit"
              verifyCallback={this.verifyCallback}
              onloadCallback={this.onloadCallback}
            />
          </form>
          {this.renderPasswordError()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          {this.renderRegisterButton()}
        </Modal.Footer>
      </Modal>
    </>
  ); 
}
}
  // export default LoginModal;