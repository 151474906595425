/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */

import React from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setInfographData } from "../../../slices/infographSlice";
import { setIsLoading } from "../../../slices/mapSlice";
import { setCountryList, setCountryFilter } from "../../../slices/filtersSlice";
import { setSipriCountry } from "../../../slices/toc/sipriSlice";
import useCountryPoly from '../../../hooks/useCountryPoly';


import AcledMap from './AcledMap';
import SipriMap from './SipriMap';
import GTDMap from './GTDMap';

const TocMapboxContainer = (props) => {
  const sipri_selected = useSelector(state => state.toc.tocLayer) === 'SIPRI'
  const gtd_selected = useSelector(state => state.toc.tocLayer) === 'GTD'
  const [ countryPoly ] = useCountryPoly()
  
  if(sipri_selected){
    return <SipriMap  {...props} countryPoly={countryPoly}/>
  }
  
  if(gtd_selected){
    return <GTDMap {...props} countryPoly={countryPoly}/>
  }
  
  return (
    <AcledMap {...props} countryPoly={countryPoly}/>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setCountryList,
    setInfographData,
    setIsLoading,
    setCountryFilter,
    setSipriCountry,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    selectedCountry: state.filters.countryFilter,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TocMapboxContainer);
