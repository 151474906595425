/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
//import {rootReducer} from 'reducers/index';
//import App from './containers/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';

import Root from './containers/Root';

import './index.css';
import customConfigureStore from "./store/configureStore";

/* setup redux store */
//const store = configureStore();
const store = customConfigureStore()

render(
  <Router basename={process.env.PUBLIC_URL}>
    <Root store={store} />
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
