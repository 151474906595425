import React from "react";

const PointLegend = (props) => {
    const {uniqueValueInfos} = props
    return (
        <>
            {
                uniqueValueInfos ? uniqueValueInfos.map((v,i)=>{
                    return (
                        <div className="TOCLegendIcon" key={i}>
                            <img width={v.symbol.width} height={v.symbol.height} src={'data:image/png;base64,'+v.symbol.imageData} />
                            <span>{v.value}</span>
                        </div>
                    )
                }):null
            }
        </>
    )
}

export default PointLegend