/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


// ------------------------- AUTH ------------------------------------------ //

export const USER_REQUEST = 'USER_REQUEST';
export const USER_RECEIVED = 'USER_RECEIVED';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';

// ------------------------- ERRORS ---------------------------------------- //

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

// ------------------------- Registration ---------------------------------- //
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

// ------------------------- MAP CONTROLS ---------------------------------- //
export const SET_MAP_LAYER = 'SET_MAP_LAYER';
//export const SET_INFOGRAPH_DATA = 'SET_INFOGRAPH_DATA';

// ------------------------- Filters ---------------------------------- //
export const SET_COUNTRY_FILTER = 'SET_COUNTRY_FILTER';
export const SET_LANGUAGE_FILTER = 'SET_LANGUAGE_FILTER';
export const SET_COUNTRY_DATA = 'SET_COUNTRY_DATA';


// ------------------------- MAP ---------------------------------- //
export const SET_INFOGRAPH_DATA = 'SET_INFOGRAPH_DATA';
export const SET_COUNTRY_COUNTS = 'SET_COUNTRY_COUNTS';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_SELECTED_ARTICLE_COUNTRY = 'SET_SELECTED_ARTICLE_COUNTRY';
