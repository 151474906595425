/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


function Footer (){

    return (
	<div className="footer">
    
            <div className="footerText footerLeftBtn">
                Legend of Acronyms
            </div>
        
            <div className="footerText copyright">
                Copyright © 2020. FIU Security Research Hub | Florida International University
            </div>
			<div className="footerText copyrightMobile">
				Copyright SRH | FIU
			</div>
			<div className="footerText">
			<img className="footerImg" src={process.env.PUBLIC_URL + '/images/fiu-footer-logo.svg'} alt="Florida International Uni versity" />
			</div>
		 
	</div>
    );
}

export default Footer;