/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */
//https://srh.fiu.edu/pulse/hyperion_pulse.json

// Copied from DashboardMap

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setInfographData, setUpdateInfograph } from "../../slices/infographSlice";
import { setIsLoading } from "../../slices/mapSlice";
import { setCountryList, setCountryFilter } from "../../slices/filtersSlice";
import { countryCentroids} from '../../utils/CountryLookups';
import { countryCentroidsSwitch} from '../../utils/CountryLookups_switch';

import { layerInstructions } from './MasterLayerConfig'

const ReactMapboxComponent = (props) => 
{
    const {uri, fsi_selected, ago_selected, selectedCountry, layers, mapMarker, IUUAOI, 
      firstVisit_dropdown, AOI_dropdown, isScoreByCountryChecked, isFirstVisitChecked} = props
    
  // const [lng, setLng] = useState(-79.019)
  // const [lat, useLat] = useState(35.759)
  // const [zoom, setZoom] = useState(3)
  const updateInfograph = useSelector(state => state.infograph.updateInfograph);
  const [sourceLoaded, setSourceLoaded] = useState(false);
  const [map, setMap] = useState(null);
  const node = useRef(null);

  const selectedDateFV    = useSelector(state => state.map.dateSelectedFV);
  const selectedDateAOI    = useSelector(state => state.map.dateSelectedAOI);
  //const myPulseData = useSelector(state => state.infograph.pulseData);
  const selectedChallenge   = useSelector(state => state.filters.irChallengeType);
  const dispatch = useDispatch();

  //const [pulseData, setPulseData]    = useState(null);
  //const [isIUUFishing, setisIUUFishing] = useState(false);
    var isIUUFishing = false;

  const popup = new mapboxgl.Popup({  
    focusAfterOpen: true,
    closeButton: true,
    closeOnClick: true,
    closeOnMove: false,
    });

  const flyToCountry = useCallback((country, challenge) => 
  {
    var centroid = ''
    for (const value of Object.values(countryCentroids)) 
    {
      if (value.name === country) 
      {
        centroid = value
        break
      }
    }   
    if (centroid && 'bbox' in centroid) 
    {
      if(challenge === 'IUU Fishing')
      {
          if(!isIUUFishing)
          {
            map.fitBounds([[0, 0], [-110, -45]]);
            //setisIUUFishing(true);
            isIUUFishing = true;
            return;
          }
      }
      else
      {
        isIUUFishing = false;
        //setisIUUFishing(false);
      }
        
        map.fitBounds(centroid.bbox);
    } 
    else 
    {
      window.alert(`Country location undefined for country ${country}`);
    }
  }, [map])

  const createMapOverlay = useCallback((property) => 
  {
    if (map.getLayer("active-overlay"))
      map.removeLayer('active-overlay');

    map.addLayer({
      id: 'active-overlay',
      source: 'composite',
      'source-layer': 'sevurity_iuuf_ss',
      type: 'fill',
      paint: {
        "fill-color": [
          "interpolate",
          ["exponential", 1.06],
          ["get", property],
          0,
          [
            "match",
            ["get", property],
            "",
            "hsla(0, 3%, 44%, 0.08)",
            "hsla(0, 4%, 67%, 0.14)"
          ],
          0.01,
          "hsl(114, 72%, 63%)",
          50,
          "hsl(41, 100%, 61%)",
          100,
          "hsl(0, 100%, 61%)"
        ],
        "fill-opacity": 0.75,
        "fill-outline-color": "hsl(0, 0%, 0%)"
      },
    }, 'waterway-label'); // This will place the FSI layer above waterway labels
    // but below admin labels so country names, etc. will appear on top
    // this filter only renders those countries where the property has a value

    // e.g. it will prevent Antractica from rendering in black

    map.setFilter("active-overlay", ["all", ["has", property]]);
    map.setFilter("country-helper-layer", ["all", ["has", property]]);
  }, [map])

  const countryByScoreMapOverlay = useCallback((property) => 
  {
    if(map.getLayer('countryByScore-overlay'))
      map.removeLayer('countryByScore-overlay');

    map.addLayer({
      id: 'countryByScore-overlay',
      source: 'composite',
      'source-layer': 'sevurity_iuuf_ss',
      type: 'fill',
      paint: {
        "fill-color": [
          "interpolate",
          ["exponential", 1.06],
          ["get", property],
          0,
          [
            "match",
            ["get", property],
            "",
            "hsla(0, 3%, 44%, 0.08)",
            "hsla(0, 4%, 67%, 0.14)"
          ],
          .99,
          "hsl(114, 72%, 63%)",
          2.5,
          "hsl(41, 100%, 61%)",
          5,
          "hsl(0, 100%, 61%)"
        ],
        "fill-opacity": 0.75,
        "fill-outline-color": "hsl(0, 0%, 0%)"
      },
    }, 'waterway-label'); 
    map.setFilter("countryByScore-overlay", ["all", ["has", property]]);
    map.setFilter("country-helper-layer",   ["all", ["has", property]]);
  }, [map])


  useEffect(() => 
  {
    const country_labels = Object.values(countryCentroids).map((el) => {
      return el.name;
    })

    dispatch(setCountryList(country_labels));
   // dispatch(setMyPulsehData(pul));
  }, [dispatch])

  function parseJson(input)
  {
    var myArr = [];
    //Object.values(input).some(value => 
   // for (const [key, value] of Object.entries(input))
    input.forEach(value =>
    {
      myArr[value.country.toUpperCase()] = value;
    });
    return myArr;
  }

  useEffect(() => 
  {
    // component did mount and component did update
    mapboxgl.accessToken = 'pk.eyJ1IjoiZml1LXNyaCIsImEiOiJja2h4cGQ3dmgwN2l0MnlwY2tncXpkbXljIn0.ew-KlA532qhxLq0rbSbseQ';
    const map = new mapboxgl.Map({
      container: node.current,
      style: 'mapbox://styles/fiu-srh/' + uri, // style to init the map with
      //style: 'mapbox://styles/mapbox/satellite-v9',
      center: [-79.019, 35.759],
      zoom: 3
    });
    map.on("load", () => 
    {
       
      const API_BASE = process.env.REACT_APP_API_URL;
      // console.log("fetch from :--------------------" + `${API_BASE}/pulse/daily`);
      /* fetch(`${API_BASE}/pulse/daily`)
        .then(response => response.json())
        .then((jsonData) => 
        {
         // console.log("get daily pulse successfully:--------------------");
         // console.log(jsonData);

          var myJson = jsonData;
          setPulseData(parseJson(myJson));
        })
        .catch((error) => 
        {
          // handle your errors here
         // console.log("get daily pulse failed:--------------------");
          console.error(error);
        }) */

      setMap(map);
      map.resize();
      map.addSource('galapagosMapPoints_source',
      {//sc_aoi_2
        type: 'geojson',
        data: 'https://services2.arcgis.com/FtJyXM7nBotS8g5t/ArcGIS/rest/services/sc_aoi_maritime_activities_Feb2022/FeatureServer/1/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token='
      });
      map.addSource('argentinaMapPoints_source',
      {//sc_aoi_1
        type: 'geojson',
        data: 'https://services2.arcgis.com/FtJyXM7nBotS8g5t/ArcGIS/rest/services/sc_aoi_maritime_activities_Feb2022/FeatureServer/2/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token='
      });
      map.addSource('peruMapPoints_source',
      {//sc_aoi_3
        type: 'geojson',
        data: 'https://services2.arcgis.com/FtJyXM7nBotS8g5t/ArcGIS/rest/services/sc_aoi_maritime_activities_Feb2022/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=' 
      });

      //first visits
      map.addSource('south_america_pacific_source', 
      {
        type: 'geojson',
        data: 'https://services2.arcgis.com/FtJyXM7nBotS8g5t/ArcGIS/rest/services/windward_first_visit_Feb2022/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnHiddenFields=false&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson'       
      });
      map.addSource('south_america_atlantic_source',
      {
        type: 'geojson',
        data: 'https://services2.arcgis.com/FtJyXM7nBotS8g5t/ArcGIS/rest/services/windward_first_visit_Feb2022/FeatureServer/1/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnHiddenFields=false&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson'
      });
      map.addSource('central_america_source', 
      {
        type: 'geojson',
        data: 'https://services2.arcgis.com/FtJyXM7nBotS8g5t/ArcGIS/rest/services/windward_first_visit_Feb2022/FeatureServer/2/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnHiddenFields=false&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson'
      });

      mapOnClick('argentinaMapPoints_layer1');
      mapOnClick('galapagosMapPoints_layer2');
      mapOnClick('peruMapPoints_layer3');
      mapOnClick('south_america_pacific_id');
      mapOnClick('south_america_atlantic_id');
      mapOnClick('central_america_id');

      function mapOnClick(layer)
      {
        map.on('click', layer, (e) => 
        {
          const coordinates = e.features[0].geometry.coordinates.slice();
          var description = formatDescription(e, coordinates);

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          popup.setLngLat(coordinates).setHTML(description).addTo(map);
        });
      }

      setMouseEnterLeave('argentinaMapPoints_layer1');
      setMouseEnterLeave('galapagosMapPoints_layer2');
      setMouseEnterLeave('peruMapPoints_layer3');
      setMouseEnterLeave('south_america_pacific_id');
      setMouseEnterLeave('south_america_atlantic_id');
      setMouseEnterLeave('central_america_id');

      function setMouseEnterLeave(layer)
      {
        map.on('mouseenter', layer, () => {
          map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', layer, () => {
            map.getCanvas().style.cursor = '';
        });
      }      
    });

    map.on('sourcedata', (e) => { //style.load or sourcedata are events from mapbox
      if (e.isSourceLoaded) {
        // eslint-disable-next-line no-unused-vars
        const { dataType, sourceId, isSourceLoaded } = e;

        setSourceLoaded(true)
        dispatch(setIsLoading(false));
      }
    });
  }, [dispatch, uri])

  function formatDescription(e, coordinates)
  {
    var description = "";

    if(e.features[0].properties.Activity_Type != null && e.features[0].properties.Activity_Type !== '')
    {
      description = //"<p class='popupTitle'>"+e.features[0].properties.Activity_Type+"</p>"+
      "<div class='popupDiv'>"+
      "<div class='iuu-sep'></div>"+
      "<table>"+
      "<tr><td><b>Vessel Name: </b></td><td><a href=https://www.myshiptracking.com/vessels/mmsi-" + e.features[0].properties.MMSI + "' target='_blank'>"  + e.features[0].properties.Vessel_Name+"</a></td></tr>"+
      "<tr><td><b>MMSI: </b></td><td>"+e.features[0].properties.MMSI+"</td></tr>"+
      "<tr><td><b>Call Sign: </b></td><td>"  +e.features[0].properties.Call_Sign+"</td></tr>"+
      "<tr><td><b>Flag: </b></td><td>"       +e.features[0].properties.Flag+"</td></tr>"+
      "<tr><td><b>Class: </b></td><td>"      +e.features[0].properties.Class+"</td></tr>"+
      "<tr><td><b>Subclass: </b></td><td>"   +e.features[0].properties.Subclass+"</td></tr>";

      switch(e.features[0].properties.Activity_Type)
      {
        case "LOW_SPEED_FISHING":
          description = "<p class='popupTitle'>"+e.features[0].properties.Activity_Type+"</p>"+
          description+"<tr><td><b>Start Date: </b></td><td>" +formateDate(e.features[0].properties.Start_Date)+"</td></tr>"+
          "<tr><td><b>Duration: </b></td><td>" +e.features[0].properties.Activity_Duration__hours_  + " hours"+"</td></tr>";
          break;
        case 'MEETING':
          description = "<p class='popupTitle'>"+e.features[0].properties.Activity_Type+"</p>"+
          description+"<tr><td><b>Start Date: </b></td><td>" +formateDate(e.features[0].properties.Start_Date)+"</td></tr>"+
          "<tr><td><b>Second vessel name: </b></td><td>" +e.features[0].properties.Second_vessel_name+"</td></tr>"+
          "<tr><td><b>Second vessel flag: </b></td><td>" +e.features[0].properties.Second_vessel_flag+"</td></tr>"+
          "<tr><td><b>Second vessel subclass: </b></td><td>" +e.features[0].properties.Second_vessel_subclass+"</td></tr>" ;
          break;
        case 'DEVIATION_FROM_PATTERN_FIRST_IN_POLYGON':
          description = "<p class='popupTitle'>First Visit</p>"+
          description+"<tr><td><b>Start Date: </b></td><td>" +formateDate(e.features[0].properties.Start_Date)+"</td></tr>";
          break;
        default:
          break;
      }
      description+="</table>";
    }
    else if(e.features[0].properties.Activity_Type != null && e.features[0].properties.Activity_Type !== '')
    {
      description = "<p class='popupTitle'>"+e.features[0].properties.Activity_Type+"</p>"+
      "<div class='popupDiv'>"+
      "<div class='iuu-sep'></div>"+
      "Name: <a href='https://www.myshiptracking.com/vessels/mmsi-" + e.features[0].properties.MMSI + "' target='_blank'>"+e.features[0].properties.Vessel_Name+ "</a>"+
      "<br/>MMSI: "  +e.features[0].properties.MMSI+
      "<br/>Call Sign: "  +e.features[0].properties.Call_Sign+
      "<br/>Flag: "       +e.features[0].properties.Flag+
      "<br/>Class: "      +e.features[0].properties.Class+
      "<br/>Subclass: "   +e.features[0].properties.Subclass;

      if(e.features[0].properties.Activity_Type === "LOW_SPEED_FISHING")
      {
        description += +"<br/>Start Date: " +formateDate(e.features[0].properties.Start_Date)+
        "<br/>Duration: " +e.features[0].properties.Activity_Duration__hours_  + " hours";
      }
      else
      {
        description += "<br/>End Date: "   +formateDate(e.features[0].properties.End_Date)+
        "<br/><br/>Coords: <div class='popup-coords' style='display: block'>"+   

        coordinates[0]+", "+coordinates[1]+"</div>";
      }
      description +='<div class="iuu-sep"></div>'+
      "</div>";
    }
 
    return description;
  }


  function formateDate(input)
  {
    var date = new Date(input);
    //var time = date.getFullYear()+"-"+(date.getMonth()+1).toString().padStart(2, '0')+"-"+(date.getDate()).toString().padStart(2, '0');
    var options = {year: 'numeric', month: 'short', day: 'numeric'};
    var time = date.toLocaleDateString("en-US", options);
    return time;
  }

  useEffect(() => 
  {
    if (map) 
    {
      /* const API_BASE = process.env.REACT_APP_API_URL;
      
      map.addSource('ago_test', {
        'type': 'geojson',
        'data': `${API_BASE}/arcgis/queryArcGISAPI`
      }) */

      //initialize county hilight layer
      map.addLayer({
        'id': 'country-hovered',
        'type': 'line',
        'source': 'composite',
        'source-layer': 'sevurity_iuuf_ss',
        // initially no highlighted countries
        'filter': ['in', 'COUNTRY_CODE', ''],
        'paint': {
          'line-color': 'yellow',
          'line-width': 2
        }
      })
      // map.on events
      // filters for country hilight layer
      map.on('mousemove', 'country-helper-layer', (e) => 
      {
        map.getCanvas().style.cursor = 'pointer';
        const feature = e.features[0];
        if(feature.properties.COUNTRY_CODE != null)
          map.setFilter('country-hovered', ['in', 'COUNTRY_CODE', feature.properties.COUNTRY_CODE]);
      });

      map.on('mouseleave', 'country-helper-layer', () => {
        map.setFilter('country-hovered', ['in', 'COUNTRY_CODE', '']);
      });

      // county click listener
      map.on('click', (e) => {
        // TODO: unittest ensure mapbox point good and features correct.
        const features = map.queryRenderedFeatures([e.point.x, e.point.y], {
          layers: ['country-helper-layer']
        });
        if (features[0] !== undefined) {
          const feature = features[0];
          const countryNE = feature.properties.COUNTRY_NE;
          dispatch(setCountryFilter(countryNE));
        }
      });
      map.setPaintProperty('summer-viirs', 'heatmap-opacity' , 0);
    }
  }, [map, dispatch])

  useEffect(() => 
  {
    if (map) 
    {
      flyToCountry(selectedCountry,selectedChallenge)
      //update the infograph data when the selected country changes
      dispatch(setUpdateInfograph(true));
    }
  }, [selectedCountry, map, dispatch, flyToCountry, selectedChallenge])

  useEffect(() =>
  {
    if (map)
    {
      map.on('zoomend', () => 
      {
        if (updateInfograph) 
        {
          if(selectedCountry === undefined)
          dispatch(setCountryFilter('USA'));
          //update the infograph data when the selected country changes
          const features = map.querySourceFeatures('composite', {
            'sourceLayer': 'sevurity_iuuf_ss',
            'filter': ['in', 'COUNTRY_NE', selectedCountry]
          })

          map.querySourceFeatures('composite', {
            'sourceLayer': 'sevurity_iuuf_ss'
          });
        

          if (features[0] !== undefined) 
          {
            dispatch(setInfographData(features[0].properties));
          }
          dispatch(setUpdateInfograph(false))
        }
      })
    }
  }, [map, updateInfograph, selectedCountry, dispatch])

  useEffect(() => 
  {
    if (map)
    {
      if (fsi_selected)
      {
        createMapOverlay("Total_2020")
      }
      else 
      {
        if(map.getLayer('active-overlay'))
          map.removeLayer("active-overlay")
      }
    }

  }, [fsi_selected, map, createMapOverlay])

  useEffect(() => 
  {
    if (map)
    {
      if (isScoreByCountryChecked)
      {
        countryByScoreMapOverlay("iuuf_IUU_Score")
      }
      else 
      {
        if(map.getLayer('countryByScore-overlay'))
         map.removeLayer("countryByScore-overlay")
      }
    }

  },[isScoreByCountryChecked, map, countryByScoreMapOverlay])

  /* useEffect(() => 
  {
    if (map) 
    {
      if (ago_selected) 
      {
        map.addLayer(
          {
            'id': 'country-pulse',
            'type': 'fill',
            'source': 'composite',
            'source-layer': 'sevurity_iuuf_ss',
            'paint': 
            {
              'fill-color': create_paint_expression(),
              'fill-opacity': 0.7
            }
          });
      }
      else {
        if(map.getLayer('ago_test_layer'))
          map.removeLayer("ago_test_layer")
        if(map.getLayer('country-pulse'))
          map.removeLayer("country-pulse")
          
      }
    }
  }, [ago_selected, map]) */

  /* function create_paint_expression()
  {
    var matchExpression = ['match', ['get', 'COUNTRY_CODE']];

    var color = null;
    var iso_3_country = '';

    Object.values(pulseData).forEach(c => 
    {
      color = interpolate_color_white_red(c['pulse']);//`rgba(${x}, 0, 0, ${opacity})`;
      
      iso_3_country = countryCentroidsSwitch(c.country);
      if (iso_3_country && iso_3_country != '')
        matchExpression.push(iso_3_country['code'], color);
    });
  
    matchExpression.push('rgba(0, 0, 0, 0)');
    return matchExpression;
  } */

  /* function interpolate_color_white_red(value) 
  {
    var col1 = [255, 0, 0];
    var col2 = [0, 255, 0];
    
    var fraction = value / 100.0;
 
    var r = Math.floor((col2[0] - col1[0]) * fraction + col1[0]);
    var g = Math.floor((col2[1] - col1[1]) * fraction + col1[1]);
    var b = Math.floor((col2[2] - col1[2]) * fraction + col1[2]);//zero
 
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  } */

  useEffect(() => 
  {
    if (layers.length > 0 && sourceLoaded && map)
    {
      layers.forEach(layer => 
      {
          let instructionArray = layerInstructions[layer] // has layer_name, opacity_key, opacity_value
          instructionArray.forEach(item => 
          {
            map.setPaintProperty(item.layer_name, item.opacity_key, item.opacity_value)
          })
      })
    }
  }, [layers, sourceLoaded, map, createMapOverlay])
  

function sc_area_of_interest(value)
{
  if(value)
  {
      map.setLayoutProperty(
        "sc-area-of-interest-base",
        'visibility',
        'visible'
      );
      map.setLayoutProperty(
        "sc-area-of-interest-selected-fill",
        'visibility',
        'visible'
      );
      map.setLayoutProperty(
        "sc-area-of-interest-selected-line",
        'visibility',
        'visible'
      );
      map.setLayoutProperty(
        "sc-area-of-interest-label",
        'visibility',
        'visible'
      );
  }
  else
  {
    map.setLayoutProperty(
      "sc-area-of-interest-base",
      'visibility',
      'none'
    );
    map.setLayoutProperty(
      "sc-area-of-interest-selected-fill",
      'visibility',
      'none'
    );
    map.setLayoutProperty(
      "sc-area-of-interest-selected-line",
      'visibility',
      'none'
    );
    map.setLayoutProperty(
      "sc-area-of-interest-label",
      'visibility',
      'none'
    );
  }
}

  useEffect(() => 
  {
    if(map)
    {
      sc_area_of_interest(IUUAOI);
      switch(AOI_dropdown)
      {
        case 'sc_aoi_0':
          map.setFilter('sc-area-of-interest-selected-line', null);
          map.setFilter('sc-area-of-interest-selected-fill', null);
          break;
        case 'sc_aoi_1':
          map.setFilter('sc-area-of-interest-selected-line', ['==', ['get', 'name'], 'sc_aoi_1']);
          map.setFilter('sc-area-of-interest-selected-fill', ['==', ['get', 'name'], 'sc_aoi_1']);
          break;
        case 'sc_aoi_2':
          map.setFilter('sc-area-of-interest-selected-line', ['==', ['get', 'name'], 'sc_aoi_2']);
          map.setFilter('sc-area-of-interest-selected-fill', ['==', ['get', 'name'], 'sc_aoi_2']);
          break;
        case 'sc_aoi_3':
          map.setFilter('sc-area-of-interest-selected-line', ['==', ['get', 'name'], 'sc_aoi_3']);
          map.setFilter('sc-area-of-interest-selected-fill', ['==', ['get', 'name'], 'sc_aoi_3']);
          break;
        default:
          map.setFilter('sc-area-of-interest-selected-line', ['==', ['get', 'name'], 'b']);
          map.setFilter('sc-area-of-interest-selected-fill', ['==', ['get', 'name'], 'b']);
          break;
      }
    }
  },[map, IUUAOI, AOI_dropdown]);

  function drawPointLayer(id, source, layer1, layer2)
  {
     // map.setFilter(id, null);
      if (map.getLayer(id)) 
        map.removeLayer(id);

      var date  = new Date(selectedDateFV);  
      var date2 = new Date(selectedDateFV);  //july 2 2021 00:00:00
      
      date.setMilliseconds(-1);
      var time = date.valueOf(); // july 1 2021 23:59:59

      date2.setDate(date2.getDate() + 1); //july 3 2021 00:00:00
      var time2  = date2.valueOf();

      var all = [
        "all",
        ["<=", 'Start_Date', time2], 
        [">=", 'End_Date', time] 
      ]; 

      map.addLayer(
      {
        'id': id,
        'type': 'circle',
        'source': source,
        'filter': all,       
        'paint':
        {
          'circle-radius': 6,
          'circle-stroke-width': 2,
          'circle-color': 'red',
          'circle-stroke-color': 'white'
        }
      });
     // map.setFilter(id, all);

     if(layer1 && layer1 !== "")
     {
      if (map.getLayer(layer1))
        map.removeLayer(layer1);
      if (map.getLayer(layer2)) 
        map.removeLayer(layer2);
     }
  }


  function toggle_visibility_FV(value)
  {
    if(value)
    {
        map.setLayoutProperty(
          "maritime-regions-fill",
          'visibility',
          'visible'
        );
        map.setLayoutProperty(
          "maritime-regions-stroke",
          'visibility',
          'visible'
        );
        map.setLayoutProperty(
          "maritime-regions-label",
          'visibility',
          'visible'
        );
    }
    else
    {
      map.setLayoutProperty(
        "maritime-regions-fill",
        'visibility',
        'none'
      );
      map.setLayoutProperty(
        "maritime-regions-stroke",
        'visibility',
        'none'
      );
      map.setLayoutProperty(
        "maritime-regions-label",
        'visibility',
        'none'
      );
    }
  }

  useEffect(() => 
  {
    if(map)
    {
      var central =  ['central_america_id','central_america_source'];
      var atlantic = ['south_america_atlantic_id','south_america_atlantic_source'];
      var pacific =  ['south_america_pacific_id','south_america_pacific_source'];
      
      if(isFirstVisitChecked)
      {
        switch(firstVisit_dropdown)
        {
          case '0':
            toggle_visibility_FV(true);
            map.setFilter('maritime-regions-label' ,  null);
            map.setFilter('maritime-regions-stroke',  null);
            map.setFilter('maritime-regions-fill'  , null);
            drawPointLayer(pacific[0],pacific[1],"","");
            drawPointLayer(central[0],central[1],"","");
            drawPointLayer(atlantic[0],atlantic[1],"","");
            break;
          case '1':
            mapFilter('Central America');
            drawPointLayer(central[0],central[1],atlantic[0],pacific[0]);
            break;
          case '2':
            mapFilter('South America Atlantic');
            drawPointLayer(atlantic[0],atlantic[1],central[0],pacific[0]);
            break;
          case '3':
            mapFilter('South America Pacific');
            drawPointLayer(pacific[0],pacific[1],central[0],atlantic[0]);
            break;
          default:
            toggle_visibility_FV(false);
            if (map.getLayer(central[0]))
              map.removeLayer(central[0]);
            if (map.getLayer(atlantic[0]))
              map.removeLayer(atlantic[0]);
            if (map.getLayer(pacific[0]))
              map.removeLayer(pacific[0]);
            break;
        }
      }
      else
      {
        toggle_visibility_FV(false);
        if (map.getLayer(central[0]))
          map.removeLayer(central[0]);
        if (map.getLayer(atlantic[0]))
          map.removeLayer(atlantic[0]);
        if (map.getLayer(pacific[0]))
          map.removeLayer(pacific[0]);
      }
    }
  }, [isFirstVisitChecked, firstVisit_dropdown, selectedDateFV]);

  function mapFilter(title)
  {
    toggle_visibility_FV(true);
    map.setFilter('maritime-regions-label' , ['==',['get','title'],title]);
    map.setFilter('maritime-regions-stroke', ['==',['get','title'],title]);
    map.setFilter('maritime-regions-fill'  , ['==',['get','title'],title]);
  }

  useEffect(() => 
  {

    if(map)
    {

      if(!mapMarker)
      {
        drawPoints('sc_aoi_0');
      }
      else
      {
        drawPoints('sc_aoi_0');
        switch(AOI_dropdown)
        {
          case 'sc_aoi_1':
            drawPoints('sc_aoi_1');
            break;
          case 'sc_aoi_2':
            drawPoints('sc_aoi_2');
            break;
          case 'sc_aoi_3':
            drawPoints('sc_aoi_3');
            break;
          case 'sc_aoi_0':
            drawPoints('sc_aoi_1');
            drawPoints('sc_aoi_2');
            drawPoints('sc_aoi_3');
            break;
          default:
            break;
        }
      }
    }
  }, [map, mapMarker, AOI_dropdown, selectedDateAOI]);


  function drawPoints(value)
  {
    var id ='';
    var source ='';

    switch(value)
    {
      case 'sc_aoi_1':
        id     = 'argentinaMapPoints_layer1';
        source = 'argentinaMapPoints_source';
        break;
      case 'sc_aoi_2':
        id     = 'galapagosMapPoints_layer2';
        source = 'galapagosMapPoints_source';
        break;
      case 'sc_aoi_3':
        id     = 'peruMapPoints_layer3';
        source = 'peruMapPoints_source';
        break;
      default:
        if (map.getLayer('argentinaMapPoints_layer1'))
          map.removeLayer('argentinaMapPoints_layer1');
        if (map.getLayer('galapagosMapPoints_layer2'))
          map.removeLayer('galapagosMapPoints_layer2');
        if (map.getLayer('peruMapPoints_layer3'))
          map.removeLayer('peruMapPoints_layer3');
        return;
    }

    //map.setFilter(id, null);
    if (map.getLayer(id)) 
      map.removeLayer(id);

    var date = new Date(selectedDateAOI);
    var date2 = new Date(selectedDateAOI);

    date.setMilliseconds(-1);
    var time = date.valueOf(); //july 1 2021, 23:59:59

    date2.setDate(date2.getDate() + 1);
    var time2  = date2.valueOf(); // july 3 2021 00:00:00

    var all = [
      "all",
      ["<=", "Start_Date", time2],
      [">=", "End_Date", time],
      ["in", "Activity_Type", "LOW_SPEED_FISHING", "MEETING"]
    ];
    map.addLayer(
    {
        'id': id,
        'type': 'circle',
        'source': source,
        'filter': all,
        'paint': 
        { 
          'circle-radius': ["case",
            ["==", ["get", "Activity_Type"], 'MEETING'],
            8,
            ["==", ["get", "Activity_Type"], 'LOW_SPEED_FISHING'],
            3,
            0 
                              ],
          'circle-color': [
              'match',
              ['get', 'Activity_Type'],
              'MEETING',
              '#00a819',
              'LOW_SPEED_FISHING',
              '#0017b0',
              'rgba(0,0,0,0)'
          ],
          'circle-stroke-color': ["case",
              ["==", ["get", "Activity_Type"], 'MEETING'],
              '#f7b748',
              ["==", ["get", "Activity_Type"], 'LOW_SPEED_FISHING'],
              '#0017b0',
              '#000'
                              ],
          'circle-stroke-width': 0.5,
          'circle-stroke-opacity': ["case",
              ["==", ["get", "Activity_Type"], 'MEETING'],
              0.8,
              ["==", ["get", "Activity_Type"], 'LOW_SPEED_FISHING'],
              0.8,
              0 
                              ],
          'circle-opacity': ["case",
              ["==", ["get", "Activity_Type"], 'MEETING'],
              0.8,
              ["==", ["get", "Activity_Type"], 'LOW_SPEED_FISHING'],
              0.5,
              1
              ],
          'circle-blur': 0.1
        },
      },'country-label');
    }


  return (
    <>
      <div ref={node} className='mapContainer' />
    </>
  );
}//end of ReactMapboxComponent

Map.propTypes = {
  setCountryList: PropTypes.func.isRequired,
  setInfographData: PropTypes.func.isRequired,
  setCountryFilter: PropTypes.func.isRequired
}

function mapDispatchToProps(dispatch)
{
  return bindActionCreators({
    setCountryList,
    setInfographData,
    setIsLoading,
    setCountryFilter
  }, dispatch);
}

function mapStateToProps(state)
{
  return {
    selectedCounty: state.map.selectedCountry,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ReactMapboxComponent);