/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { createSlice } from '@reduxjs/toolkit'
const acledSlice = createSlice({
  name: 'acled',
  initialState: {
    acledSwitch:false,
    slider_result:"",
    acledEvent:{},
    acledClass:{},
    chooseId:1,
    acledCountryArr:[],
    acledCountryEvents:{},
    acledEventType:'Battles',
    acledStartYear: '2/2018',
    acledEndYear: '2/2021',
    eventTypeTotal:{},
    countryLayer:{},
    acledLayerData:{},
    acledMax:23084,
    acledLegend:[],
    infoEventTypeNum:{},
    acledSubEventType:[],
    acledCheckedAll:true,
    acledTotal:[],
    AcledBlendFilter:[]
  },
  reducers: {
    setAcledSwitch(state, action) {
      state.acledSwitch = action.payload
    },
    setAcledEvent(state, action) {
      state.acledEvent = action.payload
    },
    setAcledClass(state, action) {
      state.acledClass = action.payload
    },
    setChooseId(state, action) {
      state.chooseId = action.payload
    },
    setAcledStartYear(state, action) {
      state.acledStartYear = action.payload
    },
    setAcledEndYear(state, action) {
      state.acledEndYear = action.payload
    },
    setAcledCountryArr(state, action) {
      if(!action.payload || action.payload.length === 0){
        state.acledCountryArr = [];
        return;
      }
      let fdx = state.acledCountryArr.findIndex(item=>item === action.payload)
      if(fdx >= 0){
        let temp = [ ...state.acledCountryArr ]
        temp.splice(fdx, 1)
        state.acledCountryArr = [...temp]
      }else{
        state.acledCountryArr = [...state.acledCountryArr,action.payload]
      }
    },
    setAcledCountryEvents(state,action) {
      state.acledCountryEvents = action.payload
    },
    setAcledEventType(state,action) {
      state.acledEventType = action.payload
    },
    setEventTypeTotal(state,action) {
      state.eventTypeTotal = action.payload
    },
    setCountryLayer(state,action) {
      state.countryLayer = action.payload
    },
    setAcledLayerData(state,action) {
      state.acledLayerData = action.payload
    },
    setAcledMax(state,action) {
      state.acledMax = action.payload
    },
    setAcledLegend(state,action) {
      state.acledLegend = action.payload
    },
    setInfoEventTypeNum(state,action) {
      state.infoEventTypeNum = action.payload
    },
    setSubEventType(state,action) {
      state.acledSubEventType = action.payload
    },
    setAcledCheckedAll(state,action) {
      state.acledCheckedAll = action.payload
    },
    setTotal(state,action) {
      state.acledTotal = action.payload
    },
    setBlendFilter(state,action) {
      state.AcledBlendFilter = action.payload
    },
  }
})

export const {
  setTotal, 
  setBlendFilter, 
  setAcledCheckedAll, 
  setAcledSwitch, 
  setAcledEvent, 
  setAcledLayers, 
  setAcledClass,
  setAcledTypes, 
  setChooseId, 
  setAcledStartYear,
  setAcledEndYear,
  setAcledCountryArr, 
  setAcledCountryEvents, 
  setAcledEventType, 
  setEventTypeTotal, 
  setCountryLayer, 
  setAcledLayerData, 
  setAcledMax, 
  setAcledLegend, 
  setInfoEventTypeNum, 
  setSubEventType 
} = acledSlice.actions;

export default acledSlice.reducer;