/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


//TODO: in the locale directory, have a file for each language, and use this
//file to import them all and combine.
const translationsObject = {
  "en": {
    referenceUrl: "Reference URL",
    searchBarTitle: "List of News, Reports, and Resources",
    newsEventsTitle: "News",
    loadMoreNews: "Load More",
    reportsPublicationsTitle: "Reports & Publications",
    seeAllRepPub: "See all Reports & Publications",
    resourcesTitle: "Resources",
    seeAllResources: "See all Resources",
    dataLayers: "Data Layers",
    mapLegends: "Map Legends",
    noNewsIUU: "No recent IUU Fishing news",
    noIrIUU: "No IUU Fishing publications for %{country}",
    noIrTOC: "No Violence and Arms publications",
    noNewsSecEnv: "No recent Security Environment news for %{country}",
    noIrSecEnv: "No Security Environment publications for %{country}",
    noIr: "No publications avalible",
    tsModalLabel: "Transshipment",
    fishModalLabel: "Fishing by Country EEZ",
    tunaModalLabel: "Tuna and Billfish Fishing History",
    newsCardLanguage: "Language",
    newsCardTopic: "Topic",
    newsCardSource: "Source",
    newsCardCountry: "Country",
    newsCardDateTime: "Date/Time",
    newsCardOpenArticle: "Open Article",
    newsSourceLabelHyperion: "Filtered for the lastest news from Hyperion",
    newsSourceLabelGDelt: "Filtered for the lastest news from GDelt (Last 24hr)",
    noFilteredNews: "No news based on selected filters.",
	  codeBook: "Code Book",
    firstVisit: "First Visits Month of ",
    IUUscore: "IUU Score",
    FSI: "Fragile State Index (FSI)",
    TotalTransshipment: "Total Transshipment",
    TotalFishingActivities: "Total Fishing Activities",
    TotalVessels: "Total Vessels",
    Maritimeactivitiesin: "Maritime activities in",
    TotalsVesselsincludeCargo: "Number of maritime activities in selected AOIs. Only activities associated with"
  },
  "esp": {
    referenceUrl: "Referencia URL",
    searchBarTitle: "Lista de Noticias, Informes y Recursos",
    newsEventsTitle: "Noticias",
    loadMoreNews: "Obtener Más",
    reportsPublicationsTitle: "Informes y publicaciones",
    seeAllRepPub: "Ver Todos Los Informes y Publicaciones",
    resourcesTitle: "Recursos",
    seeAllResources: "Ver Todos Los Recursos",
    dataLayers: "Capas de datos",
    mapLegends: "Leyendas del mapa",
    noNewsIUU: "No hay noticias de pesca INDNR recientes",
    noIrIUU: "No hay publicaciones sobre pesca INDNR para %{country}",
    noIrTOC: "No hay publicaciones de Violencia y Armas disponibles",
    noNewsSecEnv: "No hay noticias de entorno de seguridad recientes para %{country}",
    noIrSecEnv: "No hay publicaciones sobre entorno de seguridad para %{country}",
    noIr: "No hay publicaciones disponibles",
    tsModalLabel: "Transbordo",
    fishModalLabel: "Pesca por Pais EEZ",
    tunaModalLabel: "Historia de la pesca de atún y marlines",
    newsCardLanguage: "Lengua",
    newsCardTopic: "Tema",
    newsCardSource: "Origen",
    newsCardCountry: "País",
    newsCardDateTime: "Fecha/Momento",
    newsCardOpenArticle: "Abre el Articulo",
    newsSourceLabelHyperion: "Noticias filtradas de Hyperion",
    newsSourceLabelGDelt: "Noticias filtradas de las últimas 24 horas de GDelt",
    noFilteredNews: "No hay noticias basadas en filtros seleccionados.",
	  codeBook: "Libro de Códigos",
    firstVisit: "Primer mes de visitas de ",
    IUUscore: "Puntaje IUU",
    FSI: "Fragile State Index (FSI)",
    TotalTransshipment: "Total Transbordo",
    TotalFishingActivities: "Total de actividades de pesca",
    TotalVessels: "Buques totales",
    Maritimeactivitiesin: "Actividades marítimas en",
    TotalsVesselsincludeCargo: "Los buques totales incluyen carga, pesca y buques tanque"
  },
  "por": {
    referenceUrl: "Referência URL",
    searchBarTitle: "Lista de Notícias, Relatórios e Recursos",
    newsEventsTitle: "Noticias",
    loadMoreNews: "Obter Mais",
    reportsPublicationsTitle: "Relatórios e Publicações",
    seeAllRepPub: "Veja Todos Os Relatórios e Publicações",
    resourcesTitle: "Recursos",
    seeAllResources: "Veja Todos Os Recursos",
    dataLayers: "Camadas de Dados",
    mapLegends: "Lendas do mapa",
    noNewsIUU: "Nenhuma notícia de pesca IUU recente",
    noIrIUU: "Não há publicações de pesca IUU para a %{country}",
    noIrTOC: "Não há publicações sobre Violência e Armas disponíveis",
    noNewsSecEnv: "Nenhuma notícia de ambiente de segurança recente para a %{country}",
    noIrSecEnv: "Não há publicações de ambiente de segurança para a %{country}",
    noIr: "Nenhuma publicação disponível",
    tsModalLabel: "Transbordo",
    fishModalLabel: "Pesca por País EEZ",
    tunaModalLabel: "História da Pesca de Atum e Billfish",
    newsCardLanguage: "Língua",
    newsCardTopic: "Tópico",
    newsCardSource: "Origem",
    newsCardCountry: "País",
    newsCardDateTime: "Data/Momento",
    newsCardOpenArticle: "Abra o Artigo",
    newsSourceLabelHyperion: "Notícias filtradas do Hyperion",
    newsSourceLabelGDelt: "Notícias filtradas das últimas 24 horas do GDelt",
    noFilteredNews: "Nenhuma notícia com base nos filtros selecionados.",
	  codeBook: "Livro de Códigos",
    firstVisit: "Mês das primeiras visitas de ",
    IUUscore: "Pontuação IUU",
    FSI: "Fragile State Index (FSI)",
    TotalTransshipment: "Total Transbordo",
    TotalFishingActivities: "Total de atividades de pesca",
    TotalVessels: "Total de navios",
    Maritimeactivitiesin: "Atividades marítimas em",
    TotalsVesselsincludeCargo: "Os totais dos navios incluem carga, pesca e petroleiros"
  }
}

export default translationsObject;