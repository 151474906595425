/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { Component } from 'react';



//function Header (){
export default class Logo extends Component {


  render() {
    return (
      <div className="logoContainer">
        <img src={window.location.origin + '/android-chrome-192x192.png'} className={"logoMobile"} alt={"FIU SRH Logo"} />
        <img src={window.location.origin + '/images/fiu-srh-logo.svg'} className={"logoImg"} alt={"FIU SRH Logo"} />
      </div>
    );
  }
}

Logo.propTypes = {

}
//export default Header;