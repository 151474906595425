import React, { useEffect, useState, useCallback } from 'react'
import LineChart from './LineChart'
import { useSelector } from 'react-redux'
import TocUtils from "../../../utils/toc"

// const { SipriUtil } = TocUtils

const SipriInfo = () => {
	const sipriStartYear = useSelector(state => state.sipri.sipriStartYear)
	const sipriEndYear = useSelector(state => state.sipri.sipriEndYear)
	const sipriCountry = useSelector(state => state.sipri.sipriCountry)
	const sipriXportEventSource = useSelector(state => state.sipri.sipriXportEventSource)
	const sipriImportMapEvent = useSelector(state => state.sipri.sipriImportMapEvent)
	const sipriExportMapEvent = useSelector(state => state.sipri.sipriExportMapEvent)
	const sipriInd = useSelector(state => state.sipri.sipriInd)
	const panelState = useSelector(state => state.infograph.openLeftPanel)
	const [infoData, setInfoData] = useState([])

	useEffect(() => {
		if (!sipriCountry || !sipriInd) {
			return
		}
		let feature = null
		if (sipriInd === 'import' && sipriImportMapEvent) {
			feature = sipriImportMapEvent.find(it => it.SOVEREIGNT === sipriCountry)
		} else if (sipriExportMapEvent) {
			feature = sipriExportMapEvent.find(it => it.SOVEREIGNT === sipriCountry)
		}
		setInfoData(feature && feature.properties.xports)
	}, [sipriCountry, sipriStartYear, sipriEndYear, sipriInd, sipriImportMapEvent, sipriExportMapEvent])
	
	const Chart = useCallback(() => {
		if (!infoData || infoData.length === 0 || !sipriXportEventSource || !sipriStartYear || 
				!sipriEndYear || !sipriCountry || !sipriInd || !panelState) {
			return null
		}
		const lineYear = []
		for (let i = parseInt(sipriStartYear); i<= parseInt(sipriEndYear); i++) {
			lineYear.push(i)
		}

		const countryXports = sipriXportEventSource.filter(it => it.SOVEREIGNT === sipriCountry)
		const chartData = infoData.map(({ country }) => {
			const vals = []
			lineYear.forEach(year => {
				let findit = countryXports.find(it => it.YEAR === year)
				let xport = findit && findit.xfrom && findit.xfrom.find(it => it.country === country)
				vals.push(xport ? xport.val : 0)
			})
			return {
				name: country,
				type: 'line',
				data: vals
			}
		})
		return (<div className='lineChart'>
			<p>{sipriInd === 'import' ? 'Imports' : 'Exports'}</p>
			<span className="money">(Millions US Dollars)</span>
			<LineChart
				width="98%"
				height='200px'
				chartData={chartData}
				chartDate={lineYear}
			/>
		</div>)
	}, [infoData, sipriXportEventSource, sipriStartYear, sipriEndYear, sipriCountry, sipriInd, panelState])

	return (
		<>
			<div className="tocSipriHeader">
				<div className="tocSipriHeaderTitle">
					<div className="countryBox"> {sipriInd === 'import' ? `Imports to ${sipriCountry}` : `Exports from ${sipriCountry}`} </div>
					<div className="dateBox">{sipriStartYear} - {sipriEndYear}</div>
				</div>
				<div className="money">(Millions US Dollars)</div>
			</div>
			<div className="tocSipriContent">
				<div className="tocSipriCountry">
					{
						infoData && infoData.map((item, ind) => {
							return (
								<div className="tocSipriCountryIcon" key={ind}>
									<div className="countryTotalBox">{item.val}</div>
									<div className="countryNameBox">{item.country}</div>
								</div>
							)
						})
					}
				</div>
			</div>
			<Chart />
		</>
	)
}


export default SipriInfo