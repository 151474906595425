const subEventTypeFun = (data, startYear, endYear, startMonth, endMonth, country, sub_event_type) => {
    let total = 0
    let newResult = []
    const new_sub_event_type = sub_event_type.replace(/\s*/g, "").replace(/-/g, '').replace(/\//g, '').slice(0,8)
    if(data.features){
      if(startYear !== endYear){
        newResult = data.features.filter(item => {
          if (item.attributes.SOVEREIGNT == country && item.attributes.YEAR >= startYear && item.attributes.YEAR <= endYear) {
            if (startYear == item.attributes.YEAR) {
              return item.attributes.MONTHNR >= startMonth
            } else if (endYear == item.attributes.YEAR) {
              return item.attributes.MONTHNR <= endMonth
            } else {
              return item
            }
          }
        })
      }
  
      if(startYear === endYear){
        newResult = data.features.filter(item => {
          if(item.attributes.SOVEREIGNT == country && item.attributes.YEAR>=startYear && item.attributes.YEAR<=endYear){
            if (item.attributes.YEAR == startYear) {
              return item.attributes.MONTHNR >= startMonth && item.attributes.MONTHNR <= endMonth
            } 
          }
        })
      }
    }

    newResult.length && newResult.filter(item => {
      Object.entries(item.attributes).filter(v => {
        if (v[0].includes(new_sub_event_type)) {
          total += v[1]
        }
      })
    })
    return total
  }

export default subEventTypeFun