/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { createSlice } from '@reduxjs/toolkit'
//import {browserHistory} from 'react-router';

//const initPage = browserHistory.getCurrentLocation();
//TODO: put this in one place to be used by other components
const routes = ["/","/news","/collaboration"];

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    route: routes.includes(window.location.pathname) ? window.location.pathname : "/" ,
  },
  reducers: {
    setRoute(state, action) {
      state.route = action.payload
    },
  }
});

export const { setRoute } = uiSlice.actions;


export default uiSlice.reducer;