import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from '@material-ui/core/Tooltip';

export function renderInfoIconWithTooltip(tooltipText) {
    return (
        <Tooltip title={tooltipText} className='inTitleTooltipIcon' placement="right">
            <span>
                <FontAwesomeIcon icon={faInfoCircle}/>
            </span>
        </Tooltip>
    )
}