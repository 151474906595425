/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


// copied from MapLegends
import React, { useCallback, useState } from 'react'
import { Row, Col, Collapse } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { Translate } from "react-redux-i18n"
import { useSelector } from "react-redux"
import './TOCLegends.css'
import PointLegend from './PointLegend'
import SipriLegend from './SipriLegend'
import TocUtils from '../../../../utils/toc'

const { GtdUtil } = TocUtils

export default function TOCLegends(props) {

	const [showData, setShowData] = useState(true)

	const layers = useSelector(state => state.map.layers)
	const acledSwitch = useSelector(state => state.acled.acledSwitch)
	const gtdSwitch = useSelector(state => state.gtd.gtdSwitch)
	const acled_selected = useSelector(state => state.toc.tocLayer) === 'ACLED'
	const sipri_selected = useSelector(state => state.toc.tocLayer) === 'SIPRI'
	const gtd_selected = useSelector(state => state.toc.tocLayer) === 'GTD'
	const acledClass = useSelector(state => state.acled.acledClass)
	const acledMax = useSelector(state => state.acled.acledMax)
	const acledLegend = useSelector(state => state.acled.acledLegend)
	const gtdLegend = useSelector(state => state.gtd.gtdLegend)
	const gtdInd = useSelector(state => state.gtd.gtdInd)
	const subGtdInd = useSelector(state => state.gtd.subGtdInd)

	const renderCaret = () => {
		if (showData) {
			return (
				<FontAwesomeIcon className="caret" icon={faAngleDown} />
			)
		} else {
			return (
				<FontAwesomeIcon className="caret" icon={faAngleUp} />
			)
		}
	}

	const showHideData = () => {
		setShowData(!showData)
	}

	const AcledCountryLegend = () => {
		if (!acledLegend || acledLegend.length === 0) {
			return null
		}
		return acledLegend.length ? acledLegend.map((item, ind) => {
			return (
				<div className="TOCLegendIcon" key={ind}>
					<div className="acledLegend" style={{ backgroundColor: item.color, borderColor: '#000' }}></div>
					<span>{item.lable}</span>
				</div>
			)
		}) : null
	}

	const renderACLEDLegend = () => {
		return (
			<>
				{
					acledSwitch ?
						<div className="TOCLegend">
							<div className="legendTitle">
								Point View
							</div>
							<div className="legendContent">
								{
									acledClass.drawingInfo ? <PointLegend uniqueValueInfos={acledClass.drawingInfo.renderer.uniqueValueInfos} /> : null
								}
							</div>
						</div> :
						<div className="TOCLegend">
							<div className="legendTitle">
								Country View
							</div>
							<div className="legendContent">
								<AcledCountryLegend />
							</div>
						</div>
				}
			</>
		)
	}

	const GtdCountryLegend = () => {
		if (!gtdLegend || gtdLegend.length === 0) {
			return null
		}
		return gtdLegend.length ? gtdLegend.map((item, ind) => {
			return (
				<div className="TOCLegendIcon" key={ind}>
					<div className="acledLegend" style={{ backgroundColor: item.color, borderColor: '#000' }}></div>
					<span>{item.min} - {item.max}</span>
				</div>
			)
		}) : null
	}

	const GtdPointLegend = useCallback(() => {
		if(!subGtdInd || subGtdInd.length === 0){
			return
		}
		
		const info = subGtdInd.map(ind => {
			const find = GtdUtil.getSubEventTypeObject(ind, 'name')
			if(!find){
				return
			}
			return {
				symbol: {
					width: find.width,
					height: find.height,
					imageData: find.icon
				},
				value: find.title
			}
		})
		return <PointLegend uniqueValueInfos={info} />
	}, [gtdInd, subGtdInd])

	const renderGTDLegend = () => {
		return (
			<>
				{
					gtdSwitch ?
						<div className="TOCLegend">
							<div className="legendTitle">
								Point View
							</div>
							<div className="legendContent">
								<GtdPointLegend />
							</div>
						</div> :
						<div className="TOCLegend">
							<div className="legendTitle">
								Country View
							</div>
							<div className="legendContent">
								<GtdCountryLegend />
							</div>
						</div>
				}
			</>
		)

	}
	
	const renderData = () => {
		return (
			<Collapse in={showData}>
				<div>
					<div className="TOCLegendPanel">
						<Row>
							<Col>
								{acled_selected && renderACLEDLegend()}
							</Col>
						</Row>
						<Row>
							<Col>
								{
									sipri_selected && <SipriLegend />
								}
							</Col>
						</Row>
						<Row>
							<Col>
								{gtd_selected && renderGTDLegend()}
							</Col>
						</Row>
					</div>
				</div>
			</Collapse>

		)
	}

	return (
		<>
			<div className="dataBoxContainer dataLayerContiner">
				<div className="dataBoxTitleContainer legendContainer">
					<Row>
						<Col xs={8}>
							<div className="dataBoxTitle dataLayerTitle">
								{/* <Translate value="dataLayers" /> */}
								<Translate value="Legend" />
							</div>
						</Col>
						<Col xs={4}>
							<div className="dataBoxHide dataLayerTitle" onClick={showHideData}>
								{renderCaret()}
							</div>
						</Col>
					</Row>
					{renderData()}
				</div>
			</div>

		</>
	)
}