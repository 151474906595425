/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import '../App.css';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import setErrorMessage from '../actions/errors';
import { loginUser, logoutUser, setAuthStatus, getCurrentUser, registerUser } from '../actions/auth';

import Header from '../components/Header.js';
import FiltersBar from '../components/FiltersBar';
import DashboardHolder from '../components/DashboardHolder';
import Footer from "../components/Footer";
//import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import CollaborationHolder from "../components/CollaborationHolder"
//import CollaborationHolder from "../Features/Collaboration/CollaborationHolder";
import NewsContainer from "../Features/News/NewsContainer";

class App extends React.Component {
  UNSAFE_componentWillMount() { // eslint-disable-line camelcase
    this.props.getCurrentUser();
  }
      constructor(props){
        super(props);
        this.state = {
            showLoginModal: false
        }
    }
    handleClose = () => {this.setState({showLoginModal: false})};
    handleShow = () => {this.setState({showLoginModal: true})};
    //<Row style={{width: "100vw", height:"4px", background:"#081e3f"}}></Row>

    render() {
    
      const {
         isAuthenticated, authUser, authStatus, authStatusText,/*registerUser,registerStatus,registerStatusText*/
      } = this.props;
      return(
        <div className="App">
          <Router>
          <header className="App-header">
            <Header
              isAuthenticated={isAuthenticated}
              authUser={authUser}
              authStatus={authStatus}
              authStatusText={authStatusText}
              loginUser={this.props.loginUser}
              logoutUser={this.props.logoutUser}
              setAuthStatus={this.props.setAuthStatus}
              /*registerUser={this.props.registerUser}
              registerStatus={this.props.registerStatus}
              registerStatusText={this.props.registerStatusText}*/ />
            <div className="headerSeparator"></div>
          </header>

            <Switch>
              <Route exact path="/">
                <FiltersBar></FiltersBar>
                <DashboardHolder></DashboardHolder>
              </Route>
              <Route path="/collaboration">
                <CollaborationHolder/>
              </Route>
              <Route exact path={"/news"}>
                <NewsContainer/>
              </Route>
            </Switch>
          </Router>
          <Footer></Footer>
        </div>
      );
    }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  authUser: PropTypes.string,
  authStatus: PropTypes.string,
  authStatusText: PropTypes.string,
  getCurrentUser: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  setAuthStatus: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  registerStatus: PropTypes.string,
  registerStatusText: PropTypes.string,
};

// these props are used for dispatching actions
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getCurrentUser,
    loginUser,
    logoutUser,
    setAuthStatus,
    registerUser,
    setErrorMessage,
  }, dispatch);
}

// these props come from the application's state when it is started
function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authUser: state.auth.username,
    authStatus: state.auth.authStatus,
    authStatusText: state.auth.authStatusText,
    errorMessage: state.errors.errorMessage,
    registerStatus: state.auth.registerStatus,
    registerStatusText: state.auth.registerStatusText
  };
}

//export default App;
export default connect(mapStateToProps, mapDispatchToProps)(App);