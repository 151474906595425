/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

export default class LoginModal extends Component{
  constructor(props){
    super(props);
    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();
    this.state = {
      show: false, 
      authStatus: this.props.authStatus, 
      authStatusText: this.props.authStatusText
    };
  }

  setShow = (newShow) => {this.setState({show: newShow})}
  handleClose = () => this.setShow(false);
  handleShow = () => this.setShow(true);

  renderLoginError = () => {
    const { authStatus, authStatusText} = this.props;

    if(authStatus === "loginFailure") {
      return(
      <div className="errorMessage">
        <Alert variant="danger">{authStatusText}</Alert>
      </div>
      );
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    // pull inputs from dom
    const username = this.usernameRef.current.value.trim();
    const password = this.passwordRef.current.value.trim();
    // axios request to backend
    this.props.loginUser(username, password);
    // potentially put error handling here for wrong username/password

  }

  render(){
    return(
    <>
      <Button onClick={this.handleShow} className=" heading3 btnSignin" >
        Login
      </Button>

      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* form goes here */}
          <form >
            <label htmlFor="username">Enter your email:&nbsp;</label>
            <input type="text" name="username" id="username" required ref={this.usernameRef}/>
            <label htmlFor="password" >Enter your password:&nbsp;</label>
            <input  type="password" name="password" id="password" required ref={this.passwordRef}/>
          </form>
          {this.renderLoginError()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleSubmit}>Login</Button>
        </Modal.Footer>
      </Modal>
    </>
  ); 
}
}
