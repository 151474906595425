import React, { useEffect, useState, useMemo } from 'react'
import { Slider } from 'antd';
import './Slider.css'
const Slider_component = (props) => {
  const { data, range, step, before, later, result, defaultValue } = props
  
  const [marks, SetMarks] = useState({});

  const data_marks = (d, start, end) => {
    return {
      ...Object.assign({}, d.map((item, idx) => {
        if (idx >= start && idx < end) {
          return ''
        }
        return item
      }))
    }
  }

  useEffect(() => {
    SetMarks(data_marks(data, before, later))
  }, [data, before, later]);

  const onAfterChange = (value) => {
    const start = value[0];
    const end = value[1];
    const prev = data_marks(data, before, later)
    SetMarks({ ...prev, ...{ [start]: data[start] }, ...{ [end]: data[end] } })
    result(data[start], data[end])
  }

  const classUse = useMemo(()=>{
    if(!data || data.length === 0){
      return ''
    }
    if(data[0].length === 4){
      return data.length > 11 ? 'slider_top slider_year' : 'slider_year'
    }else{
      return data[0].length >= 6 ? 'slider_top slider_year slider_omit' : 'slider_year'
    }
  }, [data])

  if (!marks || Object.keys(marks).length === 0 ) {
    return null
  }

  return (
    <div className='slider_component'>
      <Slider
        className={classUse}
        range={range}
        style={{ width: Object.keys(marks).length * 22 }}
        max={Object.keys(marks).length - 1}
        step={step}
        marks={marks}
        tooltipVisible={false}
        onAfterChange={onAfterChange}
        defaultValue={defaultValue}
      />
    </div>
  )
}
export default Slider_component