/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


// copied from MapLegends
import React, { useState, useEffect } from 'react';
import { Row, Col, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Translate } from "react-redux-i18n"
import { useSelector } from "react-redux" 
import Tooltip from '@material-ui/core/Tooltip';


export default function IUULegends (props)
{
    const {fsi_selected, isScoreByCountryChecked, ago_selected, isFirstVisitChecked, IUUAOI} = props
    
    const [showData, setShowData] = useState(false);
    const [showDataLock, setShowDataLock] = useState(false);
    const [shouldShowFisheryLegend, setShouldShowFisheryLegend] = useState(false);
    const [shouldShowSquidDisclaimer, setShouldShowSquidDisclaimer] = useState(false);
    const layers = useSelector(state=>state.map.layers)
    const squidDisclaimer = "We purposefully redacted a region around South America due to enormous false detections caused by South Atlantic Anomaly (SAA). When high energy particles from the space hits satellite sensors, it creates an electrical surge and recorded as high radiance this causes the algorithm to create false detection."


    useEffect(() => 
    {
        setShouldShowFisheryLegend( ! /*(layers.includes("AIS_no_selection") && */layers.includes("seafood_no_selection") );

        setShouldShowSquidDisclaimer(
            (layers.includes("summer-viirs") ||
            layers.includes("winter-viirs")||
            layers.includes("spring-viirs") ||
            layers.includes("summer-viirs"))
        );

        if(!(fsi_selected || isScoreByCountryChecked || shouldShowFisheryLegend || shouldShowSquidDisclaimer ||ago_selected || (isFirstVisitChecked || IUUAOI) ))
            setShowData(false);
        else
            setShowData(true);

      },[layers, fsi_selected, isScoreByCountryChecked, shouldShowFisheryLegend, shouldShowSquidDisclaimer, ago_selected, isFirstVisitChecked , IUUAOI ])
  
    const renderCaret = () => 
    {
        if((!showDataLock) && showData)
        {
            return(
                <FontAwesomeIcon className="caret" icon={faAngleDown}/>
            );
        }
        else{
            return(
                <FontAwesomeIcon className="caret" icon={faAngleUp}/>
            );
        }
    }

    const showHideData = () => {
        setShowDataLock(!showDataLock);
    }

    const renderPulseLegend = () => 
    {
        return(
            <>
                <Row>
                    <div className="legendTitle">
                    Daily Pulse
                    </div>
                </Row>
                <Row>
                    <div className="pulseLegend" data-testid="fsiLegendElement"></div>
                </Row>
                <Row>
                        <Col xs={4} className="fsiLowLabel">
                            0
                        </Col>
                        <Col xs={4}>
                            50
                        </Col>
                        <Col xs={4} className="fsiHighLabel">
                            100
                        </Col>
                </Row>
            </>
        );
    }

    const renderFSILegend = () => 
    {
        return(
            <>
                <Row>
                    <div className="legendTitle">
                        FSI
                    </div>
                </Row>
                <Row>
                    <div className="fsiLegend" data-testid="fsiLegendElement">

                    </div>
                </Row>
                <Row>
                        <Col xs={4} className="fsiLowLabel">
                            0
                        </Col>
                        <Col xs={4}>
                            50
                        </Col>
                        <Col xs={4} className="fsiHighLabel">
                            100
                        </Col>
                </Row>
            </>
        );
    }
    const renderScoreByCountryLegend = () => {
        return(
            <>
                <Row>
                    <div className="legendTitle">
                        IUUF
                    </div>
                </Row>
                <Row>
                    <div className="fsiLegend" data-testid="fsiLegendElement">

                    </div>
                </Row>
                <Row>
                        <Col xs={4} className="fsiLowLabel">
                            0
                        </Col>
                        <Col xs={4}>
                            2.5
                        </Col>
                        <Col xs={4} className="fsiHighLabel">
                            5
                        </Col>
                </Row>
            </>
        );
    }
    const renderAISLegend = () => {
        return(
          <>
              <Row>
                  <div className="aisTitle">
                      AIS and Seafood
                  </div>
              </Row>
              <Row>
                  <div className="aisLegend">

                  </div>
              </Row>
              <Row>
                  <Col xs={8} className="aisLowLabel">
                  Low
                  </Col>
                  <Col xs={4} className="aisHighLabel">
                    High
                  </Col>
              </Row>
          </>
        );
    }
    const renderSquidDisclaimer = () => {
        return(
          <>
              <Row>
                  {/* <div className="aisTitle"> */}
                      <Tooltip title={squidDisclaimer}>
                        <span className={"dislaimerIcon"}>
                        <FontAwesomeIcon icon={faInfoCircle}/>
                        </span>
                      </Tooltip>
                  {/* </div> */}
              </Row>
              <Row>
 
              </Row>
          </>
        );
    }
    //<Col  className="fishMidIcon"> </Col>
    const renderFishingAcitvies= () => {
        return(
            <> {/*     
                <table className="FisingActivityDiv">
                    <th>
                        <div className="legendTitle">Fishing Activities</div>
                    </th>
                    <tr>
                        <td>
                            <div className="TransshippmentLabel">Transshippment</div>
                        </td>
                        <td>
                            <div className='TransshippmentLegend'></div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="FishingActivityLabel">Fishing activity</div>
                        </td>
                        <td>
                            <div className='FishingActivityLegend'></div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="FishVisitLabel">First Visit</div>
                        </td>
                        <td>
                            <div className='FishVisitLegend'></div>
                        </td>
                    </tr>
                </table>
            */}
            <Row>
                    <div className="legendTitle">
                        Fishing Activities
                    </div>
                </Row>
                <Row>
                    <Col xs={9} className="TransshippmentLabel">
                        Transshippment
                    </Col>
                    <Col xs={3} className="circleContainer">
                        <div className="TransshippmentLegend"></div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={9} className="FishingActivityLabel">
                        Fishing activity
                    </Col>
                    <Col xs={3} className="circleContainer">
                        <div className="FishingActivityLegend"></div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={9} className="FishVisitLabel">
                        First Visit
                    </Col>
                    <Col xs={3} className="circleContainer">
                        <div className="FishVisitLegend"></div>
                    </Col>
                </Row>
            </>
        );
    }


    const renderData = () => 
    {

        return(
            <Collapse in={(!showDataLock) && showData}>
                <div>
                    <div className="legendPanel">
                        <Row>
                            <Col>
                            { fsi_selected && renderFSILegend()}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            { isScoreByCountryChecked && renderScoreByCountryLegend()}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            { (isFirstVisitChecked || IUUAOI) && renderFishingAcitvies()}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            { shouldShowFisheryLegend && renderAISLegend()}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            { shouldShowSquidDisclaimer && renderSquidDisclaimer()}
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                            { ago_selected && renderPulseLegend()}
                            </Col>
                        </Row> */}
                    </div>
                </div>
            </Collapse>
        );
    }

    return(
            <>
                <div className="dataBoxContainer dataLayerContiner">
                    <div className="dataBoxTitleContainer legendContainer">
                        <Row>
                            <Col xs={9}>
                                <div className="dataBoxTitle dataLayerTitle">
                                    <Translate value="mapLegends"/>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="dataBoxHide dataLayerTitle" onClick={showHideData}>
                                    {renderCaret()}
                                </div>
                            </Col>
                        </Row>
                        {renderData()}
                    </div>
                </div>

            </>
        );
    }