
import React, { useState, useEffect } from 'react';
import PieGraph from "./IUU/PieGraph_IUU.js";
import Statistic from "./Statistic";
import { Translate } from'react-redux-i18n';

//was called TransnationalCrime
export default function WindwardPieGraphs(props)
{
  const [layers, setLayer] = useState(null);
 
  const {display = 0, dateAOI, title, lang} = props;
 
  const [myArray, setArray] = useState(new Array(3));

  useEffect(() => 
  {
    if(layers == null)
      getJson();
  });


  var totalObjects = {/*unqiueVessels: 0,*/ Transshipments: 0, Fishing_Activities: 0, entries: 0, processed: 0, total_entries:0, countryList:[], vesselsList: []};//Object.create(countObjects);
  var parsedObjects = [];

  const getJson = () => 
  {
      const URL3 = "https://services2.arcgis.com/FtJyXM7nBotS8g5t/ArcGIS/rest/services/sc_aoi_maritime_activities_Feb2022/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson&token=";    
      //peru sc_aoi_3 //2
      const URL1 = "https://services2.arcgis.com/FtJyXM7nBotS8g5t/ArcGIS/rest/services/sc_aoi_maritime_activities_Feb2022/FeatureServer/2/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson&token=";
      //Argentina sc_aoi_1 //0
      const URL2 = "https://services2.arcgis.com/FtJyXM7nBotS8g5t/ArcGIS/rest/services/sc_aoi_maritime_activities_Feb2022/FeatureServer/1/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson&token=";
      //galap sc_aoi_2 //1

      setupXMLRequest(URL1, callback1);//0
      setupXMLRequest(URL2, callback2);//1
      setupXMLRequest(URL3, callback3);//2
  }
  
  function setupXMLRequest(url, callback)
  {
    var xmlhttp1 = new XMLHttpRequest();
    xmlhttp1.onreadystatechange = callback;
    xmlhttp1.open("GET", url, true);
    xmlhttp1.send();
  }

  function callback1()
  {
    if (this.readyState === 4 && this.status === 200)
    {
      readJson(0, JSON.parse(this.responseText).features);
    }
  }
  function callback2()
  {
    if (this.readyState === 4 && this.status === 200)
    {
      readJson(1, JSON.parse(this.responseText).features);
    }
  } 
  function callback3()
  {
    if (this.readyState === 4 && this.status === 200)
    {
      readJson(2, JSON.parse(this.responseText).features);
    }
  }

  function readJson(i=0, myArr)
  {
      myArray[i] = myArr;
      parsedObjects[i] = countVessels(myArr);
      switch(i)
      {
        case 0:
          parsedObjects[i].location = "sc_aoi_1";
          break;
        case 1:
          parsedObjects[i].location = "sc_aoi_2";
          break;
        case 2:
          parsedObjects[i].location = "sc_aoi_3";
          break;
        default:
            break;
      }
      totalObjects.vesselsList = totalObjects.vesselsList.concat(parsedObjects[i].unqiueVessels);
      
      totalObjects.Transshipments     += parsedObjects[i].Transshipments;
      totalObjects.Fishing_Activities += parsedObjects[i].Fishing_Activities;
      totalObjects.entries            += parsedObjects[i].total_entries;
      totalObjects.processed          += parsedObjects[i].processed;
      displayTotal();
  }


  useEffect(() => 
  {
    if(myArray == null || !Array.isArray(myArray[0]))
      return;
    totalObjects.vesselsList = [];
    totalObjects.Transshipments     = 0;
    totalObjects.Fishing_Activities = 0;
    totalObjects.entries            = 0;
    totalObjects.processed          = 0;

    for(var i = 0; i<3; i++)
    {
      readJson(i, myArray[i]);
    }
  },[dateAOI]);

 
  function countVessels(myArr2)
  {
     var tempDate;
    if(dateAOI != null)
    {
      tempDate = new Date(dateAOI)
    }else{
      tempDate = new Date('2022 02 19')
      //new Date('2021 07 01');
    }

    var currentFlag = "";
    var flags = {location:"",Fishing_Activities:0, Transshipments:0, total_entries: 0, processed:0, countries: [], unqiueVessels:[], countryList:[], /*objects:[],vessel_name:[]*/};
 
    var start_date = null;
    var end_date   = null;

    myArr2.forEach(element =>
    {
        start_date = new Date(element.attributes.Start_Date);
        end_date   = new Date(element.attributes.End_Date);
        start_date.setHours(0);
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        start_date.setMilliseconds(0);
        end_date.setHours(23);
        end_date.setMinutes(59);
        end_date.setSeconds(59);
        end_date.setMilliseconds(59);

        //console.log("start_date.valueOf() = " + start_date.valueOf() + " tempDate.valueOf() = " + tempDate.valueOf() + " end_date.valueOf() = " + end_date.valueOf());
        
        if(start_date && end_date)
        {
          if(start_date.valueOf() <= tempDate.valueOf() && end_date.valueOf() >= tempDate.valueOf())
          {
            if(element.attributes.Activity_Type === 'LOW_SPEED_FISHING' && (currentFlag = element.attributes.Flag.trim()).length > 0)
            {
                if(flags['countries'][currentFlag] != null)//country flag found
                {
                    if(!(flags['countries'][currentFlag].vesselIDs.includes(element.attributes.vesselId)))//not found
                      flags['countries'][currentFlag].vesselIDs.push(element.attributes.vesselId);
                    if(!(flags['countries'][currentFlag].start_loc.includes(element.attributes.Start_Location_Name)) ) //not found
                      flags['countries'][currentFlag].start_loc.push(element.attributes.Start_Location_Name);
                    flags['countries'][currentFlag].entries++;
                }
                else //country flag not found
                {
                  flags['countryList'].push(currentFlag);
                  flags['countries'][currentFlag] = {vesselIDs: [], start_loc: [], entries: 1};      
                  flags['countries'][currentFlag].vesselIDs.push(element.attributes.vesselId);
                  flags['countries'][currentFlag].start_loc.push(element.attributes.Start_Location_Name);
                }

                flags['Fishing_Activities']++;
            }
                          
            if(!(flags['unqiueVessels'].includes(element.attributes.vesselId)))//not found
              flags['unqiueVessels'].push(element.attributes.vesselId);

            if(element.attributes.Activity_Type === 'MEETING')
              flags['Transshipments']++;

             flags['total_entries']++;
            // flags['objects'].push(element.attributes);
             //flags['vessel_name'].push(element.attributes.Vessel_Name);
          }
        }
        flags['processed']++;
    });
    //console.log(flags);
    return flags;
  }

  var c = 0;
  function displayTotal()
  { 
    c++;
    if(c === 3)
    {
      var countryCount = [];
      var k = "";
      totalObjects.vesselsList.sort();
      for (const [key, value] of Object.entries(totalObjects.vesselsList)) 
      {
        if(k === value)
          totalObjects.vesselsList.splice(key,1);
        else
          k= value;
      }
 
      for(let i =0; i<3; i++) //counts number of unqiue countries
      {
        //console.log(parsedObjects[i]);
          for (const [key, value] of Object.entries(parsedObjects[i]['countries'])) 
          {
            if((countryCount[key] == null))//not found
            {
              countryCount[key] = value.entries;
            }
            else
            {
              countryCount[key] += value.entries;
            }
          }
      }

     for (const [key, value] of Object.entries(countryCount)) 
      {
          totalObjects.countryList.push({name:key, entries:value});
      }

      parsedObjects[3] = totalObjects;
      setLayer(parsedObjects);
    }
  }

  //total countries
  const renderPieGraph = () => 
  {
    if (layers != null && layers[3]) 
    {
      var myArr = [];
      Object.values(layers[3].countryList).forEach(value => 
      {
        myArr[value.name] = value.entries;
      });
      var s = "("+(dateAOI.getMonth()+1) + "/" + dateAOI.getDate() + "/" +dateAOI.getFullYear()+")";
      //var options = {year: 'numeric', month: 'short', day: 'numeric'};
      //var s = "("+dateAOI.toLocaleDateString("en-US", options)+")";;
      //console.log(layers);
      return (
        <div className="dataBoxContainer0">
          <PieGraph data={myArr} id={'pieGraphTotal'} countryName={'Activities in Selected Areas of Interest '+s} total={"Layer 0"}/>
               
            <Statistic data={layers[3].Transshipments}     title={<Translate value="TotalTransshipment"/>} style={[2]}/>
            <Statistic data={layers[3].Fishing_Activities} title={<Translate value="TotalFishingActivities"/>} style={[2]}/>
            <Statistic data={layers[3].vesselsList.length} title={<Translate value="TotalVessels"/>} /*referenceURLLink="https://windward.ai/"*/ style={[2]}/>           
        </div>
      );
    }
  }

  function getLang()
  { 
    var s = '';
    switch(lang)
    {
      case 'en':
        s = 'en-US';
        break;
      case 'esp':
        s = 'es-US';
        break;
      case 'por':
        s = 'por-US';
        break;
      default:
        s = 'en-US';
        break;
    }
    return s;
  }

  function renderTitle()
  {
    var myTitle = "";
    var options = {year: 'numeric', month: 'short', day: 'numeric'};
    var time = "("+dateAOI.toLocaleDateString(getLang(), options)+")";;

    if(title == null && title.length <1)
      myTitle = 'Fishing by Country';
    else
      myTitle = title+" "+time;
      
      return myTitle;
  }

  const renderPieGraph1 = () => 
  {
    if (layers != null && layers[0]) 
    {
      var myArr = [];
  
      for (const [key, value] of Object.entries(layers[0]['countries'])) 
      {
        myArr[key] = value.entries;
      }

      return (
          <div className="dataBoxContainer1">
            <PieGraph data={myArr} id={'pieGraph1'} countryName={renderTitle()} total={"Layer 1"}/>
            
            <Statistic data={layers[0].Transshipments}     title={<Translate value="TotalTransshipment"/>} style={[2]}/>
            <Statistic data={layers[0].Fishing_Activities} title={<Translate value="TotalFishingActivities"/>} style={[2]}/>
            <Statistic data={layers[0].unqiueVessels.length} title={<Translate value="TotalVessels"/>} /*referenceURLLink="https://windward.ai/"*/ style={[2]}/>  
          </div>
      );
    }
  }
  const renderPieGraph2 = () => {//argentinaMapPoints_source', 
    if (layers != null && layers[1]) 
    {
      var myArr = [];

      for (const [key, value] of Object.entries(layers[1]['countries'])) 
      {
        myArr[key] = value.entries;
      }

      return (
        <div className="dataBoxContainer2">
          <PieGraph data={myArr} id={'pieGraph2'} countryName={renderTitle()} total={"Layer 2"}/>
          
            <Statistic data={layers[1].Transshipments}     title={<Translate value="TotalTransshipment"/>} style={[2]}/>
            <Statistic data={layers[1].Fishing_Activities} title={<Translate value="TotalFishingActivities"/>} style={[2]}/>
            <Statistic data={layers[1].unqiueVessels.length} title={<Translate value="TotalVessels"/>} /*referenceURLLink="https://windward.ai/"*/ style={[2]}/>  
        </div>
      );
    }
  }
  const renderPieGraph3 = () => //galapagosMapPoints_source', 
  {
    if (layers != null && layers[2]) 
    {
      var myArr = [];
      for (const [key, value] of Object.entries(layers[2]['countries'])) 
      {
        myArr[key] = value.entries;
      }
      return (
        <div className="dataBoxContainer3">
          <PieGraph data={myArr} id={'pieGraph3'} countryName={renderTitle()}  total={"Layer 3"}/>
          
            <Statistic data={layers[2].Transshipments}     title={<Translate value="TotalTransshipment"/>} style={[2]}/>
            <Statistic data={layers[2].Fishing_Activities} title={<Translate value="TotalFishingActivities"/>} style={[2]}/>
            <Statistic data={layers[2].unqiueVessels.length} title={<Translate value="TotalVessels"/>} /*referenceURLLink="https://windward.ai/"*/ style={[2]}/>  
        </div>
      );
    }
  }

  function displayPieGraphs(select)
  {
    var s=[];
    switch(select)
    {
      case 0:
        s=renderPieGraph();
        break;
      case 1://sc_aoi_1
        s=renderPieGraph1();
        break;
      case 2://sc_aoi_2
        s=renderPieGraph2();
        break;
      case 3://sc_aoi_3
        s=renderPieGraph3();
        break;
      default:
        for(var i =0; i<4; i++)
        {
          s.push( displayPieGraphs(i));
        }
        break;
    }
    return s;
  }
 
  return (
    <>
      {displayPieGraphs(display)}
    </>
  );
}