const filterDataFun = (result, startYear, endYear, monthStart, monthEnd) => {
    if (result) {
        const newResult = result.filter(item => {
            if (item.properties.year == startYear) {
                if (monthStart.includes(item.properties.Month)) {
                    return item
                }
            } else if (item.properties.year == endYear) {
                if (monthEnd.includes(item.properties.Month)) {
                    return item
                }
            } else {
                return item
            }
        })
        return newResult
    }
}

export default filterDataFun