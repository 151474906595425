/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { useState } from 'react';
import Select from 'react-select';
import {useDispatch, useSelector} from "react-redux";
import {setLocale} from "react-redux-i18n"
import {setLanguageFilter} from "../../slices/filtersSlice"

export default function LanguageFilter(props){

  const optionLabels = {
    'en': 'English',
    'esp': 'Spanish',
    'por': 'Portuguese'
  }
  const languageFilter = useSelector(state => state.filters.languageFilter)

  const [selectedOption,setSelectedOption] = useState({
    value: languageFilter,
    label: optionLabels[languageFilter]
  });
  const [options,/*setOptions*/] = useState([
    {value: 'en', label: 'English'},
    {value: 'esp', label: 'Spanish'},
    {value: 'por', label: 'Portuguese'},
  ]);
  const dispatch = useDispatch();

  function handleChange(selectedOption){
    setSelectedOption(selectedOption);
    dispatch(setLocale(selectedOption.value));
    dispatch(setLanguageFilter(selectedOption.value));
  }

  return(
    <Select
      value={selectedOption}
      className='heading3 ddl-Security'
      onChange={handleChange}
      options={options}
    />
  )
}

