/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { createSlice } from '@reduxjs/toolkit'
const gtdSlice = createSlice({
  name: 'gtd',
  initialState: {
    gtdSwitch:false,
    gtdCountryArr:[],
    gtdMapEvents: {},
    gtdYearlyEvents: {}, 
    gtdStartYear: '2008',
    gtdEndYear: '2021',
    gtdLegend: [],
    gtdInd: '',
    subGtdInd: [],
    gtdEventSource: {}
  },
  reducers: {
    setGtdSwitch(state, action) {
      state.gtdSwitch = action.payload
    },
    setGtdStartYear(state, action) {
      state.gtdStartYear = action.payload
    },
    setGtdEndYear(state, action) {
      state.gtdEndYear = action.payload
    },
    setGtdCountryArr(state,action) {
      if(!action.payload || action.payload.length === 0){
        state.gtdCountryArr = [];
        return;
      }
      let fdx = state.gtdCountryArr.findIndex(item=>item === action.payload)
      if(fdx >= 0){
        let temp = [ ...state.gtdCountryArr ]
        temp.splice(fdx, 1)
        state.gtdCountryArr = [...temp]
      }else{
        state.gtdCountryArr = [...state.gtdCountryArr,action.payload]
      }
    },
    setGtdLegend(state,action) {
      state.gtdLegend = action.payload
    },
    setGtdMapEvents(state,action) {
      state.gtdMapEvents = action.payload
    },
    setGtdYearlyEvents(state,action) {
      state.gtdYearlyEvents = action.payload
    },
    setGtdInd(state,action) {
      state.gtdInd = action.payload
    },
    setSubGtdInd(state,action) {
      state.subGtdInd = action.payload
    },
    setGtdEventSource(state, action){
      state.gtdEventSource = action.payload
    }
  }
})

export const {  
  setGtdSwitch, 
  setGtdStartYear,
  setGtdEndYear,
  setGtdCountryArr, 
  setGtdLegend,
  setGtdInd,
  setGtdMapEvents,
  setGtdYearlyEvents,
  setSubGtdInd,
  setGtdEventSource
} = gtdSlice.actions;


export default gtdSlice.reducer;