/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import './LinkWithConfirm.css'

export default function LinkWithConfirm(props) {
  /* 
    This component renders exactly like a with following difference:
      - by default link is opened in a new tab
      - before opening link a styled confirmation window pops up

    dialogContent: The text to display in the confirmation window.
  */
  const [isOpen, setIsOpen] = useState()
  const {
    dialogContent = 'Do you want to open the selected item in a new tab?',
    href = 'about:blank',
    target = '_blank',
    ...otherProps
  } = props
  const handleOnClick = (e) => {
    e.preventDefault();  // disables behavior of a-tag
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  const handleAgree = () => {
    window.open(href);
    handleClose();
  }
  const handleDisagree = () => {
    handleClose();
  }
  return (
    <>
      <a
        className='dash-more-link'
        href={href}
        onClick={handleOnClick}
        target={target}
        {...otherProps}
      >
        {props.children}
      </a>
      {/* Confirmation Dialog shows when link is clicked */}
      <Modal
        show={isOpen}
        onHide={handleClose}
        centered='true'
        className='dialog-text'
      >
        <Modal.Body className='text-center'>
          {dialogContent}
        </Modal.Body>
        <Modal.Footer className='dialog-footer'>
          <Button onClick={handleDisagree} className='dialog-no-btn rounded'>
            No
          </Button>
          <Button onClick={handleAgree} className='dialog-yes-btn rounded' autoFocus>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
