/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useState } from 'react';

import LinkWithConfirm from '../../components/LinkWithConfirm/LinkWithConfirm';

export default function ResourceItem(props) {
  const [imageIcon, setImageIcon] = useState('/images/Icon_marker.png')

  return (
    <LinkWithConfirm
      href={props.url}
      onMouseOver={() => setImageIcon('/images/Icon_marker_On.png')}
      onMouseOut={() => setImageIcon('/images/Icon_marker.png')}
      target="_blank"
    >
      <div className='dash-item-div'>
        <div className='dash-item-icon'>
          <span><img src={window.location.origin + imageIcon} alt={"Resource Item"}/></span>
        </div>
        <div className='dash-item-text'>
          <span>{props.title}</span>
        </div>
      </div>
    </LinkWithConfirm>
  );
}