/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { createSlice } from '@reduxjs/toolkit'

const filtersSlice = createSlice({
  name: 'map',
  initialState: {
    countryFilter: 'Latin America',
    languageFilter: 'en',
    countryList: [],
    uniqueCountries: {},
    searchText: '',
    gdeltArticleLanguage: '',  // this is not fully implemented
    irChallengeType: 'IUU Fishing'
  },
  reducers: {
    setCountryFilter(state, action) {
      state.countryFilter = action.payload
    },
    setLanguageFilter(state, action) {
      state.languageFilter = action.payload
    },
    setChallengeFilter(state, action) {
      state.irChallengeType = action.payload
    },

    setCountryList(state, action) {
      // sorted here, because inplace sort is mutation on redux state
      state.countryList = action.payload.sort()
    },
    setSearchText(state, action) {
      state.searchText = action.payload
      
    }
  }
})

export const { setCountryFilter, setLanguageFilter, setChallengeFilter, setCountryList, setSearchText } = filtersSlice.actions;

export default filtersSlice.reducer;