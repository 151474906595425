/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NewsItem from './NewsItem';
import { setCountryCounts } from '../../slices/mapSlice';
import { Translate } from "react-redux-i18n"

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import CircleSpinner from '../../components/CircleSpinner/CircleSpinner';

const countryMapping = {
  'United States of America': 'United States',
}

const reverse_topic_map = {
  'Illegal Logging': "illegal_logging",
  'Illegal Mining': "illegal_mining",
  'IUU Fishing': "IUUF",
  'Human Rights': "human_rights",
  'Violent Extremism': "violent_extremism",
  'Critical Infrastructure': "critical_infrastructure",
  'Cybersecurity': "cybersecurity",
  'Migration': "migration",
  'Economic Stability': "economic_stability",
  'Extreme Events': "extreme_events",
  'Health/Pandemics': "health_pandemic",
  'Violence and Arms': "toc",
  'Political Stability': "political_stability"
}

export default function DashboardNews() {
  const language = useSelector(state => state.filters.gdeltArticleLanguage)
  const query = useSelector(state => state.filters.searchText)
  const countryFilter = useSelector(state => state.filters.countryFilter)
  const irChallengeType = useSelector(state => state.filters.irChallengeType)
  const [country, setCountry] = useState(countryMapping[countryFilter] ? countryMapping[countryFilter] : countryFilter)
  const [articles, setArticles] = useState([])
  const [filteredArticles, setFilteredArticles] = useState([])
  const queryTargets = ['title']
  const [articlesBeingShown, setArticlesBeingShown] = useState(5)
  const incrementSize = 5
  const [responseFromGdelt, setResponseFromGdelt] = useState([])
  //const [responseFromHyperion, setResponseFromHyperion] = useState([])
  const [isMoreArticles, setIsMoreArticles] = useState(false)
  //const [articlesZoo, setArticlesZoo] = useState(false)
  const { promiseInProgress } = usePromiseTracker({ area: 'gdeltArticles' })

  const dispatch = useDispatch()

  const updateCountryCounts = () => {
    let updatedCounts = {}

    responseFromGdelt.map(article => {
      const key = article.sourcecountry;
      updatedCounts = {
        ...updatedCounts,
        [key]: updatedCounts[key] ? updatedCounts[key] + 1 : 1, // ternary operator
      }
    })
    dispatch(setCountryCounts(updatedCounts))
  }
  const updateArticleList = () => {
    setArticles(filteredArticles.slice(0, articlesBeingShown))
    setIsMoreArticles(filteredArticles.length > articlesBeingShown)
  }
  const resetArticleLimits = () => {
    setArticlesBeingShown(5)
  }
  const by_language = (article) => {
    // if language empty, returns true
    return !language ? true : article.language && article.language === language;
  }
  const by_country = (article) => {
    // if country empty, returns false
    return !country ? false : article.sourcecountry && article.sourcecountry === country;
  }

  const query_in_targets = (article) => {
    for (let queryTarget of queryTargets) {
      let words = String(article[queryTarget]);
      if (words.toLowerCase().indexOf(query) !== -1) {
        return true;
      }
    }
    return false;
  }
  const increase_count = () => {
    setArticlesBeingShown(articlesBeingShown + incrementSize)
  }

  useEffect(() => {
    const API_BASE = process.env.REACT_APP_API_URL;
    trackPromise(
      fetch(`${API_BASE}/gdelt/fulltext/` + reverse_topic_map[irChallengeType])
        .then(response => response.json())
        .then(data => data.articles)
        .then(setResponseFromGdelt),
        'gdeltArticles'
    )
  }, [])

  useEffect(() => {
    updateCountryCounts()
    setFilteredArticles(responseFromGdelt
      .filter(by_language)
      .filter(query_in_targets)
    )
  }, [responseFromGdelt])

  useEffect(() => {
    const API_BASE = process.env.REACT_APP_API_URL;
    var apiUrl = `${API_BASE}/gdelt/fulltext/`;
    if(irChallengeType == "Security Environment"){
      apiUrl = apiUrl + "IUUF";
    }else{
      apiUrl = apiUrl + reverse_topic_map[irChallengeType];
    }
    trackPromise(
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => data.articles)
        .then(setResponseFromGdelt),
        'gdeltArticles'
    )
  }, [irChallengeType])

  useEffect(() => {
    setFilteredArticles(responseFromGdelt
      //.filter(by_country)
      .filter(by_language)
      .filter(query_in_targets)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, language, query])

  useEffect(() => {
    updateArticleList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredArticles, articlesBeingShown])

  useEffect(() => {
    resetArticleLimits()
  }, [country])

  useEffect(() => {
    setCountry(countryMapping[countryFilter] ? countryMapping[countryFilter] : countryFilter)
  }, [countryFilter])

  return (
    <>
      <div>
        <div className='dash-article-sub-title'><Translate value="newsEventsTitle" /></div>
        {promiseInProgress ?
          <div className='dash-item-div2 spinner'>
            <CircleSpinner />
          </div> :
          articles.length > 0 ? articles.map((article,i) => {
            return (<NewsItem
              title={article.title}
              img={article.socialimage}
              url={article.url}
              country={article.sourcecountry}
            />)
          }) :
            <div className='dash-item-div'>
              <div className='dash-item-icon'>
              </div>
              <div className='dash-item-text'>
                {irChallengeType === 'IUU Fishing'
                  ? <span><Translate value="noNewsIUU" country={country} /></span>
                  : <span><Translate value="noNewsSecEnv" country={country} /></span>
                }
              </div>
            </div>
          }
          {isMoreArticles &&
            // eslint-disable-next-line
            <a className='dash-article-more-link' href="#" onClick={increase_count}>
              <Translate value="loadMoreNews" />&nbsp;
            </a>
        }
        {/*}<Link to={"/news"}>More News & Events</Link>{*/}
      </div>
    </>
  )
}