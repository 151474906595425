/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import PropTypes from 'prop-types';
import LoginModal from "./LoginModal";
import RegistrationModal from "./RegistrationModal"
import Logo from "./header/Logo"
import UserManagementBar from '../components/UserManagementBar.js'

export default class Header extends Component{
    handleSignout = (event) => {
        this.props.logoutUser();
        event.preventDefault();
    }
    renderedAuth(){
        const {
            isAuthenticated
          } = this.props;

        if (isAuthenticated){
            return(
                <Button variant="primary">
                    <div onClick={this.handleSignout}>
                    Sign Out
                    </div>
                </Button>
            );
        }

        return(
            <>
            <LoginModal
            loginUser={this.props.loginUser}
            authStatus={this.props.authStatus}
            authStatusText={this.props.authStatusText}
            />
            <RegistrationModal
            registerUser={this.props.registerUser}
            registerStatus={this.props.registerStatus}
            registerStatusText={this.props.registerStatusText}
            />
            </>
        );
    }

    render(){
        return (
    <div>
        <Row className="headerRow">
            <Col xs={2}>
                <div className="img-fluid logo">
                    <a href='http://srh.fiu.edu' target="_blank" rel={"noreferrer"}><Logo></Logo></a>
                </div>
            </Col>
            <Col>
                <Row className="headerRow-right">

                    <Col xs={8} style={{textAlign:"left"}}>
                        <div className="heading3 backMainBar" >
                            <a href='http://srh.fiu.edu' className={"backMainBarLink"} target="_blank" rel={"noreferrer"}>Back to Main SRH Site</a>
                        </div>
                    </Col>
                    
                    <Col xs={4} style={{textAlign:"right"}} className="authContainer">
                        {this.renderedAuth()}
                    </Col>

                </Row>
                
                <UserManagementBar></UserManagementBar>
               
            </Col>
        </Row>  
    </div>
    );
        }
}

Header.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    authUser: PropTypes.string,
    authStatus: PropTypes.string,
    authStatusText: PropTypes.string,
    loginUser: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    setAuthStatus: PropTypes.func.isRequired
}
//export default Header;