/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import * as types from '../actions/types';
import authReducer from './auth';
import errorsReducer from './errors';

import mapSlices from '../slices/mapSlice';
import filtersSlice from '../slices/filtersSlice';
import infographSlice from '../slices/infographSlice';
import uiSlice from '../slices/uiSlice';

import tocSlice from '../slices/toc/tocSlice';
import acledSlice from '../slices/toc/acledSlice';
import sipriSlice from '../slices/toc/sipriSlice';
import gtdSlice from '../slices/toc/gtdSlice';

import { i18nReducer } from 'react-redux-i18n';

//import testingReducer from './testing';

const appReducer = combineReducers({
  routing: routerReducer,
  auth: authReducer,
  errors: errorsReducer,
  map: mapSlices,
  filters: filtersSlice,
  infograph: infographSlice,
  ui: uiSlice,
  acled: acledSlice,
  sipri:sipriSlice,
  gtd: gtdSlice,
  toc: tocSlice,
  i18n: i18nReducer
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === types.LOGOUT_SUCCESS) {
    newState = undefined;
  }

  return appReducer(newState, action);
};

export default rootReducer;
