/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import "./News.css";
import NewsItem from "./NewsItem";
import { Row, Col } from "react-bootstrap"
import Infographs from "./Infographs"

import { Button } from "@material-ui/core"

// import { setCountryCounts } from "../../slices/mapSlice"
import { setNewsInfographData, setResetNewsFilters, resetNewsFilters } from "../../slices/infographSlice";
import { Translate } from "react-redux-i18n"
import { getCountryNameFromCode } from '../../utils/CountryLookups';
import { countryCentroidsSwitch} from '../../utils/CountryLookups_switch';
import LoadingOverlay from 'react-loading-overlay';

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import CircleSpinner from '../../components/CircleSpinner/CircleSpinner';

const topic_map = {
  "illegal_logging": 'Illegal Logging',
  "illegal_mining": 'Illegal Mining',
  "IUUF": 'IUU Fishing',
  "iuuf": 'IUU Fishing',
  "human_rights": 'Human Rights',
  "violent_extremism": 'Violent Extremism',
  "critical_infrastructure": 'Critical Infrastructure',
  "cybersecurity": 'Cybersecurity',
  "migration": 'Migration',
  "economic_stability": 'Economic Stability',
  "extreme_events": 'Extreme Events',
  "health_pandemic": 'Health/Pandemics',
  "toc": 'Violence and Arms',
  "political_stability": 'Political Stability'
}

const reverse_topic_map = {
  'Illegal Logging': "illegal_logging",
  'Illegal Mining': "illegal_mining",
  'IUU Fishing': "IUUF",
  'Human Rights': "human_rights",
  'Violent Extremism': "violent_extremism",
  'Critical Infrastructure': "critical_infrastructure",
  'Cybersecurity': "cybersecurity",
  'Migration': "migration",
  'Economic Stability': "economic_stability",
  'Extreme Events': "extreme_events",
  'Health/Pandemics': "health_pandemic",
  'Violence and Arms': "toc",
  'Political Stability': "political_stability"
}

let curOldestArticleTimestamp = '';

export default function News(props) {
  const { isLoading, setIsLoading, setCountryList, selectedCountries, setSelectedTopics, selectedTopics, selectedNewsSource } = props;
  const [articles, setArticles] = useState([]);
  const [responseFromGdelt, setResponseFromGdelt] = useState([]);
  const [responseFromHyperion, setResponseFromHyperion] = useState([]);
  const [resetNewsFilters, setResetNewsFilters] = useState([]);
  // looks like articleCounts and  numRenders were used for debugging?
  // eslint-disable-next-line
  const [articleCounts, setArticleCounts] = useState({});
  const [articlesToShow, setArticlesToShow] = useState([]);
  const [numArticles, setNumArticles] = useState(-1);
  const [numRenders, setNumRenders] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  // const [totalArticleCount, setTotalArticleCount] = useState(0);
  const { promiseInProgress } = usePromiseTracker({ area: 'hyperionArticles' })
  const dispatch = useDispatch();

  //number of articles can cause render to be slow, change the loading gif
  //until AFTER the 2nd render
  useEffect( () => {
    if(numArticles >= 1){
      setIsLoading(false);
    }
  })

  useEffect(() => {
    const API_BASE = process.env.REACT_APP_API_URL;
    fetch(`${API_BASE}/gdelt/fulltextAll`)
      .then(response => response.json())
      .then(data => data)
      .then(setResponseFromGdelt)
    setNumRenders(1);
  }, [])

  useEffect(() => {
    let updatedCounts = {}
    let updatedCounts2 = {}
    let countryList = [];
    responseFromGdelt.forEach(article => {
      const key = article.sourcecountry;
      const topic = topic_map[article.security_topic];

      if(updatedCounts2[key] && updatedCounts2[key][topic]){
        updatedCounts2[key][topic] += 1;
      }else{
        if(!updatedCounts2[key]){
          updatedCounts2[key] = {};
        }
        updatedCounts2[key][topic] = 1;
      }
      updatedCounts = {
        ...updatedCounts,
        [key]: updatedCounts[key] ? updatedCounts[key] + 1 : 1, // ternary operator
      }
      if (!countryList.includes(key)) {
        countryList.push(key);
      }
    })

    setArticleCounts(updatedCounts2);
    dispatch(setNewsInfographData(updatedCounts2));
    setCountryList(countryList);
    //setIsLoading(false);
  }, [responseFromGdelt, setCountryList]);

  useEffect(() => {
    setArticles(responseFromGdelt);
  }, [responseFromGdelt, setIsLoading])

  useEffect( () => {
    let filteredArticles = articles
      .filter(article => selectedCountries.includes('All Countries') || selectedCountries.includes(article.sourcecountry))
      .filter(article => selectedTopics.includes('All Security Topics') || selectedTopics.includes(topic_map[article.security_topic]))
    setArticlesToShow( filteredArticles.slice(0,25));

    setNumArticles(filteredArticles.length);
    filteredArticles.length > 0 ? setHasMore(true) : setHasMore(false);
  }, [articles,selectedCountries,selectedTopics])

  const fetchMoreData = () => {
    if( articlesToShow.length == numArticles){
      setHasMore(false);
      return
    }
    let filteredArticles = articles
      .filter(article => selectedCountries.includes('All Countries') || selectedCountries.includes(article.sourcecountry))
      .filter(article => selectedTopics.includes('All Security Topics') || selectedTopics.includes(topic_map[article.security_topic]))
    setArticlesToShow( filteredArticles.slice(0,articlesToShow.length+25));
  }

  //title: article.title
  //language: article.language
  //topic: IUU Fishing
  //source: article.domain
  //country: article.sourcecountry
  //date/time: article.seendate
  const renderLoadMoreButton = () => {
    return (
      <>
        <Button classes={{ label: "loadMoreButtonLabel", root: "loadMoreButton" }} onClick={fetchMoreData}>
          Load More
        </Button>
      </>);
  };

  return (
    <>
      <Row>
        <Col xs={8}>
          <div className={"newsCardContainer"} id={"newsCardContainer"}>
             {articlesToShow.length > 0 && articlesToShow
              .map((article,n) =>
                <NewsItem
                  title={article.title}
                  language={article.language}
                  topic={topic_map[article.security_topic]}
                  source={article.domain}
                  country={article.sourcecountry}
                  dateTime={article.seendate}
                  url={article.url}
                  oddEven={n % 2 === 0 ? 'cardEven' : 'cardOdd'}
                  key={"news"+n}
                />
              )}
            {articlesToShow.length == 0 && <Translate value={"noFilteredNews"}/>}
            { hasMore && renderLoadMoreButton() }
          </div>
        </Col>
        <Col xs={4}>
          <Infographs></Infographs>
        </Col>
      </Row>
    </>
  );
}