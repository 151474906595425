/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React from "react"
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import { setNewsSelectedCountries, setResetNewsFilters } from "../../slices/infographSlice";
import { Translate } from "react-redux-i18n"
import "./News.css";
import { countryCentroids } from '../../utils/CountryLookups';

const myCountryList = [];
// for (const [key, value] of Object.entries(countryCentroids)) {
//   if (value["name"] === "United States of America") {
//     myCountryList.push("United States");
//   } else {
//     myCountryList.push(value["name"]);
//   }
// }
Object.values(countryCentroids).forEach(value => {
  if (value["name"] === "United States of America") {
    myCountryList.push("United States");
  } else {
    myCountryList.push(value["name"]);
  }
})
myCountryList.sort();

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const topics = [
  'Illegal Logging',
  'Illegal Mining',
  'Political Stability',
  'Violence and Arms',
  'Health/Pandemics',
  'Extreme Events',
  'Economic Stability',
  'Migration',
  'Cybersecurity',
  'Critical Infrastructure',
  'Violent Extremism',
  'Human Rights',
  'IUU Fishing',
  'All Security Topics'
];
function getStyles(topic, selectedTopic, theme) {
  return {
    fontWeight:
      selectedTopic.indexOf(topic) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

export default function NewsFilters(props) {

  const { selectedCountries, setSelectedCountries, selectedTopics, setSelectedTopics, selectedNewsSource, setSelectedNewsSource } = props;
  const classes = useStyles();
  const theme = useTheme();
  const countries = [...myCountryList, 'All Countries'];  // Add 'All Countries' option
  // const [selectedTopics, setSelectedTopics] = React.useState(['IUU Fishing']);
  const dispatch = useDispatch();
  const handleChange = (event, child) => {
    if (child.props.value === 'All Security Topics') {
      if (event.target.value.includes('All Security Topics')) {
        setSelectedTopics(['All Security Topics'])
      } else {
        setSelectedTopics([])
      }
    } else {
      setSelectedTopics(event.target.value.filter(topic => topic !== 'All Security Topics'));
    }
  }

  /* const handleNewsSourceChange = (event) => {
    switch(event.target.value){
      case 'Hyperion':
        setSelectedNewsSource('Hyperion');
        break;
      case 'GDelt':
        setSelectedNewsSource('GDelt');
        break;
      default:
        console.log('Error setting news source');
        break;
    }
  } */

  const handleCountryChange = (event, child) => {
    // event.target.value: list after a chip selected/deselected
    // child.props.value: the actual item that was changed
    if (child.props.value === 'All Countries') {
      // handle select and deselect of 'All Countries' chip
      if (event.target.value.includes('All Countries')) {
        setSelectedCountries(['All Countries'])
        dispatch(setNewsSelectedCountries(['All Countries']));
      } else {
        setSelectedCountries([])
        dispatch(setNewsSelectedCountries([]));
      }
    } else {
      // Change selected country for the united states from "United States" to "United States of America"
      /* if(selectedNewsSource == 'Hyperion')
      {
        let tempIndex = event.target.value.indexOf("United States");
        //console.log('tempIndex ' + tempIndex);
        if(tempIndex >= 0){
          event.target.value.splice(tempIndex, 1, "United States of America");
        }
      } */
      // remove 'All Countries' whenever any country deselected
      setSelectedCountries(event.target.value.filter(country => country !== 'All Countries'));
      dispatch(setNewsSelectedCountries(event.target.value.filter(country => country !== 'All Countries')));
    }
  }

  function handleResetClick(e) {
		window.location.reload();
    //dispatch(setResetNewsFilters(true));
		//console.log('The reset was clicked.');
	}

  /* function renderSourceChangeButton(selectedNewsSource){
    switch(selectedNewsSource){
      case 'Hyperion':
        return (
          <FormControl className={classes.formControl}>
            <Button type className="news-toggle-source-button-active" value="Hyperion" onClick={handleNewsSourceChange}>
              Hyperion
            </Button>
            <Button type className="news-toggle-source-button" value="GDelt" onClick={handleNewsSourceChange}>
              GDelt
            </Button>
          </FormControl>
        );
      case 'GDelt':
        return (
          <FormControl className={classes.formControl}>
            <Button type className="news-toggle-source-button" value="Hyperion" onClick={handleNewsSourceChange}>
              Hyperion
            </Button>
            <Button type className="news-toggle-source-button-active" value="GDelt" onClick={handleNewsSourceChange}>
              GDelt
            </Button>
          </FormControl>
        );
      default:
        console.log('Error making news source change buttons');
        break;
    }
  } */

  function renderNewsSourceLabel(selectedNewsSource){
    switch(selectedNewsSource){
      /* case 'Hyperion':
        return (<span className={"newsSourceLabelHyperion"}><Translate value={"newsSourceLabelHyperion"} /></span>); */
      case 'GDelt':
        return (<span className={"newsSourceLabelGDelt"}><Translate value={"newsSourceLabelGDelt"} /></span>);
      default:
        console.log('Error making news source label');
        break;
    }
  }

  return (
    <>
      <div className={"newsFilterBar"}>
        <Row>
          <Col sm={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-multiple-chip-label">Topic</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={selectedTopics}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {topics.map((topic) => (
                  <MenuItem key={topic} value={topic} style={getStyles(topic, selectedTopics, theme)}>
                    {topic}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-multiple-country-label">Country</InputLabel>
              <Select
                labelId="demo-multiple-country-label"
                id="demo-multiple-country"
                multiple
                value={selectedCountries}
                onChange={handleCountryChange}
                input={<Input id="select-multiple-country" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country} style={getStyles(country, selectedCountries, theme)}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <FormControl className={classes.formControl}>
              <InputLabel id="demo-multiple-news-source-label">News Source</InputLabel>
              <Select
                labelId="demo-multiple-news-source-label"
                id="demo-multiple-news-source"
                value={selectedNewsSource}
                onChange={handleNewsSourceChange}
                input={<Input id="select-multiple-news-source" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {<Chip key={selected} label={selected} className={classes.chip} />}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                <MenuItem value={'Hyperion'}>Hyperion</MenuItem>
                <MenuItem value={'GDelt'}>GDelt</MenuItem>
              </Select>
            </FormControl> */}
          </Col>
          <Col sm={6} className='btnSet'>
            {/* {renderSourceChangeButton(selectedNewsSource)} */}
            <div>
              <Button type className="newsFilter-reset-button" onClick={handleResetClick}>Reset</Button>
            </div>
            <div>
              {renderNewsSourceLabel(selectedNewsSource)}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}