import { useEffect, useState } from 'react'
const API_BASE = process.env.REACT_APP_API_URL

const useCountryPoly = ( initialState ) => {
  const [ countryPoly, setCountryPoly ] = useState(initialState)

  useEffect(() => {
    fetch(`${API_BASE}/arcgis/queryCountryPoly`)
				.then(result => result.json())
				.then(data => {
					setCountryPoly(data)
				})
  }, [])

  return [
    countryPoly, setCountryPoly
  ]
}

export default useCountryPoly