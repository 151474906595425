/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import { setSearchText } from '../../slices/filtersSlice';

export default function SearchBar() {
  const dispatch = useDispatch();
  const value = useSelector(state => state.filters.searchText)

  const onChange = (event) => {
    dispatch(setSearchText(event.target.value.toLowerCase()))
  }

  return (
    <>
      <div className="dash-article-search-holder">
        <label className="form-label dash-article-search-label"><Translate value="searchBarTitle" /></label>
        <div className="dash-article-input-holder">
          <input
            value={value}
            onChange={onChange}
            type="text"
            className="form-control p-1"
            placeholder='keyword filter'
          />
        </div>

      </div>
    </>
  );
}
