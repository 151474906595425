/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import Loader from 'react-loader-spinner';

export default function CircleSpinner({height, width}) {
  return (
    <Loader
      type='Oval'
      color='#081e3f'
      height={height}
      width={width}
    />
  )
}