import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from '../../../../components/Slider/Slider'
import Radio from '../../../../components/Radio/Radio'
import TocUtils from '../../../../utils/toc'
import { setIsLoading } from "../../../../slices/mapSlice"
import {
	setSipriInd, setSipriStartYear,
	setSipriEndYear, setSipriXportEventSource, setSipriImportMapEvent, setSipriExportMapEvent
} from '../../../../slices/toc/sipriSlice';
import { dateRangeForSipri } from '../../../../utils/DateRangeForSlider';

const { SipriUtil } = TocUtils

function Sipri(props) {
	const { countryCode } = props;
	const sipriInd = useSelector(state => state.sipri.sipriInd);
	const sipriCountry = useSelector(state => state.sipri.sipriCountry);
	const sipriStartYear = useSelector(state => state.sipri.sipriStartYear);
	const sipriEndYear = useSelector(state => state.sipri.sipriEndYear);
	const dispatch = useDispatch()
	const API_BASE = process.env.REACT_APP_API_URL

	const [dateRange, setDateRange] = useState([]);

	useEffect(()=>{
		let country=`SOVEREIGNT='Brazil'`;
		let api=`${API_BASE}/arcgis/querySipriImportEvent/${country}`
		fetch(api)
		.then(response=>response.json())
		.then(res=>{
			setDateRange(dateRangeForSipri(res));
		})
	},[])

	const slider_result = (start, end) => {
		dispatch(setSipriStartYear(start))
		dispatch(setSipriEndYear(end))
	}

	useEffect(() => {
		if(!sipriStartYear || !sipriEndYear || !countryCode || countryCode?.size === 0){
			return
		}
		let whereStr = `(YEAR between ${sipriStartYear} and ${sipriEndYear})`
		if (sipriInd === 'import') {
			fetch(`${API_BASE}/arcgis/querySipriImportEvent/${whereStr}`)
				.then(result => result.json())
				.then(res => {
					let data = SipriUtil.processFeatures(res, countryCode, 'TotalImport')
					const map = SipriUtil.getCountryMap(data)
					dispatch(setSipriXportEventSource(data))
					dispatch(setSipriImportMapEvent(SipriUtil.getCountryFeatures(map)))
				})
		} else if (sipriInd==='export') {
			fetch(`${API_BASE}/arcgis/querySipriExportEvent/${whereStr}`)
				.then(result => result.json())
				.then(res => {
					let data = SipriUtil.processFeatures(res, countryCode, 'TotalExport')
					const map = SipriUtil.getCountryMap(data)
					dispatch(setSipriXportEventSource(data))
					dispatch(setSipriExportMapEvent(SipriUtil.getCountryFeatures(map)))
				})
		}
	}, [sipriInd, sipriStartYear, sipriEndYear, countryCode])

	return (
		<div className="mapLayerContent">
			<div className="mapLayerTitle_Year">Select Year</div>
			<div className="mapLayerContentHr">
				{dateRange && dateRange.length > 0 && 
					<Slider
						data={dateRange}
						range={{ draggableTrack: true }}
						step={1}
						defaultValue={[0, dateRange.length - 1]}
						result={slider_result}
					/>
				}
			</div>
			<div className="importOrExport">
				<div className="import">
					<Radio
						name="importOrExport"
						checked={sipriInd === 'import'}
						scale="0.8"
						onChange={() => {
							dispatch(setSipriInd('import'))
						}}
					/>
					<div>
						<div>Imports</div>
						<div className="money">(Millions US Dollars)</div>
					</div>
				</div>
				<div className="export">
					<Radio
						name="importOrExport"
						checked={sipriInd=== 'export'}
						scale="0.8"
						onChange={() => {
							dispatch(setSipriInd('export'))
							dispatch(setIsLoading({isLoading :true}))
						}}
					/>
					<div>
						<div>Exports</div>
						<div className="money">(Millions US Dollars)</div>
					</div>
				</div>
			</div>
		</div>
	)
}


export default Sipri
