/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux'
//import { Route, Switch, Redirect } from 'react-router-dom';

//import PrivateRoute from './PrivateRoute';
import App from './App';


const Root = (props) => {
  const { store } = props;

  return (
    <Provider store={store}>
      <App>
      </App>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired
};

Root.displayName = 'Root';

export default Root;
