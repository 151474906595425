const fields = [
    {
      "name" : "OBJECTID", 
      "type" : "esriFieldTypeOID", 
      "alias" : "OBJECTID", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : false, 
      "editable" : false, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SOVEREIGNT", 
      "type" : "esriFieldTypeString", 
      "alias" : "SOVEREIGNT", 
      "sqlType" : "sqlTypeOther", 
      "length" : 32, 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "YEAR", 
      "type" : "esriFieldTypeSmallInteger", 
      "alias" : "YEAR", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "MONTHNR", 
      "type" : "esriFieldTypeSmallInteger", 
      "alias" : "MONTHNR", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "E1_Battles", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Battles", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE1_1_Armedclash", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Armed clash", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE1_2_Governmentregainsterritor", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Government regains territory", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE1_3_Nonstateactorovertakester", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Non-state actor overtakes territory", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "E2_Explosions", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Explosions", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE2_1_Airdronestrike", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Air/drone strike", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE2_2_Chemicalweapon", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Chemical weapon", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE2_3_Grenade", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Grenade", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE2_4_RemoteexplosivelandmineIE", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Remote explosive/landmine/IED", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE2_5_Shellingartillerymissilea", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Shelling/artillery/missile attack", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE2_6_Suicidebomb", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Suicide bomb", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "E3_Protests", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Protests", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE3_1_Excessiveforceagainstprot", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Excessive force against protesters", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE3_2_Peacefulprotest", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Peaceful protest", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE3_3_Protestwithintervention", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Protest with intervention", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "E4_Riots", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Riots", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE4_1_Mobviolence", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Mob violence", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE4_2_Violentdemonstration", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Violent demonstration", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "E5_Strategicdevelopments", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Strategic developments", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE5_1_Agreement", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Agreement", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE5_2_Arrests", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Arrests", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE5_3_Changetogroupactivity", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Change to group/activity", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE5_4_Disruptedweaponsuse", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Disrupted weapons use", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE5_5_Headquartersorbaseestabli", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Headquarters or base established", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE5_6_Lootingpropertydestructio", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Looting/property destruction", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE5_7_Nonviolenttransferofterri", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Non-violent transfer of territory", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE5_8_Other", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Other", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "E6_Violenceagainstcivilians", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Violence against civilians", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE6_1_Abductionforceddisappeara", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Abduction/forced disappearance", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE6_2_Attack", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Attack", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }, 
    {
      "name" : "SE6_3_Sexualviolence", 
      "type" : "esriFieldTypeInteger", 
      "alias" : "Sexual violence", 
      "sqlType" : "sqlTypeOther", 
      "nullable" : true, 
      "editable" : true, 
      "domain" : null, 
      "defaultValue" : null
    }
  ]

  const coverSubEventType = (subEventType) => {
      let newSubEventType = ''
    fields.forEach(item=>{
        if(item.alias == subEventType){
            newSubEventType = item.name
        }
    })
    return newSubEventType
  }
  const cover_sub_event_type = (subEventType) => {
    let newSubEventType = ''
    fields.forEach(item=>{
      if(item.name == subEventType){
        newSubEventType = item.name
      }
    })
    return newSubEventType
  }

  export default coverSubEventType