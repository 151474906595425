import React, { useEffect, useState, useCallback } from "react";
import LineChart from './LineChart';
import { useSelector } from 'react-redux';
import TocUtils from "../../../utils/toc";

const { GtdUtil } = TocUtils

function GtdInfo(){
  const gtdStartYear = useSelector(state => state.gtd.gtdStartYear);
  const gtdEndYear = useSelector(state => state.gtd.gtdEndYear);
  const gtdCountryArr = useSelector(state => state.gtd.gtdCountryArr);
  const gtdEventSource = useSelector(state => state.gtd.gtdEventSource);
  const gtdInd = useSelector(state => state.gtd.gtdInd);
  const gtdSwitch = useSelector(state => state.gtd.gtdSwitch);
  const subGtdInd = useSelector(state => state.gtd.subGtdInd);
  const panelState = useSelector(state => state.infograph.openLeftPanel)

  const [ infoData, setInfoData ] = useState([])

  useEffect(() => {
    if(!gtdCountryArr || gtdCountryArr.length === 0 || !gtdEventSource){
      return
    }
    let map = GtdUtil.getCountryMap(gtdEventSource, gtdStartYear, gtdEndYear, subGtdInd)  
    setInfoData(GtdUtil.getCountryByYear(gtdCountryArr, map))
  }, [ gtdCountryArr, gtdEventSource, gtdStartYear, gtdEndYear, subGtdInd])

  

  const Chart = useCallback(() => {
    if(!infoData || infoData.length === 0 || gtdSwitch || !gtdStartYear || 
        !gtdEndYear || !gtdCountryArr || gtdCountryArr.length === 0 || !panelState){
      return null
    }
    const lineYear = []
    for(let i=parseInt(gtdStartYear); i<=parseInt(gtdEndYear); i++){
      lineYear.push(i)
    }
    const chartData = gtdCountryArr.map(country=>{
      const findctry = infoData.find(it=>it.SOVEREIGNT === country)
      const vals = []
      if(!findctry || !findctry.yearly || findctry.yearly.length === 0){
        return
      }
      lineYear.forEach(year => {
        let findit = findctry.yearly.find(it=>it.YEAR === year)
        vals.push( findit ? GtdUtil.getSumFromProperty(findit, subGtdInd) : 0)
      })
      return {
        name: country,
        type: 'line',
        data: vals
      }
    })
    return (<div className='lineChart'>
              <p>{ GtdUtil.getEventTypeTitle(gtdInd, 1) }</p>
              <LineChart
                width="98%"
                height='200px'
                chartData={chartData}
                chartDate={lineYear}
              />
            </div>)
  }, [infoData, gtdSwitch, gtdStartYear, gtdEndYear, gtdCountryArr, gtdInd, subGtdInd, panelState ])

  if(!!gtdSwitch){
    return null
  }

  return (
    <>
      {
        infoData && infoData.length > 0 && infoData.map((item, ind) => {
          return (
            <span className={'tocModalIcon'} key={ind}>
              <div className="tocModalIconHeader">
                <div className="countryBox">
                  {item?.properties?.SOVEREIGNT}
                </div>
                {ind === 0 && <div className="dateBox">{gtdStartYear} - {gtdEndYear}</div>}
              </div>
              <div className="toc-sep"></div>
              <div className="event_type">
                <span className="event_type_left">{item?.properties?.sum}</span>
                <span className="event_type_right">{ GtdUtil.getEventTypeTitle(gtdInd, 1) }</span>
              </div>
              <div className="sub_event_type">
                {subGtdInd && subGtdInd.length > 0 && subGtdInd.map((v, i) => {
                  return <div className="sub_event_typeInner" key={i}>
                    <div className="sub_event_typeLeft">
                      {
                        item?.properties && item?.properties[v]
                      }
                    </div>
                    <div className="sub_event_typeRight">{GtdUtil.getEventTypeTitle(v, 2)}</div>
                  </div>
                })
                }
              </div>
            </span>
          )
        })
      }
      <Chart />
    </>
  )
}

export default GtdInfo