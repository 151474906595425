/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


import {createSlice} from '@reduxjs/toolkit'

const infographSlice = createSlice({
  name: 'infograph',
  initialState: {
    data: {},
    updateInfograph: false,
    openLeftPanel:false,
    openRightPanel:true,
   // pulseData: {},
  },
  reducers: {
    setInfographData(state, action){
      state.data = action.payload;
    },
    setUpdateInfograph(state, action) {
      state.updateInfograph = action.payload
    },
   /* setMyPulsehData(state, action){
      state.pulseData = action.payload;
    },*/
    setNewsInfographData(state, action)
    {
      let retVal = [];
      for( const [key,value] of Object.entries(action.payload))
      {
        let tmp = {};
        let total = 0;
        tmp.countryName=key;
        tmp.data = {};
        for( const [topic, count] of Object.entries(value))
        {
          if(topic !== "total") 
          {
            total += count;
            tmp.data[topic] = count;
          }
        }
        tmp.total = total;
        retVal.push(tmp);
      }

      retVal = retVal.sort(function(a,b)
      {
        return b.total - a.total;
      });
      state.newsData = retVal;
    },
    setNewsSelectedCountries(state,action){
      state.newsSelectedCountries = action.payload;
    },
    setResetNewsFilters(state, action) {
      state.resetNewsFilters = action.payload
    },
    setOpenLeftPanel(state,action){
      state.openLeftPanel=action.payload;
    },
    setOpenRightPanel(state,action){
      state.openRightPanel=action.payload;
    }
  }
})

export const { setInfographData, setUpdateInfograph, setNewsInfographData, setNewsSelectedCountries, resetNewsFilters, setResetNewsFilters,setMyPulsehData,setOpenLeftPanel,setOpenRightPanel } = infographSlice.actions;

export default infographSlice.reducer;